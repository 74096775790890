// 中文
exports.default = {
  el: {
      colorpicker: {
          confirm: '확인하다',
          clear: '분명한'
      },
      datepicker: {
          now: '지금',
          today: '오늘',
          cancel: '취소',
          clear: '분명한',
          confirm: '확인하다',
          selectDate: '날짜 선택',
          selectTime: '시간 선택',
          startDate: '시작일',
          startTime: '시작 시간',
          endDate: '종료일',
          endTime: '종료 시간',
          prevYear: '전년도',
          nextYear: '내년',
          prevMonth: '지난 달',
          nextMonth: '다음 달',
          year: '년도',
          month1: '1월',
          month2: '2월',
          month3: '3월',
          month4: '4월',
          month5: '5월',
          month6: '6월',
          month7: '칠월',
          month8: '팔월',
          month9: '구월',
          month10: '십월',
          month11: '십일월',
          month12: '12월',
          weeks: {
              sun: '낮',
              mon: '하나',
              tue: '둘',
              wed: '삼',
              thu: '4개',
              fri: '다섯',
              sat: '육'
          },
          months: {
              jan: '1월',
              feb: '2월',
              mar: '3월',
              apr: '4월',
              may: '5월',
              jun: '6월',
              jul: '칠월',
              aug: '팔월',
              sep: '구월',
              oct: '십월',
              nov: '십일월',
              dec: '12월'
          }
      },
      select: {
          loading: '로드 중',
          noMatch: '일치하는 데이터가 없습니다.',
          noData: '정보 없음',
          placeholder: '선택 해주세요'
      },
      cascader: {
          noMatch: '일치하는 데이터가 없습니다.',
          loading: '로드 중',
          placeholder: '선택 해주세요',
          noData: '정보 없음'
      },
      pagination: {
          goto: '이동',
          pagesize: '항목/페이지',
          total: '총 {total}개 항목',
          pageClassifier: '페이지'
      },
      messagebox: {
          title: '힌트',
          confirm: '결정하다',
          cancel: '취소',
          error: '입력한 정보가 요구사항을 충족하지 않습니다!'
      },
      upload: {
          deleteTip: '삭제하려면 삭제 키를 누르세요.',
          delete: '삭제',
          preview: '사진 보기',
          continue: '계속해서 업로드하세요'
      },
      table: {
          emptyText: '아직 정보가 없습니다',
          confirmFilter: '필터',
          resetFilter: '초기화',
          clearFilter: '모두',
          sumText: '합집합'
      },
      tree: {
          emptyText: '아직 정보가 없습니다'
      },
      transfer: {
          noMatch: '일치하는 데이터가 없습니다.',
          noData: '정보 없음',
          titles: [
              '목록 1',
              '목록 2'
          ],
          filterPlaceholder: '키워드 입력',
          noCheckedFormat: '{total}개 ​​항목',
          hasCheckedFormat: '{checked}/{total} 확인됨'
      },
      image: {
          error: '로드 실패'
      },
      pageHeader: {
          title: '반품'
      },
      popconfirm: {
          confirmButtonText: '예',
          cancelButtonText: '아니요'
      },
      empty: {
          description: '아직 정보가 없습니다'
      }
  }
}
