// 中文
exports.default = {
  el: {
      colorpicker: {
          confirm: 'Bevestigen',
          clear: 'Duidelijk'
      },
      datepicker: {
          now: 'nu',
          today: 'Vandaag',
          cancel: 'Annuleren',
          clear: 'Duidelijk',
          confirm: 'Bevestigen',
          selectDate: 'Selecteer een datum',
          selectTime: 'Selecteer tijd',
          startDate: 'begin datum',
          startTime: 'starttijd',
          endDate: 'einddatum',
          endTime: 'eindtijd',
          prevYear: 'Vorig jaar',
          nextYear: 'volgend jaar',
          prevMonth: 'vorige maand',
          nextMonth: 'volgende maand',
          year: 'Jaar',
          month1: 'Januari',
          month2: 'Februari',
          month3: 'Maart',
          month4: 'april',
          month5: 'Kunnen',
          month6: 'juni',
          month7: 'juli',
          month8: 'augustus',
          month9: 'september',
          month10: 'oktober',
          month11: 'November',
          month12: 'December',
          weeks: {
              sun: 'dag',
              mon: 'een',
              tue: 'twee',
              wed: 'drie',
              thu: 'Vier',
              fri: 'vijf',
              sat: 'zes'
          },
          months: {
              jan: 'Januari',
              feb: 'Februari',
              mar: 'Maart',
              apr: 'april',
              may: 'Kunnen',
              jun: 'juni',
              jul: 'juli',
              aug: 'augustus',
              sep: 'september',
              oct: 'oktober',
              nov: 'November',
              dec: 'December'
          }
      },
      select: {
          loading: 'Bezig met laden',
          noMatch: 'Geen overeenkomende gegevens',
          noData: 'Geen informatie',
          placeholder: 'Selecteer alstublieft'
      },
      cascader: {
          noMatch: 'Geen overeenkomende gegevens',
          loading: 'Bezig met laden',
          placeholder: 'Selecteer alstublieft',
          noData: 'Geen informatie'
      },
      pagination: {
          goto: 'Ga naar',
          pagesize: 'Artikel/pagina',
          total: '{total} artikelen in totaal',
          pageClassifier: 'bladzijde'
      },
      messagebox: {
          title: 'hint',
          confirm: 'bepalen',
          cancel: 'Annuleren',
          error: 'De ingevulde gegevens voldoen niet aan de eisen!'
      },
      upload: {
          deleteTip: 'Druk op de delete-toets om te verwijderen',
          delete: 'verwijderen',
          preview: 'Bekijk foto s',
          continue: 'Ga door met uploaden'
      },
      table: {
          emptyText: 'Nog geen informatie',
          confirmFilter: 'Filter',
          resetFilter: 'opnieuw instellen',
          clearFilter: 'alle',
          sumText: 'Som'
      },
      tree: {
          emptyText: 'Nog geen informatie'
      },
      transfer: {
          noMatch: 'Geen overeenkomende gegevens',
          noData: 'Geen informatie',
          titles: [
              'Lijst 1',
              'Lijst 2'
          ],
          filterPlaceholder: 'Voer het trefwoord in',
          noCheckedFormat: '{total} artikelen',
          hasCheckedFormat: '{checked}/{total} aangevinkt'
      },
      image: {
          error: 'Laden mislukt'
      },
      pageHeader: {
          title: 'opbrengst'
      },
      popconfirm: {
          confirmButtonText: 'Ja',
          cancelButtonText: 'Nee'
      },
      empty: {
          description: 'Nog geen informatie'
      }
  }
}