<template>
    <div>

        <el-card class="top-card">

            <div class="second-div2">
                <div>
                    <div class="label-format">{{ $t('companyMessage.adminPwd') }}</div>
                </div>
                <el-button @click="editPwdDialogVisible = true" class="button-format">
                    <span class="button-font">{{ $t('companyMessage.button.Edit') }}</span>
                </el-button>
            </div>

            <el-divider></el-divider>

            <div class="second-div2">
                <div>
                    <div class="label-format">{{ $t('companyMessage.adminUserName') }}</div>
                </div>
                <div>
                    <span class="val-format val-position">
                        {{ adminUserName }}
                    </span>
                    <el-button @click="editNameDialogVisible = true" class="button-format">
                        <span class="button-font">{{ $t('companyMessage.button.Edit') }}</span>
                    </el-button>
                </div>
            </div>

            <div class="label-explain-format">{{ $t('companyMessage.adminExplain') }}</div>

            <el-divider></el-divider>


            <div class="second-div2">
                <div>
                    <div class="label-format">{{ $t('companyMessage.adminEmail') }}</div>
                </div>
                <div>
                    <span class="val-format val-position">
                        {{ adminEmail }}
                    </span>
                    <el-button @click="editEmailDialogVisible = true" class="button-format">
                        <span class="button-font">{{ $t('companyMessage.button.Edit') }}</span>
                    </el-button>
                </div>
            </div>

            <el-divider></el-divider>

        </el-card>

        <el-card class="bottom-card">

            <div class="second-div2">
                <div>
                    <div class="label-format">{{ fullName }}</div>
                </div>
                <div>
                    <span class="val-position val-format">
                        {{ companyFullName }}
                    </span>
                    <el-button @click="editCompanyFullNameDialogVisible = true" class="button-format">
                        <span class="button-font">{{ $t('companyMessage.button.Edit') }}</span>
                    </el-button>
                </div>
            </div>

            <el-divider></el-divider>


            <div class="second-div2">
                <div>
                    <div class="label-format">{{ simpleNameCn }}</div>
                </div>
                <div>
                    <span class="val-format val-position">
                        {{ companySimpleNameCn }}
                    </span>
                    <el-button @click="editCompanyAbbreviationCnDialogVisible = true" class="button-format">
                        <span class="button-font">{{ $t('companyMessage.button.Edit') }}</span>
                    </el-button>
                </div>
            </div>

            <div class="label-explain-format">{{ $t('companyMessage.companySimpleExplain') }}</div>

            <el-divider></el-divider>

            <div class="second-div2" v-show="companySimpleNameEnShow">
                <div>
                    <div class="label-format">{{ simpleNameEn }}</div>
                </div>
                <div>
                    <span class="val-format val-position">
                        {{ companySimpleNameEn }}
                    </span>
                    <el-button @click="editCompanyAbbreviationEnDialogVisible = true" class="button-format">
                        <span class="button-font">{{ $t('companyMessage.button.Edit') }}</span>
                    </el-button>
                </div>
            </div>

            <div class="label-explain-format">{{ $t('companyMessage.companySimpleExplain') }}</div>

            <el-divider></el-divider>

            <div class="second-div2">
                <div>
                    <div class="label-format">{{ website }}</div>
                </div>
                <div>
                    <span class="val-format val-position">
                        {{ companyWebsite }}
                    </span>
                    <el-button @click="editWebsiteDialogVisible = true" class="button-format">
                        <span class="button-font">{{ $t('companyMessage.button.Edit') }}</span>
                    </el-button>
                </div>
            </div>

            <el-divider></el-divider>

            <div class="second-div2">
                <div>
                    <div class="label-format">{{ mnumber }}</div>
                </div>
                <div>
                    <span class="val-format val-position">{{ companyNumber }}</span>
                </div>
            </div>

            <el-divider></el-divider>

            <div class="second-div2">
                <div>
                    <div class="label-format">{{ $t('companyMessage.companyNoAuditJoin') }}</div>
                </div>
                <div>
                    <span class="val-format val-position">
                        {{ companyNoAuditJoin }}
                    </span>
                    <el-button @click="editNoAuditJoinDialogVisible = true" class="button-format">
                        <span class="button-font">{{ $t('companyMessage.button.Edit') }}</span>
                    </el-button>
                </div>
            </div>

            <el-divider></el-divider>

        </el-card>

        <el-dialog :title="$t('companyMessage.dialog.editpwd.title')" :visible.sync="editPwdDialogVisible" width="20%"
            @close="closeEditPwdDialog" center>

            <el-form :model="editPwdForm" status-icon :rules="editPwdRules" ref="editPwdForm" label-position="top">

                <el-form-item :label="$t('companyMessage.dialog.editpwd.label.oldPwd')" prop="oldPwd">
                    <el-input v-model="editPwdForm.oldPwd"
                        :placeholder="$t('companyMessage.dialog.editpwd.inputPlaceholder.oldPwd')" autocomplete="off"
                        show-password></el-input>
                </el-form-item>

                <el-form-item :label="$t('companyMessage.dialog.editpwd.label.newPwd')" prop="newPwd">
                    <el-input v-model="editPwdForm.newPwd"
                        :placeholder="$t('companyMessage.dialog.editpwd.inputPlaceholder.newPwd')" autocomplete="off"
                        show-password></el-input>
                </el-form-item>

                <el-form-item :label="$t('companyMessage.dialog.editpwd.label.reNewPwd')" prop="reNewPwd">
                    <el-input v-model="editPwdForm.reNewPwd"
                        :placeholder="$t('companyMessage.dialog.editpwd.inputPlaceholder.reNewPwd')" autocomplete="off"
                        show-password></el-input>
                </el-form-item>

            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" @click="closeEditPwdDialog">{{ $t('button.cancel') }}</el-button>
                <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" type="primary" @click="editPwd">{{ $t('button.ok') }}</el-button>
            </span>

        </el-dialog>

        <el-dialog :title="$t('companyMessage.dialog.editName.title')" :visible.sync="editNameDialogVisible" width="20%"
            @close="closeEditNameDialog" center>

            <el-form :model="editNameForm" status-icon :rules="editNameRules" ref="editNameForm" label-position="top">

                <el-form-item :label="$t('companyMessage.dialog.editName.label.name')" prop="name">
                    <el-input v-model="editNameForm.name"
                        :placeholder="$t('companyMessage.dialog.editName.inputPlaceholder.name')"
                        autocomplete="off"></el-input>
                </el-form-item>

            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" @click="closeEditNameDialog">{{ $t('button.cancel') }}</el-button>
                <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" type="primary" @click="editName">{{ $t('button.ok') }}</el-button>
            </span>

        </el-dialog>

        <el-dialog :title="$t('companyMessage.dialog.editEmail.title')" :visible.sync="editEmailDialogVisible"
            width="20%" @close="closeEditEmailDialog" center>

            <el-form :model="editEmailForm" status-icon :rules="editEmailRules" ref="editEmailForm"
                label-position="top">

                <el-form-item :label="$t('companyMessage.dialog.editEmail.label.email')" prop="email">
                    <el-input v-model="editEmailForm.email"
                        :placeholder="$t('companyMessage.dialog.editEmail.inputPlaceholder.email')"
                        autocomplete="off"></el-input>
                </el-form-item>

            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" @click="closeEditEmailDialog">{{ $t('button.cancel') }}</el-button>
                <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" type="primary" @click="editEmail">{{ $t('button.ok') }}</el-button>
            </span>

        </el-dialog>

        <el-dialog :title="$t('companyMessage.dialog.editCompanyFullName.title')"
            :visible.sync="editCompanyFullNameDialogVisible" width="20%" @close="closeCompanyFullNameDialog" center>

            <el-form :model="editCompanyFullNameForm" status-icon :rules="editCompanyNameRules"
                ref="editCompanyFullNameForm" label-position="top">

                <el-form-item :label="$t('companyMessage.dialog.editCompanyFullName.label.name')" prop="name">
                    <el-input v-model="editCompanyFullNameForm.name"
                        :placeholder="$t('companyMessage.dialog.editCompanyFullName.inputPlaceholder.name')"
                        autocomplete="off"></el-input>
                </el-form-item>

            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" @click="closeCompanyFullNameDialog">{{ $t('button.cancel') }}</el-button>
                <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" type="primary" @click="editCompanyFullName">{{ $t('button.ok') }}</el-button>
            </span>

        </el-dialog>

        <el-dialog :title="$t('companyMessage.dialog.editCompanyAbbreviationCn.title')"
            :visible.sync="editCompanyAbbreviationCnDialogVisible" width="20%" @close="closeCompanyAbbreviationCnDialog"
            center>

            <el-form :model="editCompanyAbbreviationCnForm" status-icon :rules="editCompanyNameRules"
                ref="editCompanyAbbreviationCnForm" label-position="top">

                <el-form-item :label="$t('companyMessage.dialog.editCompanyAbbreviationCn.label.name')" prop="name">
                    <el-input v-model="editCompanyAbbreviationCnForm.name"
                        :placeholder="$t('companyMessage.dialog.editCompanyAbbreviationCn.inputPlaceholder.name')"
                        autocomplete="off"></el-input>
                </el-form-item>

            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" @click="closeCompanyAbbreviationCnDialog">{{ $t('button.cancel') }}</el-button>
                <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" type="primary" @click="editCompanyFullNameCn">{{ $t('button.ok') }}</el-button>
            </span>

        </el-dialog>

        <el-dialog :title="$t('companyMessage.dialog.editCompanyAbbreviationEn.title')"
            :visible.sync="editCompanyAbbreviationEnDialogVisible" width="20%" @close="closeCompanyAbbreviationEnDialog"
            center>

            <el-form :model="editCompanyAbbreviationEnForm" status-icon :rules="editCompanyNameRules"
                ref="editCompanyAbbreviationEnForm" label-position="top">

                <el-form-item :label="$t('companyMessage.dialog.editCompanyAbbreviationCn.label.name')" prop="name">
                    <el-input v-model="editCompanyAbbreviationEnForm.name"
                        :placeholder="$t('companyMessage.dialog.editCompanyAbbreviationCn.inputPlaceholder.name')"
                        autocomplete="off"></el-input>
                </el-form-item>

            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" @click="closeCompanyAbbreviationEnDialog">{{ $t('button.cancel') }}</el-button>
                <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" type="primary" @click="editCompanyFullNameEn">{{ $t('button.ok') }}</el-button>
            </span>

        </el-dialog>

        <el-dialog :title="$t('companyMessage.dialog.editWebsite.title')" :visible.sync="editWebsiteDialogVisible"
            width="20%" @close="closeWebsiteDialog" center>

            <el-form :model="editWebsiteForm" status-icon :rules="editWebsiteRules" ref="editWebsiteForm"
                label-position="top">

                <el-form-item :label="$t('companyMessage.dialog.editWebsite.label.website')" prop="website">
                    <el-input v-model="editWebsiteForm.website"
                        :placeholder="$t('companyMessage.dialog.editWebsite.inputPlaceholder.website')"
                        autocomplete="off"></el-input>
                </el-form-item>

            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" @click="closeWebsiteDialog">{{ $t('button.cancel') }}</el-button>
                <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" type="primary" @click="editWebsite">{{ $t('button.ok') }}</el-button>
            </span>

        </el-dialog>

        <el-dialog :title="$t('companyMessage.dialog.editNoAuditJoin.title')"
            :visible.sync="editNoAuditJoinDialogVisible" width="20%" @close="closeNoAuditJoinDialog" center>

            <el-form :model="editNoAuditJoinForm" status-icon :rules="editNoAuditJoinFormRules"
                ref="editNoAuditJoinForm" label-position="top">

                <el-form-item prop="joinFree">

                    <el-radio-group v-model="editNoAuditJoinForm.joinFree">
                        <el-radio label="true">{{ $t('companyMessage.dialog.editNoAuditJoin.radio.open') }}</el-radio>
                        <el-radio label="false">{{ $t('companyMessage.dialog.editNoAuditJoin.radio.close') }}</el-radio>
                    </el-radio-group>

                </el-form-item>

            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" @click="closeNoAuditJoinDialog">{{ $t('button.cancel') }}</el-button>
                <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" type="primary" @click="editNoAuditJoin">{{ $t('button.ok') }}</el-button>
            </span>

        </el-dialog>

    </div>
</template>

<script>
import { orgMessage } from '../js/orgMessage.js'
export default {
    mixins: [orgMessage]
}
</script>
<style src="../../assets/css/dialog.css" scoped></style>
<style src="../../assets/css/orgMessage.css" scoped></style>
