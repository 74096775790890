// 中文
exports.default = {
  el: {
      colorpicker: {
          confirm: 'Bestätigen',
          clear: 'Klar'
      },
      datepicker: {
          now: 'Jetzt',
          today: 'Heute',
          cancel: 'Stornieren',
          clear: 'Klar',
          confirm: 'Bestätigen',
          selectDate: 'Datum auswählen',
          selectTime: 'Wählen Sie die Uhrzeit aus',
          startDate: 'Startdatum',
          startTime: 'Startzeit',
          endDate: 'Endtermin',
          endTime: 'Endzeit',
          prevYear: 'Vorheriges Jahr',
          nextYear: 'nächstes Jahr',
          prevMonth: 'Im vergangenen Monat',
          nextMonth: 'nächsten Monat',
          year: 'Jahr',
          month1: 'Januar',
          month2: 'Februar',
          month3: 'Marsch',
          month4: 'April',
          month5: 'Mai',
          month6: 'Juni',
          month7: 'Juli',
          month8: 'August',
          month9: 'September',
          month10: 'Oktober',
          month11: 'November',
          month12: 'Dezember',
          weeks: {
              sun: 'Tag',
              mon: 'eins',
              tue: 'zwei',
              wed: 'drei',
              thu: 'Vier',
              fri: 'fünf',
              sat: 'sechs'
          },
          months: {
              jan: 'Januar',
              feb: 'Februar',
              mar: 'Marsch',
              apr: 'April',
              may: 'Mai',
              jun: 'Juni',
              jul: 'Juli',
              aug: 'August',
              sep: 'September',
              oct: 'Oktober',
              nov: 'November',
              dec: 'Dezember'
          }
      },
      select: {
          loading: 'Wird geladen',
          noMatch: 'Keine passenden Daten',
          noData: 'Keine Information',
          placeholder: 'Bitte auswählen'
      },
      cascader: {
          noMatch: 'Keine passenden Daten',
          loading: 'Wird geladen',
          placeholder: 'Bitte auswählen',
          noData: 'Keine Information'
      },
      pagination: {
          goto: 'Gehe zu',
          pagesize: 'Artikel/Seite',
          total: 'Insgesamt {total} Artikel',
          pageClassifier: 'Seite'
      },
      messagebox: {
          title: 'Hinweis',
          confirm: 'bestimmen',
          cancel: 'Stornieren',
          error: 'Die eingegebenen Informationen entsprechen nicht den Anforderungen!'
      },
      upload: {
          deleteTip: 'Drücken Sie zum Löschen die Entf-Taste',
          delete: 'löschen',
          preview: 'Bilder ansehen',
          continue: 'Weiter hochladen'
      },
      table: {
          emptyText: 'Noch keine Informationen',
          confirmFilter: 'Filter',
          resetFilter: 'zurücksetzen',
          clearFilter: 'alle',
          sumText: 'Summe'
      },
      tree: {
          emptyText: 'Noch keine Informationen'
      },
      transfer: {
          noMatch: 'Keine passenden Daten',
          noData: 'Keine Information',
          titles: [
              'Liste 1',
              'Liste 2'
          ],
          filterPlaceholder: 'Schlüsselwort eingeben',
          noCheckedFormat: '{total} Artikel',
          hasCheckedFormat: '{checked}/{total} überprüft'
      },
      image: {
          error: 'Das Laden ist fehlgeschlagen'
      },
      pageHeader: {
          title: 'zurückkehren'
      },
      popconfirm: {
          confirmButtonText: 'Ja',
          cancelButtonText: 'NEIN'
      },
      empty: {
          description: 'Noch keine Informationen'
      }
  }
}
