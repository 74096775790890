export default {
    language: '中文',
    serverError: '服務器異常,請聯系管理員',
    opearteSuccess: '操作成功',
    agreement: {
      agreement_1: '已閱讀並同意',
      agreement_2: '《用戶協議》',
      agreement_3: '及',
      agreement_4: '《隱私政策》',
      agreement_5: '請勾選同意協議'
    },
    login: {
      webName: 'ViiTALK企業管理平臺',
      webNameSchool: 'ViiTALK学校管理平臺',
      website: '官網：',
      tel: '電話：',
      email: '郵箱：',
      title: '企業用戶登錄',
      titleSchool: '学校用戶登錄',
      inputPlaceholder: {
        name: '請輸入用戶名/郵箱',
        pwd: '請輸入登錄密碼',
      },
      button: {
        login: '登錄',
        email: '郵箱登錄',
        account: '賬號登錄',
        register: '企業用戶註冊',
        registerSchool: '学校用戶註冊',
        pwd: '忘記密碼'
      },
      error: {
        uPwd: '用戶名或密碼錯誤',
        uLock: '帳號已被鎖定',
        uLeave: '您已離職,不能使用系統',
        uRelogin: '您已在其它地方登錄!!!',
        uReplaceLogin: '您的賬號已在其他地方登錄，您被迫下線。如果並非您本人的操作，那麽您的密碼可能已泄露，請盡快重新登錄並更改密碼',
        user_null: '請輸入用戶名',
        pwd_null: '請輸入密碼'
      }
    },
    personal: {
      companyNumber: '企業號',
      schoolNumber: '学校號',
      administrators: '管理員',
      changeLanguage: '切換語言',
      logout: '退出登錄'
    },
    companyMessage: {
      adminPwd: '管理員密碼',
      adminUserName: '管理員姓名',
      adminEmail: '管理員郵箱',
      companyFullName: '企業全稱 (法律註冊名稱)',
      companySimpleNameCn: '企業簡稱 (中文)',
      companySimpleNameEn: '企業簡稱 (英文)',
      companyWebsite: '企業網址',
      companyNumber: '企業集團號',

      schoolFullName: '学校全稱 (法律註冊名稱)',
      schoolSimpleNameCn: '学校簡稱 (中文)',
      schoolSimpleNameEn: '学校簡稱 (英文)',
      schoolWebsite: '学校網址',
      schoolNumber: '学校集團號',

      companyNoAuditJoin: '免審核加入',
      adminExplain: '管理員姓名對所有企業通訊錄成員可見',
      companySimpleExplain: '企業簡稱會顯示到Rooms設備首頁上，並對所有企業通訊錄成員可見',
      button: {
        Edit: '修改'
      },
      dialog: {
        editpwd: {
          title: '修改管理員密碼',
          label: {
            oldPwd: '舊密碼',
            newPwd: '新密碼',
            reNewPwd: '確認新密碼'
          },
          inputPlaceholder: {
            oldPwd: '請輸入舊密碼',
            newPwd: '請輸入新密碼',
            reNewPwd: '請輸入確認新密碼'
          }
        },
        editName: {
          title: '修改管理員姓名',
          label: {
            name: '新姓名'
          },
          inputPlaceholder: {
            name: '請輸入新姓名'
          }
        },
        editEmail: {
          title: '修改管理員郵箱',
          title2: '您尚未綁定郵箱，請及時綁定郵箱',
          label: {
            email: '新郵箱'
          },
          inputPlaceholder: {
            email: '請輸入新郵箱'
          }
        },
        editCompanyFullName: {
          title: '修改企業全稱',
          label: {
            name: '新全稱'
          },
          inputPlaceholder: {
            name: '請輸入新全稱'
          }
        },
        editCompanyAbbreviationCn: {
          title: '修改企業簡稱 (中文)',
          label: {
            name: '新簡稱'
          },
          inputPlaceholder: {
            name: '請輸入新簡稱'
          }
        },
        editCompanyAbbreviationEn: {
          title: '修改企業簡稱 (English)'
        },
        editWebsite: {
          title: '修改企業網址',
          label: {
            website: '新網址'
          },
          inputPlaceholder: {
            website: '請輸入新網址'
          }
        },
        editNoAuditJoin: {
          title: '修改免申請加入開關',
          radio: {
            open: '打開',
            close: '關閉'
          }
        }
      },
      validator: {
        value_null: '值不能為空',
        oldPwd: {
          null: '請輸入舊密碼'
        },
        newPwd: {
          length: '請設置6位或以上長度密碼'
        },
        reNewPwd: {
          consistent: '兩次輸入密碼不一致!'
        },
        name: {
          length: '名稱長度不可大於20位'
        },
        email: {
          rule: '郵箱格式不正確'
        },
        NoAuditJoin: {
          null: '請選擇項'
        }
      }
    },
    register: {
      step_1: '用戶名和密碼',
      step_2: '填寫企業信息',
      step_3: '完成',
      head: '企業用戶註冊',
      usernameError: '會員名為6-20個字符，不包含中文，以字母開頭',
      usernameError_exist: '用戶名已存在，請修改您的用戶名',
      emailError: '郵箱格式不正確',
      emailError_exist: '郵箱已存在',
      passwordError_length: '6-20個字符，只能包含字母數字以及標點符號',
      passwordError_same: '密碼不能是相同用戶名',
      passwordError: '密碼格式不正確',
      rePasswordError: '兩次輸入的密碼不一致，請重新輸入',
      companyNameError: '請輸入企業全稱',
      companyAbbreviationError: '請輸入企業簡稱',
      orgError_exist:'企業已註冊，請修改您的企業名',
      button: {
        next: '下一步',
        login: '立即登錄'
      },
      inputPlaceholder: {
        username: '用戶名',
        email: '郵箱',
        password: '設置密碼',
        rePassword: '再次輸入密碼',
        name: '企業全稱（法律註冊名稱）*',
        simpleNameCN: '企業簡稱 （中文）*',
        simpleNameEN: '企業簡稱 （英文）',
        website: '公司網址 （www）'
      },
      success: '您已成功創建企業賬號！',
      location: '所在地：',
      Scale: '規模：'
    },
    forgotPwd: {
      head: '郵箱找回',
      resetSuccess: '您已成功重置密碼！',
      inputPlaceholder: {
        code: '請輸入驗證碼',
      },
      sendCodeError: '驗證碼不正確',
      sendCodeError_sended: '驗證碼已發送請註意查收',
      button: {
        getCode: '獲取驗證碼',
        reGetCode: '重新獲取驗證碼'
      }
    },
    button: {
      ok: '確認',
      cancel: '取消',
      search: '搜索',
      clear: '清空'
    },
    device: {
      button: {
        setContent: '配置推送计划',
        setGroupMng: '设置分组管理员',
        startNow: '立即推送',
        stopNow: '停止推送',
        add: '添加設備',
        search: '搜索',
        adjust: '調整分組',
        del: '刪除'
      },
      inputPlaceholder: {
        search: '設備號碼/手機號碼/ViiTALK號碼',
      },
      group: {
        add: '添加分組',
        edit: '編輯分組',
        del: '刪除分組'
      },
      table: {
        th_name: '設備名稱',
        th_number: '設備號碼',
        th_time: '最近登錄時間',
        th_brandNumber: '會議門牌號碼',
        th_online: '是否在線',
        th_status: '状态',
        th_bindNumber: '綁定大屏號碼'
      },
      dialog: {
        addGroupTitle: '請輸入分組名稱',
        inputPlaceholder: {
          groupName: '請輸入分組名'
        },
        delGroupTitle: '刪除分組',
        delGroupTips: '確定刪除分組嗎？',
        editGroupTitle: '編輯分組',
        groupMng: {
          title: '编辑分组管理员 ',
          inputPlaceholder: {
            name: '请输入管理员名称',
          
          },
          label: {
            name: '管理员名称',
            
          }
        },
        addDevice: {
          title: '添加設備',
          inputPlaceholder: {
            name: '請輸入設備名稱',
            number: '請輸入設備號碼',
          },
          label: {
            name: '設備名稱',
            number: '設備號碼',
            group: '請選擇設備分組'
          }
        },
        delDevice: {
          title: '刪除設備',
          tips: '確定刪除設備嗎？'
        },
        adjustTitle: '調整分組',
        editDevice: {
          title: '修改設備名稱',
          inputPlaceholder: {
            name: '請輸入設備名稱'
          }
        },
        noChildren :{
          title: '警告',
          tips: '請先添加分組'
        }
      },
      validator: {
        group: {
          name_null: '分組名不能為空',
          name_length: '不能超過10個字符'
        },
        number_null: '號碼不能為空',
        number_rule: '只能為6或8開頭10位數',
        name_null: '終端名稱不能為空'
      }
    },
    user: {
      button: {
        add: '添加用戶',
      },
      inputPlaceholder: {
        search: 'ViiTALK號碼',
      },
      table: {
        th_name: '姓名',
        th_number: '手機號碼/麥哲號'
      },
      dialog: {
        addUser: {
          title: '添加用戶',
          inputPlaceholder: {
            number: '請輸入ViiTALK號碼',
          },
          label: {
            number: 'ViiTALK號碼',
            group: '請選擇用戶分組'
          }
        },
        delUser: {
          title: '刪除用戶',
          tips: '確定刪除用戶嗎？'
        },
      },
      validator: {
        number_null: '號碼不能為空',
        number_rule: '該號碼不存在'
      }
    },
    addrbook: {
      return: {
        delDevice: {
          tips_1: '要刪除的分組不存在',
          tips_2: '要刪除的分組下有子分組，不能刪除',
          tips_3: '要刪除的分組下有人員，不能刪除'
        },
        addDevice: {
          tips_1: '已添加，請查看其它分組',
          tips_2: '已被其它企業添加',
          tips_3: '該號碼不存在'
        }
      }
    },
    audit: {
      option: {
        label: {
          NoPass: '未通過',
          hasRefuse: '已拒絕',
          hasPass: '已通過',
          hasInvalid: '已失效',
          all: '全部'
        }
      },
      button: {
        pass: '通過',
        reject: '拒絕'
      },
      table: {
        th_number: '設備號',
        th_date: '日期',
        th_status: '狀態',
        th_source: '來源'
      },
      dialog: {
        passTitle: '通過審核',
        reject: {
          title: '拒絕審核',
          tips: '確定拒絕審核嗎？'
        }
      },
      api: {
        req: {
          tips_1: '不能通過審核，選中項來源不同！',
          tips_2: '請選擇需要分配的地區'
        },
        return: {
          tips_1: '不存在的設備號',
          tips_2: '您已加入企業',
          tips_3: '已添加，請查看其它分組'
        }
      }
    },
    conferenceLog: {
      inputPlaceholder: '請輸入內容',
      table: {
        th: {
          roomId: '房間號',
          state: '房間狀態',
          username: '創建者jid',
          createTime: '會議創建時間',
          closeTime: '會議結束時間',
          duration: '持續時長'
        }
      },
      dialog: {
        title: '成員加入/退出房間日誌記錄',
        table: {
          th: {
            username: '創建者jid',
            joinTime: '加入',
            leaveTime: '離開',
            duration: '持續時長'
          }
        }
      }
    },
    callLog: {
      table: {
        th:{
          callerMzNum: '主叫號碼',
          callTime: '撥打時間',
          endTime: '結束時間',
          duration: '持續時長',
          responderMzNum: '被叫號碼'
        }
      }
    },
    roomStatus: {
      table: {
        th:{
          roomMessage: '房間基本信息',
          Attendees: '參會者',
          bystander: '旁觀者',
          join: '待加入',
          exited: '已退出'
        }
      },
      roomNumber: '會議室號',
      name: '會議室名稱',
      dissolution: '解散',
      pattern: '模式',
      confluence: '合流',
      forward: '轉發',
      createdTime: '創建時間',
      limit: '發布/總人數限製',
      createMode: '創建模式',
      order: '預約(會議)',
      simple: '普通多人',
      type: '會議類型',
      creator: '創建者',
      host: '主持:',
      windowMode: '窗口模式:',
      WindowMode1: '一大多小',
      WindowMode2: '平铺',
      hide: '是否隱藏:',
      forbidden: '是否禁麥::',
      inorder: '順序:',
      source: '來源:',
      joinTime: '加入時間:',
      comeOut: '請出',
      openMic: '打開麥克風',
      closeMic: '關閉麥克風',
      leave: '離開:',
      peopleNumber: '人數:'
    },
    menu: {
      index: '首頁',
      device_manage: '設備管理',
      user_manage: '用戶管理',
      conference_brand: '會議門牌',
      audit_manage: '審核管理',
      orgMessage: '企業信息',
      schoolMessage: '学校信息',
      roomStatus: '進行中的會議',
      p2pCallLog: '視頻通話記錄',
      conferenceLog: '會議記錄',
      authorizedMessage: '授權信息',
      title: '企業管理平臺',
      schooltitle: '学校管理平臺',
      account_mangae: '賬號管理',
      contacts: '集團通訊錄',
      records: '通話記錄',
      LicenseInformation: '授權信息',
      pushInfo: '信息推送',
      infoGroup: '推送分组管理'
    },
    download : '下載客戶端',
    unauthorized: {
      title: 'ViiTALK企業管理平臺（未授權）',
      span_1: '檢測到您的服務器未授權ViiTALK服務，',
      span_2: '請聯系客服獲取授權文件。',
      span_3: '（獲取授權文件需要提供您的服務器IP地址及Mac地址給客服）',
      upError: '抱歉，您上傳的文件錯誤，請重新上傳',
      chooseFile_b: '選擇文件',
      upFile_b: '上傳授權文件',
      success: {
        span_1: '授權成功',
        span_2: '正在進入企業管理平臺...',
        span_3: '(若長時間未進入請刷新瀏覽器)'
      },
      dialog:{
        title: '請修改管理員密碼',
        span: '您的管理員密碼尚未修改，請立即修改密碼',
        button: '提交'
       }
    },
    lang: {
      'zh-CN': '簡體中文',
      'zh-TW': '繁體中文',
      en: 'EngLish',
      ja: '日語',
      ko: '韓語',
      th: '泰語',
      ar: '阿拉伯語',
      bn: '孟加拉語',
      de: '德語',
      el: '希臘語',
      es: '西班牙語',
      fa: '波斯語',
      fr: '法語',
      id: '印尼語',
      it: '意大利語',
      iw: '希伯來語',
      ku: '庫爾德語',
      nl: '荷蘭語',
      pl: '波蘭語',
      pt: '葡萄牙語',
      ro: '羅馬尼亞語',
      ru: '俄語',
      tr: '土耳其語',
      vi: '越南語'
    },
    authorized: {
      org: '授權企業',
      userLimit: '用戶上限',
      roomMax: '雲會議室最大容量',
      roomCountLimit: '在線雲會議室數量上限',
      authorizedActiveDate: '私有雲授權激活日期',
      authorizedValidDate: '私有雲授權有效期至',
      button_up: '更新授權',
      upFileError: '未選擇文件'
    },
    index_title: 'ViiTalk 企業管理平臺',
    brand :{
      table:{
        th : {
          brandNumber : '門牌號碼',
          bindNumber: '綁定大屏號碼',
          onlineStatus: '在線狀態',
          lastLoginTime: '最後登錄時間'
        },
        col :{
          content: {
            onlineStatus :{
              online : '在線',
              offline : '離線'
            }
          }
        }
      }
    }
  }