export default {
  language: 'English',
  serverError: 'Server exception, please contact the administrator',
  opearteSuccess: 'success',
  agreement: {
    agreement_1: 'By submitting the form,I agree to the',
    agreement_2: 'ViiTalk’s Terms of Use',
    agreement_3: '&',
    agreement_4: 'Privacy Policy',
    agreement_5: 'Please check the consent agreement'
  },
  login: {
    webName: 'ViiTalk Enterprise Management Platform',
    webNameSchool: 'ViiTalk School Management Platform',
    website: 'WebSite：',
    tel: 'Tel：',
    email: 'Email：',
    title: 'Login to your company account',
    titleSchool: 'Login to your account',
    inputPlaceholder: {
      name: 'Please enter username/email',
      pwd: 'Please enter the login password',
    },
    button: {
      login: 'Log in',
      email: 'Email',
      account: 'Account login',
      register: 'Register a new account',
      registerSchool: 'Register a new account',
      pwd: 'Forgot Password'
    },
    error: {
      uPwd: 'Wrong user name or password',
      uLock: 'Account locked',
      uLeave: 'You have resigned and cannot use the system',
      uRelogin: 'You have logged in elsewhere!!!',
      uReplaceLogin: 'Your account has been logged in elsewhere, and you are forced to go offline. If it is not your own operation, your password may have been leaked. Please log in again and change your password as soon as possible',
      user_null: 'please enter user name',
      pwd_null: 'Please enter password'
    }
  },
  personal: {
    companyNumber: 'Company ID',
    schoolNumber: 'School ID',
    administrators: 'Administrator',
    changeLanguage: 'Language',
    logout: 'Sign Out'
  },
  companyMessage: {
    adminPwd: 'Login Password',
    adminUserName: 'Administrator Name',
    adminEmail: 'Administrator Email',
    companyFullName: 'Company Legal Name',
    companySimpleNameCn: 'Company Name Abbreviation',
    companySimpleNameEn: 'Company Name Abbreviation',
    companyWebsite: 'Company Web Address',
    companyNumber: 'Company ID',

    schoolFullName: 'School Legal Name',
    schoolSimpleNameCn: 'School Name Abbreviation',
    schoolSimpleNameEn: 'School Name Abbreviation',
    schoolWebsite: 'School Web Address',
    schoolNumber: 'School ID',

    companyNoAuditJoin: 'Join No Check',
    adminExplain: 'Administrator name is visible to all corporate address book members',
    companySimpleExplain: 'The company abbreviation will be displayed on the Rooms device homepage and visible to all members of the company address book.',
    button: {
      Edit: 'Edit'
    },
    dialog: {
      editpwd: {
        title: 'Change administrator password',
        label: {
          oldPwd: 'Old Password',
          newPwd: 'Password',
          reNewPwd: 'Confirm Password'
        },
        inputPlaceholder: {
          oldPwd: 'Please enter your old password',
          newPwd: 'Please enter a new password',
          reNewPwd: 'Please enter the confirmed new password'
        }
      },
      editName: {
        title: 'Modify Administrator Name',
        label: {
          name: 'New Name'
        },
        inputPlaceholder: {
          name: 'Please enter a new name'
        }
      },
      editEmail: {
        title: 'Modify administrator email',
        title2: 'You have not bound your email address yet. Please bind your email address in time',
        label: {
          email: 'New email'
        },
        inputPlaceholder: {
          email: 'Please enter a new email address'
        }
      },
      editCompanyFullName: {
        title: 'Modify the full name of the enterprise',
        label: {
          name: 'New full name'
        },
        inputPlaceholder: {
          name: 'Please enter the new full name'
        }
      },
      editCompanyAbbreviationCn: {
        title: 'Change the company abbreviation',
        label: {
          name: 'New abbreviation'
        },
        inputPlaceholder: {
          name: 'Please enter a new abbreviation'
        }
      },
      editCompanyAbbreviationEn: {
        title: 'Change the company abbreviation (English)'
      },
      editWebsite: {
        title: 'Modify enterprise website',
        label: {
          website: 'New website'
        },
        inputPlaceholder: {
          website: 'Please enter a new website address'
        }
      },
      editNoAuditJoin: {
        title: 'Modify the exemption from application to join switch',
        radio: {
          open: 'open',
          close: 'close'
        }
      }
    },
    validator: {
      value_null: 'Value cannot be empty',
      oldPwd: {
        null: 'Please enter your old password'
      },
      newPwd: {
        length: 'Please set a password of 6 digits or more in length'
      },
      reNewPwd: {
        consistent: 'The passwords entered twice do not match!'
      },
      name: {
        length: 'The length of the name cannot exceed 20 digits'
      },
      email: {
        rule: 'Incorrect email format'
      },
      NoAuditJoin: {
        null: 'Please select an item'
      }
    }
  },
  register: {
    step_1: 'Password',
    step_2: 'Information',
    step_3: 'Finish',
    head: 'Register Your Company Account',
    usernameError: 'The member name is 6-20 characters, excluding Chinese, and starts with a letter',
    usernameError_exist: 'User name already exists, please modify your user name',
    emailError: 'Incorrect email format',
    emailError_exist: 'Email already exists',
    passwordError_length: '6-20 characters, only alphanumeric and punctuation',
    passwordError_same: 'Password cannot be the same user name',
    passwordError: 'Incorrect password format',
    rePasswordError: 'The two passwords are inconsistent',
    companyNameError: 'Please enter the full name of the enterprise',
    companyAbbreviationError: 'Please enter the company abbreviation',
    orgError_exist:'The company has been registered, please change your business name',
    button: {
      next: 'NEXT',
      login: 'Login'
    },
    inputPlaceholder: {
      username: 'Enter your username',
      email: 'Email',
      password: 'Enter your password',
      rePassword: 'Repeat Password',
      name: 'Company Legal Name *',
      simpleNameCN: 'Company Name Abbreviation *',
      simpleNameEN: '',
      website: 'Company Web Address （www）'
    },
    success: 'Your Registration Has Been Completed Successfully!',
    location: 'Location',
    Scale: 'Total Employee Count of Your Organization'
  },
  forgotPwd: {
    head: 'Email',
    resetSuccess: 'You have successfully reset your password!',
    inputPlaceholder: {
      code: 'Verification Code',
    },
    sendCodeError: 'Incorrect verification code',
    sendCodeError_sended: 'The verification code has been sent. Please check it carefully',
    button: {
      getCode: 'Send',
      reGetCode: 'Send'
    }
  },
  button: {
    ok: 'ok',
    cancel: 'cancel',
    search: 'search',
    clear: 'clear'
  },
  device: {
    button: {
      setContent: 'Push schedule',
      setGroupMng: 'Set Group Mng',
      startNow: 'Push Now',
      stopNow: 'Stop Push',
      add: 'Add Device',
      search: 'search',
      adjust: 'Change Location',
      del: 'Delete'
    },
    inputPlaceholder: {
      search: 'Device Number/Phone Number/ViiTALK Number',
    },
    group: {
      add: 'Add Department',
      edit: 'Editing department',
      del: 'Delete Department',
    },
    table: {
      th_name: 'Device name',
      th_number: 'Device Number',
      th_time: 'Last Login Time',
      th_brandNumber: 'Conference house number',
      th_online: 'Is online',
      th_status: 'Status',
      th_bindNumber: 'Bind large screen number'
    },
    dialog: {
      addGroupTitle: 'Please enter the Department name',
      inputPlaceholder: {
        groupName: 'Please enter the group name'
      },
      delGroupTitle: 'Delete Department',
      delGroupTips: 'Are you sure to delete the Department?',
      editGroupTitle: 'Edit group',
      groupMng: {
        title: 'Edit Group Mng',
        inputPlaceholder: {
          name: 'Please enter the administrator name',
        
        },
        label: {
          name: 'Administrator Name',
          
        }
      },
      addDevice: {
        title: 'Add Device',
        inputPlaceholder: {
          name: 'Please enter the device name',
          number: 'Please enter the device number',
        },
        label: {
          name: 'Device name',
          number: 'Device Number',
          group: 'Please select device grouping'
        }
      },
      delDevice: {
        title: 'Delete device',
        tips: 'Are you sure to delete the selected device?'
      },
      adjustTitle: 'Adjust grouping',
      editDevice: {
        title: 'Modify device name',
        inputPlaceholder: {
          name: 'Please enter the device name'
        }
      },
      noChildren :{
        title: 'warn',
        tips: 'Please add a group first'
      }
    },
    validator: {
      group: {
        name_null: 'The group name cannot be empty',
        name_length: 'Cannot exceed 10 characters'
      },
      number_null: 'Number cannot be empty',
      number_rule: 'Can only start with 10 digits of 6 or 8',
      name_null: 'The device name cannot be empty'
    }
  },
  user: {
    button: {
      add: 'Add User',
    },
    inputPlaceholder: {
      search: 'ViiTalk number',
    },
    table: {
      th_name: 'Name',
      th_number: 'phone number/mzNumber'
    },
    dialog: {
      addUser: {
        title: 'Add User',
        inputPlaceholder: {
          number: 'Please enter the ViiTALK number',
        },
        label: {
          number: 'ViiTalk number',
          group: 'Please select a user group'
        }
      },
      delUser: {
        title: 'delete user',
        tips: 'Are you sure to delete the selected user?'
      },
    },
    validator: {
      number_null: 'Number cannot be empty',
      number_rule: 'The number does not exist'
    }
  },
  addrbook: {
    return: {
      delDevice: {
        tips_1: 'The group to delete does not exist',
        tips_2: 'There are sub groups under the group to be deleted, which cannot be deleted',
        tips_3: 'There are persons under the group to be deleted, which cannot be deleted'
      },
      addDevice: {
        tips_1: 'Added, please check other groups',
        tips_2: 'Added by other enterprises',
        tips_3: 'The number does not exist'
      }
    }
  },
  audit: {
    option: {
      label: {
        NoPass: 'Fail',
        hasRefuse: 'Rejected',
        hasPass: 'Passed',
        hasInvalid: 'Expired',
        all: 'all'
      }
    },
    button: {
      pass: 'Pass',
      reject: 'Refuse'
    },
    table: {
      th_number: 'Device number',
      th_date: 'date',
      th_status: 'status',
      th_source: 'source'
    },
    dialog: {
      passTitle: 'Approved',
      reject: {
        title: 'Refuse review',
        tips: 'Are you sure you want to reject the review?'
      }
    },
    api: {
      req: {
        tips_1: 'Cannot pass the review, the selected item has a different source!',
        tips_2: 'Please select the region that needs to be allocated'
      },
      return: {
        tips_1: 'Nonexistent device number',
        tips_2: 'You have joined the company',
        tips_3: 'Added, please check other groups'
      }
    }
  },
  conferenceLog: {
    inputPlaceholder: 'Please enter the content',
    table: {
      th: {
        roomId: 'Room number',
        state: 'Room status',
        username: 'CreatorJid',
        createTime: 'Meeting creation time',
        closeTime: 'Meeting end time',
        duration: 'duration'
      }
    },
    dialog: {
      title: 'Member join/Exit room logging',
      table: {
        th: {
          username: 'CreatorJid',
          joinTime: 'Join',
          leaveTime: 'Leave',
          duration: 'duration'
        }
      }
    }
  },
  callLog: {
    table: {
      th: {
        callerMzNum: 'Calling number',
        callTime: 'Dialing time',
        endTime: 'End time',
        duration: 'duration',
        responderMzNum: 'Called number'
      }
    }
  },
  roomStatus: {
    table: {
      th: {
        roomMessage: 'Basic room information',
        Attendees: 'Attendees',
        bystander: 'bystander',
        join: 'To be added',
        exited: 'Exited'
      }
    },
    roomNumber: 'Room No',
    name: 'Room name:',
    dissolution: 'Dissolution',
    pattern: 'Pattern:',
    confluence: 'Confluence',
    forward: 'Forward',
    createdTime: 'CreatedTime:',
    limit: 'Release/total population limit:',
    createMode: 'CreateMode:',
    order: 'Order(meeting)',
    simple: 'Ordinary multi person',
    type: 'RoomType:',
    creator: 'Creator:',
    host: 'Host:',
    windowMode: 'WindowMode:',
    WindowMode1: 'One mostly small',
    WindowMode2: 'Tile',
    hide: 'Hide or not:',
    forbidden: 'Whether wheat is forbidden：',
    inorder: 'Order:',
    source: 'Source:',
    joinTime: 'JoinTime:',
    comeOut: 'ComeOut',
    openMic: 'OpenMic',
    closeMic: 'CloseMic',
    leave: 'Leave:',
    peopleNumber: 'PeopleNumber：'
  },
  menu: {
    index: 'home',
    device_manage: 'Device Manager',
    user_manage: 'User Manager',
    conference_brand: 'Conference Brand',
    audit_manage: 'Audit Manager',
    orgMessage: 'Company Information',
    schoolMessage: 'School Information',
    roomStatus: 'Meeting Record',
    p2pCallLog: 'Video Call Record',
    conferenceLog: 'Ongoing Meetings',
    authorizedMessage: 'Authorization information',
    title: 'ViiTALK Enterprise Management Platform',
    schooltitle: 'School Management Platform',
    account_mangae: 'Account Manager',
    contacts: 'Contacts',
    records: 'Records',
    LicenseInformation: 'License Information',
    pushInfo: 'Info Push',
    infoGroup: 'Info Group Manager'
  },
  download: 'Download client',
  unauthorized: {
    title: 'ViiTalk Enterprise Management Platform（unauthorized）',
    span_1: 'It has been detected that your server does not authorize the ViiTALK service，',
    span_2: 'Please contact customer service to obtain the authorization file。',
    span_3: '（To obtain the authorization file, you need to provide your server IP address and Mac address to customer service）',
    upError: 'Sorry, the file you uploaded is wrong, please upload again',
    chooseFile_b: 'Select',
    upFile_b: 'upload',
    success: {
      span_1: 'Authorization successful',
      span_2: 'Entering the Enterprise Management Platform...',
      span_3: '(If you have not entered for a long time, please refresh the browser.)'
    },
    dialog: {
      title: 'Please change the administrator password',
      span: 'Your administrator password has not been changed yet, please change your password immediately',
      button: 'submit'
    }
  },
  lang: {
    'zh-CN': 'Simplified Chinese',
    'zh-TW': 'traditional Chinese',
    en: 'EngLish',
    ja: 'Japanese',
    ko: 'Korean',
    th: 'Thai',
    ar: 'Arabic',
    bn: 'Bengali',
    de: 'German',
    el: 'Greek',
    es: 'spanish',
    fa: 'Persian',
    fr: 'French',
    id: 'Indonesian',
    it: 'Italian',
    iw: 'hebrew',
    ku: 'Kurdish',
    nl: 'Dutch',
    pl: 'Polish',
    pt: 'Portuguese',
    ro: 'Romanian',
    ru: 'Russian',
    tr: 'turkish',
    vi: 'Vietnamese'
  },
  authorized: {
    org: 'Authorized enterprise',
    userLimit: 'User limit',
    roomMax: 'Maximum capacity of conference room',
    roomCountLimit: 'Maximum number of online meeting rooms',
    authorizedActiveDate: 'Private Cloud License Activation Date',
    authorizedValidDate: 'The private cloud license is valid until',
    button_up: 'Update',
    upFileError: 'No file selected'
  },
  index_title: 'ViiTalk Enterprise Management Platform',
  brand :{
    table:{
      th : {
        brandNumber : 'House number',
        bindNumber: 'Bind large screen number',
        onlineStatus: 'online status',
        lastLoginTime: 'last login time'
      },
      col :{
        content: {
          onlineStatus :{
            online : 'online',
            offline : 'offline'
          }
        }
      }
    }
  }
}