<template>
  <div class="row clearfix">
    <div class="col-md-12 column">
      <!-- 数据表格区-->
      <table class="table  table-striped table-bordered table-hover table-layout:fixed;">
        <thead>
          <tr class="success">
            <th class="center">
              {{ $t('roomStatus.table.th.roomMessage') }}
            </th>

            <th class="center" id="titlePN">
              {{ $t('roomStatus.table.th.Attendees') }}
            </th>
            <th class="center" id="titleSN">
              {{ $t('roomStatus.table.th.bystander') }}
            </th>
            <th class="center">
              {{ $t('roomStatus.table.th.join') }}
            </th>
            <th class="center">
              {{ $t('roomStatus.table.th.exited') }}
            </th>
          </tr>
        </thead>
        <tbody id="div_table_datas">

        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import { methodPost2 } from '@/api'

export default {

  data() {
    return {
      orgCode: '',
      path: 'ws://192.168.0.200:8005/qrCodePage/ID=1/refreshTime=5',
      websocket: '',
      wsUrl: 'ws://tadmin.viitalk.com:80/webSocektHandler',
      wsJsUrl: 'wss://tadmin.viitalk.com/webSocektHandler/info',
      roomStatus_RoomNumber: this.$t('roomStatus.roomNumber'),
      roomStatus_Name: this.$t('roomStatus.name'),
      roomStatus_Dissolution: this.$t('roomStatus.dissolution'),
      roomStatus_Pattern: this.$t('roomStatus.pattern'),
      roomStatus_Confluence: this.$t('roomStatus.confluence'),
      roomStatus_Forward: this.$t('roomStatus.forward'),
      roomStatus_CreatedTime: this.$t('roomStatus.createdTime'),
      roomStatus_Limit: this.$t('roomStatus.limit'),
      roomStatus_CreateMode: this.$t('roomStatus.createMode'),
      roomStatus_Order: this.$t('roomStatus.order'),
      roomStatus_Simple: this.$t('roomStatus.simple'),
      roomStatus_Type: this.$t('roomStatus.type'),
      roomStatus_Creator: this.$t('roomStatus.creator'),
      roomStatus_Host: this.$t('roomStatus.host'),
      roomStatus_WindowMode: this.$t('roomStatus.windowMode'),
      roomStatus_WindowMode1: this.$t('roomStatus.WindowMode1'),
      roomStatus_WindowMode2: this.$t('roomStatus.WindowMode2'),
      roomStatus_Hide: this.$t('roomStatus.hide'),
      roomStatus_Forbidden: this.$t('roomStatus.forbidden'),
      roomStatus_Inorder: this.$t('roomStatus.inorder'),
      roomStatus_Source: this.$t('roomStatus.source'),
      roomStatus_JoinTime: this.$t('roomStatus.joinTime'),
      roomStatus_ComeOut: this.$t('roomStatus.comeOut'),
      roomStatus_OpenMic: this.$t('roomStatus.openMic'),
      roomStatus_CloseMic: this.$t('roomStatus.closeMic'),
      roomStatus_Leave: this.$t('roomStatus.leave'),
      roomStatus_PeopleNumber: this.$t('roomStatus.peopleNumber'),
      LANGUAGE_CN_CODE: 'zh_CN',
      heartCheck: ''
    }
  },

  created() {
    const regex = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
    let currentDomain = window.location.hostname
    if (!regex.test(currentDomain)) {
      if (currentDomain.indexOf("tcloud") != -1) {
        currentDomain = "tadmin.viitalk.com"
      } else {
        currentDomain = "admin.viitalk.com"
      }
    }

    if (!regex.test(currentDomain)) {
      this.wsUrl = "wss://" + currentDomain + "/webSocektHandler"
    } else {
      this.wsUrl = "ws://" + currentDomain + ":80/webSocektHandler"
    }

    console.log(this.wsUrl)
    window.actRoomClose = this.actRoomClose
    window.actLeaveRoom = this.actLeaveRoom
    window.actCmdMic = this.actCmdMic
    window.websocket = this.websocket
    this.qryOrgCode()
  },

  mounted() {
    // 初始化
    // this.qryOrgCode()
  },

  beforeRouteLeave(to, from, next) {
    // this.disconnect()
    clearTimeout(this.timeoutObj)
    next()
  },

  methods: {

    buildwebsocket(receiveData, initFun) {
      let _this = this
      var heartCheck = {
        timeout: 30000,
        timeoutObj: null,
        serverTimeoutObj: null,
        start: function () {
          console.log('start');
          var self = this;
          this.timeoutObj && clearTimeout(this.timeoutObj);
          this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
          this.timeoutObj = setTimeout(function () {
            //这里发送一个心跳，后端收到后，返回一个心跳消息，
            _this.sendM("{'businessFlag':'roomLog','dataType':'keepalive'}")
            // self.serverTimeoutObj = setTimeout(function () {
            //     console.log(111);
            //     console.log(ws);
            //     ws.close();
            // }, self.timeout);
          }, this.timeout)
        }
      }


      if ('WebSocket' in window) {
        this.websocket = new WebSocket(this.wsUrl)
      } else if ('MozWebSocket' in window) {
        this.websocket = new WebSocket(this.wsUrl)
        // eslint-disable-next-line no-undef
        this.websocket = new MozWebSocket(this.wsUrl)
      } else {
        // eslint-disable-next-line no-undef
        this.websocket = new SockJS(this.wsJsUrl)
      }
      this.websocket.onopen = function (evnt) {
        initFun()
        heartCheck.start()
      }
      this.websocket.onmessage = function (evnt) {
        heartCheck.start()
        receiveData(evnt.data)
      }
      this.websocket.onerror = function (evnt) {

      }
      this.websocket.onclose = function (evnt) {

      }
      return this.websocket
    },

    qryOrgCode() {

      methodPost2('api/account/getCompanyInfo').then((data) => {
        if (data.status) {
          this.orgCode = data.data.orgCode;
          this.buildwebsocket(this.receiveRoomStatusLog, this.initBySocket)
        } else {
          this.$message('操作失败')
        }
      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error)
      })

    },

    initBySocket() {
      // 往 websocket服务端, 注册此cli的业务类型
      this.sendM(`{'businessFlag':'roomLogByOrg','dataType':'regOrg','orgCode':'${this.orgCode}'}`)
      // 请求当前企业的多人信息
      this.sendM(`{'businessFlag':'roomLog','dataType':'businessData','businessData':{'action':'reqRoomsByOrg','orgCode':'${this.orgCode}'}}`)
    },

    actRoomClose(roomId) {
      this.sendM(`{'businessFlag':'roomLog','dataType':'businessData','businessData':{'action':'actRoomClose','roomId':'${roomId}'}}`)
    },

    actLeaveRoom(roomId, number) {
      this.sendM(`{'businessFlag':'roomLog','dataType':'businessData','businessData':{'action':'actLeaveRoom','roomId':'${roomId}','number':'${number}'}}`)
    },

    actCmdMic(roomId, number) {
      this.sendM(`{'businessFlag':'roomLog','dataType':'businessData','businessData':{'action':'actCmdMic','roomId':'${roomId}','number':'${number}'}}`)
    },

    disconnect() {
      if (this.websocket != null) {
        this.websocket.close()
      }
    },

    sendM(message) {
      if (this.websocket != null) {
        this.websocket.send(message)
      }
    },

    receiveRoomStatusLog(roomLog) {
      console.info(roomLog)
      if (!roomLog || roomLog == '' || roomLog == '' || roomLog == 'receive') {
        return
      }
      // eslint-disable-next-line no-eval
      const roomJ = eval(`(${roomLog})`)
      // eslint-disable-next-line no-console
      console.info(roomJ)
      const action = roomJ.action
      if (action == 'clear') {
        this.clearData()
      } else if (action == 'close') {
        const roomId = roomJ.roomId
        $(`tr[id=tr_${roomId}]`).remove()
      } else if (action == 'orgRoomChange') {
        this.writeRoomIf(roomJ.room[0])
      } else if (action == 'orgRoomList') {
        this.clearData()
        const rooms = roomJ.rooms
        for (let idx = 0; idx < rooms.length; idx++) {
          this.writeRoomIf(rooms[idx])
        }
      }
    },

    writeRoomIf(room) {
      let _this = this

      const tb = $('#div_table_datas')
      const trId = `tr_${room.roomId}`
      let tr = $(`#${trId}`)
      // 存在,则更新
      if (tr.length > 0) {
        tr.html('')
      } else {// 不存在,则增加
        tb.append(`<tr id='${trId}' style=' white-space:nowrap'></tr>`)
        tr = $(`#${trId}`)
      }

      let trHtml = '<td>'
      trHtml = `${trHtml}${this.roomStatus_RoomNumber}:&nbsp;&nbsp;${room.roomId}`
      trHtml = `${trHtml}<br/>${this.roomStatus_Name}&nbsp;&nbsp;${room.roomName}&nbsp;&nbsp;<a class='btn btn-danger btn-sm'  onclick='actRoomClose("${room.roomId}")'>${this.roomStatus_Dissolution}</a>`
      trHtml = `${trHtml}<br/>${this.roomStatus_Pattern}${'union' == room.roomVersion ? `<a class='text-danger' href='#'>${this.roomStatus_Confluence}</a>` : `<a class='text-success'  href='#'>${this.roomStatus_Forward}</a>`}&nbsp;&nbsp;&nbsp;&nbsp; <a class='text-danger'  href='#'>v:${room.roomVersion}</a>`

      trHtml = `${trHtml}<br/>${this.roomStatus_CreatedTime}&nbsp;&nbsp;${this.formatDataStr(room.sessionId.substr(room.sessionId.length - 14, room.sessionId.length), this.LANGUAGE_CN_CODE)}`
      trHtml = `${trHtml}<br/>KMS:&nbsp;&nbsp;${room.kms_url}`
      trHtml = `${trHtml}<br/>kms_level:&nbsp;&nbsp;${room.kmsLevel}&nbsp;&nbsp;${this.roomStatus_Limit}${room.publisherNumMax}/${room.personNumMax}`

      trHtml = `${trHtml}<br/><br/>${this.roomStatus_CreateMode}&nbsp;&nbsp;${room.createWay == 1 ? this.roomStatus_Order : this.roomStatus_Simple}`
      trHtml = `${trHtml}<br/>${this.roomStatus_Type}&nbsp;&nbsp;${room.roomMode}`

      trHtml = `${trHtml}<br/><br/>${this.roomStatus_Creator}&nbsp;&nbsp; ${room.creator}<br/>${this.roomStatus_Host}&nbsp;&nbsp;${room.host}`

      trHtml = `${trHtml}<br/><br/>${this.roomStatus_WindowMode}&nbsp;&nbsp;${room.winMode == 0 ? this.roomStatus_WindowMode1 : this.roomStatus_WindowMode2}`
      trHtml = `${trHtml}<br/>${this.roomStatus_Hide}&nbsp;&nbsp;${room.isHideWin ? 'yes' : 'no'}`
      trHtml = `${trHtml}&nbsp;&nbsp;${this.roomStatus_Forbidden}${room.isCloseMic ? 'yes' : 'no'}`
      trHtml = `${trHtml}</td>`

      // eslint-disable-next-line no-undef
      // const statsPage = statsPageBase + room.sessionId

      trHtml = trHtml + this.userHtml('1', 'in', room.uIn, 'publisher', room.roomId)
      trHtml = trHtml + this.userHtml('1', 'in', room.uIn, 'subscriber', room.roomId)
      trHtml = trHtml + this.userHtml('1', 'try', room.uTry, '', room.roomId)
      trHtml = trHtml + this.userHtml('1', 'out', room.uOut, '', room.roomId)

      tr.append(trHtml)
    },

    userHtml(statsPage, utype, users, idType, roomId) {
      let phNum = 0// 发布人数
      let shNum = 0// 旁观人数
      let td = ''
      for (let idx = 0; idx < users.length; idx++) {
        const u = users[idx]

        if (utype == 'in') {
          if (idType == 'subscriber') {
            if (u.idType == 'publisher' || u.idType == 'host') {
              continue
            }
            shNum++
          } else {
            if (u.idType == 'subscriber' || u.idType == 'subscriberAUDIO') {
              // eslint-disable-next-line no-continue
              continue
            }
            phNum++
          }
          td = `${td}<a href='${statsPage}/${u.number}' target='_blank' title='查看通话质量'>`
          td = `${td}</br>${this.roomStatus_Inorder}${u.joinIdx + 1}:&nbsp;&nbsp;&nbsp;&nbsp;${u.number}:${u.mobile}:${u.nickName}  &nbsp;&nbsp;${this.roomStatus_Source}${u.source}`

          td = `${td}</a></br>${idType == 'subscriber' ? `${u.idType}&nbsp;&nbsp;` : ''}${this.roomStatus_JoinTime}&nbsp;&nbsp;${this.formatDataStr(u.joinTime, 'en').substr(10)}&nbsp;&nbsp;<a class='btn btn-warning btn-sm' onclick='actLeaveRoom("${roomId}","${u.number}")'>${this.roomStatus_ComeOut}</a>`
          if (u.isCloseMic) {
            td = `${td}&nbsp;&nbsp;<a class='btn btn-primary btn-sm' onclick='actCmdMic("${roomId}","${u.number}")'>${this.roomStatus_OpenMic}</a>`
          } else {
            td = `${td}&nbsp;&nbsp;<a class='btn btn-success btn-sm' onclick='actCmdMic("${roomId}","${u.number}")'> ${this.roomStatus_CloseMic}</a>`
          }
          td = `${td}</br>`
        } else if (utype == 'try') {
          td = `${td}</br>${u.number}`
        } else {
          td = `${td}<a href='${statsPage}/${u.number}' target='_blank' title='查看通话质量'>`
          td = `${td}</br>${u.number}:${u.mobile}:${u.nickName}  ${this.roomStatus_Source}&nbsp;&nbsp;${u.source}`
          td = `${td}</a></br>${this.roomStatus_JoinTime}&nbsp;&nbsp;${this.formatDataStr(u.joinTime, 'en').substr(10)}${this.roomStatus_Leave}${this.formatDataStr(u.leaveTime, 'en').substr(10)}`
          td = `${td}</br>`
        }

        // td += "</br>" + "isHideWin:" + u.isHideWin + " isCloseVideo:" + u.isCloseVideo + " isCloseMic:" + u.isCloseMic
      }
      // td += "</td>";

      let tdhtml = '<td>'

      if (phNum > 0) {
        tdhtml = `${tdhtml}${this.roomStatus_PeopleNumber + phNum}</br>`
      }
      if (shNum > 0) {
        tdhtml = `${tdhtml}${this.roomStatus_PeopleNumber + shNum}</br>`
      }
      tdhtml = tdhtml + td
      tdhtml = `${tdhtml}</td>`
      return tdhtml
    },

    clearData() {
      $("#div_table_datas").html("");
    },

    formatDataStr(date, language) {
      if (!date) {
        return "";
      }
      if (date == "") {
        return "";
      }
      var fy = "-";
      var fm = "-";
      var fd = "";
      var fh = ":";
      var fmi = ":";
      var fs = "";
      if (language && (language == 'zh-CN')) {
        fy = "年";
        fm = "月";
        fd = "日";
        fh = "时";
        fmi = "分";
        fs = "秒"
      }
      date = date.replace(/[-|\.|.|:|\s|年|月|日]/g, "");
      var datastr = date.substr(0, 4) + fy + date.substr(4, 2) + fm + date.substr(6, 2) + fd;

      if (date.length > 8) {
        datastr += date.substr(8, 2) + fh + date.substr(10, 2) + fmi + date.substr(12, 2) + fs;
      }

      return datastr;
    }

  }

}

</script>

<style src="../../assets/css/bootstrap.min.css" scoped></style>
<style src="../../assets/css/ace.min.css" scoped></style>