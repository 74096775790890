// 中文
exports.default = {
  el: {
      colorpicker: {
          confirm: 'Confermare',
          clear: 'Chiaro'
      },
      datepicker: {
          now: 'Ora',
          today: 'Oggi',
          cancel: 'Annulla',
          clear: 'Chiaro',
          confirm: 'Confermare',
          selectDate: 'Seleziona la data',
          selectTime: 'Seleziona l ora',
          startDate: 'data d inizio',
          startTime: 'Ora di inizio',
          endDate: 'data di fine',
          endTime: 'Tempo scaduto',
          prevYear: 'L anno scorso',
          nextYear: 'l anno prossimo',
          prevMonth: 'lo scorso mese',
          nextMonth: 'il prossimo mese',
          year: 'Anno',
          month1: 'Gennaio',
          month2: 'Febbraio',
          month3: 'Marzo',
          month4: 'aprile',
          month5: 'Maggio',
          month6: 'Giugno',
          month7: 'Luglio',
          month8: 'agosto',
          month9: 'settembre',
          month10: 'ottobre',
          month11: 'novembre',
          month12: 'Dicembre',
          weeks: {
              sun: 'giorno',
              mon: 'uno',
              tue: 'due',
              wed: 'tre',
              thu: 'quattro',
              fri: 'cinque',
              sat: 'sei'
          },
          months: {
              jan: 'Gennaio',
              feb: 'Febbraio',
              mar: 'Marzo',
              apr: 'aprile',
              may: 'Maggio',
              jun: 'Giugno',
              jul: 'Luglio',
              aug: 'agosto',
              sep: 'settembre',
              oct: 'ottobre',
              nov: 'novembre',
              dec: 'Dicembre'
          }
      },
      select: {
          loading: 'Caricamento',
          noMatch: 'Nessun dato corrispondente',
          noData: 'Nessuna informazione',
          placeholder: 'Seleziona'
      },
      cascader: {
          noMatch: 'Nessun dato corrispondente',
          loading: 'Caricamento',
          placeholder: 'Seleziona',
          noData: 'Nessuna informazione'
      },
      pagination: {
          goto: 'Vai a',
          pagesize: 'Articolo/pagina',
          total: '{total} articoli in totale',
          pageClassifier: 'pagina'
      },
      messagebox: {
          title: 'suggerimento',
          confirm: 'determinare',
          cancel: 'Annulla',
          error: 'Le informazioni inserite non soddisfano i requisiti!'
      },
      upload: {
          deleteTip: 'Premere il tasto Elimina per eliminare',
          delete: 'eliminare',
          preview: 'Visualizza le immagini',
          continue: 'Continua a caricare'
      },
      table: {
          emptyText: 'Nessuna informazione ancora',
          confirmFilter: 'Filtro',
          resetFilter: 'Ripristina',
          clearFilter: 'Tutto',
          sumText: 'Somma'
      },
      tree: {
          emptyText: 'Nessuna informazione ancora'
      },
      transfer: {
          noMatch: 'Nessun dato corrispondente',
          noData: 'Nessuna informazione',
          titles: [
              'Elenco 1',
              'Elenco 2'
          ],
          filterPlaceholder: 'Inserire la parola chiave',
          noCheckedFormat: '{total} elementi',
          hasCheckedFormat: '{checked}/{total} selezionato'
      },
      image: {
          error: 'Caricamento non riuscito'
      },
      pageHeader: {
          title: 'ritorno'
      },
      popconfirm: {
          confirmButtonText: 'SÌ',
          cancelButtonText: 'NO'
      },
      empty: {
          description: 'Nessuna informazione ancora'
      }
  }
}
