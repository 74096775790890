import { methodPost, methodPost2 } from '@/api'
import store from '@/store'

export const orgMessage = {
    data() {
        const checkOldPwd = (rule, value, callback) => {
            if (!value) {
                return callback(new Error(this.$t('companyMessage.validator.value_null')))
            }
            callback()
        }
        const checkNewPwd = (rule, value, callback) => {
            if (!value) {
                return callback(new Error(this.$t('companyMessage.validator.value_null')))
            }

            if (value.length < 6) {
                callback(new Error(this.$t('companyMessage.validator.newPwd.length')))
            }

            if (this.editPwdForm.reNewPwd !== '') {
                this.$refs.editPwdForm.validateField('reNewPwd')
            }
            callback()
        }
        const checkReNewPwd = (rule, value, callback) => {
            if (!value) {
                return callback(new Error(this.$t('companyMessage.validator.value_null')))
            }

            if (value.length < 6) {
                callback(new Error(this.$t('companyMessage.validator.newPwd.length')))
            }

            if (value !== this.editPwdForm.newPwd) {
                callback(new Error(this.$t('companyMessage.validator.reNewPwd.consistent')))
            }

            callback()
        }
        const checkName = (rule, value, callback) => {
            if (!value) {
                return callback(new Error(this.$t('companyMessage.validator.value_null')))
            }

            if (value.length > 20) {
                callback(this.$t('companyMessage.validator.name.length'))
            }

            callback()
        }
        const checkEmail = (rule, value, callback) => {
            if (!value) {
                return callback(new Error(this.$t('companyMessage.validator.value_null')))
            }

            const emailReg = /^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/
            if (!emailReg.test(value)) {
                callback(new Error(this.$t('companyMessage.validator.email.rule')))
            }

            callback()
        }
        const checkCompanyName = (rule, value, callback) => {
            if (!value) {
                return callback(new Error(this.$t('companyMessage.validator.value_null')))
            }
            callback()
        }
        const checkWebsite = (rule, value, callback) => {
            if (!value) {
                return callback(new Error(this.$t('companyMessage.validator.value_null')))
            }
            callback()
        }
        return {
            fullName: '',
            simpleNameCn: '',
            simpleNameEn: '',
            website: '',
            mnumber: '',
            adminUserName: '',
            adminEmail: '',
            companyFullName: '',
            companySimpleNameCn: '',
            companySimpleNameEn: '',
            companyWebsite: '',
            companyNumber: '',
            companyNoAuditJoin: false,
            editPwdDialogVisible: false,
            editNameDialogVisible: false,
            editEmailDialogVisible: false,
            editCompanyFullNameDialogVisible: false,
            editCompanyAbbreviationCnDialogVisible: false,
            editCompanyAbbreviationEnDialogVisible: false,
            editNoAuditJoinDialogVisible: false,
            editWebsiteDialogVisible: false,
            companySimpleNameEnShow: true,
            editPwdForm: {
                oldPwd: '',
                newPwd: '',
                reNewPwd: ''
            },
            editNameForm: {
                name: ''
            },
            editEmailForm: {
                email: ''
            },
            editCompanyFullNameForm: {
                name: ''
            },
            editCompanyAbbreviationCnForm: {
                name: ''
            },
            editCompanyAbbreviationEnForm: {
                name: ''
            },
            editWebsiteForm: {
                website: ''
            },
            editNoAuditJoinForm: {
                joinFree: ''
            },
            editPwdRules: {
                oldPwd: [
                    {
                        validator: checkOldPwd,
                        trigger: 'blur'
                    }
                ],
                newPwd: [
                    {
                        validator: checkNewPwd,
                        trigger: 'blur'
                    }
                ],
                reNewPwd: [
                    {
                        validator: checkReNewPwd,
                        trigger: 'blur'
                    }
                ]
            },
            editNameRules: {
                name: [
                    {
                        validator: checkName,
                        trigger: 'blur'
                    }
                ]
            },
            editEmailRules: {
                email: [
                    {
                        validator: checkEmail,
                        trigger: 'blur'
                    }
                ]
            },
            editCompanyNameRules: {
                name: [
                    {
                        validator: checkCompanyName,
                        trigger: 'blur'
                    }
                ]
            },
            editWebsiteRules: {
                website: [
                    {
                        validator: checkWebsite,
                        trigger: 'blur'
                    }
                ]
            },
            editNoAuditJoinFormRules: {
                joinFree: [
                    {
                        required: true,
                        message: this.$t('companyMessage.validator.NoAuditJoin.null'),
                        trigger: 'change'
                    }
                ]
            }
        }
    },
    created() {
        console.log('****123456*********store.state.myMode='+store.state.myMode)
        if (store.state.myMode == 'school') {
           
            this.fullName = this.$t('companyMessage.schoolFullName')
            this.simpleNameCn = this.$t('companyMessage.schoolSimpleNameCn')
            this.simpleNameEn = this.$t('companyMessage.schoolSimpleNameEn')
            this.website = this.$t('companyMessage.schoolWebsite')
            this.mnumber = this.$t('companyMessage.schoolNumber')
        } else {
            this.fullName = this.$t('companyMessage.companyFullName')
            this.simpleNameCn = this.$t('companyMessage.companySimpleNameCn')
            this.simpleNameEn = this.$t('companyMessage.companySimpleNameEn')
            this.website = this.$t('companyMessage.companyWebsite')
            this.mnumber = this.$t('companyMessage.companyNumber')
        }

        this.qryCompanyInfo()
    },
    watch: {
        '$i18n.locale': {
            handler(newVal, oldVal) {
                if (newVal === 'en') {
                  this.companySimpleNameEnShow = false
                }
            },
            immediate: true,
        }
    },
    methods: {
        qryCompanyInfo() {
            methodPost('api/account/getCompanyInfo').then((data) => {
                if (data.status) {
                    this.adminUserName = data.data.nickName
                    this.adminEmail = data.data.email
                    this.companyFullName = data.data.orgName
                    this.companySimpleNameCn = data.data.company_abbreviation
                    this.companySimpleNameEn = data.data.company_abbreviationEn
                    this.companyWebsite = data.data.companyWebsite
                    this.companyNumber = data.data.orgCode
                    this.companyNoAuditJoin = data.data.joinFree

                    console.log('aaaaaaaaaaaaaaa='+data.data.isGroupMng)
                    this.$store.commit('setGroupMng', data.data.isGroupMng)
                    this.$store.commit('setGroupID', data.data.groupID)
                }
            })
        },
        editPwd() {
            this.$refs['editPwdForm'].validate((valid) => {
                if (valid) {
                    this.editPwdDialogVisible = false
                    const params = {
                        pswOld: this.editPwdForm.oldPwd,
                        pswNew: this.editPwdForm.newPwd,
                        pswNew2: this.editPwdForm.reNewPwd
                    }
                    methodPost2('api/user/chgPsw', params).then((data) => {
                        if (data.status) {
                            this.qryCompanyInfo()
                            this.$message({ message: this.$t('opearteSuccess'), type: 'success' })
                        } else {
                            this.$message.error(this.$t('serverError'));
                        }
                    })
                } else {
                    return false
                }
            })
        },
        editName() {
            this.$refs['editNameForm'].validate((valid) => {
                if (valid) {
                    this.editNameDialogVisible = false
                    const params = {
                        userName: this.editNameForm.name
                    }
                    methodPost('api/user/chgUserName', params).then((data) => {
                        if (data.status) {
                            this.qryCompanyInfo()
                            this.$message({ message: this.$t('opearteSuccess'), type: 'success' })
                        } else {
                            this.$message.error(this.$t('serverError'));
                        }
                    })
                } else {
                    return false
                }
            })
        },
        editEmail() {
            this.$refs['editEmailForm'].validate((valid) => {
                if (valid) {
                    this.editEmailDialogVisible = false
                    const params = {
                        email: this.editEmailForm.email
                    }
                    methodPost('api/user/chgEmail', params).then((data) => {
                        if (data.status) {
                            this.qryCompanyInfo()
                            this.$message({ message: this.$t('opearteSuccess'), type: 'success' })
                        } else {
                            this.$message.error(data.msg);
                        }
                    })
                } else {
                    return false
                }
            })
        },
        editCompanyFullName() {
            this.$refs['editCompanyFullNameForm'].validate((valid) => {
                if (valid) {
                    this.editCompanyFullNameDialogVisible = false
                    const params = {
                        companyName: this.editCompanyFullNameForm.name.replace(/\s+/g, '')
                    }
                    methodPost('api/org/chgCompanyName', params).then((data) => {
                        if (data.status) {
                            this.qryCompanyInfo()
                            this.$message({ message: this.$t('opearteSuccess'), type: 'success' })
                        } else {
                            this.$message.error(this.$t('serverError'));
                        }
                    })
                } else {
                    return false
                }
            })
        },
        editCompanyFullNameCn() {
            this.$refs['editCompanyAbbreviationCnForm'].validate((valid) => {
                if (valid) {
                    this.editCompanyAbbreviationCnDialogVisible = false
                    const params = {
                        companyAbbreviation: this.editCompanyAbbreviationCnForm.name
                    }
                    methodPost('api/org/chgCompanyAbbreviation', params).then((data) => {
                        if (data.status) {
                            this.qryCompanyInfo()
                            this.$message({ message: this.$t('opearteSuccess'), type: 'success' })
                        } else {
                            this.$message.error(this.$t('serverError'));
                        }
                    })
                } else {
                    return false
                }
            })
        },
        editCompanyFullNameEn() {
            this.$refs['editCompanyAbbreviationEnForm'].validate((valid) => {
                if (valid) {
                    this.editCompanyAbbreviationEnDialogVisible = false
                    const params = {
                        companyAbbreviationEn: this.editCompanyAbbreviationEnForm.name
                    }
                    methodPost('api/org/chgCompanyAbbreviationEn', params).then((data) => {
                        if (data.status) {
                            this.qryCompanyInfo()
                            this.$message({ message: this.$t('opearteSuccess'), type: 'success' })
                        } else {
                            this.$message.error(this.$t('serverError'));
                        }
                    })
                } else {
                    return false
                }
            })
        },
        editWebsite() {
            this.$refs['editWebsiteForm'].validate((valid) => {
                console.log(valid)
                if (valid) {
                    this.editWebsiteDialogVisible = false
                    const params = {
                        companyWebsite: this.editWebsiteForm.website
                    }
                    methodPost('api/org/chgCompanyWebsite', params).then((data) => {
                        if (data.status) {
                            this.qryCompanyInfo()
                            this.$message({ message: this.$t('opearteSuccess'), type: 'success' })
                        } else {
                            this.$message.error(this.$t('serverError'));
                        }
                    })
                } else {
                    return false
                }
            })
        },
        editNoAuditJoin() {
            this.$refs['editNoAuditJoinForm'].validate((valid) => {
                if (valid) {
                    this.editNoAuditJoinDialogVisible = false
                    const params = {
                        joinFree: this.editNoAuditJoinForm.joinFree
                    }
                    methodPost('api/org/chgJoinFree', params).then((data) => {
                        if (data.status) {
                            this.qryCompanyInfo()
                            this.$message({ message: this.$t('opearteSuccess'), type: 'success' })
                        } else {
                            this.$message.error(this.$t('serverError'));
                        }
                    })
                } else {
                    return false
                }
            })
        },
        closeEditPwdDialog() {
            this.editPwdDialogVisible = false
            this.editPwdForm = {}
            this.$refs.editPwdForm.resetFields()
        },
        closeEditNameDialog() {
            this.editNameDialogVisible = false
            this.editNameForm = {}
            this.$refs.editNameForm.resetFields()
        },
        closeEditEmailDialog() {
            this.editEmailDialogVisible = false
            this.editEmailForm = {}
            this.$refs.editEmailForm.resetFields()
        },
        closeCompanyFullNameDialog() {
            this.editCompanyFullNameDialogVisible = false
            this.editCompanyFullNameForm = {}
            this.$refs.editCompanyFullNameForm.resetFields()
        },
        closeCompanyAbbreviationCnDialog() {
            this.editCompanyAbbreviationCnDialogVisible = false
            this.editCompanyAbbreviationCnForm = {}
            this.$refs.editCompanyAbbreviationCnForm.resetFields()
        },
        closeCompanyAbbreviationEnDialog() {
            this.editCompanyAbbreviationEnDialogVisible = false
            this.editCompanyAbbreviationEnForm = {}
            this.$refs.editCompanyAbbreviationEnForm.resetFields()
        },
        closeWebsiteDialog() {
            this.editWebsiteDialogVisible = false
            this.editWebsiteForm = {}
            this.$refs.editWebsiteForm.resetFields()
        },
        closeNoAuditJoinDialog() {
            this.editNameDialogVisible = false
            this.editNoAuditJoinForm = {}
            this.$refs.editNoAuditJoinForm.resetFields()
        }
    }
}