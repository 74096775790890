import InfoGroup from "@/components/pushInfo/infoGroup.vue";

export default {
    language: 'Kurdî',
    serverError: 'Nerazîbûna serverê, ji kerema xwe bi rêvebirê re têkilî daynin',
    opearteSuccess: 'Operasyona serketî',
    agreement: {
        agreement_1: 'Xwendin û li hev kirin',
        agreement_2: '\'Peymana Bikarhêner\'',
        agreement_3: 'û',
        agreement_4: '\'Siyaseta nepenîtiyê\'',
        agreement_5: 'Ji kerema xwe qutîkê kontrol bikin ku hûn bi peymanê re bipejirînin'
    },
    login: {
        webName: 'Platforma rêveberiya pargîdaniya ViiTALK',
        website: 'Malpera fermî:',
        tel: 'Telefûn:',
        email: 'Poste:',
        title: 'Têketina bikarhênerê Enterprise',
        inputPlaceholder: {
            name: 'Ji kerema xwe navê bikarhêner / e-nameyê binivîse',
            pwd: 'Ji kerema xwe şîfreya xwe binivîse'
        },
        button: {
            login: 'Têkeve',
            email: 'Têkeve Email',
            account: 'Têketina hesabê',
            register: 'Tomarkirina bikarhênerê Enterprise',
            pwd: 'şîfreyê ji bîr bike'
        },
        error: {
            uPwd: 'navê bikarhêner an şîfreya çewt',
            uLock: 'Hesab hatiye girtin',
            uLeave: 'We îstifa kir û hûn nikarin pergalê bikar bînin',
            uRelogin: 'Jixwe tu li cihekî din têkevî!!!',
            uReplaceLogin: 'Hesabê we li cîhek din hat têketî û hûn neçar man ku jê derkevin. Ger ev ne sûcê we bû, dibe ku şîfreya we derketibe.',
            user_null: 'ji kerema xwe navê bikarhêner binivîse',
            pwd_null: 'Ji kerema xwe şîfreyê binivîse'
        }
    },
    personal: {
        companyNumber: 'Enterprise',
        administrators: 'birêvebir',
        changeLanguage: 'ziman biguherîne',
        logout: 'guhê xwe nedene'
    },
    companyMessage: {
        adminPwd: 'Şîfreya rêveberê',
        adminUserName: 'Navê rêveberê',
        adminEmail: 'E-nameya rêveberê',
        companyFullName: 'Navê tevahî ya pargîdaniyê (navê bi qanûnî qeydkirî)',
        companySimpleNameCn: 'Kurtenivîsa şirketê',
        companySimpleNameEn: 'Kurtenivîsa şirketê (Îngilîzî)',
        companyWebsite: 'Malpera pargîdanî',
        companyNumber: 'Hejmara koma Enterprise',
        companyNoAuditJoin: 'Tevlî bêyî nirxandinê bibin',
        adminExplain: 'Navê rêveber ji hemî endamên pirtûka navnîşana pargîdanî re xuya ye',
        companySimpleExplain: 'Kurtenivîsa pargîdanî dê li ser rûpela cîhaza Rooms were xuyang kirin û ji hemî endamên pirtûka navnîşana pargîdanî re xuya bibe.',
        button: {
            Edit: 'Şûndeavêtin'
        },
        dialog: {
            editpwd: {
                title: 'Şîfreya rêveberê biguherînin',
                label: {
                    oldPwd: 'Şîfreya Kevin',
                    newPwd: 'Şîfreya Nû',
                    reNewPwd: 'Şîfreya nû piştrast bikin'
                },
                inputPlaceholder: {
                    oldPwd: 'Ji kerema xwe şîfreya kevn binivîse',
                    newPwd: 'Ji kerema xwe şîfreyek nû binivîse',
                    reNewPwd: 'Ji kerema xwe têkevin da ku şîfreya nû piştrast bikin'
                }
            },
            editName: {
                title: 'Navê rêveberê biguherînin',
                label: {
                    name: 'navê nû'
                },
                inputPlaceholder: {
                    name: 'Ji kerema xwe navê nû binivîse'
                }
            },
            editEmail: {
                title: 'E-nameya rêveberê biguherînin',
                title2: 'Te hîn navnîşana e-nameya xwe girê nedaye.',
                label: {
                    email: 'qutiya posteyê ya nû'
                },
                inputPlaceholder: {
                    email: 'Ji kerema xwe e-nameyek nû binivîse'
                }
            },
            editCompanyFullName: {
                title: 'Navê tevahî pargîdaniyê biguherînin',
                label: {
                    name: 'navê tevahî nû'
                },
                inputPlaceholder: {
                    name: 'Ji kerema xwe navê tevahî nû binivîse'
                }
            },
            editCompanyAbbreviationCn: {
                title: 'Guhertina kurteya pargîdaniyê (Çînî)',
                label: {
                    name: 'Kurtenivîsa nû'
                },
                inputPlaceholder: {
                    name: 'Ji kerema xwe navekî nû yê kurt binivîse'
                }
            },
            editCompanyAbbreviationEn: {
                title: 'Guhertina kurteya pargîdaniyê (Îngilîzî)'
            },
            editWebsite: {
                title: 'Navnîşana malpera pargîdanî biguherînin',
                label: {
                    website: 'URL-ya nû'
                },
                inputPlaceholder: {
                    website: 'Ji kerema xwe URL-ya nû binivîse'
                }
            },
            editNoAuditJoin: {
                title: 'Guhestina biguherîne ku bêyî serîlêdanê tevlê bibe',
                radio: {
                    open: 'Vekirî',
                    close: 'girtinî'
                }
            }
        },
        validator: {
            value_null: 'Nirx nikare vala be',
            oldPwd: {
                null: 'Ji kerema xwe şîfreya kevn binivîse'
            },
            newPwd: {
                length: 'Ji kerema xwe şîfreyek ji 6 tîpan an zêdetir bi dirêjahî destnîşan bikin'
            },
            reNewPwd: {
                consistent: 'Şîfreyên ku du caran hatine nivîsandin nakokî ne!'
            },
            name: {
                length: 'Nabe ku nav ji 20 tîpan dirêjtir be'
            },
            email: {
                rule: 'Forma e-nameyê xelet e'
            },
            NoAuditJoin: {
                null: 'Ji kerema xwe babetek hilbijêre'
            }
        }
    },
    register: {
        step_1: 'navê bikarhêner û şîfreya',
        step_2: 'Agahdariya pargîdaniyê tije bikin',
        step_3: 'Qedandin',
        head: 'Tomarkirina bikarhênerê Enterprise',
        usernameError: 'Navê endam divê 6-20 tîpan dirêj be, tîpên çînî ne tê de, û bi tîpekê dest pê bike.',
        usernameError_exist: 'Navê bikarhêner jixwe heye, ji kerema xwe navê bikarhênerê xwe biguherîne',
        emailError: 'Forma e-nameyê xelet e',
        emailError_exist: 'Email jixwe heye',
        passwordError_length: '6-20 tîp, tenê nîşanên alfabeyî û xalbendî',
        passwordError_same: 'Şîfre nikare heman navê bikarhêner be',
        passwordError: 'Forma şîfreya çewt e',
        rePasswordError: 'Şîfreyên ku du caran hatine nivîsandin nakokî ne, ji kerema xwe ji nû ve têkevin',
        companyNameError: 'Ji kerema xwe navê tevahî ya pargîdaniyê binivîse',
        companyAbbreviationError: 'Ji kerema xwe kurteya şirketê binivîse',
        orgError_exist:'Şirket hatiye qeyd kirin, ji kerema xwe navê şirketa xwe biguherînin',
        button: {
            next: 'Pêngava paşîn',
            login: 'yekser têkeve'
        },
        inputPlaceholder: {
            username: 'navê bikarhêner',
            email: 'Poste',
            password: 'şîfreya xwe danîn',
            rePassword: 'Dîsa şîfreyê binivîse',
            name: 'Navê tevahî ya pargîdaniyê (navê bi qanûnî qeydkirî) *',
            simpleNameCN: 'Kurtenivîsa şirketê (Çînî)*',
            simpleNameEN: 'Kurtenivîsa şirketê (Îngilîzî)',
            website: 'Malpera şirketê (www)'
        },
        success: 'We bi serfirazî hesabek karsaziyê çêkir!',
        location: 'cîh:',
        Scale: 'pîvan:'
    },
    forgotPwd: {
        head: 'Vegerandina e-nameyê',
        resetSuccess: 'Te şîfreya xwe bi serfirazî sifir kir!',
        inputPlaceholder: {
            code: 'ji kerema xwe koda verastkirinê binivîse'
        },
        sendCodeError: 'Koda verastkirinê ya çewt',
        sendCodeError_sended: 'Koda verastkirinê hat şandin, ji kerema xwe wê kontrol bikin.',
        button: {
            getCode: 'koda verastkirinê bistînin',
            reGetCode: 'Dîsa koda verastkirinê bistînin'
        }
    },
    button: {
        ok: 'tesdîqkirin',
        cancel: 'Bişûndekirin',
        search: 'gerr',
        clear: 'Zelal'
    },
    device: {
        button: {
            setContent: 'Demjimêra push mîheng bikin',
            setGroupMng: 'Rêvebirê komê saz bikin',
            startNow: 'Niha bişkînin',
            stopNow: 'Dev ji zorê berdin',
            add: 'Amûrek zêde bikin',
            search: 'gerr',
            adjust: 'Kombûnê eyar bikin',
            del: 'jêbirin'
        },
        inputPlaceholder: {
            search: 'Hejmara amûrê / Hejmara telefona mobîl / jimareya ViiTALK'
        },
        group: {
            add: 'Komê zêde bikin',
            edit: 'Koma biguherîne',
            del: 'Komê jêbirin'
        },
        table: {
            th_name: 'Navê cîhazê',
            th_number: 'Hejmara cîhazê',
            th_time: 'Dema têketinê ya dawî',
            th_brandNumber: 'Hejmara mala konferansê',
            th_online: 'Serhêl e',
            th_status: 'rewş',
            th_bindNumber: 'Hejmara ekrana mezin girêdin'
        },
        dialog: {
            addGroupTitle: 'Ji kerema xwe navek komê binivîse',
            inputPlaceholder: {
                groupName: 'Ji kerema xwe navê komê binivîse'
            },
            delGroupTitle: 'Komê jêbirin',
            delGroupTips: 'Ma tu bi rastî dixwazî ​​komê jê bibî?',
            editGroupTitle: 'Koma biguherîne',
            groupMng: {
                title: 'Rêveberê komê biguherîne',
                inputPlaceholder: {
                    name: 'Ji kerema xwe navê rêveberê binivîse'
                },
                label: {
                    name: 'Navê rêveberê'
                }
            },
            addDevice: {
                title: 'Amûrek zêde bikin',
                inputPlaceholder: {
                    name: 'Ji kerema xwe navê cîhazê binivîse',
                    number: 'Ji kerema xwe hejmara cîhazê binivîse'
                },
                label: {
                    name: 'Navê cîhazê',
                    number: 'Hejmara cîhazê',
                    group: 'Ji kerema xwe komek amûrekê hilbijêrin'
                }
            },
            delDevice: {
                title: 'Amûrê rakin',
                tips: 'Ma tu bi rastî dixwazî ​​amûrê jê bibî?'
            },
            startNowDevice: {
                title: 'Niha bişkînin',
                tips: 'Ma hûn guman dikin ku hûn dixwazin niha wê bişopînin?'
            },
            stopNowDevice: {
                title: 'Dev ji zorê berdin',
                tips: 'Ma hûn guman dikin ku hûn dixwazin dev ji zordariyê berdin?'
            },
            adjustTitle: 'Kombûnê eyar bikin',
            editDevice: {
                title: 'Navê cîhazê biguherînin',
                inputPlaceholder: {
                    name: 'Ji kerema xwe navê cîhazê binivîse'
                }
            },
            noChildren: {
                title: 'gazîgîhandin',
                tips: 'Ji kerema xwe pêşî komekê zêde bike'
            }
        },
        validator: {
            group: {
                name_null: 'Navê komê vala nabe',
                name_length: 'Ji 10 tîpan derbas nabe'
            },
            number_null: 'Hejmar nikare vala be',
            number_rule: 'Tenê dikarin 10 reqeman bin ku bi 6 an 8-an dest pê dikin',
            name_null: 'Navê termînalê vala nabe'
        }
    },
    user: {
        button: {
            add: 'Bikarhêner zêde bike'
        },
        inputPlaceholder: {
            search: 'hejmara ViiTALK'
        },
        table: {
            th_name: 'Nav',
            th_number: 'Hejmara telefona desta/hejmara Maizhe'
        },
        dialog: {
            addUser: {
                title: 'Bikarhêner zêde bike',
                inputPlaceholder: {
                    number: 'Ji kerema xwe hejmara ViiTALK binivîse'
                },
                label: {
                    number: 'hejmara ViiTALK',
                    group: 'Ji kerema xwe komek bikarhêner hilbijêrin'
                }
            },
            delUser: {
                title: 'bikarhêneran jêbirin',
                tips: 'Ma hûn guman dikin ku hûn dixwazin bikarhêner jêbirin?'
            }
        },
        validator: {
            number_null: 'Hejmar nikare vala be',
            number_rule: 'Ev hejmar nîne'
        }
    },
    addrbook: {
        return: {
            delDevice: {
                tips_1: 'Koma ku were jêbirin tune ye',
                tips_2: 'Di bin komê de binekom hene ku werin jêbirin û nayên jêbirin.',
                tips_3: 'Di bin komê de kesên bên jêbirin hene û nayên jêbirin.'
            },
            addDevice: {
                tips_1: 'Jixwe hatî zêdekirin, ji kerema xwe komên din bibînin',
                tips_2: 'Ji hêla pargîdaniyên din ve hatî zêdekirin',
                tips_3: 'Ev hejmar nîne'
            }
        }
    },
    audit: {
        option: {
            label: {
                NoPass: 'Derbas nebû',
                hasRefuse: 'red kirin',
                hasPass: 'derbas bû',
                hasInvalid: 'qediya',
                all: 'gişt'
            }
        },
        button: {
            pass: 'nasname',
            reject: 'refzkirin'
        },
        table: {
            th_number: 'Amûra No',
            th_date: 'rojek',
            th_status: 'rewş',
            th_source: 'kanî'
        },
        dialog: {
            passTitle: 'Pejirandin',
            reject: {
                title: 'Vekolînê red bike',
                tips: 'Ma tu bawer î ku tu dixwazî ​​vekolînê red bikî?'
            }
        },
        api: {
            req: {
                tips_1: 'Nikarin ji vekolînê derbas bibin, tiştên hilbijartî ji çavkaniyên cihêreng têne!',
                tips_2: 'Ji kerema xwe herêma ku tê veqetandin hilbijêrin'
            },
            return: {
                tips_1: 'Hejmara cîhazê tune',
                tips_2: 'Hûn tevlî pargîdaniyê bûne',
                tips_3: 'Jixwe hatî zêdekirin, ji kerema xwe komên din bibînin'
            }
        }
    },
    conferenceLog: {
        inputPlaceholder: 'Ji kerema xwe naverokê binivîse',
        table: {
            th: {
                roomId: 'hejmara odeyê',
                state: 'rewşa odeyê',
                username: 'Ji hêlajid ve hatî afirandin',
                createTime: 'Dema çêkirina civînê',
                closeTime: 'dema dawiya civînê',
                duration: 'Demajok'
            }
        },
        dialog: {
            title: 'Endam tevlêbûn/derketina ji têketina odeyê',
            table: {
                th: {
                    username: 'Ji hêlajid ve hatî afirandin',
                    joinTime: 'beşdar bibe',
                    leaveTime: 'terikandin',
                    duration: 'Demajok'
                }
            }
        }
    },
    callLog: {
        table: {
            th: {
                callerMzNum: 'hejmara telefonê',
                callTime: 'Dial time',
                endTime: 'Dema Dawî',
                duration: 'Demajok',
                responderMzNum: 'jimare tê gotin'
            }
        }
    },
    roomStatus: {
        table: {
            th: {
                roomMessage: 'Agahdariya odeya bingehîn',
                Attendees: 'beşdar',
                bystander: 'binêr',
                join: 'Tê zêdekirin',
                exited: 'Derketin'
            }
        },
        roomNumber: 'Hejmara odeya konferansê',
        name: 'Navê odeya civînê',
        dissolution: 'Belakirin',
        pattern: 'cins',
        confluence: 'hevgirtin',
        forward: 'Pêşve',
        createdTime: 'dema afirandinê',
        limit: 'Serbestberdan / hejmara giştî ya mirovan sînordar dike',
        createMode: 'nimûne çêbikin',
        order: 'Randevu (civîn)',
        simple: 'Multiplayer asayî',
        type: 'Tîpa hevdîtinê',
        creator: 'afirîner',
        host: 'Mazûban:',
        windowMode: 'Moda pencereyê:',
        WindowMode1: 'Yek mezin û gelek piçûk',
        WindowMode2: 'Tile',
        hide: 'Veşêre gelo:',
        forbidden: 'Ji bo qedexekirina genim an na:',
        inorder: 'emir:',
        source: 'kanî:',
        joinTime: 'Dema tevlêbûnê:',
        comeOut: 'Ji kerema xwe derkevin',
        openMic: 'mîkrofonê veke',
        closeMic: 'Mîkrofonê vekin',
        leave: 'terikandin:',
        peopleNumber: 'Hejmara mirovan:'
    },
    menu: {
        index: 'rûpela pêşîn',
        device_manage: 'management Device',
        user_manage: 'Bikarhêner Management',
        conference_brand: 'Hejmara deriyê konferansê',
        audit_manage: 'rêveberiya Audit',
        orgMessage: 'Agahdariya Pargîdaniyê',
        roomStatus: 'civîna berdewam',
        p2pCallLog: 'Dîroka banga vîdyoyê',
        conferenceLog: 'deqeyên civînê',
        authorizedMessage: 'Agahdariya destûrnameyê',
        title: 'Platforma rêveberiya pargîdaniyê',
        account_mangae: 'Rêveberiya hesabê',
        contacts: 'Pirtûka navnîşana komê',
        records: 'tomar bang',
        LicenseInformation: 'Agahdariya destûrnameyê',
        pushInfo: 'Pûçek agahdarî',
        infoGroup: 'Rêveberiya koma Push'
    },
    download: 'sepanan daxin',
    unauthorized: {
        title: 'Platforma rêveberiya pargîdaniya ViiTALK (ne destûr)',
        span_1: 'Hat dîtin ku servera we destûr nade karûbarê ViiTALK,',
        span_2: 'Ji kerema xwe bi karûbarê xerîdar re têkilî daynin da ku pelê destûrnameyê bistînin.',
        span_3: '(Ji bo bidestxistina pelê destûrnameyê, hûn hewce ne ku navnîşana IP-ya servera xwe û navnîşana Mac-ê ji karûbarê xerîdar re peyda bikin)',
        upError: 'Bibore, pelê ku te barkirî xelet e, ji kerema xwe dîsa bar bike',
        chooseFile_b: 'Belgeyek hilbijêrin',
        upFile_b: 'Pelê destûrnameyê barkirin',
        success: {
            span_1: 'Destûrname serketî ye',
            span_2: 'Ketina platforma rêveberiya pargîdaniyê ...',
            span_3: '(Heke we demek dirêj e têketî, ji kerema xwe gerokê nûve bikin)'
        },
        dialog: {
            title: 'Ji kerema xwe şîfreya rêveberê biguherînin',
            span: 'Şîfreya rêveberê we hîn nehatiye guhertin, ji kerema xwe şîfreya xwe tavilê biguherînin',
            button: 'nermijîn'
        }
    },
    lang: {
        'zh-CN': 'Çînî ya hêsankirî',
        'zh-TW': 'kevneşop Chinese',
        en: 'Îngilîzî',
        ja: 'Japonî',
        ko: 'Koreyî',
        th: 'Thayîkî',
        ar: 'Erebî',
        bn: 'Bengalî',
        de: 'Almanî',
        el: 'Yewnanî',
        es: 'Îspanyolî',
        fa: 'Farsî',
        fr: 'Fransî',
        id: 'Îndonezî',
        it: 'Îtalî',
        iw: 'Îbranî',
        ku: 'Kurdî',
        nl: 'Holandî',
        pl: 'Polandî',
        pt: 'Portekizî',
        ro: 'Romanyayî',
        ru: 'Rûsî',
        tr: 'Tirkî',
        vi: 'Vîetnamî'
    },
    authorized: {
        org: 'Karsaziya destûrdar',
        userLimit: 'Sînorê bikarhêner',
        roomMax: 'Kapasîteya herî zêde ya odeya konferansa ewr',
        roomCountLimit: 'Hejmara herî zêde odeyên civîna ewr a serhêl',
        authorizedActiveDate: 'Dîroka Çalakkirina Lîsansê Cloud Taybet',
        authorizedValidDate: 'Lîsansa ewr a taybet heya ku derbasdar e',
        button_up: 'Destûrnameyê nûve bikin',
        upFileError: 'Ti pel nehat hilbijartin'
    },
    index_title: 'Platforma Rêveberiya Pargîdaniya ViiTalk',
    brand: {
        table: {
            th: {
                brandNumber: 'hejmara malê',
                bindNumber: 'Hejmara ekrana mezin girêdin',
                onlineStatus: 'rewşa liserxetê',
                lastLoginTime: 'dema têketina dawî'
            },
            col: {
                content: {
                    onlineStatus: {
                        online: 'liserxetê',
                        offline: 'Offline'
                    }
                }
            }
        }
    }
}