// 日文
exports.default = {
  el: {
      colorpicker: {
          confirm: '確認する',
          clear: 'クリア'
      },
      datepicker: {
          now: '今',
          today: '今日',
          cancel: 'キャンセル',
          clear: 'クリア',
          confirm: '確認する',
          selectDate: '日付を選択してください',
          selectTime: '時間を選択してください',
          startDate: '開始日',
          startTime: '始まる時間',
          endDate: '終了日',
          endTime: '終了時間',
          prevYear: '前年',
          nextYear: '来年',
          prevMonth: '先月',
          nextMonth: '来月',
          year: '年',
          month1: '1月',
          month2: '2月',
          month3: '行進',
          month4: '4月',
          month5: '5月',
          month6: '六月',
          month7: '7月',
          month8: '8月',
          month9: '9月',
          month10: '10月',
          month11: '11月',
          month12: '12月',
          weeks: {
              sun: '日',
              mon: '1つ',
              tue: '二',
              wed: '三つ',
              thu: '四',
              fri: '五',
              sat: '六'
          },
          months: {
              jan: '1月',
              feb: '2月',
              mar: '行進',
              apr: '4月',
              may: '5月',
              jun: '六月',
              jul: '7月',
              aug: '8月',
              sep: '9月',
              oct: '10月',
              nov: '11月',
              dec: '12月'
          }
      },
      select: {
          loading: '読み込み中',
          noMatch: '一致するデータがありません',
          noData: '情報なし',
          placeholder: '選んでください'
      },
      cascader: {
          noMatch: '一致するデータがありません',
          loading: '読み込み中',
          placeholder: '選んでください',
          noData: '情報なし'
      },
      pagination: {
          goto: 'に行く',
          pagesize: 'アイテム/ページ',
          total: '合計 {total} 個のアイテム',
          pageClassifier: 'ページ'
      },
      messagebox: {
          title: 'ヒント',
          confirm: '決定する',
          cancel: 'キャンセル',
          error: '入力された情報は要件を満たしていません。'
      },
      upload: {
          deleteTip: '削除するには削除キーを押してください',
          delete: '消去',
          preview: '写真を見る',
          continue: 'アップロードを続ける'
      },
      table: {
          emptyText: 'まだ情報はありません',
          confirmFilter: 'フィルター',
          resetFilter: 'リセット',
          clearFilter: '全て',
          sumText: '和'
      },
      tree: {
          emptyText: 'まだ情報はありません'
      },
      transfer: {
          noMatch: '一致するデータがありません',
          noData: '情報なし',
          titles: [
              'リスト 1',
              'リスト 2'
          ],
          filterPlaceholder: 'キーワードを入力してください',
          noCheckedFormat: '{total} 個のアイテム',
          hasCheckedFormat: '{checked}/{total} チェック済み'
      },
      image: {
          error: '読み込みに失敗しました'
      },
      pageHeader: {
          title: '戻る'
      },
      popconfirm: {
          confirmButtonText: 'はい',
          cancelButtonText: 'いいえ'
      },
      empty: {
          description: 'まだ情報はありません'
      }
  }
}
