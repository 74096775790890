import Vue from 'vue'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './components/i18n'

// 禁用生产环境提示
Vue.config.productionTip = false

// Element挂载到Vue
Vue.$message = Element.Message
Element.Dialog.props.closeOnClickModal.default = false
Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value)
})

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')