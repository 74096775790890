import InfoGroup from "@/components/pushInfo/infoGroup.vue";

export default {
  language: 'עִברִית',
  serverError: 'חריגה בשרת, אנא פנה למנהל המערכת',
  opearteSuccess: 'מבצע מוצלח',
  agreement: {
      agreement_1: 'קראתי והסכמתי',
      agreement_2: '\'הסכמת המשתמש\'',
      agreement_3: 'ו',
      agreement_4: '\'מדיניות הפרטיות\'',
      agreement_5: 'אנא סמן את התיבה כדי להסכים להסכם'
  },
  login: {
      webName: 'פלטפורמת ניהול ארגונית של ViiTALK',
      website: 'אתר רשמי:',
      tel: 'טֵלֵפוֹן:',
      email: 'דוֹאַר:',
      title: 'כניסה למשתמש ארגוני',
      inputPlaceholder: {
          name: 'נא להזין שם משתמש/מייל',
          pwd: 'הזן את סיסמתך בבקשה'
      },
      button: {
          login: 'התחברות',
          email: 'התחברות באימייל',
          account: 'כניסה לחשבון',
          register: 'רישום משתמש ארגוני',
          pwd: 'לשכוח את הסיסמה'
      },
      error: {
          uPwd: 'שם משתמש או סיסמא אינם נכונים',
          uLock: 'החשבון ננעל',
          uLeave: 'התפטרת ולא תוכל להשתמש במערכת',
          uRelogin: 'אתה כבר מחובר למקום אחר!!!',
          uReplaceLogin: 'חשבונך התחבר במקום אחר ונאלצת להתנתק. אם זו לא הייתה אשמתך, ייתכן שהסיסמה שלך דלפה אנא היכנס שוב והשנה את הסיסמה שלך בהקדם האפשרי.',
          user_null: 'נא להזין שם משתמש',
          pwd_null: 'נא להזין סיסמה'
      }
  },
  personal: {
      companyNumber: 'מִפְעָל',
      administrators: 'מנהל',
      changeLanguage: 'להחליף שפה',
      logout: 'התנתק'
  },
  companyMessage: {
      adminPwd: 'סיסמת מנהל',
      adminUserName: 'שם מנהל',
      adminEmail: 'דוא\'ל מנהל',
      companyFullName: 'שם מלא של החברה (שם רשום כחוק)',
      companySimpleNameCn: 'קיצור חברה ',
      companySimpleNameEn: 'קיצור חברה (אנגלית)',
      companyWebsite: 'אתר תאגידי',
      companyNumber: 'מספר קבוצת ארגוני',
      companyNoAuditJoin: 'הצטרף ללא ביקורת',
      adminExplain: 'שם מנהל המערכת גלוי לכל חברי פנקס הכתובות הארגוני',
      companySimpleExplain: 'קיצור החברה יוצג בדף הבית של מכשיר החדרים ויהיה גלוי לכל חברי פנקס הכתובות של החברה.',
      button: {
          Edit: 'לְשַׁנוֹת'
      },
      dialog: {
          editpwd: {
              title: 'שנה את סיסמת המנהל',
              label: {
                  oldPwd: 'סיסמה ישנה',
                  newPwd: 'סיסמה חדשה',
                  reNewPwd: 'אשר את הסיסמה החדשה'
              },
              inputPlaceholder: {
                  oldPwd: 'נא להזין סיסמה ישנה',
                  newPwd: 'נא להזין סיסמה חדשה',
                  reNewPwd: 'נא להזין כדי לאשר סיסמה חדשה'
              }
          },
          editName: {
              title: 'שנה את שם המנהל',
              label: {
                  name: 'שם חדש'
              },
              inputPlaceholder: {
                  name: 'נא להזין שם חדש'
              }
          },
          editEmail: {
              title: 'שנה את הדוא\'ל של מנהל המערכת',
              title2: 'עדיין לא קשרת את כתובת הדואר האלקטרוני שלך.',
              label: {
                  email: 'תיבת דואר חדשה'
              },
              inputPlaceholder: {
                  email: 'נא להזין מייל חדש'
              }
          },
          editCompanyFullName: {
              title: 'שנה את השם המלא של החברה',
              label: {
                  name: 'שם מלא חדש'
              },
              inputPlaceholder: {
                  name: 'נא להזין שם מלא חדש'
              }
          },
          editCompanyAbbreviationCn: {
              title: 'שנה קיצור חברה (סינית)',
              label: {
                  name: 'קיצור חדש'
              },
              inputPlaceholder: {
                  name: 'נא להזין שם קצר חדש'
              }
          },
          editCompanyAbbreviationEn: {
              title: 'שנה את קיצור החברה (אנגלית)'
          },
          editWebsite: {
              title: 'שנה את כתובת האתר של החברה',
              label: {
                  website: 'כתובת אתר חדשה'
              },
              inputPlaceholder: {
                  website: 'נא להזין כתובת URL חדשה'
              }
          },
          editNoAuditJoin: {
              title: 'שנה את מתג ההצטרפות ללא יישומים',
              radio: {
                  open: 'לִפְתוֹחַ',
                  close: 'סגירת מעגל'
              }
          }
      },
      validator: {
          value_null: 'הערך לא יכול להיות ריק',
          oldPwd: {
              null: 'נא להזין סיסמה ישנה'
          },
          newPwd: {
              length: 'נא להגדיר סיסמה באורך של 6 תווים או יותר'
          },
          reNewPwd: {
              consistent: 'הסיסמאות שהוזנו פעמיים אינן עקביות!'
          },
          name: {
              length: 'השם לא יכול להיות יותר מ-20 תווים'
          },
          email: {
              rule: 'פורמט הדואר האלקטרוני שגוי'
          },
          NoAuditJoin: {
              null: 'אנא בחר פריט'
          }
      }
  },
  register: {
      step_1: 'שם משתמש וסיסמא',
      step_2: 'מלא את פרטי החברה',
      step_3: 'סיים',
      head: 'רישום משתמש ארגוני',
      usernameError: 'שם החבר חייב להיות באורך 6-20 תווים, לא כולל תווים סיניים, ומתחיל באות.',
      usernameError_exist: 'שם המשתמש כבר קיים, אנא שנה את שם המשתמש שלך',
      emailError: 'פורמט הדואר האלקטרוני שגוי',
      emailError_exist: 'האימייל כבר קיים',
      passwordError_length: '6-20 תווים, רק אלפאנומריים וסימני פיסוק',
      passwordError_same: 'הסיסמה לא יכולה להיות אותו שם משתמש',
      passwordError: 'פורמט הסיסמה שגוי',
      rePasswordError: 'הסיסמאות שהוזנו פעמיים אינן עקביות, נא להזין מחדש',
      companyNameError: 'נא להזין את השם המלא של החברה',
      companyAbbreviationError: 'נא להזין את קיצור החברה',
      orgError_exist:'החברה נרשמה, אנא שנו את שם העסק שלכם',
      button: {
          next: 'השלב הבא',
          login: 'להיכנס מיד'
      },
      inputPlaceholder: {
          username: 'שם משתמש',
          email: 'דוֹאַר',
          password: 'הגדר סיסמא',
          rePassword: 'הקלד את הסיסמא מחדש',
          name: 'שם מלא של החברה (שם רשום כחוק)*',
          simpleNameCN: 'קיצור חברה (סינית)*',
          simpleNameEN: 'קיצור חברה (אנגלית)',
          website: 'אתר החברה (www)'
      },
      success: 'יצרת בהצלחה חשבון עסקי!',
      location: 'מקום:',
      Scale: 'סוּלָם:'
  },
  forgotPwd: {
      head: 'אחזור דואר אלקטרוני',
      resetSuccess: 'איפסת בהצלחה את הסיסמה שלך!',
      inputPlaceholder: {
          code: 'נא להזין קוד אימות'
      },
      sendCodeError: 'קוד אימות שגוי',
      sendCodeError_sended: 'קוד האימות נשלח, אנא בדוק אותו',
      button: {
          getCode: 'לקבל קוד אימות',
          reGetCode: 'קבל שוב קוד אימות'
      }
  },
  button: {
      ok: 'לְאַשֵׁר',
      cancel: 'לְבַטֵל',
      search: 'לחפש',
      clear: 'ברור'
  },
  device: {
      button: {
          setContent: 'הגדר לוח זמנים לדחיפה',
          setGroupMng: 'הגדר מנהל קבוצה',
          startNow: 'תלחץ עכשיו',
          stopNow: 'תפסיק לדחוף',
          add: 'הוסף מכשיר',
          search: 'לחפש',
          adjust: 'התאם קיבוץ',
          del: 'לִמְחוֹק'
      },
      inputPlaceholder: {
          search: 'מספר מכשיר/מספר טלפון נייד/מספר ViiTALK'
      },
      group: {
          add: 'הוסף קבוצה',
          edit: 'ערוך קבוצה',
          del: 'מחק את הקבוצה'
      },
      table: {
          th_name: 'שם התקן',
          th_number: 'מספר מכשיר',
          th_time: 'שעת הכניסה האחרונה',
          th_brandNumber: 'מספר בית הכנס',
          th_online: 'מחובר',
          th_status: 'מדינה',
          th_bindNumber: 'קשר מספר מסך גדול'
      },
      dialog: {
          addGroupTitle: 'נא להזין שם קבוצה',
          inputPlaceholder: {
              groupName: 'נא להזין את שם הקבוצה'
          },
          delGroupTitle: 'מחק את הקבוצה',
          delGroupTips: 'האם אתה בטוח שברצונך למחוק את הקבוצה?',
          editGroupTitle: 'ערוך קבוצה',
          groupMng: {
              title: 'ערוך את מנהל הקבוצה',
              inputPlaceholder: {
                  name: 'נא להזין את שם המנהל'
              },
              label: {
                  name: 'שם מנהל'
              }
          },
          addDevice: {
              title: 'הוסף מכשיר',
              inputPlaceholder: {
                  name: 'נא להזין את שם המכשיר',
                  number: 'נא להזין את מספר המכשיר'
              },
              label: {
                  name: 'שם התקן',
                  number: 'מספר מכשיר',
                  group: 'אנא בחר קבוצת מכשירים'
              }
          },
          delDevice: {
              title: 'הסר מכשיר',
              tips: 'האם אתה בטוח שברצונך למחוק את המכשיר?'
          },
          startNowDevice: {
              title: 'תלחץ עכשיו',
              tips: 'האם אתה בטוח שאתה רוצה לדחוף את זה עכשיו?'
          },
          stopNowDevice: {
              title: 'תפסיק לדחוף',
              tips: 'האם אתה בטוח שאתה רוצה להפסיק לדחוף?'
          },
          adjustTitle: 'התאם קיבוץ',
          editDevice: {
              title: 'שנה את שם המכשיר',
              inputPlaceholder: {
                  name: 'נא להזין את שם המכשיר'
              }
          },
          noChildren: {
              title: 'לְהַזהִיר',
              tips: 'נא הוסף קבוצה תחילה'
          }
      },
      validator: {
          group: {
              name_null: 'שם הקבוצה לא יכול להיות ריק',
              name_length: 'לא יכול לחרוג מ-10 תווים'
          },
          number_null: 'מספר לא יכול להיות ריק',
          number_rule: 'יכול להיות רק 10 ספרות שמתחיל ב-6 או 8',
          name_null: 'שם המסוף לא יכול להיות ריק'
      }
  },
  user: {
      button: {
          add: 'הוסף משתמש'
      },
      inputPlaceholder: {
          search: 'מספר ViiTALK'
      },
      table: {
          th_name: 'שֵׁם',
          th_number: 'מספר טלפון נייד/מספר מייזה'
      },
      dialog: {
          addUser: {
              title: 'הוסף משתמש',
              inputPlaceholder: {
                  number: 'נא להזין את מספר ViiTALK'
              },
              label: {
                  number: 'מספר ViiTALK',
                  group: 'אנא בחר קבוצת משתמשים'
              }
          },
          delUser: {
              title: 'למחוק משתמשים',
              tips: 'האם אתה בטוח שברצונך למחוק את המשתמש?'
          }
      },
      validator: {
          number_null: 'מספר לא יכול להיות ריק',
          number_rule: 'המספר הזה לא קיים'
      }
  },
  addrbook: {
      return: {
          delDevice: {
              tips_1: 'הקבוצה שיש למחוק אינה קיימת',
              tips_2: 'ישנן תת-קבוצות מתחת לקבוצה שיש למחוק ולא ניתן למחוק אותן.',
              tips_3: 'יש אנשים מתחת לקבוצה שיש למחוק ולא ניתן למחוק אותם.'
          },
          addDevice: {
              tips_1: 'כבר נוספו, אנא הצג קבוצות אחרות',
              tips_2: 'נוספה על ידי חברות אחרות',
              tips_3: 'המספר הזה לא קיים'
          }
      }
  },
  audit: {
      option: {
          label: {
              NoPass: 'לא עבר',
              hasRefuse: 'נִדחֶה',
              hasPass: 'עבר',
              hasInvalid: 'לא בתוקף',
              all: 'את כל'
          }
      },
      button: {
          pass: 'לַעֲבוֹר',
          reject: 'לִדחוֹת'
      },
      table: {
          th_number: 'מכשיר מס',
          th_date: 'תַאֲרִיך',
          th_status: 'מדינה',
          th_source: 'מָקוֹר'
      },
      dialog: {
          passTitle: 'אושר',
          reject: {
              title: 'דחי ביקורת',
              tips: 'האם אתה בטוח שברצונך לדחות את הביקורת?'
          }
      },
      api: {
          req: {
              tips_1: 'לא ניתן לעבור ביקורת, הפריטים שנבחרו מגיעים ממקורות שונים!',
              tips_2: 'אנא בחר את האזור להקצאה'
          },
          return: {
              tips_1: 'מספר המכשיר אינו קיים',
              tips_2: 'הצטרפת למפעל',
              tips_3: 'כבר נוספו, אנא הצג קבוצות אחרות'
          }
      }
  },
  conferenceLog: {
      inputPlaceholder: 'נא להזין תוכן',
      table: {
          th: {
              roomId: 'מספר חדר',
              state: 'מצב החדר',
              username: 'נוצר על ידי ג',
              createTime: 'זמן יצירת פגישה',
              closeTime: 'שעת סיום הפגישה',
              duration: 'מֶשֶׁך'
          }
      },
      dialog: {
          title: 'חבר הצטרפות/יציאה מחדר רישום',
          table: {
              th: {
                  username: 'נוצר על ידי ג',
                  joinTime: 'להצטרף',
                  leaveTime: 'לעזוב',
                  duration: 'מֶשֶׁך'
              }
          }
      }
  },
  callLog: {
      table: {
          th: {
              callerMzNum: 'מספר מתקשר',
              callTime: 'זמן חיוג',
              endTime: 'שעת סיום',
              duration: 'מֶשֶׁך',
              responderMzNum: 'מספר שנקרא'
          }
      }
  },
  roomStatus: {
      table: {
          th: {
              roomMessage: 'מידע בסיסי על החדר',
              Attendees: 'משתתף',
              bystander: 'צופה מהצד',
              join: 'להתווסף',
              exited: 'יצא'
          }
      },
      roomNumber: 'מספר חדר ישיבות',
      name: 'שם חדר ישיבות',
      dissolution: 'לְפַרֵק',
      pattern: 'דֶגֶם',
      confluence: 'מִפגָשׁ',
      forward: 'קָדִימָה',
      createdTime: 'זמן יצירה',
      limit: 'הגבלת שחרור/מספר כולל של אנשים',
      createMode: 'ליצור דפוס',
      order: 'מינוי (פגישה)',
      simple: 'מרובה משתתפים רגיל',
      type: 'סוג פגישה',
      creator: 'בורא',
      host: 'מנחה:',
      windowMode: 'מצב חלון:',
      WindowMode1: 'אחד גדול ורבים קטנים',
      WindowMode2: 'אָרִיחַ',
      hide: 'האם להסתיר:',
      forbidden: 'האם לאסור חיטה:',
      inorder: 'להזמין:',
      source: 'מָקוֹר:',
      joinTime: 'זמן הצטרפות:',
      comeOut: 'בבקשה צא החוצה',
      openMic: 'להפעיל את המיקרופון',
      closeMic: 'כבה את המיקרופון',
      leave: 'לעזוב:',
      peopleNumber: 'מספר אנשים:'
  },
  menu: {
      index: 'עמוד ראשי',
      device_manage: 'ניהול מכשירים',
      user_manage: 'ניהול משתמשים',
      conference_brand: 'מספר דלת הכנס',
      audit_manage: 'ניהול ביקורת',
      orgMessage: 'מידע תאגידי',
      roomStatus: 'פגישה מתמשכת',
      p2pCallLog: 'היסטוריית שיחות וידאו',
      conferenceLog: 'פרוטוקולים של פגישה',
      authorizedMessage: 'פרטי הרשאה',
      title: 'פלטפורמת ניהול ארגונית',
      account_mangae: 'ניהול חשבון',
      contacts: 'פנקס כתובות קבוצתי',
      records: 'רישומי שיחות',
      LicenseInformation: 'פרטי הרשאה',
      pushInfo: 'דחיפה של מידע',
      infoGroup: 'לדחוף את ניהול הקבוצה'
  },
  download: 'להוריד אפליקציות',
  unauthorized: {
      title: 'פלטפורמת ניהול ארגונית של ViiTALK (לא מורשית)',
      span_1: 'זוהה שהשרת שלך אינו מאשר את שירות ViiTALK,',
      span_2: 'אנא צור קשר עם שירות הלקוחות לקבלת קובץ ההרשאה.',
      span_3: '(כדי לקבל את קובץ ההרשאה, עליך לספק את כתובת ה-IP של השרת וכתובת ה-Mac לשירות הלקוחות)',
      upError: 'מצטערים, הקובץ שהעלית שגוי, אנא העלה שוב',
      chooseFile_b: 'בחר מסמך',
      upFile_b: 'העלה קובץ הרשאה',
      success: {
          span_1: 'ההרשאה הצליחה',
          span_2: 'נכנסים לפלטפורמת הניהול הארגוני...',
          span_3: '(אם לא נכנסת הרבה זמן, אנא רענן את הדפדפן)'
      },
      dialog: {
          title: 'אנא שנה את סיסמת המנהל',
          span: 'סיסמת המנהל שלך עדיין לא שונתה, אנא שנה את הסיסמה שלך מיד',
          button: 'שלח'
      }
  },
  lang: {
    'zh-CN': 'סינית פשוטה',
    'zh-TW': 'סינית מסורתית',
    en: 'אנגלית',
    ja: 'יַפָּנִית',
    ko: 'קוריאנית',
    th: 'תאילנדית',
    ar: 'עֲרָבִית',
    bn: 'בנגלית',
    de: 'גֶרמָנִיָת',
    el: 'יווני',
    es: 'ספרדית',
    fa: 'פַּרסִית',
    fr: 'צָרְפָתִית',
    id: 'אינדונזית',
    it: 'אִיטַלְקִית',
    iw: 'עִברִית',
    ku: 'כּוּרדִי',
    nl: 'הוֹלַנדִי',
    pl: 'פולנית',
    pt: 'פורטוגזית',
    ro: 'רומנית',
    ru: 'רוּסִי',
    tr: 'טורקי',
    vi: 'וייטנאמית'
  },
  authorized: {
      org: 'מפעל מורשה',
      userLimit: 'מגבלת משתמשים',
      roomMax: 'קיבולת מקסימלית של חדר ישיבות בענן',
      roomCountLimit: 'מספר מרבי של חדרי ישיבות בענן מקוונים',
      authorizedActiveDate: 'תאריך הפעלת רישיון ענן פרטי',
      authorizedValidDate: 'רישיון הענן הפרטי תקף עד',
      button_up: 'עדכון הרשאה',
      upFileError: 'אף קובץ לא נבחר'
  },
  index_title: 'ViiTalk Enterprise Management Platform',
  brand: {
    table: {
        th: {
            brandNumber: 'מספר הבית',
            bindNumber: 'קשר מספר מסך גדול',
            onlineStatus: 'סטטוס מקוון',
            lastLoginTime: 'זמן הכניסה האחרון'
        },
        col: {
            content: {
                onlineStatus: {
                    online: 'באינטרנט',
                    offline: 'לא מקוון'
                }
            }
        }
    }
  }
}