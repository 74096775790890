import InfoGroup from "@/components/pushInfo/infoGroup.vue";

export default {
  language: 'عربي',
  serverError: 'خلل في الخادم، يرجى الاتصال بالمسؤول',
  opearteSuccess: 'عملية ناجحة',
  agreement: {
      agreement_1: 'قرأت ووافقت',
      agreement_2: '\'اتفاقية المستخدم\'',
      agreement_3: 'و',
      agreement_4: '\'سياسة الخصوصية\'',
      agreement_5: 'يرجى تحديد المربع للموافقة على الاتفاقية'
  },
  login: {
      webName: 'منصة إدارة المشاريع ViiTALK',
      website: 'الموقع الرسمي:',
      tel: 'هاتف:',
      email: 'بريد:',
      title: 'تسجيل دخول مستخدم المؤسسة',
      inputPlaceholder: {
          name: 'الرجاء إدخال اسم المستخدم/البريد الإلكتروني',
          pwd: 'من فضلك أدخل رقمك السري'
      },
      button: {
          login: 'تسجيل الدخول',
          email: 'تسجيل الدخول بالبريد الإلكتروني',
          account: 'تسجل الدخول',
          register: 'تسجيل مستخدم المؤسسة',
          pwd: 'ننسى كلمة المرور'
      },
      error: {
          uPwd: 'اسم المستخدم أو كلمة المرور خاطئة',
          uLock: 'تم قفل الحساب',
          uLeave: 'لقد استقالت ولا يمكنك استخدام النظام',
          uRelogin: 'لقد قمت بالفعل بتسجيل الدخول في مكان آخر!!!',
          uReplaceLogin: 'لقد تم تسجيل الدخول إلى حسابك في مكان آخر وتم إجبارك على تسجيل الخروج. إذا لم يكن ذلك خطأك، فربما تم تسريب كلمة المرور الخاصة بك، يرجى تسجيل الدخول مرة أخرى وتغيير كلمة المرور الخاصة بك في أقرب وقت ممكن.',
          user_null: 'الرجاء إدخال اسم المستخدم',
          pwd_null: 'الرجاء إدخال كلمة المرور'
      }
  },
  personal: {
      companyNumber: 'مَشرُوع',
      administrators: 'مدير',
      changeLanguage: 'تبديل اللغة',
      logout: 'خروج'
  },
  companyMessage: {
      adminPwd: 'كلمة مرور المسؤول',
      adminUserName: 'اسم المسؤول',
      adminEmail: 'البريد الإلكتروني للمسؤول',
      companyFullName: 'الاسم الكامل للشركة (الاسم المسجل قانونيا)',
      companySimpleNameCn: 'اختصار الشركة ',
      companySimpleNameEn: 'اختصار الشركة (الإنجليزية)',
      companyWebsite: 'موقع الشركة الألكتروني',
      companyNumber: 'رقم مجموعة المؤسسة',
      companyNoAuditJoin: 'الانضمام دون مراجعة',
      adminExplain: 'اسم المسؤول مرئي لجميع أعضاء دفتر عناوين الشركة',
      companySimpleExplain: 'سيتم عرض اختصار الشركة على الصفحة الرئيسية لجهاز الغرف وسيكون مرئيًا لجميع أعضاء دفتر عناوين الشركة.',
      button: {
          Edit: 'يراجع'
      },
      dialog: {
          editpwd: {
              title: 'تغيير كلمة مرور المسؤول',
              label: {
                  oldPwd: 'كلمة المرور القديمة',
                  newPwd: 'كلمة المرور الجديدة',
                  reNewPwd: 'قم بتأكيد كلمة المرور الجديدة'
              },
              inputPlaceholder: {
                  oldPwd: 'الرجاء إدخال كلمة المرور القديمة',
                  newPwd: 'الرجاء إدخال كلمة مرور جديدة',
                  reNewPwd: 'الرجاء الدخول لتأكيد كلمة المرور الجديدة'
              }
          },
          editName: {
              title: 'تعديل اسم المسؤول',
              label: {
                  name: 'اسم جديد'
              },
              inputPlaceholder: {
                  name: 'الرجاء إدخال الاسم الجديد'
              }
          },
          editEmail: {
              title: 'تعديل البريد الإلكتروني للمسؤول',
              title2: 'لم تقم بربط عنوان بريدك الإلكتروني بعد، يرجى ربط عنوان بريدك الإلكتروني في الوقت المناسب.',
              label: {
                  email: 'صندوق بريد جديد'
              },
              inputPlaceholder: {
                  email: 'الرجاء إدخال بريد إلكتروني جديد'
              }
          },
          editCompanyFullName: {
              title: 'تعديل الاسم الكامل للشركة',
              label: {
                  name: 'الاسم الكامل الجديد'
              },
              inputPlaceholder: {
                  name: 'الرجاء إدخال الاسم الكامل الجديد'
              }
          },
          editCompanyAbbreviationCn: {
              title: 'تعديل اختصار الشركة (الصينية)',
              label: {
                  name: 'اختصار جديد'
              },
              inputPlaceholder: {
                  name: 'الرجاء إدخال اسم قصير جديد'
              }
          },
          editCompanyAbbreviationEn: {
              title: 'تعديل اختصار الشركة (انجليزي)'
          },
          editWebsite: {
              title: 'تعديل عنوان موقع الشركة',
              label: {
                  website: 'عنوان URL جديد'
              },
              inputPlaceholder: {
                  website: 'الرجاء إدخال عنوان URL الجديد'
              }
          },
          editNoAuditJoin: {
              title: 'قم بتعديل مفتاح الانضمام الخالي من التطبيقات',
              radio: {
                  open: 'يفتح',
                  close: 'إنهاء'
              }
          }
      },
      validator: {
          value_null: 'لا يمكن أن تكون القيمة فارغة',
          oldPwd: {
              null: 'الرجاء إدخال كلمة المرور القديمة'
          },
          newPwd: {
              length: 'يرجى تعيين كلمة مرور مكونة من 6 أحرف أو أكثر'
          },
          reNewPwd: {
              consistent: 'كلمات المرور التي تم إدخالها مرتين غير متناسقة!'
          },
          name: {
              length: 'لا يمكن أن يزيد الاسم عن 20 حرفًا'
          },
          email: {
              rule: 'تنسيق البريد الإلكتروني غير صحيح'
          },
          NoAuditJoin: {
              null: 'الرجاء تحديد عنصر'
          }
      }
  },
  register: {
      step_1: 'اسم المستخدم و كلمة السر',
      step_2: 'قم بملء معلومات الشركة',
      step_3: 'ينهي',
      head: 'تسجيل مستخدم المؤسسة',
      usernameError: 'يجب أن يتراوح طول اسم العضو من 6 إلى 20 حرفًا، ولا يتضمن الأحرف الصينية، ويبدأ بحرف.',
      usernameError_exist: 'اسم المستخدم موجود بالفعل، يرجى تعديل اسم المستخدم الخاص بك',
      emailError: 'تنسيق البريد الإلكتروني غير صحيح',
      emailError_exist: 'البريد الالكتروني موجود بالفعل',
      passwordError_length: 'من 6 إلى 20 حرفًا، فقط الحروف الأبجدية الرقمية وعلامات الترقيم',
      passwordError_same: 'لا يمكن أن تكون كلمة المرور هي نفس اسم المستخدم',
      passwordError: 'تنسيق كلمة المرور غير صحيح',
      rePasswordError: 'كلمات المرور التي تم إدخالها مرتين غير متناسقة، يرجى إعادة إدخالها',
      companyNameError: 'الرجاء إدخال الاسم الكامل للشركة',
      companyAbbreviationError: 'الرجاء إدخال اختصار الشركة',
      orgError_exist:'تم تسجيل الشركة ، يرجى تغيير اسم عملك',
      button: {
          next: 'الخطوة التالية',
          login: 'قم بتسجيل الدخول على الفور'
      },
      inputPlaceholder: {
          username: 'اسم المستخدم',
          email: 'بريد',
          password: 'ضبط كلمة السر',
          rePassword: 'أدخل كلمة المرور مرة أخرى',
          name: 'الاسم الكامل للشركة (الاسم المسجل قانونيا)*',
          simpleNameCN: 'اختصار الشركة (الصينية)*',
          simpleNameEN: 'اختصار الشركة (الإنجليزية)',
          website: 'موقع الشركة (www)'
      },
      success: 'لقد قمت بإنشاء حساب تجاري بنجاح!',
      location: 'موقع:',
      Scale: 'حجم:'
  },
  forgotPwd: {
      head: 'استرجاع البريد الإلكتروني',
      resetSuccess: 'لقد قمت بإعادة تعيين كلمة المرور الخاصة بك بنجاح!',
      inputPlaceholder: {
          code: 'الرجاء إدخال رمز التحقق'
      },
      sendCodeError: 'رمز التحقق غير صحيح',
      sendCodeError_sended: 'تم إرسال رمز التحقق، يرجى التحقق منه',
      button: {
          getCode: 'الحصول على رمز التحقق',
          reGetCode: 'احصل على رمز التحقق مرة أخرى'
      }
  },
  button: {
      ok: 'يتأكد',
      cancel: 'يلغي',
      search: 'يبحث',
      clear: 'واضح'
  },
  device: {
      button: {
          setContent: 'تكوين جدول الدفع',
          setGroupMng: 'إعداد مسؤول المجموعة',
          startNow: 'ادفع الآن',
          stopNow: 'توقف عن الدفع',
          add: 'اضف جهاز',
          search: 'يبحث',
          adjust: 'ضبط التجميع',
          del: 'يمسح'
      },
      inputPlaceholder: {
          search: 'رقم الجهاز/رقم الهاتف المحمول/رقم ViiTALK'
      },
      group: {
          add: 'أضف مجموعة',
          edit: 'تحرير المجموعة',
          del: 'حذف المجموعة'
      },
      table: {
          th_name: 'اسم الجهاز',
          th_number: 'رقم الجهاز',
          th_time: 'آخر وقت لتسجيل الدخول',
          th_brandNumber: 'رقم دار المؤتمرات',
          th_online: 'متصل',
          th_status: 'ولاية',
          th_bindNumber: 'ربط رقم الشاشة الكبيرة'
      },
      dialog: {
          addGroupTitle: 'الرجاء إدخال اسم المجموعة',
          inputPlaceholder: {
              groupName: 'الرجاء إدخال اسم المجموعة'
          },
          delGroupTitle: 'حذف المجموعة',
          delGroupTips: 'هل أنت متأكد أنك تريد حذف المجموعة؟',
          editGroupTitle: 'تحرير المجموعة',
          groupMng: {
              title: 'تحرير مدير المجموعة',
              inputPlaceholder: {
                  name: 'الرجاء إدخال اسم المسؤول'
              },
              label: {
                  name: 'اسم المسؤول'
              }
          },
          addDevice: {
              title: 'اضف جهاز',
              inputPlaceholder: {
                  name: 'الرجاء إدخال اسم الجهاز',
                  number: 'الرجاء إدخال رقم الجهاز'
              },
              label: {
                  name: 'اسم الجهاز',
                  number: 'رقم الجهاز',
                  group: 'الرجاء تحديد مجموعة الأجهزة'
              }
          },
          delDevice: {
              title: 'إنزع الجهاز',
              tips: 'هل أنت متأكد أنك تريد حذف الجهاز؟'
          },
          startNowDevice: {
              title: 'ادفع الآن',
              tips: 'هل أنت متأكد أنك تريد الضغط عليه الآن؟'
          },
          stopNowDevice: {
              title: 'توقف عن الدفع',
              tips: 'هل أنت متأكد أنك تريد التوقف عن الدفع؟'
          },
          adjustTitle: 'ضبط التجميع',
          editDevice: {
              title: 'تعديل اسم الجهاز',
              inputPlaceholder: {
                  name: 'الرجاء إدخال اسم الجهاز'
              }
          },
          noChildren: {
              title: 'تحذير',
              tips: 'الرجاء إضافة مجموعة أولا'
          }
      },
      validator: {
          group: {
              name_null: 'لا يمكن أن يكون اسم المجموعة فارغًا',
              name_length: 'لا يمكن أن يتجاوز 10 أحرف'
          },
          number_null: 'لا يمكن أن يكون الرقم فارغًا',
          number_rule: 'يمكن أن يتكون من 10 أرقام فقط بدءًا من 6 أو 8',
          name_null: 'لا يمكن أن يكون اسم المحطة فارغًا'
      }
  },
  user: {
      button: {
          add: 'إضافة مستخدم'
      },
      inputPlaceholder: {
          search: 'رقم فيي توك'
      },
      table: {
          th_name: 'اسم',
          th_number: 'رقم الهاتف المحمول/رقم Maizhe'
      },
      dialog: {
          addUser: {
              title: 'إضافة مستخدم',
              inputPlaceholder: {
                  number: 'الرجاء إدخال رقم ViiTALK'
              },
              label: {
                  number: 'رقم فيي توك',
                  group: 'الرجاء تحديد مجموعة المستخدمين'
              }
          },
          delUser: {
              title: 'حذف المستخدمين',
              tips: 'هل أنت متأكد أنك تريد حذف المستخدم؟'
          }
      },
      validator: {
          number_null: 'لا يمكن أن يكون الرقم فارغًا',
          number_rule: 'هذا الرقم غير موجود'
      }
  },
  addrbook: {
      return: {
          delDevice: {
              tips_1: 'المجموعة المراد حذفها غير موجودة',
              tips_2: 'توجد مجموعات فرعية ضمن المجموعة ليتم حذفها ولا يمكن حذفها.',
              tips_3: 'يوجد أشخاص ضمن المجموعة ليتم حذفهم ولا يمكن حذفهم.'
          },
          addDevice: {
              tips_1: 'تمت إضافتها بالفعل، يرجى عرض المجموعات الأخرى',
              tips_2: 'تمت إضافته من قبل شركات أخرى',
              tips_3: 'هذا الرقم غير موجود'
          }
      }
  },
  audit: {
      option: {
          label: {
              NoPass: 'لم ينجح',
              hasRefuse: 'مرفوض',
              hasPass: 'اجتاز',
              hasInvalid: 'منتهي الصلاحية',
              all: 'الجميع'
          }
      },
      button: {
          pass: 'يمر',
          reject: 'يرفض'
      },
      table: {
          th_number: 'رقم الجهاز',
          th_date: 'تاريخ',
          th_status: 'ولاية',
          th_source: 'مصدر'
      },
      dialog: {
          passTitle: 'موافقة',
          reject: {
              title: 'رفض المراجعة',
              tips: 'هل أنت متأكد أنك تريد رفض المراجعة؟'
          }
      },
      api: {
          req: {
              tips_1: 'غير قادر على اجتياز المراجعة، العناصر المحددة تأتي من مصادر مختلفة!',
              tips_2: 'الرجاء تحديد المنطقة التي سيتم تخصيصها'
          },
          return: {
              tips_1: 'رقم الجهاز غير موجود',
              tips_2: 'لقد انضممت إلى المؤسسة',
              tips_3: 'تمت إضافتها بالفعل، يرجى عرض المجموعات الأخرى'
          }
      }
  },
  conferenceLog: {
      inputPlaceholder: 'الرجاء إدخال المحتوى',
      table: {
          th: {
              roomId: 'رقم الغرفة',
              state: 'حالة الغرفة',
              username: 'تم إنشاؤها بواسطة jid',
              createTime: 'وقت إنشاء الاجتماع',
              closeTime: 'وقت نهاية الاجتماع',
              duration: 'مدة'
          }
      },
      dialog: {
          title: 'انضمام الأعضاء/الخروج من تسجيل الغرفة',
          table: {
              th: {
                  username: 'تم إنشاؤها بواسطة jid',
                  joinTime: 'إنضم إلى',
                  leaveTime: 'يترك',
                  duration: 'مدة'
              }
          }
      }
  },
  callLog: {
      table: {
          th: {
              callerMzNum: 'عدد داعيا',
              callTime: 'اطلب الوقت',
              endTime: 'وقت النهاية',
              duration: 'مدة',
              responderMzNum: 'اتصل بالرقم'
          }
      }
  },
  roomStatus: {
      table: {
          th: {
              roomMessage: 'معلومات الغرفة الأساسية',
              Attendees: 'الحضور',
              bystander: 'المارة',
              join: 'لتضاف',
              exited: 'خرج'
          }
      },
      roomNumber: 'رقم قاعة الاجتماعات',
      name: 'اسم غرفة الاجتماعات',
      dissolution: 'حل \\ سرح',
      pattern: 'نموذج',
      confluence: 'التقاء نهرين',
      forward: 'إلى الأمام',
      createdTime: 'وقت الابتكار',
      limit: 'الإصدار/العدد الإجمالي للأشخاص محدود',
      createMode: 'خلق نمط',
      order: 'اجتماع التعيين)',
      simple: 'متعددة عادية',
      type: 'نوع الاجتماع',
      creator: 'المنشئ',
      host: 'يستضيف:',
      windowMode: 'وضع الإطار:',
      WindowMode1: 'واحدة كبيرة والعديد من الصغيرة',
      WindowMode2: 'بلاط',
      hide: 'ما إذا كان سيتم إخفاء:',
      forbidden: 'هل سيتم حظر القمح:',
      inorder: 'طلب:',
      source: 'مصدر:',
      joinTime: 'وقت الإنضمام:',
      comeOut: 'من فضلك اخرج',
      openMic: 'قم بتشغيل الميكروفون',
      closeMic: 'قم بإيقاف تشغيل الميكروفون',
      leave: 'يترك:',
      peopleNumber: 'عدد الاشخاص:'
  },
  menu: {
      index: 'الصفحة الأمامية',
      device_manage: 'إدارة الجهاز',
      user_manage: 'إدارةالمستخدم',
      conference_brand: 'رقم باب المؤتمر',
      audit_manage: 'إدارة التدقيق',
      orgMessage: 'معلومات الشركات',
      roomStatus: 'الاجتماع المستمر',
      p2pCallLog: 'سجل مكالمات الفيديو',
      conferenceLog: 'محضر الاجتماع',
      authorizedMessage: 'معلومات التفويض',
      title: 'منصة إدارة المؤسسات',
      account_mangae: 'ادارة الحساب',
      contacts: 'دفتر عناوين المجموعة',
      records: 'سجلات المكالمات',
      LicenseInformation: 'معلومات التفويض',
      pushInfo: 'دفع المعلومات',
      infoGroup: 'دفع إدارة المجموعة'
  },
  download: 'تنزيل التطبيقات',
  unauthorized: {
      title: 'منصة إدارة المؤسسات ViiTALK (غير مصرح بها)',
      span_1: 'لقد تم اكتشاف أن الخادم الخاص بك لا يسمح بخدمة ViiTALK،',
      span_2: 'يرجى الاتصال بخدمة العملاء للحصول على ملف التفويض.',
      span_3: '(للحصول على ملف الترخيص، يتعين عليك تقديم عنوان IP لخادمك وعنوان Mac إلى خدمة العملاء)',
      upError: 'عذرا، الملف الذي قمت بتحميله خاطئ، يرجى رفعه مرة أخرى',
      chooseFile_b: 'حدد مستندًا',
      upFile_b: 'تحميل ملف الترخيص',
      success: {
          span_1: 'تم التفويض بنجاح',
          span_2: 'الدخول إلى منصة إدارة المؤسسات...',
          span_3: '(إذا لم تدخل لفترة طويلة، يرجى تحديث المتصفح)'
      },
      dialog: {
          title: 'الرجاء تغيير كلمة مرور المسؤول',
          span: 'لم يتم تغيير كلمة مرور المسؤول الخاصة بك بعد، يرجى تغيير كلمة المرور الخاصة بك على الفور',
          button: 'يُقدِّم'
      }
  },
  lang: {
    'zh-CN': 'الصينية المبسطة',
    'zh-TW': 'الصينية التقليدية',
    en: 'إنجليزي',
    ja: 'اليابانية',
    ko: 'الكورية',
    th: 'التايلاندية',
    ar: 'عربي',
    bn: 'البنغالية',
    de: 'ألمانية',
    el: 'اليونانية',
    es: 'الأسبانية',
    fa: 'اللغة الفارسية',
    fr: 'فرنسي',
    id: 'الاندونيسية',
    it: 'ايطالي',
    iw: 'اللغة العبرية',
    ku: 'كردي',
    nl: 'هولندي',
    pl: 'تلميع',
    pt: 'البرتغالية',
    ro: 'روماني',
    ru: 'الروسية',
    tr: 'اللغة التركية',
    vi: 'الفيتنامية'
  },
  authorized: {
      org: 'مؤسسة معتمدة',
      userLimit: 'حد المستخدم',
      roomMax: 'الحد الأقصى لسعة قاعة المؤتمرات السحابية',
      roomCountLimit: 'الحد الأقصى لعدد غرف الاجتماعات السحابية عبر الإنترنت',
      authorizedActiveDate: 'تاريخ تفعيل ترخيص السحابة الخاصة',
      authorizedValidDate: 'ترخيص السحابة الخاصة صالح حتى',
      button_up: 'تحديث الترخيص',
      upFileError: 'لم يتم اختيار اي ملف'
  },
  index_title: 'منصة إدارة المؤسسات ViiTalk',
  brand: {
    table: {
        th: {
            brandNumber: 'رقم الدار',
            bindNumber: 'ربط رقم الشاشة الكبيرة',
            onlineStatus: 'الموجودين',
            lastLoginTime: 'آخر وقت لتسجيل الدخول'
        },
        col: {
            content: {
                onlineStatus: {
                    online: 'متصل',
                    offline: 'غير متصل على الانترنت'
                }
            }
        }
    }
  }
}