import InfoGroup from "@/components/pushInfo/infoGroup.vue";

export default {
  language: 'Türkçe',
  serverError: 'Sunucuda anormallik, lütfen yöneticiyle iletişime geçin',
  opearteSuccess: 'Başarılı operasyon',
  agreement: {
      agreement_1: 'Okudum ve kabul ettim',
      agreement_2: '\'Kullanıcı Sözleşmesi\'',
      agreement_3: 'Ve',
      agreement_4: '\'Gizlilik Politikası\'',
      agreement_5: 'Sözleşmeyi kabul etmek için lütfen kutuyu işaretleyin'
  },
  login: {
      webName: 'ViiTALK kurumsal yönetim platformu',
      website: 'Resmi internet sitesi:',
      tel: 'Telefon:',
      email: 'Posta:',
      title: 'Kurumsal kullanıcı girişi',
      inputPlaceholder: {
          name: 'Lütfen kullanıcı adını/e-posta adresini girin',
          pwd: 'Şifrenizi giriniz Lütfen'
      },
      button: {
          login: 'Giriş yapmak',
          email: 'E-posta Girişi',
          account: 'Hesap girişi',
          register: 'Kurumsal kullanıcı kaydı',
          pwd: 'şifreyi unut'
      },
      error: {
          uPwd: 'Kullanıcı adı ya da parola yanlış',
          uLock: 'Hesap kilitlendi',
          uLeave: 'İstifa ettiniz ve sistemi kullanamıyorsunuz',
          uRelogin: 'Zaten başka bir yerde oturum açtınız!!!',
          uReplaceLogin: 'Hesabınıza başka bir yerden giriş yapıldı ve siz çıkış yapmak zorunda kaldınız. Eğer sizin hatanız değilse şifreniz sızdırılmış olabilir. Lütfen tekrar giriş yapın ve en kısa sürede şifrenizi değiştirin.',
          user_null: 'lütfen kullanıcı adını girin',
          pwd_null: 'Lütfen şifre giriniz'
      }
  },
  personal: {
      companyNumber: 'Girişim',
      administrators: 'yönetici',
      changeLanguage: 'dili değiştir',
      logout: 'oturumu Kapat'
  },
  companyMessage: {
      adminPwd: 'Yönetici şifresi',
      adminUserName: 'Yönetici adı',
      adminEmail: 'Yönetici e-postası',
      companyFullName: 'Şirketin tam adı (yasal olarak kayıtlı adı)',
      companySimpleNameCn: 'Şirket kısaltması',
      companySimpleNameEn: 'Şirket kısaltması (İngilizce)',
      companyWebsite: 'Kurumsal internet sitesi',
      companyNumber: 'Kurumsal grup numarası',
      companyNoAuditJoin: 'İncelemeden katıl',
      adminExplain: 'Yönetici adı tüm kurumsal adres defteri üyeleri tarafından görülebilir',
      companySimpleExplain: 'Şirket kısaltması, Rooms cihazının ana sayfasında görüntülenecek ve şirket adres defterinin tüm üyeleri tarafından görülebilecektir.',
      button: {
          Edit: 'Tekrar düzeltme yapmak'
      },
      dialog: {
          editpwd: {
              title: 'Yönetici şifresini değiştir',
              label: {
                  oldPwd: 'eski Şifre',
                  newPwd: 'Yeni Şifre',
                  reNewPwd: 'Yeni şifreyi onaylayın'
              },
              inputPlaceholder: {
                  oldPwd: 'Lütfen eski şifreyi girin',
                  newPwd: 'Lütfen yeni bir şifre girin',
                  reNewPwd: 'Yeni şifreyi onaylamak için lütfen girin'
              }
          },
          editName: {
              title: 'Yönetici adını değiştirin',
              label: {
                  name: 'yeni isim'
              },
              inputPlaceholder: {
                  name: 'Lütfen yeni adı girin'
              }
          },
          editEmail: {
              title: 'Yönetici e-postasını değiştirin',
              title2: 'E-posta adresinizi henüz bağlamadınız. Lütfen e-posta adresinizi zamanında bağlayın.',
              label: {
                  email: 'yeni posta kutusu'
              },
              inputPlaceholder: {
                  email: 'Lütfen yeni bir e-posta girin'
              }
          },
          editCompanyFullName: {
              title: 'Şirketin tam adını değiştirin',
              label: {
                  name: 'yeni tam ad'
              },
              inputPlaceholder: {
                  name: 'Lütfen yeni tam adı girin'
              }
          },
          editCompanyAbbreviationCn: {
              title: 'Şirket kısaltmasını değiştirin (Çince)',
              label: {
                  name: 'Yeni kısaltma'
              },
              inputPlaceholder: {
                  name: 'Lütfen yeni bir kısa ad girin'
              }
          },
          editCompanyAbbreviationEn: {
              title: 'Şirket kısaltmasını değiştirin (İngilizce)'
          },
          editWebsite: {
              title: 'Kurumsal web sitesi adresini değiştirin',
              label: {
                  website: 'Yeni URL'
              },
              inputPlaceholder: {
                  website: 'Lütfen yeni URL girin'
              }
          },
          editNoAuditJoin: {
              title: 'Uygulama gerektirmeyen birleştirme anahtarını değiştirin',
              radio: {
                  open: 'Açık',
                  close: 'kapanış'
              }
          }
      },
      validator: {
          value_null: 'Değer boş olamaz',
          oldPwd: {
              null: 'Lütfen eski şifreyi girin'
          },
          newPwd: {
              length: 'Lütfen 6 karakter veya daha uzun bir şifre belirleyin'
          },
          reNewPwd: {
              consistent: 'İki kez girilen şifreler tutarsız!'
          },
          name: {
              length: 'Ad 20 karakterden uzun olamaz'
          },
          email: {
              rule: 'E-posta formatı yanlış'
          },
          NoAuditJoin: {
              null: 'Lütfen bir öğe seçin'
          }
      }
  },
  register: {
      step_1: 'kullanıcı adı ve şifre',
      step_2: 'Şirket bilgilerini doldurun',
      step_3: 'Sona ermek',
      head: 'Kurumsal kullanıcı kaydı',
      usernameError: 'Üye adı 6-20 karakter uzunluğunda olmalı, Çince karakterler hariç olmalı ve bir harfle başlamalıdır.',
      usernameError_exist: 'Kullanıcı adı zaten mevcut, lütfen kullanıcı adınızı değiştirin',
      emailError: 'E-posta formatı yanlış',
      emailError_exist: 'Bu e-posta zaten var',
      passwordError_length: '6-20 karakter, yalnızca alfanümerik ve noktalama işaretleri',
      passwordError_same: 'Şifre aynı kullanıcı adı olamaz',
      passwordError: 'Şifre formatı yanlış',
      rePasswordError: 'İki kez girilen şifreler tutarsız, lütfen tekrar girin',
      companyNameError: 'Lütfen şirketin tam adını girin',
      companyAbbreviationError: 'Lütfen şirket kısaltmasını girin',
      orgError_exist:'Şirket tescil edildi, lütfen işletme adınızı değiştirin',
      button: {
          next: 'Sonraki adım',
          login: 'hemen giriş yapın'
      },
      inputPlaceholder: {
          username: 'Kullanıcı adı',
          email: 'Posta',
          password: 'şifreyi belirle',
          rePassword: 'Şifreyi tekrar girin',
          name: 'Şirketin tam adı (yasal olarak kayıtlı adı)*',
          simpleNameCN: 'Şirket kısaltması (Çince)*',
          simpleNameEN: 'Şirket kısaltması (İngilizce)',
          website: 'Şirket web sitesi (www)'
      },
      success: 'Başarıyla bir işletme hesabı oluşturdunuz!',
      location: 'konum:',
      Scale: 'ölçek:'
  },
  forgotPwd: {
      head: 'E-posta alımı',
      resetSuccess: 'Şifrenizi başarıyla sıfırladınız!',
      inputPlaceholder: {
          code: 'lütfen doğrulama kodunu girin'
      },
      sendCodeError: 'Yanlış doğrulama kodu',
      sendCodeError_sended: 'Doğrulama kodu gönderildi, lütfen kontrol edin',
      button: {
          getCode: 'doğrulama kodunu al',
          reGetCode: 'Doğrulama kodunu tekrar alın'
      }
  },
  button: {
      ok: 'onaylamak',
      cancel: 'İptal etmek',
      search: 'aramak',
      clear: 'Temizlemek'
  },
  device: {
      button: {
          setContent: 'Push zamanlamasını yapılandırma',
          setGroupMng: 'Grup yöneticisini ayarla',
          startNow: 'Şimdi itin',
          stopNow: 'İtmeyi bırak',
          add: 'Cihaz ekle',
          search: 'aramak',
          adjust: 'Gruplandırmayı ayarlayın',
          del: 'silmek'
      },
      inputPlaceholder: {
          search: 'Cihaz numarası/Cep telefonu numarası/ViiTALK numarası'
      },
      group: {
          add: 'Grup ekle',
          edit: 'Grubu düzenle',
          del: 'Grubu sil'
      },
      table: {
          th_name: 'Cihaz adı',
          th_number: 'Cihaz numarası',
          th_time: 'Son giriş zamanı',
          th_brandNumber: 'Konferans evi numarası',
          th_online: 'çevrimiçi',
          th_status: 'durum',
          th_bindNumber: 'Büyük ekran numarasını bağlayın'
      },
      dialog: {
          addGroupTitle: 'Lütfen bir grup adı girin',
          inputPlaceholder: {
              groupName: 'Lütfen grup adını girin'
          },
          delGroupTitle: 'Grubu sil',
          delGroupTips: 'Grubu silmek istediğinizden emin misiniz?',
          editGroupTitle: 'Grubu düzenle',
          groupMng: {
              title: 'Grup yöneticisini düzenle',
              inputPlaceholder: {
                  name: 'Lütfen yönetici adını girin'
              },
              label: {
                  name: 'Yönetici adı'
              }
          },
          addDevice: {
              title: 'Cihaz ekle',
              inputPlaceholder: {
                  name: 'Lütfen cihaz adını girin',
                  number: 'Lütfen cihaz numarasını girin'
              },
              label: {
                  name: 'Cihaz adı',
                  number: 'Cihaz numarası',
                  group: 'Lütfen bir cihaz grubu seçin'
              }
          },
          delDevice: {
              title: 'Aygıtı kaldır',
              tips: 'Cihazı silmek istediğinizden emin misiniz?'
          },
          startNowDevice: {
              title: 'Şimdi itin',
              tips: 'Şimdi itmek istediğinden emin misin?'
          },
          stopNowDevice: {
              title: 'İtmeyi bırak',
              tips: 'İtmeyi bırakmak istediğinden emin misin?'
          },
          adjustTitle: 'Gruplandırmayı ayarlayın',
          editDevice: {
              title: 'Cihaz adını değiştir',
              inputPlaceholder: {
                  name: 'Lütfen cihaz adını girin'
              }
          },
          noChildren: {
              title: 'uyarmak',
              tips: 'Lütfen önce bir grup ekleyin'
          }
      },
      validator: {
          group: {
              name_null: 'Grup adı boş olamaz',
              name_length: '10 karakteri aşamaz'
          },
          number_null: 'Numara boş olamaz',
          number_rule: '6 veya 8 ile başlayan yalnızca 10 rakam olabilir',
          name_null: 'Terminal adı boş olamaz'
      }
  },
  user: {
      button: {
          add: 'Kullanıcı Ekle'
      },
      inputPlaceholder: {
          search: 'ViiTALK numarası'
      },
      table: {
          th_name: 'İsim',
          th_number: 'Cep telefonu numarası/Maizhe numarası'
      },
      dialog: {
          addUser: {
              title: 'Kullanıcı Ekle',
              inputPlaceholder: {
                  number: 'Lütfen ViiTALK numarasını girin'
              },
              label: {
                  number: 'ViiTALK numarası',
                  group: 'Lütfen kullanıcı grubunu seçin'
              }
          },
          delUser: {
              title: 'kullanıcıları sil',
              tips: 'Kullanıcıyı silmek istediğinizden emin misiniz?'
          }
      },
      validator: {
          number_null: 'Numara boş olamaz',
          number_rule: 'Bu numara mevcut değil'
      }
  },
  addrbook: {
      return: {
          delDevice: {
              tips_1: 'Silinecek grup mevcut değil',
              tips_2: 'Grubun altında silinecek alt gruplar bulunmaktadır ve silinemez.',
              tips_3: 'Grubun altında silinecek ve silinemeyecek kişiler var.'
          },
          addDevice: {
              tips_1: 'Zaten eklendi, lütfen diğer grupları görüntüleyin',
              tips_2: 'Diğer şirketler tarafından eklendi',
              tips_3: 'Bu numara mevcut değil'
          }
      }
  },
  audit: {
      option: {
          label: {
              NoPass: 'Geçmedi',
              hasRefuse: 'Reddedilmiş',
              hasPass: 'geçti',
              hasInvalid: 'günü geçmiş',
              all: 'Tümü'
          }
      },
      button: {
          pass: 'geçmek',
          reject: 'reddetmek'
      },
      table: {
          th_number: 'Cihaz No',
          th_date: 'tarih',
          th_status: 'durum',
          th_source: 'kaynak'
      },
      dialog: {
          passTitle: 'Onaylı',
          reject: {
              title: 'İncelemeyi reddet',
              tips: 'İncelemeyi reddetmek istediğinizden emin misiniz?'
          }
      },
      api: {
          req: {
              tips_1: 'İnceleme geçilemedi, seçilen öğeler farklı kaynaklardan geliyor!',
              tips_2: 'Lütfen tahsis edilecek bölgeyi seçin'
          },
          return: {
              tips_1: 'Cihaz numarası mevcut değil',
              tips_2: 'İşletmeye katıldınız',
              tips_3: 'Zaten eklendi, lütfen diğer grupları görüntüleyin'
          }
      }
  },
  conferenceLog: {
      inputPlaceholder: 'Lütfen içeriği girin',
      table: {
          th: {
              roomId: 'oda numarası',
              state: 'oda durumu',
              username: 'Jid tarafından oluşturuldu',
              createTime: 'Toplantı oluşturma zamanı',
              closeTime: 'toplantı bitiş zamanı',
              duration: 'Süre'
          }
      },
      dialog: {
          title: 'Üyenin odaya katılması/çıkış yapması',
          table: {
              th: {
                  username: 'Jid tarafından oluşturuldu',
                  joinTime: 'katıl',
                  leaveTime: 'ayrılmak',
                  duration: 'Süre'
              }
          }
      }
  },
  callLog: {
      table: {
          th: {
              callerMzNum: 'arayan numara',
              callTime: 'Arama süresi',
              endTime: 'Bitiş zamanı',
              duration: 'Süre',
              responderMzNum: 'aranan numara'
          }
      }
  },
  roomStatus: {
      table: {
          th: {
              roomMessage: 'Temel oda bilgileri',
              Attendees: 'katılımcı',
              bystander: 'seyirci',
              join: 'Eklenecek',
              exited: 'Çıkıldı'
          }
      },
      roomNumber: 'Konferans odası numarası',
      name: 'Toplantı odası adı',
      dissolution: 'Dağıt',
      pattern: 'modeli',
      confluence: 'izdiham',
      forward: 'İleri',
      createdTime: 'yaratılış zamanı',
      limit: 'Sürüm/toplam kişi sayısı sınırı',
      createMode: 'desen oluştur',
      order: 'Randevu (toplantı)',
      simple: 'Sıradan çok oyunculu',
      type: 'Toplantı türü',
      creator: 'yaratıcı',
      host: 'Ev sahibi:',
      windowMode: 'Pencere modu:',
      WindowMode1: 'Bir büyük ve birçok küçük',
      WindowMode2: 'Fayans',
      hide: 'Gizlenip gizlenmeyeceği:',
      forbidden: 'Buğdayın yasaklanıp yasaklanmayacağı:',
      inorder: 'emir:',
      source: 'kaynak:',
      joinTime: 'Katılım zamanı:',
      comeOut: 'Lütfen dışarı çık',
      openMic: 'mikrofonu aç',
      closeMic: 'Mikrofonu kapat',
      leave: 'ayrılmak:',
      peopleNumber: 'İnsanların sayısı:'
  },
  menu: {
      index: 'ön Sayfa',
      device_manage: 'Cihaz yönetimi',
      user_manage: 'Kullanıcı yönetimi',
      conference_brand: 'Konferans kapı numarası',
      audit_manage: 'Denetim yönetimi',
      orgMessage: 'Şirket bilgisi',
      roomStatus: 'devam eden toplantı',
      p2pCallLog: 'Görüntülü görüşme geçmişi',
      conferenceLog: 'görüşme süreleri',
      authorizedMessage: 'Yetkilendirme bilgileri',
      title: 'Kurumsal yönetim platformu',
      account_mangae: 'Hesap Yönetimi',
      contacts: 'Grup adres defteri',
      records: 'çağrı kayıtları',
      LicenseInformation: 'Yetkilendirme bilgileri',
      pushInfo: 'Bilgi aktarımı',
      infoGroup: 'Grup yönetimini itin'
  },
  download: 'uygulamaları indir',
  unauthorized: {
      title: 'ViiTALK kurumsal yönetim platformu (yetkisiz)',
      span_1: 'Sunucunuzun ViiTALK hizmetine yetki vermediği tespit edildi,',
      span_2: 'Yetkilendirme dosyasını almak için lütfen müşteri hizmetlerine başvurun.',
      span_3: '(Yetkilendirme dosyasını alabilmek için sunucu IP adresinizi ve Mac adresinizi müşteri hizmetlerine vermeniz gerekmektedir)',
      upError: 'Üzgünüz, yüklediğiniz dosya hatalı, lütfen tekrar yükleyin',
      chooseFile_b: 'Bir belge seçin',
      upFile_b: 'Yetkilendirme dosyasını yükle',
      success: {
          span_1: 'Yetkilendirme başarılı',
          span_2: 'Kurumsal yönetim platformuna giriş...',
          span_3: '(Uzun süredir giriş yapmadıysanız lütfen tarayıcınızı yenileyiniz)'
      },
      dialog: {
          title: 'Lütfen yönetici şifresini değiştirin',
          span: 'Yönetici şifreniz henüz değiştirilmedi, lütfen şifrenizi hemen değiştirin',
          button: 'göndermek'
      }
  },
  lang: {
    'zh-CN': 'Basitleştirilmiş Çince',
    'zh-TW': 'Geleneksel çince',
    en: 'İngilizce',
    ja: 'Japonca',
    ko: 'Koreli',
    th: 'Tay dili',
    ar: 'Arapça',
    bn: 'Bengalce',
    de: 'Almanca',
    el: 'Yunan',
    es: 'İspanyol',
    fa: 'Farsça',
    fr: 'Fransızca',
    id: 'Endonezya dili',
    it: 'İtalyan',
    iw: 'İbranice',
    ku: 'Kürt',
    nl: 'Flemenkçe',
    pl: 'Lehçe',
    pt: 'Portekizce',
    ro: 'Romen',
    ru: 'Rusça',
    tr: 'Türkçe',
    vi: 'Vietnam'
  },
  authorized: {
      org: 'Yetkili kuruluş',
      userLimit: 'Kullanıcı sınırı',
      roomMax: 'Bulut konferans odasının maksimum kapasitesi',
      roomCountLimit: 'Maksimum çevrimiçi bulut toplantı odası sayısı',
      authorizedActiveDate: 'Özel Bulut Lisansı Etkinleştirme Tarihi',
      authorizedValidDate: 'Özel bulut lisansı şu tarihe kadar geçerlidir:',
      button_up: 'Yetkilendirmeyi güncelle',
      upFileError: 'Dosya seçilmedi'
  },
  index_title: 'ViiTalk Kurumsal Yönetim Platformu',
  brand: {
    table: {
        th: {
            brandNumber: 'ev numarası',
            bindNumber: 'Büyük ekran numarasını bağlayın',
            onlineStatus: 'çevrimiçi durum',
            lastLoginTime: 'son giriş zamanı'
        },
        col: {
            content: {
                onlineStatus: {
                    online: 'çevrimiçi',
                    offline: 'Çevrimdışı'
                }
            }
        }
    }
  }
}