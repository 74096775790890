import InfoGroup from "@/components/pushInfo/infoGroup.vue";

export default {
  language: 'বাংলা',
  serverError: 'সার্ভারের অস্বাভাবিকতা, অনুগ্রহ করে প্রশাসকের সাথে যোগাযোগ করুন',
  opearteSuccess: 'সফল অপারেশন',
  agreement: {
      agreement_1: 'পড়েছেন এবং সম্মত হয়েছেন',
      agreement_2: '\'ব্যবহারকারী চুক্তি\'',
      agreement_3: 'এবং',
      agreement_4: '\'গোপনীয়তা নীতি\'',
      agreement_5: 'চুক্তিতে সম্মত হতে অনুগ্রহ করে বক্সটি চেক করুন৷'
  },
  login: {
      webName: 'ViiTALK এন্টারপ্রাইজ ম্যানেজমেন্ট প্ল্যাটফর্ম',
      website: 'সরকারী ওয়েবসাইট:',
      tel: 'টেলিফোন:',
      email: 'মেইল:',
      title: 'এন্টারপ্রাইজ ব্যবহারকারী লগইন',
      inputPlaceholder: {
          name: 'অনুগ্রহ করে ব্যবহারকারীর নাম/ইমেল লিখুন',
          pwd: 'আপনার পাসওয়ার্ড দিন'
      },
      button: {
          login: 'প্রবেশ করুন',
          email: 'ইমেইল লগইন',
          account: 'অ্যাকাউন্টে লগইন',
          register: 'এন্টারপ্রাইজ ব্যবহারকারী নিবন্ধন',
          pwd: 'পাসওয়ার্ড ভুলে যান'
      },
      error: {
          uPwd: 'ভুল ব্যবহারকারী নাম বা পাসওয়ার্ড',
          uLock: 'অ্যাকাউন্ট লক করা হয়েছে',
          uLeave: 'আপনি পদত্যাগ করেছেন এবং সিস্টেম ব্যবহার করতে পারবেন না',
          uRelogin: 'আপনি ইতিমধ্যেই অন্য কোথাও লগ ইন করেছেন!!!',
          uReplaceLogin: 'আপনার অ্যাকাউন্ট অন্য কোথাও লগ ইন করা হয়েছে এবং আপনাকে লগ আউট করতে বাধ্য করা হয়েছে৷ যদি এটি আপনার দোষ না হয় তবে আপনার পাসওয়ার্ড ফাঁস হয়ে থাকতে পারে দয়া করে আবার লগ ইন করুন এবং যত তাড়াতাড়ি সম্ভব আপনার পাসওয়ার্ড পরিবর্তন করুন৷',
          user_null: 'অনুগ্রহ করে ব্যবহারকারীর নাম লিখুন',
          pwd_null: 'পাসওয়ার্ড লিখুন'
      }
  },
  personal: {
      companyNumber: 'এন্টারপ্রাইজ',
      administrators: 'প্রশাসক',
      changeLanguage: 'ভাষা পরিবর্তন করুন',
      logout: 'সাইন আউট'
  },
  companyMessage: {
      adminPwd: 'প্রশাসকের পাসওয়ার্ড',
      adminUserName: 'প্রশাসকের নাম',
      adminEmail: 'প্রশাসকের ইমেল',
      companyFullName: 'কোম্পানির পুরো নাম (আইনিভাবে নিবন্ধিত নাম)',
      companySimpleNameCn: 'কোম্পানির সংক্ষিপ্ত নাম',
      companySimpleNameEn: 'কোম্পানির সংক্ষিপ্ত নাম (ইংরেজি)',
      companyWebsite: 'কর্পোরেট ওয়েবসাইট',
      companyNumber: 'এন্টারপ্রাইজ গ্রুপ নম্বর',
      companyNoAuditJoin: 'পর্যালোচনা ছাড়াই যোগদান করুন',
      adminExplain: 'প্রশাসকের নাম সমস্ত কর্পোরেট ঠিকানা বই সদস্যদের কাছে দৃশ্যমান৷',
      companySimpleExplain: 'কোম্পানির সংক্ষিপ্ত নামটি রুম ডিভাইসের হোমপেজে প্রদর্শিত হবে এবং কোম্পানির ঠিকানা বইয়ের সকল সদস্যদের কাছে দৃশ্যমান হবে।',
      button: {
          Edit: 'সংশোধন করুন'
      },
      dialog: {
          editpwd: {
              title: 'প্রশাসকের পাসওয়ার্ড পরিবর্তন করুন',
              label: {
                  oldPwd: 'পুরানো পাসওয়ার্ড',
                  newPwd: 'নতুন পাসওয়ার্ড',
                  reNewPwd: 'নতুন পাসওয়ার্ড নিশ্চিত করুন'
              },
              inputPlaceholder: {
                  oldPwd: 'পুরানো পাসওয়ার্ড লিখুন',
                  newPwd: 'একটি নতুন পাসওয়ার্ড লিখুন',
                  reNewPwd: 'নতুন পাসওয়ার্ড নিশ্চিত করতে প্রবেশ করুন'
              }
          },
          editName: {
              title: 'প্রশাসকের নাম পরিবর্তন করুন',
              label: {
                  name: 'নতুন নাম'
              },
              inputPlaceholder: {
                  name: 'নতুন নাম লিখুন'
              }
          },
          editEmail: {
              title: 'প্রশাসকের ইমেল পরিবর্তন করুন',
              title2: 'আপনি এখনও আপনার ইমেল ঠিকানা আবদ্ধ করেননি সময় আপনার ইমেল ঠিকানা.',
              label: {
                  email: 'নতুন মেইল ​​বক্স'
              },
              inputPlaceholder: {
                  email: 'একটি নতুন ইমেল লিখুন'
              }
          },
          editCompanyFullName: {
              title: 'কোম্পানির পুরো নাম পরিবর্তন করুন',
              label: {
                  name: 'নতুন পুরো নাম'
              },
              inputPlaceholder: {
                  name: 'নতুন পুরো নাম লিখুন'
              }
          },
          editCompanyAbbreviationCn: {
              title: 'কোম্পানির সংক্ষিপ্ত রূপ পরিবর্তন করুন (চীনা)',
              label: {
                  name: 'নতুন সংক্ষেপণ'
              },
              inputPlaceholder: {
                  name: 'একটি নতুন সংক্ষিপ্ত নাম লিখুন'
              }
          },
          editCompanyAbbreviationEn: {
              title: 'কোম্পানির সংক্ষিপ্ত রূপ পরিবর্তন করুন (ইংরেজি)'
          },
          editWebsite: {
              title: 'কর্পোরেট ওয়েবসাইটের ঠিকানা পরিবর্তন করুন',
              label: {
                  website: 'নতুন URL'
              },
              inputPlaceholder: {
                  website: 'নতুন URL লিখুন'
              }
          },
          editNoAuditJoin: {
              title: 'অ্যাপ্লিকেশন-মুক্ত যোগদানের সুইচ পরিবর্তন করুন',
              radio: {
                  open: 'খোলা',
                  close: 'বন্ধ'
              }
          }
      },
      validator: {
          value_null: 'মান খালি হতে পারে না',
          oldPwd: {
              null: 'পুরানো পাসওয়ার্ড লিখুন'
          },
          newPwd: {
              length: 'দয়া করে 6 অক্ষর বা তার বেশি দৈর্ঘ্যের একটি পাসওয়ার্ড সেট করুন'
          },
          reNewPwd: {
              consistent: 'দুইবার প্রবেশ করা পাসওয়ার্ডগুলি অসামঞ্জস্যপূর্ণ!'
          },
          name: {
              length: 'নাম 20 অক্ষরের বেশি হতে পারে না'
          },
          email: {
              rule: 'ই-মেইল বিন্যাস ভুল'
          },
          NoAuditJoin: {
              null: 'একটি আইটেম নির্বাচন করুন'
          }
      }
  },
  register: {
      step_1: 'ব্যবহারকারীর নাম এবং পাসওয়ার্ড',
      step_2: 'কোম্পানির তথ্য পূরণ করুন',
      step_3: 'শেষ করুন',
      head: 'এন্টারপ্রাইজ ব্যবহারকারী নিবন্ধন',
      usernameError: 'সদস্যের নাম অবশ্যই 6-20 অক্ষর লম্বা হতে হবে, চাইনিজ অক্ষর সহ নয় এবং একটি অক্ষর দিয়ে শুরু হবে।',
      usernameError_exist: 'ব্যবহারকারীর নাম ইতিমধ্যেই বিদ্যমান, অনুগ্রহ করে আপনার ব্যবহারকারীর নাম পরিবর্তন করুন৷',
      emailError: 'ই-মেইল বিন্যাস ভুল',
      emailError_exist: 'ই - মেইল ​​টি আগে থেকেই আছে',
      passwordError_length: '6-20 অক্ষর, শুধুমাত্র আলফানিউমেরিক এবং বিরাম চিহ্ন',
      passwordError_same: 'পাসওয়ার্ড একই ব্যবহারকারীর নাম হতে পারে না',
      passwordError: 'পাসওয়ার্ড বিন্যাস ভুল',
      rePasswordError: 'দুবার প্রবেশ করা পাসওয়ার্ডগুলি অসামঞ্জস্যপূর্ণ, অনুগ্রহ করে পুনরায় প্রবেশ করুন৷',
      companyNameError: 'কোম্পানির পুরো নাম লিখুন',
      companyAbbreviationError: 'অনুগ্রহ করে কোম্পানির সংক্ষিপ্ত নাম লিখুন',
      orgError_exist:'সংস্থাটি নিবন্ধিত হয়েছে, অনুগ্রহ করে আপনার ব্যবসায়ের নাম পরিবর্তন করুন',
      button: {
          next: 'পরবর্তী পর্ব',
          login: 'অবিলম্বে লগ ইন করুন'
      },
      inputPlaceholder: {
          username: 'ব্যবহারকারীর নাম',
          email: 'মেইল',
          password: 'পাসওয়ার্ড সেট করুন',
          rePassword: 'আবার পাসওয়ার্ড দিন',
          name: 'কোম্পানির পুরো নাম (আইনিভাবে নিবন্ধিত নাম)*',
          simpleNameCN: 'কোম্পানির সংক্ষিপ্ত নাম (চীনা)*',
          simpleNameEN: 'কোম্পানির সংক্ষিপ্ত নাম (ইংরেজি)',
          website: 'কোম্পানির ওয়েবসাইট (www)'
      },
      success: 'আপনি সফলভাবে একটি ব্যবসায়িক অ্যাকাউন্ট তৈরি করেছেন!',
      location: 'অবস্থান:',
      Scale: 'স্কেল:'
  },
  forgotPwd: {
      head: 'ইমেল পুনরুদ্ধার',
      resetSuccess: 'আপনি সফলভাবে আপনার পাসওয়ার্ড রিসেট করেছেন!',
      inputPlaceholder: {
          code: 'যাচাইকরণ কোড লিখুন'
      },
      sendCodeError: 'ভুল যাচাইকরণ কোড',
      sendCodeError_sended: 'যাচাইকরণ কোড পাঠানো হয়েছে, এটি পরীক্ষা করুন',
      button: {
          getCode: 'যাচাইকরণ কোড পান',
          reGetCode: 'আবার যাচাইকরণ কোড পান'
      }
  },
  button: {
      ok: 'নিশ্চিত করুন',
      cancel: 'বাতিল করুন',
      search: 'অনুসন্ধান',
      clear: 'পরিষ্কার'
  },
  device: {
      button: {
          setContent: 'পুশ সময়সূচী কনফিগার করুন',
          setGroupMng: 'গ্রুপ অ্যাডমিনিস্ট্রেটর সেট আপ করুন',
          startNow: 'এখন ধাক্কা',
          stopNow: 'ধাক্কা দেওয়া বন্ধ করুন',
          add: 'যন্ত্র সংযুক্ত করুন',
          search: 'অনুসন্ধান',
          adjust: 'গ্রুপিং সামঞ্জস্য করুন',
          del: 'মুছে ফেলা'
      },
      inputPlaceholder: {
          search: 'ডিভাইস নম্বর/মোবাইল ফোন নম্বর/ViiTALK নম্বর'
      },
      group: {
          add: 'গ্রুপ যোগ করুন',
          edit: 'গোষ্ঠী সম্পাদনা করুন',
          del: 'গ্রুপ মুছুন'
      },
      table: {
          th_name: 'ডিভাইসের নাম',
          th_number: 'ডিভাইস সংখ্যা',
          th_time: 'শেষ লগইন সময়',
          th_brandNumber: 'কনফারেন্স হাউস নম্বর',
          th_online: 'অনলাইন',
          th_status: 'অবস্থা',
          th_bindNumber: 'বড় স্ক্রীন নম্বর বাঁধুন'
      },
      dialog: {
          addGroupTitle: 'একটি গ্রুপ নাম লিখুন',
          inputPlaceholder: {
              groupName: 'গ্রুপের নাম লিখুন'
          },
          delGroupTitle: 'গ্রুপ মুছুন',
          delGroupTips: 'আপনি কি নিশ্চিত যে আপনি গ্রুপটি মুছে ফেলতে চান?',
          editGroupTitle: 'গোষ্ঠী সম্পাদনা করুন',
          groupMng: {
              title: 'গ্রুপ ম্যানেজার সম্পাদনা করুন',
              inputPlaceholder: {
                  name: 'অনুগ্রহ করে প্রশাসকের নাম লিখুন'
              },
              label: {
                  name: 'প্রশাসকের নাম'
              }
          },
          addDevice: {
              title: 'যন্ত্র সংযুক্ত করুন',
              inputPlaceholder: {
                  name: 'অনুগ্রহ করে ডিভাইসের নাম লিখুন',
                  number: 'ডিভাইস নম্বর লিখুন'
              },
              label: {
                  name: 'ডিভাইসের নাম',
                  number: 'ডিভাইস সংখ্যা',
                  group: 'একটি ডিভাইস গ্রুপ নির্বাচন করুন'
              }
          },
          delDevice: {
              title: 'ডিভাইস অপসারণ',
              tips: 'আপনি কি নিশ্চিত আপনি ডিভাইস মুছে ফেলতে চান?'
          },
          startNowDevice: {
              title: 'এখন ধাক্কা',
              tips: 'আপনি কি নিশ্চিত আপনি এখন এটি ধাক্কা চান?'
          },
          stopNowDevice: {
              title: 'ধাক্কা দেওয়া বন্ধ করুন',
              tips: 'আপনি কি নিশ্চিত আপনি ধাক্কা বন্ধ করতে চান?'
          },
          adjustTitle: 'গ্রুপিং সামঞ্জস্য করুন',
          editDevice: {
              title: 'ডিভাইসের নাম পরিবর্তন করুন',
              inputPlaceholder: {
                  name: 'অনুগ্রহ করে ডিভাইসের নাম লিখুন'
              }
          },
          noChildren: {
              title: 'সতর্ক করা',
              tips: 'প্রথমে একটি গ্রুপ যোগ করুন'
          }
      },
      validator: {
          group: {
              name_null: 'গ্রুপের নাম খালি রাখা যাবে না',
              name_length: '10টি অক্ষরের বেশি হওয়া যাবে না'
          },
          number_null: 'নম্বর খালি হতে পারে না',
          number_rule: '6 বা 8 দিয়ে শুরু করে শুধুমাত্র 10 সংখ্যা হতে পারে',
          name_null: 'টার্মিনাল নাম খালি রাখা যাবে না'
      }
  },
  user: {
      button: {
          add: 'ব্যবহারকারী যোগ করুন'
      },
      inputPlaceholder: {
          search: 'ViiTALK নম্বর'
      },
      table: {
          th_name: 'নাম',
          th_number: 'মোবাইল ফোন নম্বর/মাইজে নম্বর'
      },
      dialog: {
          addUser: {
              title: 'ব্যবহারকারী যোগ করুন',
              inputPlaceholder: {
                  number: 'অনুগ্রহ করে ViiTALK নম্বর লিখুন'
              },
              label: {
                  number: 'ViiTALK নম্বর',
                  group: 'ব্যবহারকারী গ্রুপ নির্বাচন করুন'
              }
          },
          delUser: {
              title: 'ব্যবহারকারীদের মুছুন',
              tips: 'আপনি কি ব্যবহারকারীকে মুছতে চান?'
          }
      },
      validator: {
          number_null: 'নম্বর খালি হতে পারে না',
          number_rule: 'এই সংখ্যা বিদ্যমান নেই'
      }
  },
  addrbook: {
      return: {
          delDevice: {
              tips_1: 'যে গোষ্ঠীটি মুছে ফেলা হবে সেটি বিদ্যমান নেই৷',
              tips_2: 'গোষ্ঠীর অধীনে মুছে ফেলার জন্য উপগোষ্ঠী রয়েছে এবং মুছে ফেলা যাবে না।',
              tips_3: 'গোষ্ঠীর অধীনে এমন লোক রয়েছে যাদেরকে মুছে ফেলা হবে এবং মুছে ফেলা যাবে না।'
          },
          addDevice: {
              tips_1: 'ইতিমধ্যে যোগ করা হয়েছে, অন্যান্য গ্রুপ দেখুন',
              tips_2: 'অন্যান্য কোম্পানি দ্বারা যোগ করা হয়েছে',
              tips_3: 'এই সংখ্যা বিদ্যমান নেই'
          }
      }
  },
  audit: {
      option: {
          label: {
              NoPass: 'পাস করেনি',
              hasRefuse: 'প্রত্যাখ্যাত',
              hasPass: 'পাস',
              hasInvalid: 'মেয়াদোত্তীর্ণ',
              all: 'সব'
          }
      },
      button: {
          pass: 'পাস',
          reject: 'প্রত্যাখ্যান'
      },
      table: {
          th_number: 'ডিভাইস নং',
          th_date: 'তারিখ',
          th_status: 'অবস্থা',
          th_source: 'উৎস'
      },
      dialog: {
          passTitle: 'অনুমোদিত',
          reject: {
              title: 'পর্যালোচনা অস্বীকার করুন',
              tips: 'আপনি কি নিশ্চিত আপনি পর্যালোচনা প্রত্যাখ্যান করতে চান?'
          }
      },
      api: {
          req: {
              tips_1: 'পর্যালোচনা পাস করতে অক্ষম, নির্বাচিত আইটেম বিভিন্ন উত্স থেকে আসে!',
              tips_2: 'অনুগ্রহ করে বরাদ্দ করা অঞ্চল নির্বাচন করুন'
          },
          return: {
              tips_1: 'ডিভাইস নম্বর বিদ্যমান নেই',
              tips_2: 'আপনি এন্টারপ্রাইজে যোগদান করেছেন',
              tips_3: 'ইতিমধ্যে যোগ করা হয়েছে, অন্যান্য গ্রুপ দেখুন'
          }
      }
  },
  conferenceLog: {
      inputPlaceholder: 'বিষয়বস্তু লিখুন',
      table: {
          th: {
              roomId: 'রুম নম্বর',
              state: 'রুমের অবস্থা',
              username: 'জিদ তৈরি করেছে',
              createTime: 'মিটিং সৃষ্টির সময়',
              closeTime: 'মিটিং শেষ সময়',
              duration: 'সময়কাল'
          }
      },
      dialog: {
          title: 'সদস্য যোগদান/প্রস্থান রুম লগিং',
          table: {
              th: {
                  username: 'জিদ তৈরি করেছে',
                  joinTime: 'যোগ দিতে',
                  leaveTime: 'ছেড়ে',
                  duration: 'সময়কাল'
              }
          }
      }
  },
  callLog: {
      table: {
          th: {
              callerMzNum: 'কলিং নম্বর',
              callTime: 'সময় ডায়াল করুন',
              endTime: 'শেষ সময়',
              duration: 'সময়কাল',
              responderMzNum: 'নম্বর বলা হয়'
          }
      }
  },
  roomStatus: {
      table: {
          th: {
              roomMessage: 'বেসিক রুম তথ্য',
              Attendees: 'অংশগ্রহণকারী',
              bystander: 'দর্শক',
              join: 'যোগ করা',
              exited: 'প্রস্থান'
          }
      },
      roomNumber: 'সম্মেলন কক্ষ নম্বর',
      name: 'মিটিং রুমের নাম',
      dissolution: 'বিচ্ছিন্ন করা',
      pattern: 'মডেল',
      confluence: 'সঙ্গম',
      forward: 'ফরোয়ার্ড',
      createdTime: 'সৃষ্টির সময়',
      limit: 'রিলিজ/মোট সংখ্যা লোকের সীমা',
      createMode: 'প্যাটার্ন তৈরি করুন',
      order: 'অ্যাপয়েন্টমেন্ট (সভা)',
      simple: 'সাধারণ মাল্টিপ্লেয়ার',
      type: 'মিটিং টাইপ',
      creator: 'সৃষ্টিকর্তা',
      host: 'হোস্ট:',
      windowMode: 'উইন্ডো মোড:',
      WindowMode1: 'একটা বড় আর অনেকগুলো ছোট',
      WindowMode2: 'টালি',
      hide: 'লুকাতে হবে কিনা:',
      forbidden: 'গম নিষিদ্ধ কি না:',
      inorder: 'আদেশ:',
      source: 'উৎস:',
      joinTime: 'যোগদানের সময়:',
      comeOut: 'দয়া করে বেরিয়ে আসুন',
      openMic: 'মাইক্রোফোন চালু করুন',
      closeMic: 'মাইক্রোফোন বন্ধ করুন',
      leave: 'ছেড়ে দিন:',
      peopleNumber: 'জনগণের সংখ্যা:'
  },
  menu: {
      index: 'প্রথম পাতা',
      device_manage: 'যন্ত্র ব্যবস্থাপনা',
      user_manage: 'ইউজার ম্যানেজমেন্ট',
      conference_brand: 'সম্মেলনের দরজা নম্বর',
      audit_manage: 'অডিট ব্যবস্থাপনা',
      orgMessage: 'কর্পোরেট তথ্য',
      roomStatus: 'চলমান বৈঠক',
      p2pCallLog: 'ভিডিও কল ইতিহাস',
      conferenceLog: 'মিটিং মিনিট',
      authorizedMessage: 'অনুমোদন তথ্য',
      title: 'এন্টারপ্রাইজ ম্যানেজমেন্ট প্ল্যাটফর্ম',
      account_mangae: 'হিসাব ব্যবস্থাপনা',
      contacts: 'গ্রুপ ঠিকানা বই',
      records: 'কল রেকর্ড',
      LicenseInformation: 'অনুমোদন তথ্য',
      pushInfo: 'তথ্য ধাক্কা',
      infoGroup: 'পুশ গ্রুপ ম্যানেজমেন্ট'
  },
  download: 'অ্যাপস ডাউনলোড করুন',
  unauthorized: {
      title: 'ViiTALK এন্টারপ্রাইজ ম্যানেজমেন্ট প্ল্যাটফর্ম (অননুমোদিত)',
      span_1: 'এটি সনাক্ত করা হয়েছে যে আপনার সার্ভার ViiTALK পরিষেবাকে অনুমোদন করে না,',
      span_2: 'অনুমোদন ফাইল পেতে গ্রাহক পরিষেবার সাথে যোগাযোগ করুন.',
      span_3: '(অনুমোদন ফাইল পেতে, আপনাকে গ্রাহক পরিষেবাতে আপনার সার্ভার আইপি ঠিকানা এবং ম্যাক ঠিকানা প্রদান করতে হবে)',
      upError: 'দুঃখিত, আপনার আপলোড করা ফাইলটি ভুল, অনুগ্রহ করে আবার আপলোড করুন',
      chooseFile_b: 'একটি নথি নির্বাচন করুন',
      upFile_b: 'অনুমোদন ফাইল আপলোড করুন',
      success: {
          span_1: 'অনুমোদন সফল হয়েছে',
          span_2: 'এন্টারপ্রাইজ ম্যানেজমেন্ট প্ল্যাটফর্মে প্রবেশ করা হচ্ছে...',
          span_3: '(যদি আপনি দীর্ঘ সময়ের জন্য প্রবেশ না করে থাকেন তবে অনুগ্রহ করে ব্রাউজারটি রিফ্রেশ করুন)'
      },
      dialog: {
          title: 'প্রশাসক পাসওয়ার্ড পরিবর্তন করুন',
          span: 'আপনার প্রশাসকের পাসওয়ার্ড পরিবর্তন করা হয়নি, অনুগ্রহ করে অবিলম্বে এটি পরিবর্তন করুন',
          button: 'জমা'
      }
  },
  lang: {
    'zh-CN': 'সরলীকৃত চীনা',
    'zh-TW': 'ঐতিহ্যবাহী চাইনিজ',
    en: 'ইংরেজি',
    ja: 'জাপানিজ',
    ko: 'কোরিয়ান',
    th: 'থাই',
    ar: 'আরবি',
    bn: 'বাংলা',
    de: 'জার্মান',
    el: 'গ্রীক',
    es: 'স্পেনীয়',
    fa: 'ফার্সি',
    fr: 'ফরাসি',
    id: 'ইন্দোনেশিয়ান',
    it: 'ইতালীয়',
    iw: 'হিব্রু',
    ku: 'কুর্দি',
    nl: 'ডাচ',
    pl: 'পোলিশ',
    pt: 'পর্তুগীজ',
    ro: 'রোমানিয়ান',
    ru: 'রাশিয়ান',
    tr: 'তুর্কি',
    vi: 'ভিয়েতনামী'
  },
  authorized: {
      org: 'অনুমোদিত এন্টারপ্রাইজ',
      userLimit: 'ব্যবহারকারীর সীমা',
      roomMax: 'ক্লাউড কনফারেন্স রুমের সর্বোচ্চ ক্ষমতা',
      roomCountLimit: 'অনলাইন ক্লাউড মিটিং রুমের সর্বাধিক সংখ্যা',
      authorizedActiveDate: 'ব্যক্তিগত ক্লাউড লাইসেন্স সক্রিয়করণের তারিখ',
      authorizedValidDate: 'প্রাইভেট ক্লাউড লাইসেন্স পর্যন্ত বৈধ',
      button_up: 'অনুমোদন আপডেট করুন',
      upFileError: 'নির্বাচিত কোন ফাইল'
  },
  index_title: 'ViiTalk এন্টারপ্রাইজ ম্যানেজমেন্ট প্ল্যাটফর্ম',
  brand: {
    table: {
        th: {
            brandNumber: 'বাড়ির নম্বর',
            bindNumber: 'বড় স্ক্রীন নম্বর বাঁধুন',
            onlineStatus: 'অনলাইন অবস্থা',
            lastLoginTime: 'শেষ লগইন সময়'
        },
        col: {
            content: {
                onlineStatus: {
                    online: 'অনলাইন',
                    offline: 'অফলাইন'
                }
            }
        }
    }
  }
}