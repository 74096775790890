import InfoGroup from "@/components/pushInfo/infoGroup.vue";

export default {
  language: '日本語',
  serverError: 'サーバー異常ですので管理者にご連絡ください',
  opearteSuccess: '操作成功',
  agreement: {
      agreement_1: '読んで同意しました',
      agreement_2: '\'ユーザー規約\'',
      agreement_3: 'そして',
      agreement_4: '\'プライバシーポリシー\'',
      agreement_5: '協議に同意してください'
  },
  login: {
      webName: 'ViiTALK エンタープライズ管理プラットフォーム',
      webNameSchool: 'ViiTalkエンタープライズ管理プラットフォーム',
      website: '公式ウェブサイト：',
      tel: '電話：',
      email: '郵便：',
      title: 'ユーザーログイン',
      titleSchool: 'ユーザーログイン',
      inputPlaceholder: {
          name: 'ユーザー名/メールアドレスを入力してください',
          pwd: 'パスワードを入力してください'
      },
      button: {
          login: 'ログイン',
          email: 'メールログイン',
          account: 'アカウントログイン',
          register: 'エンタープライズユーザー登録',
          registerSchool: 'エンタープライズユーザー登録',
          pwd: 'パスワードを忘れる'
      },
      error: {
          uPwd: '間違ったユーザー名またはパスワード',
          uLock: 'アカウントがロックされました',
          uLeave: '退会したため制度が利用できない',
          uRelogin: 'すでに別の場所でログインしています!!!',
          uReplaceLogin: 'あなたのアカウントは別の場所でログインしており、強制的にログアウトされました。あなたのせいではない場合は、パスワードが漏洩した可能性がありますので、できるだけ早く再ログインし、パスワードを変更してください。',
          user_null: 'ユーザー名を入力してください',
          pwd_null: 'パスワードを入力してください'
      }
  },
  personal: {
      companyNumber: '企業',
      schoolNumber: '学校',
      administrators: '管理者',
      changeLanguage: '言語を切り替える',
      logout: 'サインアウト'
  },
  companyMessage: {
      adminPwd: '管理者パスワード',
      adminUserName: '管理者名',
      adminEmail: '管理者の電子メール',
      companyFullName: '会社の正式名称（法定登記上の名称）',
      companySimpleNameCn: '会社略称',
      companySimpleNameEn: '会社略称（英語）',
      companyWebsite: 'コーポレートサイト',
      companyNumber: '企業グループ番号',

      schoolFullName: '会社の正式名称（法定登記上の名称）',
      schoolSimpleNameCn: '略称',
      schoolSimpleNameEn: '略称（英語）',
      schoolWebsite: 'コーポレートサイト',
      schoolNumber: 'グループ番号',

      companyNoAuditJoin: 'レビューなしで参加する',
      adminExplain: '管理者名はすべての企業アドレス帳メンバーに表示されます',
      companySimpleExplain: '会社の略称は Rooms デバイスのホームページに表示され、会社のアドレス帳のすべてのメンバーに表示されます。',
      button: {
          Edit: '改訂'
      },
      dialog: {
          editpwd: {
              title: '管理者パスワードを変更する',
              label: {
                  oldPwd: '以前のパスワード',
                  newPwd: '新しいパスワード',
                  reNewPwd: '新しいパスワードを確認します'
              },
              inputPlaceholder: {
                  oldPwd: '古いパスワードを入力してください',
                  newPwd: '新しいパスワードを入力してください',
                  reNewPwd: '新しいパスワードを確認するために入力してください'
              }
          },
          editName: {
              title: '管理者名の変更',
              label: {
                  name: '新しい名前'
              },
              inputPlaceholder: {
                  name: '新しい名前を入力してください'
              }
          },
          editEmail: {
              title: '管理者の電子メールを変更する',
              title2: 'メールアドレスをまだバインドしていません。時間内にメールアドレスをバインドしてください。',
              label: {
                  email: '新しいメールボックス'
              },
              inputPlaceholder: {
                  email: '新しいメールアドレスを入力してください'
              }
          },
          editCompanyFullName: {
              title: '会社の正式名を変更します',
              label: {
                  name: '新しいフルネーム'
              },
              inputPlaceholder: {
                  name: '新しいフルネームを入力してください'
              }
          },
          editCompanyAbbreviationCn: {
              title: '会社略称の変更',
              label: {
                  name: '新しい略語'
              },
              inputPlaceholder: {
                  name: '新しい短縮名を入力してください'
              }
          },
          editCompanyAbbreviationEn: {
              title: '会社略称の変更（English）'
          },
          editWebsite: {
              title: '企業Webサイトのアドレスを変更する',
              label: {
                  website: '新しいURL'
              },
              inputPlaceholder: {
                  website: '新しいURLを入力してください'
              }
          },
          editNoAuditJoin: {
              title: 'アプリケーション不要の参加スイッチを変更する',
              radio: {
                  open: '開ける',
                  close: '閉鎖'
              }
          }
      },
      validator: {
          value_null: '値を空にすることはできません',
          oldPwd: {
              null: '古いパスワードを入力してください'
          },
          newPwd: {
              length: 'パスワードは6文字以上で設定してください'
          },
          reNewPwd: {
              consistent: '2 回入力したパスワードは一致しません。'
          },
          name: {
              length: '名前は 20 文字を超えることはできません'
          },
          email: {
              rule: '電子メールの形式が正しくありません'
          },
          NoAuditJoin: {
              null: '項目を選択してください'
          }
      }
  },
  register: {
      step_1: 'パスワード',
      step_2: '情報',
      step_3: '仕上げる',
      head: 'エンタープライズユーザー登録',
      usernameError: 'メンバー名は、漢字を含まず、文字で始まる 6 ～ 20 文字にする必要があります。',
      usernameError_exist: 'ユーザー名はすでに存在します。ユーザー名を変更してください',
      emailError: '電子メールの形式が正しくありません',
      emailError_exist: 'メールは既に存在します',
      passwordError_length: '6 ～ 20 文字、英数字と句読点のみ',
      passwordError_same: 'パスワードに同じユーザー名を使用することはできません',
      passwordError: 'パスワードの形式が正しくありません',
      rePasswordError: '2 回入力したパスワードは一致しません',
      companyNameError: '会社の正式名を入力してください',
      companyAbbreviationError: '会社略称を入力してください',
      orgError_exist:'会社が登記されていますので、商号を変更してください',
      button: {
          next: '次のステップ',
          login: 'すぐにログインしてください'
      },
      inputPlaceholder: {
          username: 'ユーザー名',
          email: '郵便',
          password: 'パスワードを設定してください',
          rePassword: 'パスワードを再度入力してください',
          name: '会社の正式名称（法定登録名）*',
          simpleNameCN: '会社略称（中国語）*',
          simpleNameEN: '会社略称（英語）',
          website: '会社ウェブサイト (www)'
      },
      success: 'ビジネスアカウントが正常に作成されました。',
      location: '位置：',
      Scale: '規模：'
  },
  forgotPwd: {
      head: '電子メールの取得',
      resetSuccess: 'パスワードが正常にリセットされました。',
      inputPlaceholder: {
          code: '確認コードを入力してください'
      },
      sendCodeError: '間違った確認コード',
      sendCodeError_sended: '認証コードを送信しましたのでご確認ください',
      button: {
          getCode: '確認コードを取得する',
          reGetCode: '確認コードを再度取得する'
      }
  },
  button: {
      ok: '確認する',
      cancel: 'キャンセル',
      search: '検索',
      clear: 'クリア'
  },
  device: {
      button: {
          setContent: 'プッシュスケジュールを設定する',
          setGroupMng: 'グループ管理者の設定',
          startNow: '今すぐプッシュ',
          stopNow: '押すのをやめてください',
          add: 'デバイスを追加',
          search: '検索',
          adjust: 'グループ化を調整する',
          del: '消去'
      },
      inputPlaceholder: {
          search: '端末番号/携帯電話番号/ViiTALK番号'
      },
      group: {
          add: 'グループを追加',
          edit: 'グループの編集',
          del: 'グループの削除'
      },
      table: {
          th_name: '装置名',
          th_number: '装置番号',
          th_time: '最終ログイン時間',
          th_brandNumber: '会議室番号',
          th_online: 'オンラインです',
          th_status: '州',
          th_bindNumber: '大画面番号をバインドする'
      },
      dialog: {
          addGroupTitle: 'グループ名を入力してください',
          inputPlaceholder: {
              groupName: 'グループ名を入力してください'
          },
          delGroupTitle: 'グループの削除',
          delGroupTips: 'グループを削除してもよろしいですか?',
          editGroupTitle: 'グループの編集',
          groupMng: {
              title: 'グループマネージャーの編集',
              inputPlaceholder: {
                  name: '管理者名を入力してください'
              },
              label: {
                  name: '管理者名'
              }
          },
          addDevice: {
              title: 'デバイスを追加',
              inputPlaceholder: {
                  name: 'デバイス名を入力してください',
                  number: 'デバイス番号を入力してください'
              },
              label: {
                  name: '装置名',
                  number: '装置番号',
                  group: 'デバイスグループを選択してください'
              }
          },
          delDevice: {
              title: 'デバイスを削除',
              tips: 'デバイスを削除してもよろしいですか?'
          },
          startNowDevice: {
              title: '今すぐプッシュ',
              tips: '今すぐ押してもよろしいですか?'
          },
          stopNowDevice: {
              title: '押すのをやめてください',
              tips: 'プッシュをやめてもよろしいですか?'
          },
          adjustTitle: 'グループ化を調整する',
          editDevice: {
              title: 'デバイス名の変更',
              inputPlaceholder: {
                  name: 'デバイス名を入力してください'
              }
          },
          noChildren: {
              title: '警告する',
              tips: 'まずグループを追加してください'
          }
      },
      validator: {
          group: {
              name_null: 'グループ名を空にすることはできません',
              name_length: '10文字を超えることはできません'
          },
          number_null: '数値を空にすることはできません',
          number_rule: '6 または 8 で始まる 10 桁のみを指定できます',
          name_null: '端末名を空にすることはできません'
      }
  },
  user: {
      button: {
          add: 'ユーザーを追加する'
      },
      inputPlaceholder: {
          search: 'ViiTALK番号'
      },
      table: {
          th_name: '名前',
          th_number: '携帯電話番号/梅澤番号'
      },
      dialog: {
          addUser: {
              title: 'ユーザーを追加する',
              inputPlaceholder: {
                  number: 'ViiTALK番号を入力してください'
              },
              label: {
                  number: 'ViiTALK番号',
                  group: 'ユーザーグループを選択してください'
              }
          },
          delUser: {
              title: 'ユーザーを削除する',
              tips: 'ユーザーを削除してもよろしいですか?'
          }
      },
      validator: {
          number_null: '数値を空にすることはできません',
          number_rule: 'この番号は存在しません'
      }
  },
  addrbook: {
      return: {
          delDevice: {
              tips_1: '削除対象のグループが存在しません',
              tips_2: '削除するグループの下にサブグループがあり、削除できません。',
              tips_3: '削除対象のグループ配下に削除できない人がいます。'
          },
          addDevice: {
              tips_1: 'すでに追加されています。他のグループをご覧ください',
              tips_2: '他社が追加したもの',
              tips_3: 'この番号は存在しません'
          }
      }
  },
  audit: {
      option: {
          label: {
              NoPass: '不合格',
              hasRefuse: '拒否されました',
              hasPass: '合格した',
              hasInvalid: '期限切れ',
              all: '全て'
          }
      },
      button: {
          pass: '合格',
          reject: '拒否する'
      },
      table: {
          th_number: 'デバイス番号',
          th_date: '日付',
          th_status: '州',
          th_source: 'ソース'
      },
      dialog: {
          passTitle: '承認された',
          reject: {
              title: 'レビューを拒否する',
              tips: 'レビューを拒否してもよろしいですか?'
          }
      },
      api: {
          req: {
              tips_1: 'レビューに合格できませんでした。選択されたアイテムは別のソースからのものです。',
              tips_2: '割り当てる地域を選択してください'
          },
          return: {
              tips_1: 'デバイス番号が存在しません',
              tips_2: 'あなたは企業に加わりました',
              tips_3: 'すでに追加されています。他のグループをご覧ください'
          }
      }
  },
  conferenceLog: {
      inputPlaceholder: '内容を入力してください',
      table: {
          th: {
              roomId: '部屋番号',
              state: '部屋のステータス',
              username: '作成者:jid',
              createTime: '会議の作成時間',
              closeTime: '会議の終了時間',
              duration: '間隔'
          }
      },
      dialog: {
          title: 'メンバーのルームへの入退室ログ',
          table: {
              th: {
                  username: '作成者:jid',
                  joinTime: '参加する',
                  leaveTime: '離れる',
                  duration: '間隔'
              }
          }
      }
  },
  callLog: {
      table: {
          th: {
              callerMzNum: '発信番号',
              callTime: 'ダイヤル時間',
              endTime: '終了時間',
              duration: '間隔',
              responderMzNum: '着信番号'
          }
      }
  },
  roomStatus: {
      table: {
          th: {
              roomMessage: 'お部屋の基本情報',
              Attendees: '出席者',
              bystander: '傍観者',
              join: '追加予定',
              exited: '終了しました'
          }
      },
      roomNumber: '会議室番号',
      name: '会議室名',
      dissolution: '解散',
      pattern: 'モデル',
      confluence: '合流',
      forward: 'フォワード',
      createdTime: '作成時間',
      limit: '解除/総人数制限',
      createMode: 'パターンを作成する',
      order: 'アポイント（打ち合わせ）',
      simple: '通常のマルチプレイヤー',
      type: '会議の種類',
      creator: 'クリエイター',
      host: 'ホスト：',
      windowMode: 'ウィンドウモード：',
      WindowMode1: '大きいものは 1 つ、小さいものは多数あります',
      WindowMode2: 'タイル',
      hide: '非表示にするかどうか:',
      forbidden: '小麦を禁止するかどうか:',
      inorder: '注文：',
      source: 'ソース：',
      joinTime: '参加時間:',
      comeOut: '出てきてください',
      openMic: 'マイクをオンにする',
      closeMic: 'マイクをオフにする',
      leave: '離れる：',
      peopleNumber: '人々の数：'
  },
  menu: {
      index: '表紙',
      device_manage: '端末管理',
      user_manage: 'ユーザー管理',
      conference_brand: '会議室のドア番号',
      audit_manage: '監査管理',
      orgMessage: '企業情報',
      schoolMessage: '学校情報',
      roomStatus: '進行中の会議',
      p2pCallLog: 'ビデオ通話履歴',
      conferenceLog: '会議議事録',
      authorizedMessage: '認可情報',
      title: 'エンタープライズ管理プラットフォーム',
      schooltitle: 'エンタープライズ管理プラットフォーム',
      account_mangae: 'アカウント管理',
      contacts: 'グループアドレス帳',
      records: '通話記録',
      LicenseInformation: '認可情報',
      pushInfo: '情報プッシュ',
      infoGroup: 'プッシュグループ管理'
  },
  download: 'アプリをダウンロードする',
  unauthorized: {
      title: 'ViiTALK エンタープライズ管理プラットフォーム (非認可)',
      span_1: 'サーバーが ViiTALK サービスを承認していないことが検出されました。',
      span_2: '認証ファイルを取得するには、カスタマー サービスにお問い合わせください。',
      span_3: '(認証ファイルを取得するには、サーバーの IP アドレスと Mac アドレスをカスタマー サービスに提供する必要があります)',
      upError: '申し訳ありませんが、アップロードしたファイルが間違っています。もう一度アップロードしてください',
      chooseFile_b: 'ドキュメントを選択してください',
      upFile_b: '認証ファイルをアップロードする',
      success: {
          span_1: '認可成功',
          span_2: 'エンタープライズ管理プラットフォームへの参入...',
          span_3: '（長時間入力されていない場合はブラウザを更新してください）'
      },
      dialog: {
          title: '管理者パスワードを変更してください',
          span: '管理者パスワードはまだ変更されていません。すぐにパスワードを変更してください。',
          button: '提出する'
      }
  },
  lang: {
    'zh-CN': '簡体字中国語',
    'zh-TW': '繁体字中国語',
    en: '英語',
    ja: '日本語',
    ko: '韓国語',
    th: 'タイ語',
    ar: 'アラビア語',
    bn: 'ベンガル語',
    de: 'ドイツ人',
    el: 'ギリシャ語',
    es: 'スペイン語',
    fa: 'ペルシア語',
    fr: 'フランス語',
    id: 'インドネシア語',
    it: 'イタリアの',
    iw: 'ヘブライ語',
    ku: 'クルド',
    nl: 'オランダの',
    pl: 'ポーランド語',
    pt: 'ポルトガル語',
    ro: 'ルーマニア語',
    ru: 'ロシア',
    tr: 'トルコ語',
    vi: 'ベトナム語'
  },
  authorized: {
      org: '認定事業者',
      userLimit: 'ユーザー制限',
      roomMax: 'クラウド会議室の最大収容人数',
      roomCountLimit: 'オンラインクラウド会議室の最大数',
      authorizedActiveDate: 'プライベート クラウド ライセンスのアクティベーション日',
      authorizedValidDate: 'プライベート クラウド ライセンスの有効期限は次のとおりです。',
      button_up: '更新承認',
      upFileError: 'ファイルが選択されていません'
  },
  index_title: 'ViiTalk エンタープライズ管理プラットフォーム',
  brand: {
    table: {
        th: {
            brandNumber: '家屋番号',
            bindNumber: '大画面番号をバインドする',
            onlineStatus: 'オンラインステータス',
            lastLoginTime: '最終ログイン時間'
        },
        col: {
            content: {
                onlineStatus: {
                    online: 'オンライン',
                    offline: 'オフライン'
                }
            }
        }
    }
  }
}