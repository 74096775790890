import { methodPost, methodUpload } from '@/api'

export const authorized = {
    data() {
        return {
            company: '',
            userLimit: '',
            roomMax: '',
            roomCountLimit: '',
            authorizedActiveDate: '',
            authorizedValidDate: ''
        }
    },
    created() {
        this.loadAuthInfo()
    },
    watch: {
        '$i18n.locale': {
            handler(newVal, oldVal) {
                if (newVal === 'en') {
                    this.companySimpleNameEnShow = false
                }
            },
            immediate: true,
        }
    },
    methods: {
        loadAuthInfo() {
            methodPost('api/lecen/getAuthInfoList').then((data) => {
                if (data.status) {
                    this.company = data.data[0].authCompany
                    this.userLimit = data.data[0].authRoomCap
                    this.roomMax = data.data[0].authRoomMax
                    this.roomCountLimit = data.data[0].authUserMax
                    this.authorizedActiveDate = data.data[0].authActiveDate
                    this.authorizedValidDate = data.data[0].authExpiryDate
                }
            })
        },
        uploadFile() {
            this.$refs.uploadInput.click()
        },
        handleFileUpload() {
            const file = this.$refs.uploadInput.files[0] // 获取选择的文件对象
            if (!file) {
                this.$message.error(this.$t('authorized.upFileError'))
                return
            }
            methodUpload('api/lecen/update', file).then((data) => {
                console.log(data)
                if (data.status) {
                    this.$message({ message: this.$t('opearteSuccess'), type: 'success' })
                    this.loadAuthInfo()
                } else {
                    this.$message.error(this.$t('serverError'))
                }
            })
        }
    }
}