import InfoGroup from "@/components/pushInfo/infoGroup.vue";

export default {
  language: 'Română',
  serverError: 'Anormalitate de server, vă rugăm să contactați administratorul',
  opearteSuccess: 'Operare reușită',
  agreement: {
      agreement_1: 'Am citit și am fost de acord',
      agreement_2: '\'Acordul Utilizatorului\'',
      agreement_3: 'și',
      agreement_4: '\'Politica de confidențialitate\'',
      agreement_5: 'Vă rugăm să bifați caseta pentru a fi de acord cu acordul'
  },
  login: {
      webName: 'Platforma de management al întreprinderii ViiTALK',
      website: 'Site oficial:',
      tel: 'Telefon:',
      email: 'Poștă:',
      title: 'Autentificare utilizator Enterprise',
      inputPlaceholder: {
          name: 'Vă rugăm să introduceți numele de utilizator/e-mail',
          pwd: 'Va rugam sa introduceti parola'
      },
      button: {
          login: 'Log in',
          email: 'Autentificare prin e-mail',
          account: 'Conectare la cont',
          register: 'Înregistrarea utilizatorului întreprinderii',
          pwd: 'uitați parola'
      },
      error: {
          uPwd: 'nume de utilizator sau parola greșite',
          uLock: 'Contul a fost blocat',
          uLeave: 'Ați demisionat și nu puteți utiliza sistemul',
          uRelogin: 'Ești deja autentificat în altă parte!!!',
          uReplaceLogin: 'Contul dvs. a fost conectat în altă parte și ați fost forțat să vă deconectați. Dacă nu a fost vina dvs., este posibil ca parola dvs. să fi fost scursă. Vă rugăm să vă conectați din nou și să vă schimbați parola cât mai curând posibil.',
          user_null: 'vă rugăm să introduceți numele de utilizator',
          pwd_null: 'Te rog introdu parola'
      }
  },
  personal: {
      companyNumber: 'Afacere',
      administrators: 'administrator',
      changeLanguage: 'schimba limba',
      logout: 'sign out'
  },
  companyMessage: {
      adminPwd: 'Parola de administrator',
      adminUserName: 'Numele administratorului',
      adminEmail: 'E-mailul administratorului',
      companyFullName: 'Numele complet al companiei (numele legal înregistrat)',
      companySimpleNameCn: 'Abrevierea companiei',
      companySimpleNameEn: 'Abrevierea companiei (engleză)',
      companyWebsite: 'Site-ul corporativ',
      companyNumber: 'Numărul grupului de întreprinderi',
      companyNoAuditJoin: 'Alăturați-vă fără recenzie',
      adminExplain: 'Numele administratorului este vizibil pentru toți membrii agendei de adrese corporative',
      companySimpleExplain: 'Abrevierea companiei va fi afișată pe pagina de pornire a dispozitivului Rooms și va fi vizibilă pentru toți membrii agendei companiei.',
      button: {
          Edit: 'Revizui'
      },
      dialog: {
          editpwd: {
              title: 'Schimbați parola de administrator',
              label: {
                  oldPwd: 'Parola veche',
                  newPwd: 'Parolă Nouă',
                  reNewPwd: 'Confirmați noua parolă'
              },
              inputPlaceholder: {
                  oldPwd: 'Vă rugăm să introduceți vechea parolă',
                  newPwd: 'Vă rugăm să introduceți o nouă parolă',
                  reNewPwd: 'Vă rugăm să introduceți pentru a confirma parola nouă'
              }
          },
          editName: {
              title: 'Modificați numele administratorului',
              label: {
                  name: 'nume nou'
              },
              inputPlaceholder: {
                  name: 'Vă rugăm să introduceți un nume nou'
              }
          },
          editEmail: {
              title: 'Modificați adresa de e-mail a administratorului',
              title2: 'Nu v-ați legat încă adresa de e-mail. Vă rugăm să vă legați adresa de e-mail la timp.',
              label: {
                  email: 'noua cutie postala'
              },
              inputPlaceholder: {
                  email: 'Vă rugăm să introduceți un nou e-mail'
              }
          },
          editCompanyFullName: {
              title: 'Modificați numele complet al companiei',
              label: {
                  name: 'nou nume complet'
              },
              inputPlaceholder: {
                  name: 'Vă rugăm să introduceți un nou nume complet'
              }
          },
          editCompanyAbbreviationCn: {
              title: 'Modificați abrevierea companiei (chineză)',
              label: {
                  name: 'Abreviere nouă'
              },
              inputPlaceholder: {
                  name: 'Vă rugăm să introduceți un nou nume scurt'
              }
          },
          editCompanyAbbreviationEn: {
              title: 'Modificați abrevierea companiei (engleză)'
          },
          editWebsite: {
              title: 'Modificați adresa site-ului corporativ',
              label: {
                  website: 'Adresă URL nouă'
              },
              inputPlaceholder: {
                  website: 'Vă rugăm să introduceți o nouă adresă URL'
              }
          },
          editNoAuditJoin: {
              title: 'Modificați comutatorul de conectare fără aplicații',
              radio: {
                  open: 'Deschis',
                  close: 'închidere'
              }
          }
      },
      validator: {
          value_null: 'Valoarea nu poate fi goală',
          oldPwd: {
              null: 'Vă rugăm să introduceți vechea parolă'
          },
          newPwd: {
              length: 'Vă rugăm să setați o parolă de 6 caractere sau mai mult'
          },
          reNewPwd: {
              consistent: 'Parolele introduse de două ori sunt inconsistente!'
          },
          name: {
              length: 'Numele nu poate depăși 20 de caractere'
          },
          email: {
              rule: 'Formatul de e-mail este incorect'
          },
          NoAuditJoin: {
              null: 'Vă rugăm să selectați un articol'
          }
      }
  },
  register: {
      step_1: 'numele de utilizator și parola',
      step_2: 'Completați informațiile companiei',
      step_3: 'finalizarea',
      head: 'Înregistrarea utilizatorului întreprinderii',
      usernameError: 'Numele membrului trebuie să aibă 6-20 de caractere, fără să includă caracterele chinezești și să înceapă cu o literă.',
      usernameError_exist: 'Numele de utilizator există deja, vă rugăm să vă modificați numele de utilizator',
      emailError: 'Formatul de e-mail este incorect',
      emailError_exist: 'E-mail există deja',
      passwordError_length: '6-20 de caractere, doar semne alfanumerice și de punctuație',
      passwordError_same: 'Parola nu poate fi același nume de utilizator',
      passwordError: 'Formatul parolei este incorect',
      rePasswordError: 'Parolele introduse de două ori sunt inconsistente, vă rugăm să reintroduceți',
      companyNameError: 'Vă rugăm să introduceți numele complet al companiei',
      companyAbbreviationError: 'Vă rugăm să introduceți abrevierea companiei',
      orgError_exist:'Compania a fost înregistrată, vă rugăm să vă schimbați numele companiei',
      button: {
          next: 'Urmatorul pas',
          login: 'conectați-vă imediat'
      },
      inputPlaceholder: {
          username: 'nume de utilizator',
          email: 'Poștă',
          password: 'Seteaza parola',
          rePassword: 'Introduceți din nou parola',
          name: 'Numele complet al companiei (numele legal înregistrat)*',
          simpleNameCN: 'Abrevierea companiei (chineză)*',
          simpleNameEN: 'Abrevierea companiei (engleză)',
          website: 'Site-ul companiei (www)'
      },
      success: 'Ai creat cu succes un cont de afaceri!',
      location: 'Locație:',
      Scale: 'scară:'
  },
  forgotPwd: {
      head: 'Preluare e-mail',
      resetSuccess: 'V-ați resetat parola cu succes!',
      inputPlaceholder: {
          code: 'vă rugăm să introduceți codul de verificare'
      },
      sendCodeError: 'Cod de verificare incorect',
      sendCodeError_sended: 'Codul de verificare a fost trimis, vă rugăm să-l verificați',
      button: {
          getCode: 'obține codul de verificare',
          reGetCode: 'Obțineți din nou codul de verificare'
      }
  },
  button: {
      ok: 'a confirma',
      cancel: 'Anulare',
      search: 'căutare',
      clear: 'clar'
  },
  device: {
      button: {
          setContent: 'Configurați programul push',
          setGroupMng: 'Configurați administratorul grupului',
          startNow: 'Împingeți acum',
          stopNow: 'Nu mai împinge',
          add: 'Adăugați dispozitivul',
          search: 'căutare',
          adjust: 'Ajustați gruparea',
          del: 'șterge'
      },
      inputPlaceholder: {
          search: 'Număr de dispozitiv/Număr de telefon mobil/Număr ViiTALK'
      },
      group: {
          add: 'Adăugare grup',
          edit: 'Editați grupul',
          del: 'Șterge grupul'
      },
      table: {
          th_name: 'Nume dispozitiv',
          th_number: 'Numarul dispozitivului',
          th_time: 'Ultima conectare',
          th_brandNumber: 'Numărul casei conferinței',
          th_online: 'E online',
          th_status: 'stat',
          th_bindNumber: 'Legați numărul de ecran mare'
      },
      dialog: {
          addGroupTitle: 'Vă rugăm să introduceți un nume de grup',
          inputPlaceholder: {
              groupName: 'Vă rugăm să introduceți numele grupului'
          },
          delGroupTitle: 'Șterge grupul',
          delGroupTips: 'Sigur doriți să ștergeți grupul?',
          editGroupTitle: 'Editați grupul',
          groupMng: {
              title: 'Editați managerul de grup',
              inputPlaceholder: {
                  name: 'Vă rugăm să introduceți numele administratorului'
              },
              label: {
                  name: 'Numele administratorului'
              }
          },
          addDevice: {
              title: 'Adăugați dispozitivul',
              inputPlaceholder: {
                  name: 'Vă rugăm să introduceți numele dispozitivului',
                  number: 'Vă rugăm să introduceți numărul dispozitivului'
              },
              label: {
                  name: 'Nume dispozitiv',
                  number: 'Numarul dispozitivului',
                  group: 'Vă rugăm să selectați un grup de dispozitive'
              }
          },
          delDevice: {
              title: 'Indepartati dispozitivul',
              tips: 'Sigur doriți să ștergeți dispozitivul?'
          },
          startNowDevice: {
              title: 'Împingeți acum',
              tips: 'Ești sigur că vrei să-l împingi acum?'
          },
          stopNowDevice: {
              title: 'Nu mai împinge',
              tips: 'Ești sigur că vrei să nu mai împingi?'
          },
          adjustTitle: 'Ajustați gruparea',
          editDevice: {
              title: 'Modificați numele dispozitivului',
              inputPlaceholder: {
                  name: 'Vă rugăm să introduceți numele dispozitivului'
              }
          },
          noChildren: {
              title: 'a avertiza',
              tips: 'Vă rugăm să adăugați mai întâi un grup'
          }
      },
      validator: {
          group: {
              name_null: 'Numele grupului nu poate fi gol',
              name_length: 'Nu poate depăși 10 caractere'
          },
          number_null: 'Numărul nu poate fi gol',
          number_rule: 'Poate avea doar 10 cifre, începând cu 6 sau 8',
          name_null: 'Numele terminalului nu poate fi gol'
      }
  },
  user: {
      button: {
          add: 'Adăugați utilizator'
      },
      inputPlaceholder: {
          search: 'Numărul ViiTALK'
      },
      table: {
          th_name: 'Nume',
          th_number: 'Număr de telefon mobil/număr Maizhe'
      },
      dialog: {
          addUser: {
              title: 'Adăugați utilizator',
              inputPlaceholder: {
                  number: 'Vă rugăm să introduceți numărul ViiTALK'
              },
              label: {
                  number: 'Numărul ViiTALK',
                  group: 'Vă rugăm să selectați grupul de utilizatori'
              }
          },
          delUser: {
              title: 'ștergeți utilizatori',
              tips: 'Sigur doriți să ștergeți utilizatorul?'
          }
      },
      validator: {
          number_null: 'Numărul nu poate fi gol',
          number_rule: 'Acest număr nu există'
      }
  },
  addrbook: {
      return: {
          delDevice: {
              tips_1: 'Grupul de șters nu există',
              tips_2: 'Există subgrupuri sub grupul care trebuie șters și nu pot fi șterse.',
              tips_3: 'Există persoane în grup care urmează să fie șterse și nu pot fi șterse.'
          },
          addDevice: {
              tips_1: 'Adăugat deja, vă rugăm să vizualizați alte grupuri',
              tips_2: 'A fost adăugat de alte companii',
              tips_3: 'Acest număr nu există'
          }
      }
  },
  audit: {
      option: {
          label: {
              NoPass: 'Nu a trecut',
              hasRefuse: 'respins',
              hasPass: 'a trecut',
              hasInvalid: 'expirat',
              all: 'toate'
          }
      },
      button: {
          pass: 'trece',
          reject: 'respinge'
      },
      table: {
          th_number: 'Nr. dispozitiv',
          th_date: 'Data',
          th_status: 'stat',
          th_source: 'sursă'
      },
      dialog: {
          passTitle: 'Aprobat',
          reject: {
              title: 'Respinge recenzia',
              tips: 'Sigur doriți să respingeți recenzia?'
          }
      },
      api: {
          req: {
              tips_1: 'Nu pot trece recenzia, articolele selectate provin din surse diferite!',
              tips_2: 'Vă rugăm să selectați regiunea care va fi alocată'
          },
          return: {
              tips_1: 'Numărul dispozitivului nu există',
              tips_2: 'Te-ai alăturat întreprinderii',
              tips_3: 'Adăugat deja, vă rugăm să vizualizați alte grupuri'
          }
      }
  },
  conferenceLog: {
      inputPlaceholder: 'Vă rugăm să introduceți conținut',
      table: {
          th: {
              roomId: 'Numărul de cameră',
              state: 'starea camerei',
              username: 'Creat de jid',
              createTime: 'Timpul de creare a întâlnirii',
              closeTime: 'ora de încheiere a întâlnirii',
              duration: 'Durată'
          }
      },
      dialog: {
          title: 'Membru care se alătură/ie iese din înregistrarea camerei',
          table: {
              th: {
                  username: 'Creat de jid',
                  joinTime: 'alăturați-vă',
                  leaveTime: 'părăsi',
                  duration: 'Durată'
              }
          }
      }
  },
  callLog: {
      table: {
          th: {
              callerMzNum: 'număr de apel',
              callTime: 'Apelați ora',
              endTime: 'Sfârșitul timpului',
              duration: 'Durată',
              responderMzNum: 'număr numit'
          }
      }
  },
  roomStatus: {
      table: {
          th: {
              roomMessage: 'Informații de bază despre cameră',
              Attendees: 'participant',
              bystander: 'spectator',
              join: 'A fi adaugat',
              exited: 'Ieșit'
          }
      },
      roomNumber: 'Numărul sălii de conferințe',
      name: 'Numele sălii de ședințe',
      dissolution: 'Dizolva',
      pattern: 'model',
      confluence: 'confluenţă',
      forward: 'Redirecţiona',
      createdTime: 'timpul de creație',
      limit: 'Limită de lansare/număr total de persoane',
      createMode: 'creați un model',
      order: 'Numire (întâlnire)',
      simple: 'Multiplayer obișnuit',
      type: 'Tip de întâlnire',
      creator: 'creator',
      host: 'Gazdă:',
      windowMode: 'Modul fereastră:',
      WindowMode1: 'Unul mare și multe mici',
      WindowMode2: 'Ţiglă',
      hide: 'Dacă să se ascundă:',
      forbidden: 'Dacă să interzică grâul:',
      inorder: 'Ordin:',
      source: 'sursă:',
      joinTime: 'Ora de aderare:',
      comeOut: 'Te rog ieși afară',
      openMic: 'porniți microfonul',
      closeMic: 'Opriți microfonul',
      leave: 'părăsi:',
      peopleNumber: 'Numărul de persoane:'
  },
  menu: {
      index: 'prima pagina',
      device_manage: 'Gestionarea dispozitivelor',
      user_manage: 'Managementul utilizatorilor',
      conference_brand: 'Numărul ușii conferinței',
      audit_manage: 'Managementul auditului',
      orgMessage: 'Informație corporativă',
      roomStatus: 'întâlnire în curs',
      p2pCallLog: 'Istoricul apelurilor video',
      conferenceLog: 'proces-verbal de întâlnire',
      authorizedMessage: 'Informații de autorizare',
      title: 'Platformă de management al întreprinderii',
      account_mangae: 'Managementul contului',
      contacts: 'Agenda grupului',
      records: 'înregistrările apelurilor',
      LicenseInformation: 'Informații de autorizare',
      pushInfo: 'Impingerea informațiilor',
      infoGroup: 'Push managementul grupului'
  },
  download: 'descărcați aplicații',
  unauthorized: {
      title: 'Platformă de management al întreprinderii ViiTALK (neautorizată)',
      span_1: 'S-a detectat că serverul dvs. nu autorizează serviciul ViiTALK,',
      span_2: 'Vă rugăm să contactați serviciul clienți pentru a obține dosarul de autorizare.',
      span_3: '(Pentru a obține fișierul de autorizare, trebuie să furnizați adresa IP a serverului și adresa Mac serviciului clienți)',
      upError: 'Ne pare rău, fișierul pe care l-ați încărcat este greșit, vă rugăm să încărcați din nou',
      chooseFile_b: 'Selectați un document',
      upFile_b: 'Încărcați fișierul de autorizare',
      success: {
          span_1: 'Autorizarea reușită',
          span_2: 'Se intră în platforma de management al întreprinderii...',
          span_3: '(Dacă nu ați intrat de mult timp, vă rugăm să reîmprospătați browserul)'
      },
      dialog: {
          title: 'Vă rugăm să schimbați parola de administrator',
          span: 'Parola de administrator nu a fost încă modificată, vă rugăm să vă schimbați imediat parola',
          button: 'Trimite'
      }
  },
  lang: {
    'zh-CN': 'Chineză simplificată',
    'zh-TW': 'chineză tradițională',
    en: 'Engleză',
    ja: 'japonez',
    ko: 'coreeană',
    th: 'thailandez',
    ar: 'arabic',
    bn: 'bengaleză',
    de: 'limba germana',
    el: 'greacă',
    es: 'Spaniolă',
    fa: 'persană',
    fr: 'limba franceza',
    id: 'indoneziană',
    it: 'Italiană',
    iw: 'ebraică',
    ku: 'kurdă',
    nl: 'olandeză',
    pl: 'Lustrui',
    pt: 'portugheză',
    ro: 'Română',
    ru: 'Rusă',
    tr: 'turc',
    vi: 'vietnamez'
  },
  authorized: {
      org: 'Întreprindere autorizată',
      userLimit: 'Limită de utilizatori',
      roomMax: 'Capacitatea maximă a sălii de conferințe cloud',
      roomCountLimit: 'Număr maxim de săli de întâlnire online cloud',
      authorizedActiveDate: 'Data de activare a licenței pentru cloud privat',
      authorizedValidDate: 'Licența cloud privat este valabilă până la',
      button_up: 'Actualizați autorizația',
      upFileError: 'Niciun fisier selectat'
  },
  index_title: 'ViiTalk Enterprise Management Platform',
  brand: {
    table: {
        th: {
            brandNumber: 'numărul casei',
            bindNumber: 'Legați numărul de ecran mare',
            onlineStatus: 'starea online',
            lastLoginTime: 'ultima conectare'
        },
        col: {
            content: {
                onlineStatus: {
                    online: 'pe net',
                    offline: 'Deconectat'
                }
            }
        }
    }
  }
}