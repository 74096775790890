import InfoGroup from "@/components/pushInfo/infoGroup.vue";

export default {
  language: 'Deutsch',
  serverError: 'Serveranomalie. Bitte wenden Sie sich an den Administrator',
  opearteSuccess: 'Erfolgreiche Operation',
  agreement: {
      agreement_1: 'Lesevereinbarung',
      agreement_2: '\'Vereinbarung\'',
      agreement_3: 'Und',
      agreement_4: '\'Datenschutz\'',
      agreement_5: 'Bitte kreuzen Sie das Kästchen an'
  },
  login: {
      webName: 'ViiTALK-Unternehmensverwaltungsplattform',
      webNameSchool: 'ViiTALK-Unternehmensverwaltungsplattform',
      website: 'Offizielle Website:',
      tel: 'Telefon:',
      email: 'E-Mail:',
      title: 'Benutzeranmeldung',
      titleSchool: 'Benutzeranmeldung',
      inputPlaceholder: {
          name: 'Bitte geben Sie Benutzernamen/E-Mail ein',
          pwd: 'Bitte geben Sie Ihr Passwort ein'
      },
      button: {
          login: 'Anmeldung',
          email: 'E-Mail-Login',
          account: 'Account Login',
          register: 'Benutzerregistrierung',
          registerSchool: 'Benutzerregistrierung',
          pwd: 'vergessen Sie das Passwort'
      },
      error: {
          uPwd: 'Benutzername oder Passwort falsch',
          uLock: 'Konto wurde gesperrt',
          uLeave: 'Sie haben gekündigt und können das System nicht nutzen',
          uRelogin: 'Sie sind bereits woanders eingeloggt!!!',
          uReplaceLogin: 'Ihr Konto wurde an anderer Stelle angemeldet und Sie wurden gezwungen, sich abzumelden. Wenn es nicht Ihre Schuld war, ist Ihr Passwort möglicherweise durchgesickert. Bitte melden Sie sich erneut an und ändern Sie Ihr Passwort so schnell wie möglich.',
          user_null: 'Bitte geben sie einen Benutzernamen ein',
          pwd_null: 'Bitte Passwort eingeben'
      }
  },
  personal: {
      companyNumber: 'Unternehmen',
      schoolNumber: 'Unternehmen',
      administrators: 'Administrator',
      changeLanguage: 'Sprache wechseln',
      logout: 'Abmelden'
  },
  companyMessage: {
      adminPwd: 'Administrator-Passwort',
      adminUserName: 'Administratorname',
      adminEmail: 'E-Mail des Administrators',
      companyFullName: 'Vollständiger Name des Unternehmens',
      companySimpleNameCn: 'Firmenkürzel',
      companySimpleNameEn: 'Firmenkürzel (Englisch)',
      companyWebsite: 'Firmenwebsite',
      companyNumber: 'Unternehmensgruppennummer',

      schoolFullName: 'Vollständiger Name des Unternehmens',
      schoolSimpleNameCn: 'Firmenkürzel',
      schoolSimpleNameEn: 'Firmenkürzel (Englisch)',
      schoolWebsite: 'Firmenwebsite',
      schoolNumber: 'Unternehmensgruppennummer',

      companyNoAuditJoin: 'Ohne Bewertung beitreten',
      adminExplain: 'Der Administratorname ist für alle Mitglieder des Unternehmensadressbuchs sichtbar',
      companySimpleExplain: 'Das Firmenkürzel wird auf der Startseite des Rooms-Geräts angezeigt und ist für alle Mitglieder des Firmenadressbuchs sichtbar.',
      button: {
          Edit: 'Überarbeiten'
      },
      dialog: {
          editpwd: {
              title: 'Administratorpasswort ändern',
              label: {
                  oldPwd: 'Altes Passwort',
                  newPwd: 'Neues Kennwort',
                  reNewPwd: 'Bestätigen Sie das neue Passwort'
              },
              inputPlaceholder: {
                  oldPwd: 'Bitte geben Sie das alte Passwort ein',
                  newPwd: 'Bitte geben Sie ein neues Passwort ein',
                  reNewPwd: 'Bitte geben Sie ein, um das neue Passwort zu bestätigen'
              }
          },
          editName: {
              title: 'Ändern Sie den Administratornamen',
              label: {
                  name: 'neuer Name'
              },
              inputPlaceholder: {
                  name: 'Bitte geben Sie einen neuen Namen ein'
              }
          },
          editEmail: {
              title: 'Ändern Sie die E-Mail-Adresse des Administrators',
              title2: 'Sie haben Ihre E-Mail-Adresse noch nicht gebunden. Bitte binden Sie Ihre E-Mail-Adresse rechtzeitig ein.',
              label: {
                  email: 'neuer Briefkasten'
              },
              inputPlaceholder: {
                  email: 'Bitte geben Sie eine neue E-Mail ein'
              }
          },
          editCompanyFullName: {
              title: 'Ändern Sie den vollständigen Namen des Unternehmens',
              label: {
                  name: 'neuer vollständiger Name'
              },
              inputPlaceholder: {
                  name: 'Bitte geben Sie einen neuen vollständigen Namen ein'
              }
          },
          editCompanyAbbreviationCn: {
              title: 'Firmenkürzel ändern (Chinesisch)',
              label: {
                  name: 'Neue Abkürzung'
              },
              inputPlaceholder: {
                  name: 'Bitte geben Sie einen neuen Kurznamen ein'
              }
          },
          editCompanyAbbreviationEn: {
              title: 'Firmenkürzel ändern (Englisch)'
          },
          editWebsite: {
              title: 'Ändern Sie die Adresse der Unternehmenswebsite',
              label: {
                  website: 'Neue URL'
              },
              inputPlaceholder: {
                  website: 'Bitte geben Sie eine neue URL ein'
              }
          },
          editNoAuditJoin: {
              title: 'Ändern Sie den anwendungsfreien Beitrittsschalter',
              radio: {
                  open: 'Offen',
                  close: 'Schließung'
              }
          }
      },
      validator: {
          value_null: 'Der Wert darf nicht leer sein',
          oldPwd: {
              null: 'Bitte geben Sie das alte Passwort ein'
          },
          newPwd: {
              length: 'Bitte legen Sie ein Passwort mit mindestens 6 Zeichen Länge fest'
          },
          reNewPwd: {
              consistent: 'Die doppelt eingegebenen Passwörter sind inkonsistent!'
          },
          name: {
              length: 'Der Name darf nicht länger als 20 Zeichen sein'
          },
          email: {
              rule: 'Das E-Mail-Format ist falsch'
          },
          NoAuditJoin: {
              null: 'Bitte wählen Sie einen Artikel aus'
          }
      }
  },
  register: {
      step_1: 'Passwort',
      step_2: 'Information',
      step_3: 'Beenden',
      head: 'Benutzerregistrierung',
      usernameError: 'Der Mitgliedsname muss 6–20 Zeichen lang sein (ohne chinesische Zeichen) und mit einem Buchstaben beginnen.',
      usernameError_exist: 'Der Benutzername ist bereits vorhanden. Bitte ändern Sie Ihren Benutzernamen',
      emailError: 'Das E-Mail-Format ist falsch',
      emailError_exist: 'E-Mail existiert bereits',
      passwordError_length: '6–20 Zeichen, nur alphanumerische Zeichen und Satzzeichen',
      passwordError_same: 'Das Passwort darf nicht mit dem Benutzernamen identisch sein',
      passwordError: 'Das Passwortformat ist falsch',
      rePasswordError: 'Die zweimal Passwörter sind inkonsistent',
      companyNameError: 'Bitte geben Sie den vollständigen Namen des Unternehmens ein',
      companyAbbreviationError: 'Bitte geben Sie das Firmenkürzel ein',
      orgError_exist:'Das Unternehmen wurde registriert, bitte ändern Sie Ihren Firmennamen',
      button: {
          next: 'Nächster Schritt',
          login: 'Melden Sie sich sofort an'
      },
      inputPlaceholder: {
          username: 'Nutzername',
          email: 'Post',
          password: 'Passwort festlegen',
          rePassword: 'Passwort erneut eingeben',
          name: 'Vollständiger Name des Unternehmens',
          simpleNameCN: 'Firmenkürzel (Chinesisch)*',
          simpleNameEN: 'Firmenkürzel (Englisch)',
          website: 'Unternehmenswebsite (www)'
      },
      success: 'Sie haben erfolgreich ein Geschäftskonto erstellt!',
      location: 'Standort:',
      Scale: 'Skala:'
  },
  forgotPwd: {
      head: 'E-Mail-Abruf',
      resetSuccess: 'Sie haben Ihr Passwort erfolgreich zurückgesetzt!',
      inputPlaceholder: {
          code: 'Bitte geben Sie den Bestätigungscode ein'
      },
      sendCodeError: 'Falscher verifikationscode',
      sendCodeError_sended: 'Der Bestätigungscode wurde gesendet, bitte überprüfen Sie ihn',
      button: {
          getCode: 'Holen Sie sich den Bestätigungscode',
          reGetCode: 'Holen Sie sich den Bestätigungscode erneut'
      }
  },
  button: {
      ok: 'bestätigen',
      cancel: 'Stornieren',
      search: 'suchen',
      clear: 'Klar'
  },
  device: {
      button: {
          setContent: 'Konfigurieren Sie den Push-Zeitplan',
          setGroupMng: 'Gruppenadministrator einrichten',
          startNow: 'Jetzt drücken',
          stopNow: 'Hör auf zu drängen',
          add: 'Gerät hinzufügen',
          search: 'suchen',
          adjust: 'Gruppierung anpassen',
          del: 'löschen'
      },
      inputPlaceholder: {
          search: 'Gerätenummer/Mobiltelefonnummer/ViiTALK-Nummer'
      },
      group: {
          add: 'Gruppe hinzufügen',
          edit: 'Gruppe bearbeiten',
          del: 'Gruppe löschen'
      },
      table: {
          th_name: 'Gerätename',
          th_number: 'Gerätenummer',
          th_time: 'Letzter Anmeldezeitpunkt',
          th_brandNumber: 'Hausnummer der Tagung',
          th_online: 'Ist online',
          th_status: 'Zustand',
          th_bindNumber: 'Große Bildschirmnummer binden'
      },
      dialog: {
          addGroupTitle: 'Bitte geben Sie einen Gruppennamen ein',
          inputPlaceholder: {
              groupName: 'Bitte geben Sie den Gruppennamen ein'
          },
          delGroupTitle: 'Gruppe löschen',
          delGroupTips: 'Sind Sie sicher, dass Sie die Gruppe löschen möchten?',
          editGroupTitle: 'Gruppe bearbeiten',
          groupMng: {
              title: 'Gruppenmanager bearbeiten',
              inputPlaceholder: {
                  name: 'Bitte geben Sie den Administratornamen ein'
              },
              label: {
                  name: 'Administratorname'
              }
          },
          addDevice: {
              title: 'Gerät hinzufügen',
              inputPlaceholder: {
                  name: 'Bitte geben Sie den Gerätenamen ein',
                  number: 'Bitte geben Sie die Gerätenummer ein'
              },
              label: {
                  name: 'Gerätename',
                  number: 'Gerätenummer',
                  group: 'Bitte wählen Sie eine Gerätegruppe aus'
              }
          },
          delDevice: {
              title: 'Gerät entfernen',
              tips: 'Sind Sie sicher, dass Sie das Gerät löschen möchten?'
          },
          startNowDevice: {
              title: 'Jetzt drücken',
              tips: 'Sind Sie sicher, dass Sie es jetzt pushen möchten?'
          },
          stopNowDevice: {
              title: 'Hör auf zu drängen',
              tips: 'Sind Sie sicher, dass Sie mit dem Pressen aufhören möchten?'
          },
          adjustTitle: 'Gruppierung anpassen',
          editDevice: {
              title: 'Gerätenamen ändern',
              inputPlaceholder: {
                  name: 'Bitte geben Sie den Gerätenamen ein'
              }
          },
          noChildren: {
              title: 'warnen',
              tips: 'Bitte fügen Sie zuerst eine Gruppe hinzu'
          }
      },
      validator: {
          group: {
              name_null: 'Der Gruppenname darf nicht leer sein',
              name_length: 'Darf 10 Zeichen nicht überschreiten'
          },
          number_null: 'Die Zahl darf nicht leer sein',
          number_rule: 'Darf nur 10-stellig sein, beginnend mit 6 oder 8',
          name_null: 'Der Terminalname darf nicht leer sein'
      }
  },
  user: {
      button: {
          add: 'Benutzer hinzufügen'
      },
      inputPlaceholder: {
          search: 'ViiTALK-Nummer'
      },
      table: {
          th_name: 'Name',
          th_number: 'Mobiltelefonnummer/Maizhe-Nummer'
      },
      dialog: {
          addUser: {
              title: 'Benutzer hinzufügen',
              inputPlaceholder: {
                  number: 'Bitte geben Sie die ViiTALK-Nummer ein'
              },
              label: {
                  number: 'ViiTALK-Nummer',
                  group: 'Bitte wählen Sie eine Benutzergruppe aus'
              }
          },
          delUser: {
              title: 'Benutzer löschen',
              tips: 'Sind Sie sicher, dass Sie den Benutzer löschen möchten?'
          }
      },
      validator: {
          number_null: 'Die Zahl darf nicht leer sein',
          number_rule: 'Diese Nummer existiert nicht'
      }
  },
  addrbook: {
      return: {
          delDevice: {
              tips_1: 'Die zu löschende Gruppe existiert nicht',
              tips_2: 'Unter der zu löschenden Gruppe befinden sich Untergruppen, die nicht gelöscht werden können.',
              tips_3: 'Es gibt Personen in der Gruppe, die gelöscht werden sollen und nicht gelöscht werden können.'
          },
          addDevice: {
              tips_1: 'Bereits hinzugefügt, bitte sehen Sie sich andere Gruppen an',
              tips_2: 'Wurde von anderen Unternehmen hinzugefügt',
              tips_3: 'Diese Nummer existiert nicht'
          }
      }
  },
  audit: {
      option: {
          label: {
              NoPass: 'Nicht bestanden',
              hasRefuse: 'abgelehnt',
              hasPass: 'bestanden',
              hasInvalid: 'Abgelaufen',
              all: 'alle'
          }
      },
      button: {
          pass: 'passieren',
          reject: 'ablehnen'
      },
      table: {
          th_number: 'Gerätenr',
          th_date: 'Datum',
          th_status: 'Zustand',
          th_source: 'Quelle'
      },
      dialog: {
          passTitle: 'Genehmigt',
          reject: {
              title: 'Überprüfung ablehnen',
              tips: 'Sind Sie sicher, dass Sie die Bewertung ablehnen möchten?'
          }
      },
      api: {
          req: {
              tips_1: 'Die Überprüfung konnte nicht bestanden werden, da die ausgewählten Artikel aus unterschiedlichen Quellen stammen!',
              tips_2: 'Bitte wählen Sie die zuzuordnende Region aus'
          },
          return: {
              tips_1: 'Gerätenummer existiert nicht',
              tips_2: 'Sie sind dem Unternehmen beigetreten',
              tips_3: 'Bereits hinzugefügt, bitte sehen Sie sich andere Gruppen an'
          }
      }
  },
  conferenceLog: {
      inputPlaceholder: 'Bitte geben Sie Inhalte ein',
      table: {
          th: {
              roomId: 'Zimmernummer',
              state: 'Zimmerstatus',
              username: 'Erstellt vonjid',
              createTime: 'Zeitpunkt der Besprechungserstellung',
              closeTime: 'Endzeit des Treffens',
              duration: 'Dauer'
          }
      },
      dialog: {
          title: 'Protokollierung des Betretens/Verlassens des Raums durch Mitglieder',
          table: {
              th: {
                  username: 'Erstellt vonjid',
                  joinTime: 'dazu kommen',
                  leaveTime: 'verlassen',
                  duration: 'Dauer'
              }
          }
      }
  },
  callLog: {
      table: {
          th: {
              callerMzNum: 'Rufnummer',
              callTime: 'Wählzeit',
              endTime: 'Endzeit',
              duration: 'Dauer',
              responderMzNum: 'angerufene Nummer'
          }
      }
  },
  roomStatus: {
      table: {
          th: {
              roomMessage: 'Grundlegende Zimmerinformationen',
              Attendees: 'Teilnehmer',
              bystander: 'Zuschauer',
              join: 'Hinzugefügt werden',
              exited: 'Ausgegangen'
          }
      },
      roomNumber: 'Nummer des Konferenzraums',
      name: 'Name des Besprechungsraums',
      dissolution: 'Auflösen',
      pattern: 'Modell',
      confluence: 'Zusammenfluss',
      forward: 'Nach vorne',
      createdTime: 'Erstellungszeitpunkt',
      limit: 'Freigabe/Gesamtpersonenzahlbegrenzung',
      createMode: 'Muster erstellen',
      order: 'Geplantes Treffen)',
      simple: 'Gewöhnlicher Mehrspielermodus',
      type: 'Besprechungstyp',
      creator: 'Schöpfer',
      host: 'Gastgeber:',
      windowMode: 'Fenstermodus:',
      WindowMode1: 'Ein großes und viele kleine',
      WindowMode2: 'Fliese',
      hide: 'Ob versteckt werden soll:',
      forbidden: 'Ob Weizen verboten werden soll:',
      inorder: 'Befehl:',
      source: 'Quelle:',
      joinTime: 'Beitrittszeit:',
      comeOut: 'Bitte komm raus',
      openMic: 'Mikrofon einschalten',
      closeMic: 'Mikrofon ausschalten',
      leave: 'verlassen:',
      peopleNumber: 'Anzahl der Personen:'
  },
  menu: {
      index: 'Titelseite',
      device_manage: 'Geräteverwaltung',
      user_manage: 'Benutzerverwaltung',
      conference_brand: 'Türnummer der Konferenz',
      audit_manage: 'Auditmanagement',
      orgMessage: 'Unternehmensinformationen',
      schoolMessage: 'Unternehmensinformationen',
      roomStatus: 'laufendes Treffen',
      p2pCallLog: 'Videoanrufverlauf',
      conferenceLog: 'Sitzungsprotokolle',
      authorizedMessage: 'Autorisierungsinformationen',
      title: 'Unternehmensverwaltungsplattform',
      schooltitle: 'Unternehmensverwaltungsplattform',
      account_mangae: 'Kontoverwaltung',
      contacts: 'Gruppenadressbuch',
      records: 'Anrufaufzeichnungen',
      LicenseInformation: 'Autorisierungsinformationen',
      pushInfo: 'Informationsschub',
      infoGroup: 'Push-Gruppenverwaltung'
  },
  download: 'Apps herunterladen',
  unauthorized: {
      title: 'ViiTALK-Unternehmensverwaltungsplattform (nicht autorisiert)',
      span_1: 'Es wurde festgestellt, dass Ihr Server den ViiTALK-Dienst nicht autorisiert.',
      span_2: 'Bitte wenden Sie sich an den Kundendienst, um die Autorisierungsdatei zu erhalten.',
      span_3: '(Um die Autorisierungsdatei zu erhalten, müssen Sie dem Kundendienst Ihre Server-IP-Adresse und Mac-Adresse mitteilen.)',
      upError: 'Leider ist die von Ihnen hochgeladene Datei falsch. Bitte laden Sie sie erneut hoch',
      chooseFile_b: 'Wählen Sie ein Dokument aus',
      upFile_b: 'Autorisierungsdatei hochladen',
      success: {
          span_1: 'Autorisierung erfolgreich',
          span_2: 'Einstieg in die Enterprise-Management-Plattform...',
          span_3: '(Wenn Sie sich längere Zeit nicht angemeldet haben, aktualisieren Sie bitte den Browser)'
      },
      dialog: {
          title: 'Bitte ändern Sie das Administratorkennwort',
          span: 'Ihr Administrator-Passwort wurde noch nicht geändert. Bitte ändern Sie Ihr Passwort umgehend',
          button: 'einreichen'
      }
  },
  lang: {
    'zh-CN': 'Vereinfachtes Chinesisch',
    'zh-TW': 'traditionelles Chinesisch',
    en: 'Englisch',
    ja: 'japanisch',
    ko: 'Koreanisch',
    th: 'Thailändisch',
    ar: 'Arabisch',
    bn: 'Bengali',
    de: 'Deutsch',
    el: 'griechisch',
    es: 'Spanisch',
    fa: 'persisch',
    fr: 'Französisch',
    id: 'Indonesisch',
    it: 'Italienisch',
    iw: 'hebräisch',
    ku: 'kurdisch',
    nl: 'Niederländisch',
    pl: 'Polieren',
    pt: 'Portugiesisch',
    ro: 'rumänisch',
    ru: 'Russisch',
    tr: 'Türkisch',
    vi: 'Vietnamesisch'
  },
  authorized: {
      org: 'Autorisiertes Unternehmen',
      userLimit: 'Benutzerlimit',
      roomMax: 'Maximale Kapazität des Cloud-Konferenzraums',
      roomCountLimit: 'Maximale Anzahl an Online-Cloud-Besprechungsräumen',
      authorizedActiveDate: 'Aktivierungsdatum der Private Cloud-Lizenz',
      authorizedValidDate: 'Die Private-Cloud-Lizenz ist gültig bis',
      button_up: 'Autorisierung aktualisieren',
      upFileError: 'Keine Datei ausgewählt'
  },
  index_title: 'ViiTalk Enterprise Management-Plattform',
  brand: {
    table: {
        th: {
            brandNumber: 'Hausnummer',
            bindNumber: 'Große Bildschirmnummer binden',
            onlineStatus: 'Online Status',
            lastLoginTime: 'letzte Anmeldezeit'
        },
        col: {
            content: {
                onlineStatus: {
                    online: 'online',
                    offline: 'Offline'
                }
            }
        }
    }
  }
}