import InfoGroup from "@/components/pushInfo/infoGroup.vue";

export default {
  language: 'bahasa Indonesia',
  serverError: 'Kelainan server, harap menghubungi administrator',
  opearteSuccess: 'Operasi yang berhasil',
  agreement: {
      agreement_1: 'Telah membaca dan menyetujui',
      agreement_2: '\'Perjanjian Pengguna\'',
      agreement_3: 'Dan',
      agreement_4: '\'Kebijakan pribadi\'',
      agreement_5: 'Silakan centang kotak untuk menyetujui perjanjian tersebut'
  },
  login: {
      webName: 'Platform manajemen perusahaan ViiTALK',
      website: 'Situs web resmi:',
      tel: 'Telepon:',
      email: 'Surat:',
      title: 'Login pengguna perusahaan',
      inputPlaceholder: {
          name: 'Silakan masukkan nama pengguna/email',
          pwd: 'Silakan masukkan kata sandi Anda'
      },
      button: {
          login: 'Gabung',
          email: 'Masuk Email',
          account: 'Akun Masuk',
          register: 'Pendaftaran pengguna perusahaan',
          pwd: 'lupa kata sandinya'
      },
      error: {
          uPwd: 'Username atau password salah',
          uLock: 'Akun telah dikunci',
          uLeave: 'Anda telah mengundurkan diri dan tidak dapat menggunakan sistem',
          uRelogin: 'Anda sudah masuk di tempat lain!!!',
          uReplaceLogin: 'Akun Anda telah masuk di tempat lain dan Anda terpaksa keluar. Jika itu bukan kesalahan Anda, kata sandi Anda mungkin bocor. Silakan masuk kembali dan ubah kata sandi Anda sesegera mungkin.',
          user_null: 'silakan masukkan nama pengguna',
          pwd_null: 'Silakan masukkan kata sandi'
      }
  },
  personal: {
      companyNumber: 'Perusahaan',
      administrators: 'administrator',
      changeLanguage: 'beralih bahasa',
      logout: 'keluar'
  },
  companyMessage: {
      adminPwd: 'Kata sandi administrator',
      adminUserName: 'Nama administrator',
      adminEmail: 'email administrator',
      companyFullName: 'Nama lengkap perusahaan (nama yang terdaftar secara sah)',
      companySimpleNameCn: 'Singkatan perusahaan',
      companySimpleNameEn: 'Singkatan perusahaan (Bahasa Inggris)',
      companyWebsite: 'Situs perusahaan',
      companyNumber: 'Nomor grup perusahaan',
      companyNoAuditJoin: 'Bergabunglah tanpa ulasan',
      adminExplain: 'Nama administrator dapat dilihat oleh semua anggota buku alamat perusahaan',
      companySimpleExplain: 'Singkatan perusahaan akan ditampilkan di beranda perangkat Kamar dan dapat dilihat oleh semua anggota buku alamat perusahaan.',
      button: {
          Edit: 'Merevisi'
      },
      dialog: {
          editpwd: {
              title: 'Ubah kata sandi administrator',
              label: {
                  oldPwd: 'password lama',
                  newPwd: 'kata sandi baru',
                  reNewPwd: 'Konfirmasikan kata sandi baru'
              },
              inputPlaceholder: {
                  oldPwd: 'Silakan masukkan kata sandi lama',
                  newPwd: 'Silakan masukkan kata sandi baru',
                  reNewPwd: 'Silakan masuk untuk mengonfirmasi kata sandi baru'
              }
          },
          editName: {
              title: 'Ubah nama administrator',
              label: {
                  name: 'nama baru'
              },
              inputPlaceholder: {
                  name: 'Silakan masukkan nama baru'
              }
          },
          editEmail: {
              title: 'Ubah email administrator',
              title2: 'Anda belum mengikat alamat email Anda. Harap ikat alamat email Anda tepat waktu.',
              label: {
                  email: 'kotak surat baru'
              },
              inputPlaceholder: {
                  email: 'Silakan masukkan email baru'
              }
          },
          editCompanyFullName: {
              title: 'Ubah nama lengkap perusahaan',
              label: {
                  name: 'nama lengkap baru'
              },
              inputPlaceholder: {
                  name: 'Silakan masukkan nama lengkap baru'
              }
          },
          editCompanyAbbreviationCn: {
              title: 'Ubah singkatan perusahaan (Cina)',
              label: {
                  name: 'Singkatan baru'
              },
              inputPlaceholder: {
                  name: 'Silakan masukkan nama pendek baru'
              }
          },
          editCompanyAbbreviationEn: {
              title: 'Ubah singkatan perusahaan (Bahasa Inggris)'
          },
          editWebsite: {
              title: 'Ubah alamat situs web perusahaan',
              label: {
                  website: 'URL baru'
              },
              inputPlaceholder: {
                  website: 'Silakan masukkan URL baru'
              }
          },
          editNoAuditJoin: {
              title: 'Ubah sakelar bergabung tanpa aplikasi',
              radio: {
                  open: 'Membuka',
                  close: 'penutup'
              }
          }
      },
      validator: {
          value_null: 'Nilai tidak boleh kosong',
          oldPwd: {
              null: 'Silakan masukkan kata sandi lama'
          },
          newPwd: {
              length: 'Silakan atur kata sandi yang panjangnya 6 karakter atau lebih'
          },
          reNewPwd: {
              consistent: 'Kata sandi yang dimasukkan dua kali tidak konsisten!'
          },
          name: {
              length: 'Nama tidak boleh lebih dari 20 karakter'
          },
          email: {
              rule: 'Format email salah'
          },
          NoAuditJoin: {
              null: 'Silakan pilih item'
          }
      }
  },
  register: {
      step_1: 'nama pengguna dan kata sandi',
      step_2: 'Isi informasi perusahaan',
      step_3: 'Menyelesaikan',
      head: 'Pendaftaran pengguna perusahaan',
      usernameError: 'Nama anggota harus terdiri dari 6-20 karakter, tidak termasuk karakter Cina, dan diawali dengan huruf.',
      usernameError_exist: 'Nama pengguna sudah ada, silakan ubah nama pengguna Anda',
      emailError: 'Format email salah',
      emailError_exist: 'Email sudah ada',
      passwordError_length: '6-20 karakter, hanya alfanumerik dan tanda baca',
      passwordError_same: 'Kata sandi tidak boleh sama dengan nama pengguna',
      passwordError: 'Format kata sandi salah',
      rePasswordError: 'Kata sandi yang dimasukkan dua kali tidak konsisten, silakan masukkan kembali',
      companyNameError: 'Silakan masukkan nama lengkap perusahaan',
      companyAbbreviationError: 'Silakan masukkan singkatan perusahaan',
      orgError_exist:'Perusahaan telah terdaftar, silakan ubah nama bisnis Anda',
      button: {
          next: 'Langkah berikutnya',
          login: 'segera masuk'
      },
      inputPlaceholder: {
          username: 'nama belakang',
          email: 'Surat',
          password: 'mengatur kata sandi',
          rePassword: 'Masukkan kata sandi lagi',
          name: 'Nama lengkap perusahaan (nama yang terdaftar secara sah)*',
          simpleNameCN: 'Singkatan perusahaan (Cina)*',
          simpleNameEN: 'Singkatan perusahaan (Bahasa Inggris)',
          website: 'Situs web perusahaan (www)'
      },
      success: 'Anda telah berhasil membuat akun bisnis!',
      location: 'lokasi:',
      Scale: 'skala:'
  },
  forgotPwd: {
      head: 'Pengambilan email',
      resetSuccess: 'Anda telah berhasil mengatur ulang kata sandi Anda!',
      inputPlaceholder: {
          code: 'silakan masukkan kode verifikasi'
      },
      sendCodeError: 'Kode verifikasi salah',
      sendCodeError_sended: 'Kode verifikasi sudah terkirim, silahkan dicek',
      button: {
          getCode: 'dapatkan kode verifikasi',
          reGetCode: 'Dapatkan kode verifikasi lagi'
      }
  },
  button: {
      ok: 'mengonfirmasi',
      cancel: 'Membatalkan',
      search: 'mencari',
      clear: 'Jernih'
  },
  device: {
      button: {
          setContent: 'Konfigurasikan jadwal push',
          setGroupMng: 'Siapkan administrator grup',
          startNow: 'Dorong sekarang',
          stopNow: 'Berhenti mendorong',
          add: 'Tambahkan perangkat',
          search: 'Mencari',
          adjust: 'Sesuaikan pengelompokan',
          del: 'menghapus'
      },
      inputPlaceholder: {
          search: 'Nomor perangkat/Nomor ponsel/Nomor ViiTALK'
      },
      group: {
          add: 'Tambahkan grup',
          edit: 'Sunting grup',
          del: 'Hapus grup'
      },
      table: {
          th_name: 'Nama perangkat',
          th_number: 'Nomor perangkat',
          th_time: 'Waktu masuk terakhir',
          th_brandNumber: 'Nomor gedung konferensi',
          th_online: 'Sedang on line',
          th_status: 'negara',
          th_bindNumber: 'Ikat nomor layar besar'
      },
      dialog: {
          addGroupTitle: 'Silakan masukkan nama grup',
          inputPlaceholder: {
              groupName: 'Silakan masukkan nama grup'
          },
          delGroupTitle: 'Hapus grup',
          delGroupTips: 'Apakah Anda yakin ingin menghapus grup tersebut?',
          editGroupTitle: 'Sunting grup',
          groupMng: {
              title: 'Edit manajer grup',
              inputPlaceholder: {
                  name: 'Silakan masukkan nama administrator'
              },
              label: {
                  name: 'Nama administrator'
              }
          },
          addDevice: {
              title: 'Tambahkan perangkat',
              inputPlaceholder: {
                  name: 'Silakan masukkan nama perangkat',
                  number: 'Silakan masukkan nomor perangkat'
              },
              label: {
                  name: 'Nama perangkat',
                  number: 'Nomor perangkat',
                  group: 'Silakan pilih grup perangkat'
              }
          },
          delDevice: {
              title: 'Hapus perangkat',
              tips: 'Apakah Anda yakin ingin menghapus perangkat?'
          },
          startNowDevice: {
              title: 'Dorong sekarang',
              tips: 'Apakah Anda yakin ingin mendorongnya sekarang?'
          },
          stopNowDevice: {
              title: 'Berhenti mendorong',
              tips: 'Apakah Anda yakin ingin berhenti mengejan?'
          },
          adjustTitle: 'Sesuaikan pengelompokan',
          editDevice: {
              title: 'Ubah nama perangkat',
              inputPlaceholder: {
                  name: 'Silakan masukkan nama perangkat'
              }
          },
          noChildren: {
              title: 'memperingatkan',
              tips: 'Silakan tambahkan grup terlebih dahulu'
          }
      },
      validator: {
          group: {
              name_null: 'Nama grup tidak boleh kosong',
              name_length: 'Tidak boleh melebihi 10 karakter'
          },
          number_null: 'Nomor tidak boleh kosong',
          number_rule: 'Hanya boleh 10 digit dimulai dengan 6 atau 8',
          name_null: 'Nama terminal tidak boleh kosong'
      }
  },
  user: {
      button: {
          add: 'Tambahkan pengguna'
      },
      inputPlaceholder: {
          search: 'Nomor ViiTALK'
      },
      table: {
          th_name: 'Nama',
          th_number: 'Nomor ponsel/nomor Maizhe'
      },
      dialog: {
          addUser: {
              title: 'Tambahkan pengguna',
              inputPlaceholder: {
                  number: 'Silakan masukkan nomor ViiTALK'
              },
              label: {
                  number: 'Nomor ViiTALK',
                  group: 'Silakan pilih grup pengguna'
              }
          },
          delUser: {
              title: 'menghapus pengguna',
              tips: 'Apakah Anda yakin ingin menghapus pengguna tersebut?'
          }
      },
      validator: {
          number_null: 'Nomor tidak boleh kosong',
          number_rule: 'Nomor ini tidak ada'
      }
  },
  addrbook: {
      return: {
          delDevice: {
              tips_1: 'Grup yang akan dihapus tidak ada',
              tips_2: 'Ada subgrup di bawah grup yang akan dihapus dan tidak dapat dihapus.',
              tips_3: 'Ada orang di bawah grup yang akan dihapus dan tidak dapat dihapus.'
          },
          addDevice: {
              tips_1: 'Sudah ditambahkan, silakan lihat grup lain',
              tips_2: 'Telah ditambahkan oleh perusahaan lain',
              tips_3: 'Nomor ini tidak ada'
          }
      }
  },
  audit: {
      option: {
          label: {
              NoPass: 'Tidak lulus',
              hasRefuse: 'ditolak',
              hasPass: 'lulus',
              hasInvalid: 'kedaluwarsa',
              all: 'semua'
          }
      },
      button: {
          pass: 'lulus',
          reject: 'menolak'
      },
      table: {
          th_number: 'Nomor Perangkat',
          th_date: 'tanggal',
          th_status: 'negara',
          th_source: 'sumber'
      },
      dialog: {
          passTitle: 'Disetujui',
          reject: {
              title: 'Tolak ulasan',
              tips: 'Apakah Anda yakin ingin menolak ulasan tersebut?'
          }
      },
      api: {
          req: {
              tips_1: 'Tidak dapat lolos tinjauan, item yang dipilih berasal dari sumber berbeda!',
              tips_2: 'Silakan pilih wilayah yang akan dialokasikan'
          },
          return: {
              tips_1: 'Nomor perangkat tidak ada',
              tips_2: 'Anda telah bergabung dengan perusahaan',
              tips_3: 'Sudah ditambahkan, silakan lihat grup lain'
          }
      }
  },
  conferenceLog: {
      inputPlaceholder: 'Silakan masukkan konten',
      table: {
          th: {
              roomId: 'nomor kamar',
              state: 'status kamar',
              username: 'Dibuat oleh jid',
              createTime: 'Waktu pembuatan pertemuan',
              closeTime: 'waktu berakhirnya pertemuan',
              duration: 'Durasi'
          }
      },
      dialog: {
          title: 'Pencatatan anggota yang bergabung/keluar dari ruang',
          table: {
              th: {
                  username: 'Dibuat oleh jid',
                  joinTime: 'bergabunglah',
                  leaveTime: 'meninggalkan',
                  duration: 'Durasi'
              }
          }
      }
  },
  callLog: {
      table: {
          th: {
              callerMzNum: 'nomor panggilan',
              callTime: 'Waktu panggilan',
              endTime: 'Akhir waktu',
              duration: 'Durasi',
              responderMzNum: 'nomor yang dipanggil'
          }
      }
  },
  roomStatus: {
      table: {
          th: {
              roomMessage: 'Informasi kamar dasar',
              Attendees: 'peserta',
              bystander: 'pengamat',
              join: 'Untuk ditambahkan',
              exited: 'Keluar'
          }
      },
      roomNumber: 'Nomor ruang konferensi',
      name: 'Nama ruang pertemuan',
      dissolution: 'Bubar',
      pattern: 'model',
      confluence: 'pertemuan',
      forward: 'Maju',
      createdTime: 'Waktu penciptaan',
      limit: 'Batas pelepasan/jumlah orang',
      createMode: 'membuat pola',
      order: 'Janji temu (pertemuan)',
      simple: 'Multipemain biasa',
      type: 'Jenis pertemuan',
      creator: 'pencipta',
      host: 'Tuan rumah:',
      windowMode: 'Modus jendela:',
      WindowMode1: 'Satu besar dan banyak kecil',
      WindowMode2: 'Ubin',
      hide: 'Apakah akan menyembunyikan:',
      forbidden: 'Apakah akan melarang gandum:',
      inorder: 'memesan:',
      source: 'sumber:',
      joinTime: 'Waktu bergabung:',
      comeOut: 'Silakan keluar',
      openMic: 'nyalakan mikrofon',
      closeMic: 'Matikan mikrofon',
      leave: 'meninggalkan:',
      peopleNumber: 'Jumlah orang:'
  },
  menu: {
      index: 'halaman Depan',
      device_manage: 'Manajemen perangkat',
      user_manage: 'manajemen pengguna',
      conference_brand: 'Nomor pintu konferensi',
      audit_manage: 'Manajemen audit',
      orgMessage: 'Informasi perusahaan',
      roomStatus: 'pertemuan yang sedang berlangsung',
      p2pCallLog: 'Riwayat panggilan video',
      conferenceLog: 'notulen rapat',
      authorizedMessage: 'Informasi otorisasi',
      title: 'Platform manajemen perusahaan',
      account_mangae: 'Manajemen akun',
      contacts: 'Buku alamat grup',
      records: 'catatan panggilan',
      LicenseInformation: 'Informasi otorisasi',
      pushInfo: 'Dorongan informasi',
      infoGroup: 'Dorong manajemen grup'
  },
  download: 'mengunduh aplikasi',
  unauthorized: {
      title: 'Platform manajemen perusahaan ViiTALK (tidak sah)',
      span_1: 'Telah terdeteksi bahwa server Anda tidak mengotorisasi layanan ViiTALK,',
      span_2: 'Silakan hubungi layanan pelanggan untuk mendapatkan file otorisasi.',
      span_3: '(Untuk mendapatkan file otorisasi, Anda perlu memberikan alamat IP server dan alamat Mac Anda ke layanan pelanggan)',
      upError: 'Maaf file yang anda upload salah, silahkan upload kembali',
      chooseFile_b: 'Pilih dokumen',
      upFile_b: 'Unggah file otorisasi',
      success: {
          span_1: 'Otorisasi berhasil',
          span_2: 'Memasuki platform manajemen perusahaan...',
          span_3: '(Jika Anda sudah lama tidak masuk, harap segarkan browser)'
      },
      dialog: {
          title: 'Silakan ubah kata sandi administrator',
          span: 'Kata sandi administrator Anda belum diubah, harap segera ubah kata sandi Anda',
          button: 'kirim'
      }
  },
  lang: {
    'zh-CN': 'Cina disederhanakan',
    'zh-TW': 'Cina tradisional',
    en: 'Bahasa inggris',
    ja: 'Jepang',
    ko: 'Korea',
    th: 'Thai',
    ar: 'Arab',
    bn: 'Bengali',
    de: 'Jerman',
    el: 'Orang yunani',
    es: 'Spanyol',
    fa: 'Orang Persia',
    fr: 'Perancis',
    id: 'bahasa Indonesia',
    it: 'Italia',
    iw: 'Ibrani',
    ku: 'Kurdi',
    nl: 'Belanda',
    pl: 'Polandia',
    pt: 'Portugis',
    ro: 'Rumania',
    ru: 'Rusia',
    tr: 'Turki',
    vi: 'Orang Vietnam'
  },
  authorized: {
      org: 'Perusahaan resmi',
      userLimit: 'Batasan pengguna',
      roomMax: 'Kapasitas maksimum ruang konferensi cloud',
      roomCountLimit: 'Jumlah maksimum ruang pertemuan cloud online',
      authorizedActiveDate: 'Tanggal Aktivasi Lisensi Cloud Pribadi',
      authorizedValidDate: 'Lisensi private cloud berlaku hingga',
      button_up: 'Perbarui otorisasi',
      upFileError: 'Tidak ada file yang dipilih'
  },
  index_title: 'Platform Manajemen Perusahaan ViiTalk',
  brand: {
    table: {
        th: {
            brandNumber: 'nomor rumah',
            bindNumber: 'Ikat nomor layar besar',
            onlineStatus: 'status daring',
            lastLoginTime: 'waktu masuk terakhir'
        },
        col: {
            content: {
                onlineStatus: {
                    online: 'on line',
                    offline: 'Luring'
                }
            }
        }
    }
  }
}