// 中文
exports.default = {
  el: {
      colorpicker: {
          confirm: 'ยืนยัน',
          clear: 'ชัดเจน'
      },
      datepicker: {
          now: 'ตอนนี้',
          today: 'วันนี้',
          cancel: 'ยกเลิก',
          clear: 'ชัดเจน',
          confirm: 'ยืนยัน',
          selectDate: 'เลือกวันที่',
          selectTime: 'เลือกเวลา',
          startDate: 'วันที่เริ่มต้น',
          startTime: 'เวลาเริ่มต้น',
          endDate: 'วันที่สิ้นสุด',
          endTime: 'เวลาสิ้นสุด',
          prevYear: 'ปีก่อน',
          nextYear: 'ปีหน้า',
          prevMonth: 'เดือนที่แล้ว',
          nextMonth: 'เดือนหน้า',
          year: 'ปี',
          month1: 'มกราคม',
          month2: 'กุมภาพันธ์',
          month3: 'มีนาคม',
          month4: 'เมษายน',
          month5: 'อาจ',
          month6: 'มิถุนายน',
          month7: 'กรกฎาคม',
          month8: 'สิงหาคม',
          month9: 'กันยายน',
          month10: 'ตุลาคม',
          month11: 'พฤศจิกายน',
          month12: 'ธันวาคม',
          weeks: {
              sun: 'วัน',
              mon: 'หนึ่ง',
              tue: 'สอง',
              wed: 'สาม',
              thu: 'สี่',
              fri: 'ห้า',
              sat: 'หก'
          },
          months: {
              jan: 'มกราคม',
              feb: 'กุมภาพันธ์',
              mar: 'มีนาคม',
              apr: 'เมษายน',
              may: 'อาจ',
              jun: 'มิถุนายน',
              jul: 'กรกฎาคม',
              aug: 'สิงหาคม',
              sep: 'กันยายน',
              oct: 'ตุลาคม',
              nov: 'พฤศจิกายน',
              dec: 'ธันวาคม'
          }
      },
      select: {
          loading: 'กำลังโหลด',
          noMatch: 'ไม่มีข้อมูลที่ตรงกัน',
          noData: 'ไม่มีข้อมูล',
          placeholder: 'โปรดเลือก'
      },
      cascader: {
          noMatch: 'ไม่มีข้อมูลที่ตรงกัน',
          loading: 'กำลังโหลด',
          placeholder: 'โปรดเลือก',
          noData: 'ไม่มีข้อมูล'
      },
      pagination: {
          goto: 'ไปที่',
          pagesize: 'รายการ/หน้า',
          total: 'ทั้งหมด {total} รายการ',
          pageClassifier: 'หน้าหนังสือ'
      },
      messagebox: {
          title: 'คำใบ้',
          confirm: 'กำหนด',
          cancel: 'ยกเลิก',
          error: 'ข้อมูลที่ป้อนไม่ตรงตามข้อกำหนด!'
      },
      upload: {
          deleteTip: 'กดปุ่ม Delete เพื่อลบ',
          delete: 'ลบ',
          preview: 'ดูภาพ',
          continue: 'ดำเนินการอัปโหลดต่อไป'
      },
      table: {
          emptyText: 'ยังไม่มีข้อมูล',
          confirmFilter: 'กรอง',
          resetFilter: 'รีเซ็ต',
          clearFilter: 'ทั้งหมด',
          sumText: 'ผลรวม'
      },
      tree: {
          emptyText: 'ยังไม่มีข้อมูล'
      },
      transfer: {
          noMatch: 'ไม่มีข้อมูลที่ตรงกัน',
          noData: 'ไม่มีข้อมูล',
          titles: [
              'รายการ 1',
              'รายการ 2'
          ],
          filterPlaceholder: 'ป้อนคำหลัก',
          noCheckedFormat: '{total} รายการ',
          hasCheckedFormat: 'เลือก {checked}/{total} แล้ว'
      },
      image: {
          error: 'การโหลดล้มเหลว'
      },
      pageHeader: {
          title: 'กลับ'
      },
      popconfirm: {
          confirmButtonText: 'ใช่',
          cancelButtonText: 'เลขที่'
      },
      empty: {
          description: 'ยังไม่มีข้อมูล'
      }
  }
}
