// 中文
exports.default = {
  el: {
      colorpicker: {
          confirm: 'Onaylamak',
          clear: 'Temizlemek'
      },
      datepicker: {
          now: 'Şimdi',
          today: 'Bugün',
          cancel: 'İptal etmek',
          clear: 'Temizlemek',
          confirm: 'Onaylamak',
          selectDate: 'Tarih seç',
          selectTime: 'Zamanı seçin',
          startDate: 'Başlangıç ​​tarihi',
          startTime: 'Başlangıç ​​saati',
          endDate: 'bitiş tarihi',
          endTime: 'bitiş zamanı',
          prevYear: 'Geçen yıl',
          nextYear: 'gelecek yıl',
          prevMonth: 'geçen ay',
          nextMonth: 'gelecek ay',
          year: 'Yıl',
          month1: 'Ocak',
          month2: 'Şubat',
          month3: 'Mart',
          month4: 'Nisan',
          month5: 'Mayıs',
          month6: 'Haziran',
          month7: 'Temmuz',
          month8: 'Ağustos',
          month9: 'Eylül',
          month10: 'Ekim',
          month11: 'Kasım',
          month12: 'Aralık',
          weeks: {
              sun: 'gün',
              mon: 'bir',
              tue: 'iki',
              wed: 'üç',
              thu: 'Dört',
              fri: 'beş',
              sat: 'altı'
          },
          months: {
              jan: 'Ocak',
              feb: 'Şubat',
              mar: 'Mart',
              apr: 'Nisan',
              may: 'Mayıs',
              jun: 'Haziran',
              jul: 'Temmuz',
              aug: 'Ağustos',
              sep: 'Eylül',
              oct: 'Ekim',
              nov: 'Kasım',
              dec: 'Aralık'
          }
      },
      select: {
          loading: 'Yükleniyor',
          noMatch: 'Eşleşen veri yok',
          noData: 'Bilgi yok',
          placeholder: 'Lütfen seçin'
      },
      cascader: {
          noMatch: 'Eşleşen veri yok',
          loading: 'Yükleniyor',
          placeholder: 'Lütfen seçin',
          noData: 'Bilgi yok'
      },
      pagination: {
          goto: 'Git',
          pagesize: 'Öğe/sayfa',
          total: 'Toplamda {total} öğe',
          pageClassifier: 'sayfa'
      },
      messagebox: {
          title: 'ipucu',
          confirm: 'belirlemek',
          cancel: 'İptal etmek',
          error: 'Girilen bilgiler gereksinimleri karşılamıyor!'
      },
      upload: {
          deleteTip: 'Silmek için sil tuşuna basın',
          delete: 'silmek',
          preview: 'Resimleri görüntüle',
          continue: 'Yüklemeye devam et'
      },
      table: {
          emptyText: 'Henüz bilgi yok',
          confirmFilter: 'Filtre',
          resetFilter: 'Sıfırla',
          clearFilter: 'Tümü',
          sumText: 'Toplam'
      },
      tree: {
          emptyText: 'Henüz bilgi yok'
      },
      transfer: {
          noMatch: 'Eşleşen veri yok',
          noData: 'Bilgi yok',
          titles: [
              'Liste 1',
              'Liste 2'
          ],
          filterPlaceholder: 'Anahtar kelimeyi girin',
          noCheckedFormat: '{total} öğe',
          hasCheckedFormat: '{checked}/{total} işaretlendi'
      },
      image: {
          error: 'Yükleme başarısız oldu'
      },
      pageHeader: {
          title: 'geri dönmek'
      },
      popconfirm: {
          confirmButtonText: 'Evet',
          cancelButtonText: 'HAYIR'
      },
      empty: {
          description: 'Henüz bilgi yok'
      }
  }
}
