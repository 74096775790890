import InfoGroup from "@/components/pushInfo/infoGroup.vue";

export default {
  language: ' فارسی',
  serverError: 'ناهنجاری سرور، لطفا با مدیر تماس بگیرید',
  opearteSuccess: 'عملیات موفقیت آمیز',
  agreement: {
      agreement_1: 'خوانده اند و موافقت کرده اند',
      agreement_2: '\'توافق کاربر\'',
      agreement_3: 'و',
      agreement_4: '\'سیاست حفظ حریم خصوصی\'',
      agreement_5: 'لطفاً کادر را علامت بزنید تا با توافق موافقت کنید'
  },
  login: {
      webName: 'پلتفرم مدیریت سازمانی ViiTALK',
      website: 'وب سایت رسمی:',
      tel: 'تلفن:',
      email: 'پست الکترونیکی:',
      title: 'ورود کاربر سازمانی',
      inputPlaceholder: {
          name: 'لطفا نام کاربری/ایمیل را وارد کنید',
          pwd: 'لطفا رمز عبور خود را وارد کنید'
      },
      button: {
          login: 'وارد شدن',
          email: 'ورود ایمیل',
          account: 'ورود به حساب کاربری',
          register: 'ثبت نام کاربر سازمانی',
          pwd: 'رمز عبور را فراموش کنید'
      },
      error: {
          uPwd: 'نام کاربری یا رمز عبور اشتباه است',
          uLock: 'حساب قفل شده است',
          uLeave: 'شما استعفا داده اید و نمی توانید از سیستم استفاده کنید',
          uRelogin: 'شما قبلاً در جای دیگری وارد شده اید!!!',
          uReplaceLogin: 'حساب شما در جای دیگری وارد شده است و شما مجبور به خروج از سیستم شده اید. اگر تقصیر شما نبود، ممکن است رمز عبور شما لو رفته باشد، لطفاً در اسرع وقت وارد شوید و رمز عبور خود را تغییر دهید.',
          user_null: 'لطفا نام کاربری را وارد کنید',
          pwd_null: 'لطفا رمز عبور را وارد کنید'
      }
  },
  personal: {
      companyNumber: 'شرکت، پروژه',
      administrators: 'مدیر',
      changeLanguage: 'تغییر زبان',
      logout: 'خروج از سیستم'
  },
  companyMessage: {
      adminPwd: 'رمز عبور مدیر',
      adminUserName: 'نام مدیر',
      adminEmail: 'ایمیل مدیر',
      companyFullName: 'نام کامل شرکت (نام قانونی ثبت شده)',
      companySimpleNameCn: 'مخفف شرکت ',
      companySimpleNameEn: 'مخفف شرکت (انگلیسی)',
      companyWebsite: 'وب سایت شرکتی',
      companyNumber: 'شماره گروه سازمانی',
      companyNoAuditJoin: 'بدون بررسی بپیوندید',
      adminExplain: 'نام مدیر برای همه اعضای دفترچه آدرس شرکت قابل مشاهده است',
      companySimpleExplain: 'مخفف شرکت در صفحه اصلی دستگاه Rooms نمایش داده می شود و برای همه اعضای دفترچه آدرس شرکت قابل مشاهده است.',
      button: {
          Edit: 'تجدید نظر کنید'
      },
      dialog: {
          editpwd: {
              title: 'رمز عبور مدیر را تغییر دهید',
              label: {
                  oldPwd: 'رمز عبور قدیمی',
                  newPwd: 'رمز عبور جدید',
                  reNewPwd: 'رمز عبور جدید را تایید کنید'
              },
              inputPlaceholder: {
                  oldPwd: 'لطفا رمز عبور قدیمی را وارد کنید',
                  newPwd: 'لطفا یک رمز عبور جدید وارد کنید',
                  reNewPwd: 'لطفا برای تایید رمز عبور جدید را وارد کنید'
              }
          },
          editName: {
              title: 'نام مدیر را تغییر دهید',
              label: {
                  name: 'نام جدید'
              },
              inputPlaceholder: {
                  name: 'لطفا نام جدید را وارد کنید'
              }
          },
          editEmail: {
              title: 'ایمیل مدیر را اصلاح کنید',
              title2: 'شما هنوز آدرس ایمیل خود را قید نکرده اید لطفا آدرس ایمیل خود را به موقع وصل کنید.',
              label: {
                  email: 'صندوق پستی جدید'
              },
              inputPlaceholder: {
                  email: 'لطفا یک ایمیل جدید وارد کنید'
              }
          },
          editCompanyFullName: {
              title: 'نام کامل شرکت را تغییر دهید',
              label: {
                  name: 'نام کامل جدید'
              },
              inputPlaceholder: {
                  name: 'لطفا نام کامل جدید را وارد کنید'
              }
          },
          editCompanyAbbreviationCn: {
              title: 'تغییر مخفف شرکت (چینی)',
              label: {
                  name: 'مخفف جدید'
              },
              inputPlaceholder: {
                  name: 'لطفا یک نام کوتاه جدید وارد کنید'
              }
          },
          editCompanyAbbreviationEn: {
              title: 'تغییر مخفف شرکت (انگلیسی)'
          },
          editWebsite: {
              title: 'آدرس وب سایت شرکت را تغییر دهید',
              label: {
                  website: 'URL جدید'
              },
              inputPlaceholder: {
                  website: 'لطفا URL جدید را وارد کنید'
              }
          },
          editNoAuditJoin: {
              title: 'سوئیچ اتصال بدون برنامه را تغییر دهید',
              radio: {
                  open: 'باز کن',
                  close: 'بسته'
              }
          }
      },
      validator: {
          value_null: 'مقدار نمی تواند خالی باشد',
          oldPwd: {
              null: 'لطفا رمز عبور قدیمی را وارد کنید'
          },
          newPwd: {
              length: 'لطفا یک رمز عبور از 6 کاراکتر یا بیشتر تعیین کنید'
          },
          reNewPwd: {
              consistent: 'گذرواژه هایی که دو بار وارد شده اند متناقض هستند!'
          },
          name: {
              length: 'نام نمی تواند بیشتر از 20 کاراکتر باشد'
          },
          email: {
              rule: 'قالب ایمیل نادرست است'
          },
          NoAuditJoin: {
              null: 'لطفاً یک مورد را انتخاب کنید'
          }
      }
  },
  register: {
      step_1: 'نام کاربری و رمز عبور',
      step_2: 'اطلاعات شرکت را پر کنید',
      step_3: 'پایان',
      head: 'ثبت نام کاربر سازمانی',
      usernameError: 'نام عضو باید بین 6 تا 20 کاراکتر، بدون حروف چینی باشد و با یک حرف شروع شود.',
      usernameError_exist: 'نام کاربری از قبل وجود دارد، لطفا نام کاربری خود را تغییر دهید',
      emailError: 'قالب ایمیل نادرست است',
      emailError_exist: 'ایمیل از قبل وجود دارد',
      passwordError_length: '6-20 کاراکتر، فقط حروف عددی و علائم نگارشی',
      passwordError_same: 'رمز عبور نمی تواند همان نام کاربری باشد',
      passwordError: 'فرمت رمز عبور نادرست است',
      rePasswordError: 'گذرواژه‌های وارد شده دو بار متناقض هستند، لطفاً دوباره وارد کنید',
      companyNameError: 'لطفا نام کامل شرکت را وارد کنید',
      companyAbbreviationError: 'لطفا مخفف شرکت را وارد کنید',
      orgError_exist:'شرکت ثبت شده است، لطفا نام تجاری خود را تغییر دهید',
      button: {
          next: 'گام بعدی',
          login: 'بلافاصله وارد شوید'
      },
      inputPlaceholder: {
          username: 'نام کاربری',
          email: 'ایمیل',
          password: 'قراردادن رمز عبور',
          rePassword: 'رمز عبور را دوباره وارد کنید',
          name: 'نام کامل شرکت (نام قانونی ثبت شده)*',
          simpleNameCN: 'مخفف شرکت (چینی)*',
          simpleNameEN: 'مخفف شرکت (انگلیسی)',
          website: 'وب سایت شرکت (www)'
      },
      success: 'شما با موفقیت یک حساب تجاری ایجاد کردید!',
      location: 'محل:',
      Scale: 'مقیاس:'
  },
  forgotPwd: {
      head: 'بازیابی ایمیل',
      resetSuccess: 'رمز عبور خود را با موفقیت بازنشانی کردید!',
      inputPlaceholder: {
          code: 'لطفا کد تایید را وارد کنید'
      },
      sendCodeError: 'کد تایید نادرست',
      sendCodeError_sended: 'کد تأیید ارسال شده است، لطفاً آن را بررسی کنید',
      button: {
          getCode: 'دریافت کد تایید',
          reGetCode: 'دوباره کد تأیید را دریافت کنید'
      }
  },
  button: {
      ok: 'تایید',
      cancel: 'لغو کنید',
      search: 'جستجو کردن',
      clear: 'پاک کردن'
  },
  device: {
      button: {
          setContent: 'برنامه زمانبندی فشار را پیکربندی کنید',
          setGroupMng: 'مدیر گروه را تنظیم کنید',
          startNow: 'اکنون فشار دهید',
          stopNow: 'فشار را متوقف کنید',
          add: 'افزودن دستگاه',
          search: 'جستجو کردن',
          adjust: 'گروه بندی را تنظیم کنید',
          del: 'حذف'
      },
      inputPlaceholder: {
          search: 'شماره دستگاه/شماره تلفن همراه/شماره ViiTALK'
      },
      group: {
          add: 'اضافه کردن گروه',
          edit: 'ویرایش گروه',
          del: 'حذف گروه'
      },
      table: {
          th_name: 'نام دستگاه',
          th_number: 'شماره دستگاه',
          th_time: 'آخرین زمان ورود',
          th_brandNumber: 'شماره خانه کنفرانس',
          th_online: 'برخط است',
          th_status: 'حالت',
          th_bindNumber: 'اتصال شماره صفحه نمایش بزرگ'
      },
      dialog: {
          addGroupTitle: 'لطفا یک نام گروه وارد کنید',
          inputPlaceholder: {
              groupName: 'لطفا نام گروه را وارد کنید'
          },
          delGroupTitle: 'حذف گروه',
          delGroupTips: 'آیا مطمئن هستید که می خواهید گروه را حذف کنید؟',
          editGroupTitle: 'ویرایش گروه',
          groupMng: {
              title: 'مدیر گروه را ویرایش کنید',
              inputPlaceholder: {
                  name: 'لطفا نام مدیر را وارد کنید'
              },
              label: {
                  name: 'نام مدیر'
              }
          },
          addDevice: {
              title: 'افزودن دستگاه',
              inputPlaceholder: {
                  name: 'لطفا نام دستگاه را وارد کنید',
                  number: 'لطفا شماره دستگاه را وارد کنید'
              },
              label: {
                  name: 'نام دستگاه',
                  number: 'شماره دستگاه',
                  group: 'لطفاً یک گروه دستگاه انتخاب کنید'
              }
          },
          delDevice: {
              title: 'دستگاه را خارج کنید',
              tips: 'آیا مطمئن هستید که می خواهید دستگاه را حذف کنید؟'
          },
          startNowDevice: {
              title: 'اکنون فشار دهید',
              tips: 'آیا مطمئنید که اکنون می خواهید آن را فشار دهید؟'
          },
          stopNowDevice: {
              title: 'فشار را متوقف کنید',
              tips: 'آیا مطمئن هستید که می خواهید فشار را متوقف کنید؟'
          },
          adjustTitle: 'گروه بندی را تنظیم کنید',
          editDevice: {
              title: 'نام دستگاه را تغییر دهید',
              inputPlaceholder: {
                  name: 'لطفا نام دستگاه را وارد کنید'
              }
          },
          noChildren: {
              title: 'هشدار دهد',
              tips: 'لطفا ابتدا یک گروه اضافه کنید'
          }
      },
      validator: {
          group: {
              name_null: 'نام گروه نمی تواند خالی باشد',
              name_length: 'نمی تواند بیش از 10 کاراکتر باشد'
          },
          number_null: 'شماره نمی تواند خالی باشد',
          number_rule: 'فقط می تواند 10 رقمی باشد که با 6 یا 8 شروع می شود',
          name_null: 'نام ترمینال نمی تواند خالی باشد'
      }
  },
  user: {
      button: {
          add: 'کاربر اضافه کنید'
      },
      inputPlaceholder: {
          search: 'شماره ViiTALK'
      },
      table: {
          th_name: 'نام',
          th_number: 'شماره تلفن همراه / شماره Maizhe'
      },
      dialog: {
          addUser: {
              title: 'کاربر اضافه کنید',
              inputPlaceholder: {
                  number: 'لطفا شماره ViiTALK را وارد کنید'
              },
              label: {
                  number: 'شماره ViiTALK',
                  group: 'لطفا گروه کاربری را انتخاب کنید'
              }
          },
          delUser: {
              title: 'کاربران را حذف کنید',
              tips: 'آیا مطمئن هستید که می خواهید کاربر را حذف کنید؟'
          }
      },
      validator: {
          number_null: 'شماره نمی تواند خالی باشد',
          number_rule: 'این عدد وجود ندارد'
      }
  },
  addrbook: {
      return: {
          delDevice: {
              tips_1: 'گروهی که باید حذف شود وجود ندارد',
              tips_2: 'زیر گروه هایی در زیر گروه وجود دارد که باید حذف شوند و نمی توان آنها را حذف کرد.',
              tips_3: 'افرادی در زیر گروه هستند که باید حذف شوند و نمی توان آنها را حذف کرد.'
          },
          addDevice: {
              tips_1: 'قبلاً اضافه شده است، لطفاً گروه های دیگر را مشاهده کنید',
              tips_2: 'توسط شرکت های دیگر اضافه شده است',
              tips_3: 'این عدد وجود ندارد'
          }
      }
  },
  audit: {
      option: {
          label: {
              NoPass: 'عبور نکرد',
              hasRefuse: 'رد شد',
              hasPass: 'گذشت',
              hasInvalid: 'منقضی شده',
              all: 'همه'
          }
      },
      button: {
          pass: 'عبور',
          reject: 'رد کردن'
      },
      table: {
          th_number: 'شماره دستگاه',
          th_date: 'تاریخ',
          th_status: 'حالت',
          th_source: 'منبع'
      },
      dialog: {
          passTitle: 'تایید شده',
          reject: {
              title: 'بررسی را رد کنید',
              tips: 'آیا مطمئن هستید که می خواهید بررسی را رد کنید؟'
          }
      },
      api: {
          req: {
              tips_1: 'امکان بررسی وجود ندارد، موارد انتخاب شده از منابع مختلف آمده است!',
              tips_2: 'لطفاً منطقه ای را که باید اختصاص داده شود انتخاب کنید'
          },
          return: {
              tips_1: 'شماره دستگاه وجود ندارد',
              tips_2: 'شما به شرکت ملحق شده اید',
              tips_3: 'قبلاً اضافه شده است، لطفاً گروه های دیگر را مشاهده کنید'
          }
      }
  },
  conferenceLog: {
      inputPlaceholder: 'لطفا محتوا را وارد کنید',
      table: {
          th: {
              roomId: 'شماره اتاق',
              state: 'وضعیت اتاق',
              username: 'توسط جید ایجاد شد',
              createTime: 'زمان ایجاد جلسه',
              closeTime: 'زمان پایان جلسه',
              duration: 'مدت زمان'
          }
      },
      dialog: {
          title: 'عضو در حال عضویت/خروج از ورود به سیستم اتاق',
          table: {
              th: {
                  username: 'توسط جید ایجاد شد',
                  joinTime: 'پیوستن به',
                  leaveTime: 'ترک کردن',
                  duration: 'مدت زمان'
              }
          }
      }
  },
  callLog: {
      table: {
          th: {
              callerMzNum: 'شماره تماس',
              callTime: 'زمان شماره گیری',
              endTime: 'زمان پایان',
              duration: 'مدت زمان',
              responderMzNum: 'شماره تماس گرفت'
          }
      }
  },
  roomStatus: {
      table: {
          th: {
              roomMessage: 'اطلاعات اولیه اتاق',
              Attendees: 'شرکت کننده',
              bystander: 'تماشاچی',
              join: 'اضافه شود',
              exited: 'خارج شد'
          }
      },
      roomNumber: 'شماره اتاق کنفرانس',
      name: 'نام اتاق جلسه',
      dissolution: 'منحل کند',
      pattern: 'مدل',
      confluence: 'تلاقی',
      forward: 'رو به جلو',
      createdTime: 'زمان ایجاد',
      limit: 'محدودیت انتشار/تعداد کل افراد',
      createMode: 'ایجاد الگو',
      order: 'قرار ملاقات (جلسه)',
      simple: 'چند نفره معمولی',
      type: 'نوع جلسه',
      creator: 'ایجاد کننده',
      host: 'میزبان:',
      windowMode: 'حالت پنجرهای:',
      WindowMode1: 'یکی بزرگ و خیلی کوچک',
      WindowMode2: 'کاشی',
      hide: 'پنهان کردن یا نه:',
      forbidden: 'ممنوعیت گندم:',
      inorder: 'سفارش:',
      source: 'منبع:',
      joinTime: 'زمان عضویت:',
      comeOut: 'لطفا بیا بیرون',
      openMic: 'میکروفون را روشن کنید',
      closeMic: 'میکروفون را خاموش کنید',
      leave: 'ترک کردن:',
      peopleNumber: 'تعداد مردم:'
  },
  menu: {
      index: 'صفحه اول',
      device_manage: 'مدیریت دستگاه',
      user_manage: 'مدیریت کاربر',
      conference_brand: 'شماره درب کنفرانس',
      audit_manage: 'مدیریت حسابرسی',
      orgMessage: 'اطلاعات شرکت ها',
      roomStatus: 'جلسه در حال انجام',
      p2pCallLog: 'سابقه تماس تصویری',
      conferenceLog: 'صورتجلسه',
      authorizedMessage: 'اطلاعات مجوز',
      title: 'پلتفرم مدیریت سازمانی',
      account_mangae: 'مدیریت حساب',
      contacts: 'دفترچه آدرس گروه',
      records: 'سوابق تماس',
      LicenseInformation: 'اطلاعات مجوز',
      pushInfo: 'فشار اطلاعات',
      infoGroup: 'مدیریت گروه فشار'
  },
  download: 'دانلود برنامه ها',
  unauthorized: {
      title: 'پلت فرم مدیریت سازمانی ViiTALK (غیر مجاز)',
      span_1: 'مشخص شده است که سرور شما به سرویس ViiTALK اجازه نمی دهد،',
      span_2: 'لطفاً برای دریافت فایل مجوز با خدمات مشتری تماس بگیرید.',
      span_3: '(برای دریافت فایل مجوز، باید آدرس IP سرور و آدرس مک خود را به خدمات مشتری ارائه دهید)',
      upError: 'با عرض پوزش، فایلی که آپلود کردید اشتباه است، لطفا دوباره آپلود کنید',
      chooseFile_b: 'یک سند را انتخاب کنید',
      upFile_b: 'آپلود فایل مجوز',
      success: {
          span_1: 'مجوز با موفقیت انجام شد',
          span_2: 'ورود به پلتفرم مدیریت سازمانی...',
          span_3: '(اگر برای مدت طولانی وارد نشده اید، لطفا مرورگر را به روز کنید)'
      },
      dialog: {
          title: 'لطفا رمز عبور مدیر را تغییر دهید',
          span: 'رمز عبور مدیریت شما هنوز تغییر نکرده است، لطفا سریعا رمز عبور خود را تغییر دهید',
          button: 'ارسال'
      }
  },
  lang: {
    'zh-CN': 'چینی ساده شده',
    'zh-TW': 'چینی سنتی',
    en: 'انگلیسی',
    ja: 'ژاپنی',
    ko: 'کره ای',
    th: 'تایلندی',
    ar: 'عربی',
    bn: 'بنگالی',
    de: 'آلمانی',
    el: 'یونانی',
    es: 'اسپانیایی',
    fa: 'فارسی',
    fr: 'فرانسوی',
    id: 'اندونزیایی',
    it: 'ایتالیایی',
    iw: 'عبری',
    ku: 'کردی',
    nl: 'هلندی',
    pl: 'لهستانی',
    pt: 'پرتغالی',
    ro: 'رومانیایی',
    ru: 'روسی',
    tr: 'ترکی',
    vi: 'ویتنامی'
  },
  authorized: {
      org: 'شرکت مجاز',
      userLimit: 'محدودیت کاربر',
      roomMax: 'حداکثر ظرفیت اتاق کنفرانس ابری',
      roomCountLimit: 'حداکثر تعداد اتاق جلسات ابری آنلاین',
      authorizedActiveDate: 'تاریخ فعال سازی مجوز ابر خصوصی',
      authorizedValidDate: 'مجوز ابر خصوصی تا زمان اعتبار دارد',
      button_up: 'به روز رسانی مجوز',
      upFileError: 'هیچ فایلی انتخاب نشده است'
  },
  index_title: 'پلتفرم مدیریت سازمانی ViiTalk',
  brand: {
    table: {
        th: {
            brandNumber: 'شماره خانه',
            bindNumber: 'شماره صفحه نمایش بزرگ را متصل کنید',
            onlineStatus: 'وضعیت آنلاین',
            lastLoginTime: 'آخرین زمان ورود'
        },
        col: {
            content: {
                onlineStatus: {
                    online: 'برخط',
                    offline: 'آفلاین'
                }
            }
        }
    }
  }
}