<template>
  <div>
    <div class="input-container">

      <span style="margin-right: 20px;">{{ $t('callLog.table.th.callerMzNum') }}</span>
      <el-input style="margin-right: 50px; width: 200px;" v-model="numCaller" :placeholder="$t('conferenceLog.inputPlaceholder')"
        class="custom-input"></el-input>

      <span style="margin-right: 20px;">{{ $t('callLog.table.th.responderMzNum') }}</span>
      <el-input style="margin-right: 50px; width: 200px;" v-model="numResponder" :placeholder="$t('conferenceLog.inputPlaceholder')"
        class="custom-input">
      </el-input>

      <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" type="primary" @click="search">{{ $t('button.search') }}</el-button>
      <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" type="primary" @click="clear">{{ $t('button.clear') }}</el-button>
    </div>

    <div class="table-container">
      <el-table :data="tableData" border style="width: 900px">
        <el-table-column prop="callerMzNum" :label="$t('callLog.table.th.callerMzNum')" width="180"></el-table-column>
        <el-table-column prop="callTime" :label="$t('callLog.table.th.callTime')" width="180">
          <template slot-scope="scope">{{ formatTime(scope.row.callTime) }}</template>
        </el-table-column>
        <el-table-column prop="endTime" :label="$t('callLog.table.th.endTime')" width="130">

          <template slot-scope="scope">{{ formatHMSTime(scope.row.endTime) }}</template>
        </el-table-column>
        <el-table-column :label="$t('callLog.table.th.duration')" width="130">

          <template slot-scope="scope">{{ timeLong(scope.row.callTime, scope.row.endTime) }}</template>
        </el-table-column>
        <el-table-column prop="responderMzNum" :label="$t('callLog.table.th.responderMzNum')"></el-table-column>
      </el-table>

      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex"
        :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
        :total="dataCount" class="pagination-right-aligned"></el-pagination>
    </div>

  </div>
</template>

<script>
import { methodGet } from '@/api'

export default {
  created() {
    this.loadP2PLogList()
  },
  data() {
    return {
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      dataCount: 0,
      numCaller: '',
      numResponder: ''
    }
  },
  methods: {
    search() {
      this.loadP2PLogList()
    },
    clear() {
      this.numCaller = ''
      this.numResponder = ''
    },
    handleSizeChange(size) {
      this.pageSize = size
      this.loadP2PLogList()
    },
    handleCurrentChange(page) {
      this.pageIndex = page
      this.loadP2PLogList()
    },
    loadP2PLogList() {
      const params = {
        params: {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
          numCaller: this.numCaller,
          numResponder: this.numResponder
        }
      }
      methodGet('/api/callLog/p2p', params).then((data) => {
        if (data.status) {
          this.dataCount = data.data.dataCount
          this.tableData = data.data.datas
        }
      })
    },
    formatHMSTime(time) {
      if (time == null || time == 0) {
        return ''
      }
      const date = new Date(time).toLocaleString()
      return date.match(/\d+:\d+:\d+/)[0]
    },
    formatTime(time) {
      // eslint-disable-next-line use-isnan
      if (time == null || time == NaN || time == '') {
        return ''
      }
      return this.chgUnintimestr(time / 1000)
    },
    chgUnintimestr(time) {
      const unixTimestamp = new Date(time * 1000)
      return unixTimestamp.toLocaleString()
    },
    timeLong(callTime, endTime) {
      if (callTime == null || callTime == '') {
        return '为空'
      }
      if (endTime == null || endTime == '') {
        return '为空'
      }
      return this.sec2timeLong(Math.floor((endTime - callTime) / 1000), 1, 0)
    },
    sec2timeLong(sec, dispSec, ch) {
      let str = ''
      if (sec >= 3600) {
        str = Math.floor(sec / 3600) + (ch ? '小时' : ':')
        // eslint-disable-next-line no-param-reassign
        sec = sec % 3600
      }
      if (sec >= 60) {
        str = str + (Math.floor(sec / 60) + (ch ? '分' : '\''))
        // eslint-disable-next-line no-param-reassign
        sec = sec % 60
      }
      if (dispSec) {
        str = str + (sec + (ch ? '秒' : '"'))
      }
      return str
    }
  }
}
</script>

<style>

.input-container {
  /* display: inline-block; */
  margin-bottom: 20px;
}

.custom-input {
  width: 200px;
}

.pagination-right-aligned {
  margin-top: 15px;
  text-align: right;
}

.table-container {
  position: relative;
  width: 900px;
  /* 设置表格容器的宽度 */
}
</style>
