// 中文
exports.default = {
  el: {
      colorpicker: {
          confirm: 'يتأكد',
          clear: 'واضح'
      },
      datepicker: {
          now: 'الآن',
          today: 'اليوم',
          cancel: 'يلغي',
          clear: 'واضح',
          confirm: 'يتأكد',
          selectDate: 'حدد تاريخ',
          selectTime: 'حدد الوقت',
          startDate: 'تاريخ البدء',
          startTime: 'وقت البدء',
          endDate: 'تاريخ الانتهاء',
          endTime: 'وقت النهاية',
          prevYear: 'السنة الماضية',
          nextYear: 'العام القادم',
          prevMonth: 'الشهر الماضي',
          nextMonth: 'الشهر القادم',
          year: 'سنة',
          month1: 'يناير',
          month2: 'شهر فبراير',
          month3: 'يمشي',
          month4: 'أبريل',
          month5: 'يمكن',
          month6: 'يونيو',
          month7: 'يوليو',
          month8: 'أغسطس',
          month9: 'سبتمبر',
          month10: 'اكتوبر',
          month11: 'شهر نوفمبر',
          month12: 'ديسمبر',
          weeks: {
              sun: 'يوم',
              mon: 'واحد',
              tue: 'اثنين',
              wed: 'ثلاثة',
              thu: 'أربعة',
              fri: 'خمسة',
              sat: 'ستة'
          },
          months: {
              jan: 'يناير',
              feb: 'شهر فبراير',
              mar: 'يمشي',
              apr: 'أبريل',
              may: 'يمكن',
              jun: 'يونيو',
              jul: 'يوليو',
              aug: 'أغسطس',
              sep: 'سبتمبر',
              oct: 'اكتوبر',
              nov: 'شهر نوفمبر',
              dec: 'ديسمبر'
          }
      },
      select: {
          loading: 'تحميل',
          noMatch: 'لا توجد بيانات مطابقة',
          noData: 'لا يوجد معلومات',
          placeholder: 'الرجاء التحديد'
      },
      cascader: {
          noMatch: 'لا توجد بيانات مطابقة',
          loading: 'تحميل',
          placeholder: 'الرجاء التحديد',
          noData: 'لا يوجد معلومات'
      },
      pagination: {
          goto: 'اذهب إلى',
          pagesize: 'العنصر/الصفحة',
          total: '{total} عنصر إجمالاً',
          pageClassifier: 'صفحة'
      },
      messagebox: {
          title: 'تَلمِيح',
          confirm: 'يحدد',
          cancel: 'يلغي',
          error: 'المعلومات المدخلة لا تلبي المتطلبات!'
      },
      upload: {
          deleteTip: 'اضغط على مفتاح الحذف للحذف',
          delete: 'يمسح',
          preview: 'عرض الصور',
          continue: 'استمر في التحميل'
      },
      table: {
          emptyText: 'لا توجد معلومات حتى الآن',
          confirmFilter: 'منقي',
          resetFilter: 'إعادة ضبط',
          clearFilter: 'الجميع',
          sumText: 'مجموع'
      },
      tree: {
          emptyText: 'لا توجد معلومات حتى الآن'
      },
      transfer: {
          noMatch: 'لا توجد بيانات مطابقة',
          noData: 'لا يوجد معلومات',
          titles: [
              'القائمة 1',
              'القائمة 2'
          ],
          filterPlaceholder: 'أدخل الكلمة المفتاحية',
          noCheckedFormat: '{total} عنصر',
          hasCheckedFormat: 'تم تحديد {checked}/{total}.'
      },
      image: {
          error: 'فشل التحميل'
      },
      pageHeader: {
          title: 'يعود'
      },
      popconfirm: {
          confirmButtonText: 'نعم',
          cancelButtonText: 'لا'
      },
      empty: {
          description: 'لا توجد معلومات حتى الآن'
      }
  }
}
