import InfoGroup from "@/components/pushInfo/infoGroup.vue";

export default {
  language: 'แบบไทย',
  serverError: 'ความผิดปกติของเซิร์ฟเวอร์ โปรดติดต่อผู้ดูแลระบบ',
  opearteSuccess: 'การดำเนินงานที่ประสบความสำเร็จ',
  agreement: {
      agreement_1: 'อ่านแล้วเห็นด้วย',
      agreement_2: '\'ข้อตกลงการใช้\'',
      agreement_3: 'และ',
      agreement_4: '\'นโยบายความเป็นส่วนตัว\'',
      agreement_5: 'โปรดทำเครื่องหมายในช่องเพื่อยอมรับข้อตกลง'
  },
  login: {
      webName: 'ViiTALK แพลตฟอร์มการจัดการองค์กร',
      website: 'เว็บไซต์อย่างเป็นทางการ:',
      tel: 'โทรศัพท์:',
      email: 'จดหมาย:',
      title: 'การเข้าสู่ระบบของผู้ใช้ระดับองค์กร',
      inputPlaceholder: {
          name: 'กรุณากรอกชื่อผู้ใช้/อีเมล',
          pwd: 'กรุณาใส่รหัสผ่านของคุณ'
      },
      button: {
          login: 'เข้าสู่ระบบ',
          email: 'เข้าสู่ระบบอีเมล',
          account: 'ลงชื่อเข้าใช้',
          register: 'การลงทะเบียนผู้ใช้ระดับองค์กร',
          pwd: 'ลืมรหัสผ่าน'
      },
      error: {
          uPwd: 'ชื่อผู้ใช้หรือรหัสผ่านผิด',
          uLock: 'บัญชีถูกล็อค',
          uLeave: 'ท่านได้ลาออกและไม่สามารถใช้งานระบบได้',
          uRelogin: 'คุณเข้าสู่ระบบที่อื่นแล้ว!!!',
          uReplaceLogin: 'บัญชีของคุณถูกลงชื่อเข้าใช้ที่อื่นแล้ว และคุณถูกบังคับให้ออกจากระบบ หากไม่ใช่ความผิดของคุณ รหัสผ่านของคุณอาจรั่วไหล โปรดเข้าสู่ระบบอีกครั้งและเปลี่ยนรหัสผ่านโดยเร็วที่สุด',
          user_null: 'กรุณากรอกชื่อผู้ใช้',
          pwd_null: 'กรุณากรอกรหัสผ่าน'
      }
  },
  personal: {
      companyNumber: 'องค์กร',
      administrators: 'ผู้ดูแลระบบ',
      changeLanguage: 'เปลี่ยนภาษา',
      logout: 'ออกจากระบบ'
  },
  companyMessage: {
      adminPwd: 'รหัสผ่านผู้ดูแลระบบ',
      adminUserName: 'ชื่อผู้ดูแลระบบ',
      adminEmail: 'อีเมลผู้ดูแลระบบ',
      companyFullName: 'ชื่อเต็มของบริษัท (ชื่อที่จดทะเบียนตามกฎหมาย)',
      companySimpleNameCn: 'ชื่อย่อบริษัท',
      companySimpleNameEn: 'ชื่อย่อบริษัท (ภาษาอังกฤษ)',
      companyWebsite: 'เว็บไซต์ของบริษัท',
      companyNumber: 'หมายเลขกลุ่มองค์กร',
      companyNoAuditJoin: 'เข้าร่วมโดยไม่ต้องตรวจสอบ',
      adminExplain: 'ชื่อผู้ดูแลระบบจะปรากฏแก่สมาชิกสมุดที่อยู่ขององค์กรทั้งหมด',
      companySimpleExplain: 'ตัวย่อของบริษัทจะปรากฏบนหน้าแรกของอุปกรณ์ Rooms และสมาชิกทุกคนในสมุดที่อยู่ของบริษัทจะมองเห็นได้',
      button: {
          Edit: 'ปรับปรุงใหม่'
      },
      dialog: {
          editpwd: {
              title: 'เปลี่ยนรหัสผ่านผู้ดูแลระบบ',
              label: {
                  oldPwd: 'รหัสผ่านเก่า',
                  newPwd: 'รหัสผ่านใหม่',
                  reNewPwd: 'ยืนยันรหัสผ่านใหม่'
              },
              inputPlaceholder: {
                  oldPwd: 'กรุณากรอกรหัสผ่านเก่า',
                  newPwd: 'กรุณากรอกรหัสผ่านใหม่',
                  reNewPwd: 'กรุณากรอกเพื่อยืนยันรหัสผ่านใหม่'
              }
          },
          editName: {
              title: 'แก้ไขชื่อผู้ดูแลระบบ',
              label: {
                  name: 'ชื่อใหม่'
              },
              inputPlaceholder: {
                  name: 'กรุณากรอกชื่อใหม่'
              }
          },
          editEmail: {
              title: 'แก้ไขอีเมลของผู้ดูแลระบบ',
              title2: 'คุณยังไม่ได้ผูกที่อยู่อีเมลของคุณ โปรดผูกที่อยู่อีเมลของคุณให้ทันเวลา',
              label: {
                  email: 'กล่องจดหมายใหม่'
              },
              inputPlaceholder: {
                  email: 'กรุณากรอกอีเมล์ใหม่'
              }
          },
          editCompanyFullName: {
              title: 'แก้ไขชื่อเต็มของบริษัท',
              label: {
                  name: 'ชื่อเต็มใหม่'
              },
              inputPlaceholder: {
                  name: 'กรุณากรอกชื่อเต็มใหม่'
              }
          },
          editCompanyAbbreviationCn: {
              title: 'แก้ไขอักษรย่อบริษัท (ภาษาจีน)',
              label: {
                  name: 'อักษรย่อใหม่'
              },
              inputPlaceholder: {
                  name: 'โปรดป้อนชื่อย่อใหม่'
              }
          },
          editCompanyAbbreviationEn: {
              title: 'แก้ไขอักษรย่อบริษัท (ภาษาอังกฤษ)'
          },
          editWebsite: {
              title: 'แก้ไขที่อยู่เว็บไซต์ของบริษัท',
              label: {
                  website: 'URL ใหม่'
              },
              inputPlaceholder: {
                  website: 'กรุณากรอก URL ใหม่'
              }
          },
          editNoAuditJoin: {
              title: 'ปรับเปลี่ยนสวิตช์เข้าร่วมแบบไม่มีแอปพลิเคชัน',
              radio: {
                  open: 'เปิด',
                  close: 'ปิด'
              }
          }
      },
      validator: {
          value_null: 'ค่าต้องไม่เว้นว่าง',
          oldPwd: {
              null: 'กรุณากรอกรหัสผ่านเก่า'
          },
          newPwd: {
              length: 'กรุณาตั้งรหัสผ่านให้มีความยาว 6 ตัวอักษรขึ้นไป'
          },
          reNewPwd: {
              consistent: 'รหัสผ่านที่ป้อนสองครั้งไม่สอดคล้องกัน!'
          },
          name: {
              length: 'ชื่อไม่สามารถยาวเกิน 20 ตัวอักษร'
          },
          email: {
              rule: 'รูปแบบอีเมลไม่ถูกต้อง'
          },
          NoAuditJoin: {
              null: 'กรุณาเลือกรายการ'
          }
      }
  },
  register: {
      step_1: 'ชื่อผู้ใช้และรหัสผ่าน',
      step_2: 'กรอกข้อมูลบริษัท',
      step_3: 'เสร็จ',
      head: 'การลงทะเบียนผู้ใช้ระดับองค์กร',
      usernameError: 'ชื่อสมาชิกต้องมีความยาว 6-20 ตัวอักษร ไม่รวมตัวอักษรจีนและขึ้นต้นด้วยตัวอักษร',
      usernameError_exist: 'มีชื่อผู้ใช้อยู่แล้ว กรุณาแก้ไขชื่อผู้ใช้ของคุณ',
      emailError: 'รูปแบบอีเมลไม่ถูกต้อง',
      emailError_exist: 'มีอีเมลอยู่แล้ว',
      passwordError_length: '6-20 ตัวอักษร เฉพาะตัวอักษรและตัวเลขและเครื่องหมายวรรคตอน',
      passwordError_same: 'รหัสผ่านไม่สามารถเป็นชื่อผู้ใช้เดียวกันได้',
      passwordError: 'รูปแบบรหัสผ่านไม่ถูกต้อง',
      rePasswordError: 'รหัสผ่านที่ป้อนสองครั้งไม่สอดคล้องกัน โปรดป้อนใหม่',
      companyNameError: 'กรุณากรอกชื่อเต็มของบริษัท',
      companyAbbreviationError: 'กรุณากรอกอักษรย่อของบริษัท',
      orgError_exist:'จดทะเบียนบริษัทแล้ว โปรดเปลี่ยนชื่อธุรกิจของคุณ',
      button: {
          next: 'ขั้นตอนต่อไป',
          login: 'เข้าสู่ระบบทันที'
      },
      inputPlaceholder: {
          username: 'ชื่อผู้ใช้',
          email: 'จดหมาย',
          password: 'ตั้งรหัสผ่าน',
          rePassword: 'ป้อนรหัสผ่านอีกครั้ง',
          name: 'ชื่อเต็มของบริษัท (ชื่อที่จดทะเบียนตามกฎหมาย)*',
          simpleNameCN: 'ชื่อย่อบริษัท (ภาษาจีน)*',
          simpleNameEN: 'ชื่อย่อบริษัท (ภาษาอังกฤษ)',
          website: 'เว็บไซต์ของบริษัท (www)'
      },
      success: 'คุณสร้างบัญชีธุรกิจสำเร็จแล้ว!',
      location: 'ที่ตั้ง:',
      Scale: 'มาตราส่วน:'
  },
  forgotPwd: {
      head: 'การเรียกค้นอีเมล',
      resetSuccess: 'คุณรีเซ็ตรหัสผ่านสำเร็จแล้ว!',
      inputPlaceholder: {
          code: 'กรุณากรอกรหัสยืนยัน'
      },
      sendCodeError: 'รหัสยืนยันไม่ถูกต้อง',
      sendCodeError_sended: 'รหัสยืนยันถูกส่งไปแล้ว โปรดตรวจสอบ',
      button: {
          getCode: 'รับรหัสยืนยัน',
          reGetCode: 'รับรหัสยืนยันอีกครั้ง'
      }
  },
  button: {
      ok: 'ยืนยัน',
      cancel: 'ยกเลิก',
      search: 'ค้นหา',
      clear: 'ชัดเจน'
  },
  device: {
      button: {
          setContent: 'กำหนดค่ากำหนดการพุช',
          setGroupMng: 'ตั้งค่าผู้ดูแลกลุ่ม',
          startNow: 'ดันเลย',
          stopNow: 'หยุดผลักดัน',
          add: 'เพิ่มอุปกรณ์',
          search: 'ค้นหา',
          adjust: 'ปรับการจัดกลุ่ม',
          del: 'ลบ'
      },
      inputPlaceholder: {
          search: 'หมายเลขอุปกรณ์/หมายเลขโทรศัพท์มือถือ/หมายเลข ViiTALK'
      },
      group: {
          add: 'เพิ่มกลุ่ม',
          edit: 'แก้ไขกลุ่ม',
          del: 'ลบกลุ่ม'
      },
      table: {
          th_name: 'ชื่ออุปกรณ์',
          th_number: 'หมายเลขอุปกรณ์',
          th_time: 'เวลาเข้าสู่ระบบครั้งล่าสุด',
          th_brandNumber: 'เลขที่ห้องประชุม',
          th_online: 'กำลังออนไลน์อยู่',
          th_status: 'สถานะ',
          th_bindNumber: 'ผูกเลขหน้าจอขนาดใหญ่'
      },
      dialog: {
          addGroupTitle: 'กรุณากรอกชื่อกลุ่ม',
          inputPlaceholder: {
              groupName: 'กรุณากรอกชื่อกลุ่ม'
          },
          delGroupTitle: 'ลบกลุ่ม',
          delGroupTips: 'คุณแน่ใจหรือไม่ว่าต้องการลบกลุ่ม?',
          editGroupTitle: 'แก้ไขกลุ่ม',
          groupMng: {
              title: 'แก้ไขผู้จัดการกลุ่ม',
              inputPlaceholder: {
                  name: 'กรุณากรอกชื่อผู้ดูแลระบบ'
              },
              label: {
                  name: 'ชื่อผู้ดูแลระบบ'
              }
          },
          addDevice: {
              title: 'เพิ่มอุปกรณ์',
              inputPlaceholder: {
                  name: 'กรุณากรอกชื่ออุปกรณ์',
                  number: 'กรุณากรอกหมายเลขอุปกรณ์'
              },
              label: {
                  name: 'ชื่ออุปกรณ์',
                  number: 'หมายเลขอุปกรณ์',
                  group: 'โปรดเลือกกลุ่มอุปกรณ์'
              }
          },
          delDevice: {
              title: 'ถอดอุปกรณ์',
              tips: 'คุณแน่ใจหรือไม่ว่าต้องการลบอุปกรณ์'
          },
          startNowDevice: {
              title: 'ดันเลย',
              tips: 'คุณแน่ใจหรือว่าต้องการผลักดันตอนนี้?'
          },
          stopNowDevice: {
              title: 'หยุดผลักดัน',
              tips: 'คุณแน่ใจหรือว่าต้องการหยุดผลักดัน?'
          },
          adjustTitle: 'ปรับการจัดกลุ่ม',
          editDevice: {
              title: 'แก้ไขชื่ออุปกรณ์',
              inputPlaceholder: {
                  name: 'กรุณากรอกชื่ออุปกรณ์'
              }
          },
          noChildren: {
              title: 'เตือน',
              tips: 'กรุณาเพิ่มกลุ่มก่อน'
          }
      },
      validator: {
          group: {
              name_null: 'ชื่อกลุ่มต้องไม่เว้นว่าง',
              name_length: 'ต้องไม่เกิน 10 ตัวอักษร'
          },
          number_null: 'หมายเลขต้องไม่เว้นว่าง',
          number_rule: 'สามารถมีได้เพียง 10 หลักที่ขึ้นต้นด้วย 6 หรือ 8',
          name_null: 'ชื่อเทอร์มินัลต้องไม่เว้นว่าง'
      }
  },
  user: {
      button: {
          add: 'เพิ่มผู้ใช้'
      },
      inputPlaceholder: {
          search: 'หมายเลข ViiTALK'
      },
      table: {
          th_name: 'ชื่อ',
          th_number: 'เบอร์โทรศัพท์มือถือ/เบอร์มายเจ๋อ'
      },
      dialog: {
          addUser: {
              title: 'เพิ่มผู้ใช้',
              inputPlaceholder: {
                  number: 'กรุณากรอกหมายเลข ViiTALK'
              },
              label: {
                  number: 'หมายเลข ViiTALK',
                  group: 'กรุณาเลือกกลุ่มผู้ใช้งาน'
              }
          },
          delUser: {
              title: 'ลบผู้ใช้',
              tips: 'คุณแน่ใจหรือไม่ว่าต้องการลบผู้ใช้รายนี้'
          }
      },
      validator: {
          number_null: 'หมายเลขต้องไม่เว้นว่าง',
          number_rule: 'ไม่มีหมายเลขนี้'
      }
  },
  addrbook: {
      return: {
          delDevice: {
              tips_1: 'ไม่มีกลุ่มที่จะลบ',
              tips_2: 'มีกลุ่มย่อยภายใต้กลุ่มที่จะลบและไม่สามารถลบได้',
              tips_3: 'มีคนในกลุ่มที่จะลบและไม่สามารถลบได้'
          },
          addDevice: {
              tips_1: 'เพิ่มแล้ว โปรดดูกลุ่มอื่น',
              tips_2: 'ได้รับการเพิ่มโดยบริษัทอื่น',
              tips_3: 'ไม่มีหมายเลขนี้'
          }
      }
  },
  audit: {
      option: {
          label: {
              NoPass: 'ไม่ผ่าน',
              hasRefuse: 'ถูกปฏิเสธ',
              hasPass: 'ผ่าน',
              hasInvalid: 'หมดอายุแล้ว',
              all: 'ทั้งหมด'
          }
      },
      button: {
          pass: 'ผ่าน',
          reject: 'ปฏิเสธ'
      },
      table: {
          th_number: 'หมายเลขอุปกรณ์',
          th_date: 'วันที่',
          th_status: 'สถานะ',
          th_source: 'แหล่งที่มา'
      },
      dialog: {
          passTitle: 'ที่ได้รับการอนุมัติ',
          reject: {
              title: 'ปฏิเสธการตรวจสอบ',
              tips: 'คุณแน่ใจหรือไม่ว่าต้องการปฏิเสธการตรวจสอบนี้'
          }
      },
      api: {
          req: {
              tips_1: 'ไม่สามารถผ่านการตรวจสอบได้ รายการที่เลือกมาจากแหล่งที่แตกต่างกัน!',
              tips_2: 'โปรดเลือกภูมิภาคที่จะจัดสรร'
          },
          return: {
              tips_1: 'ไม่มีหมายเลขอุปกรณ์',
              tips_2: 'คุณได้เข้าร่วมองค์กรแล้ว',
              tips_3: 'เพิ่มแล้ว โปรดดูกลุ่มอื่น'
          }
      }
  },
  conferenceLog: {
      inputPlaceholder: 'กรุณากรอกเนื้อหา',
      table: {
          th: {
              roomId: 'หมายเลขห้อง',
              state: 'สถานะห้อง',
              username: 'สร้างโดยจิด',
              createTime: 'เวลาสร้างการประชุม',
              closeTime: 'เวลาสิ้นสุดการประชุม',
              duration: 'ระยะเวลา'
          }
      },
      dialog: {
          title: 'สมาชิกเข้าร่วม/ออกจากห้องเข้าสู่ระบบ',
          table: {
              th: {
                  username: 'สร้างโดยจิด',
                  joinTime: 'เข้าร่วม',
                  leaveTime: 'ออกจาก',
                  duration: 'ระยะเวลา'
              }
          }
      }
  },
  callLog: {
      table: {
          th: {
              callerMzNum: 'หมายเลขโทรศัพท์',
              callTime: 'เวลาโทร',
              endTime: 'เวลาสิ้นสุด',
              duration: 'ระยะเวลา',
              responderMzNum: 'หมายเลขที่เรียก'
          }
      }
  },
  roomStatus: {
      table: {
          th: {
              roomMessage: 'ข้อมูลเบื้องต้นของห้องพัก',
              Attendees: 'ผู้เข้าร่วม',
              bystander: 'ผู้ยืนดู',
              join: 'ที่จะนำมาเพิ่ม',
              exited: 'ออกแล้ว'
          }
      },
      roomNumber: 'หมายเลขห้องประชุม',
      name: 'ชื่อห้องประชุม',
      dissolution: 'ยุบวง',
      pattern: 'แบบอย่าง',
      confluence: 'จุดบรรจบกัน',
      forward: 'ซึ่งไปข้างหน้า',
      createdTime: 'เวลาสร้าง',
      limit: 'ปล่อย/จำกัดจำนวนคนทั้งหมด',
      createMode: 'สร้างลวดลาย',
      order: 'การนัดหมาย (ประชุม)',
      simple: 'ผู้เล่นหลายคนธรรมดา',
      type: 'ประเภทการประชุม',
      creator: 'ผู้สร้าง',
      host: 'เจ้าภาพ:',
      windowMode: 'โหมดหน้าต่าง:',
      WindowMode1: 'หนึ่งใหญ่และเล็กมากมาย',
      WindowMode2: 'กระเบื้อง',
      hide: 'ไม่ว่าจะซ่อน:',
      forbidden: 'ไม่ว่าจะห้ามข้าวสาลี:',
      inorder: 'คำสั่ง:',
      source: 'แหล่งที่มา:',
      joinTime: 'เวลาเข้าร่วม:',
      comeOut: 'กรุณาออกมา',
      openMic: 'เปิดไมโครโฟน',
      closeMic: 'ปิดไมโครโฟน',
      leave: 'ออกจาก:',
      peopleNumber: 'จำนวนคน:'
  },
  menu: {
      index: 'หน้าแรก',
      device_manage: 'การจัดการอุปกรณ์',
      user_manage: 'การจัดการผู้ใช้',
      conference_brand: 'หมายเลขประตูการประชุม',
      audit_manage: 'การจัดการตรวจสอบ',
      orgMessage: 'ข้อมูลองค์กร',
      roomStatus: 'การประชุมที่กำลังดำเนินอยู่',
      p2pCallLog: 'ประวัติการโทรวิดีโอ',
      conferenceLog: 'พบกันแปปเดี่ยว',
      authorizedMessage: 'ข้อมูลการอนุญาต',
      title: 'แพลตฟอร์มการจัดการองค์กร',
      account_mangae: 'การจัดการบัญชี',
      contacts: 'สมุดที่อยู่ของกลุ่ม',
      records: 'บันทึกการโทร',
      LicenseInformation: 'ข้อมูลการอนุญาต',
      pushInfo: 'การผลักดันข้อมูล',
      infoGroup: 'ผลักดันการจัดการกลุ่ม'
  },
  download: 'ดาวน์โหลดแอป',
  unauthorized: {
      title: 'แพลตฟอร์มการจัดการองค์กร ViiTALK (ไม่ได้รับอนุญาต)',
      span_1: 'ตรวจพบว่าเซิร์ฟเวอร์ของคุณไม่อนุญาตให้ใช้บริการ ViiTALK',
      span_2: 'โปรดติดต่อฝ่ายบริการลูกค้าเพื่อรับไฟล์การอนุญาต',
      span_3: '(หากต้องการรับไฟล์การอนุญาต คุณจะต้องระบุที่อยู่ IP ของเซิร์ฟเวอร์และที่อยู่ Mac ให้กับฝ่ายบริการลูกค้า)',
      upError: 'ขออภัย ไฟล์ที่คุณอัปโหลดไม่ถูกต้อง โปรดอัปโหลดอีกครั้ง',
      chooseFile_b: 'เลือกเอกสาร',
      upFile_b: 'อัปโหลดไฟล์การอนุญาต',
      success: {
          span_1: 'การอนุญาตสำเร็จ',
          span_2: 'เข้าสู่แพลตฟอร์มการจัดการองค์กร...',
          span_3: '(หากคุณไม่ได้เข้ามาเป็นเวลานาน กรุณารีเฟรชเบราว์เซอร์)'
      },
      dialog: {
          title: 'กรุณาเปลี่ยนรหัสผ่านผู้ดูแลระบบ',
          span: 'รหัสผ่านผู้ดูแลระบบของคุณยังไม่ถูกเปลี่ยน โปรดเปลี่ยนรหัสผ่านทันที',
          button: 'ส่ง'
      }
  },
  lang: {
    'zh-CN': 'จีนตัวย่อ',
    'zh-TW': 'จีนดั้งเดิม',
    en: 'ภาษาอังกฤษ',
    ja: 'ญี่ปุ่น',
    ko: 'เกาหลี',
    th: 'แบบไทย',
    ar: 'ภาษาอาหรับ',
    bn: 'เบงกาลี',
    de: 'เยอรมัน',
    el: 'กรีก',
    es: 'สเปน',
    fa: 'เปอร์เซีย',
    fr: 'ภาษาฝรั่งเศส',
    id: 'ชาวอินโดนีเซีย',
    it: 'อิตาลี',
    iw: 'ภาษาฮีบรู',
    ku: 'เคิร์ด',
    nl: 'ภาษาดัตช์',
    pl: 'ขัด',
    pt: 'โปรตุเกส',
    ro: 'โรมาเนีย',
    ru: 'รัสเซีย',
    tr: 'ตุรกี',
    vi: 'ภาษาเวียดนาม'
  },
  authorized: {
      org: 'องค์กรที่ได้รับอนุญาต',
      userLimit: 'ขีดจำกัดผู้ใช้',
      roomMax: 'ความจุสูงสุดของห้องประชุมระบบคลาวด์',
      roomCountLimit: 'จำนวนห้องประชุมคลาวด์ออนไลน์สูงสุด',
      authorizedActiveDate: 'วันที่เปิดใช้งานใบอนุญาต Private Cloud',
      authorizedValidDate: 'ใบอนุญาตระบบคลาวด์ส่วนตัวมีผลใช้ได้จนถึง',
      button_up: 'อัพเดทการอนุญาต',
      upFileError: 'ไม่ได้เลือกไฟล์'
  },
  index_title: 'แพลตฟอร์มการจัดการองค์กร ViiTalk',
  brand: {
    table: {
        th: {
            brandNumber: 'บ้านเลขที่',
            bindNumber: 'ผูกเลขหน้าจอขนาดใหญ่',
            onlineStatus: 'สถานะออนไลน์',
            lastLoginTime: 'เวลาเข้าสู่ระบบครั้งล่าสุด'
        },
        col: {
            content: {
                onlineStatus: {
                    online: 'ออนไลน์',
                    offline: 'ออฟไลน์'
                }
            }
        }
    }
  }
}