import InfoGroup from "@/components/pushInfo/infoGroup.vue";

export default {
  language: 'Français',
  serverError: 'Anomalie du serveur, veuillez contacter l administrateur',
  opearteSuccess: 'Opération réussie',
  agreement: {
      agreement_1: 'J ai lu et accepté',
      agreement_2: '\'Accord de l utilisateur\'',
      agreement_3: 'et',
      agreement_4: '\'Politique de confidentialité\'',
      agreement_5: 'cocher pour accepter l accord'
  },
  login: {
      webName: 'Plateforme de gestion d entreprise ViiTALK',
      webNameSchool: 'Plateforme de gestion d entreprise ViiTALK',
      website: 'Site officiel:',
      tel: 'Téléphone:',
      email: 'Mail:',
      title: 'Connexion utilisateur entreprise',
      titleSchool: 'Connexion utilisateur entreprise',
      inputPlaceholder: {
          name: 'Veuillez entrer votre nom d utilisateur/e-mail',
          pwd: 's il vous plait entrez votre mot de passe'
      },
      button: {
          login: 'Se connecter',
          email: 'Connexion par e-mail',
          account: 'Connexion au compte',
          register: 'Enregistrement des utilisateurs',
          registerSchool: 'Enregistrement des utilisateurs',
          pwd: 'oublier le mot de passe'
      },
      error: {
          uPwd: 'mauvais nom d utilisateur ou mot de passe',
          uLock: 'Le compte a été verrouillé',
          uLeave: 'Vous avez démissionné et ne pouvez pas utiliser le système',
          uRelogin: 'Vous êtes déjà connecté ailleurs !!!',
          uReplaceLogin: 'Votre compte a été connecté ailleurs et vous avez été obligé de vous déconnecter. Si ce n est pas de votre faute, votre mot de passe a peut-être été divulgué. Veuillez vous reconnecter et modifier votre mot de passe dès que possible.',
          user_null: 'veuillez entrer le nom d utilisateur',
          pwd_null: 'Veuillez entrer le mot de passe'
      }
  },
  personal: {
      companyNumber: 'Entreprise',
      schoolNumber: 'Entreprise',
      administrators: 'administrateur',
      changeLanguage: 'changer de langue',
      logout: 'se déconnecter'
  },
  companyMessage: {
      adminPwd: 'Mot de passe administrateur',
      adminUserName: 'Nom de l administrateur',
      adminEmail: 'E-mail de l administrateur',
      companyFullName: 'Nom complet de la société (dénomination légalement enregistrée)',
      companySimpleNameCn: 'Abréviation de l entreprise',
      companySimpleNameEn: 'Abréviation de l entreprise (anglais)',
      companyWebsite: 'Site Web d entreprise',
      companyNumber: 'Numéro de groupe d entreprises',

      schoolFullName: 'Nom complet de la société (dénomination légalement enregistrée)',
      schoolSimpleNameCn: 'Abréviation de l entreprise',
      schoolSimpleNameEn: 'Abréviation de l entreprise (anglais)',
      schoolWebsite: 'Site Web d entreprise',
      schoolNumber: 'Numéro de groupe d entreprises',

      companyNoAuditJoin: 'Rejoindre sans avis',
      adminExplain: 'Le nom de l administrateur est visible par tous les membres du carnet d adresses de l entreprise',
      companySimpleExplain: 'L abréviation de l entreprise sera affichée sur la page d accueil de l appareil Rooms et visible par tous les membres du carnet d adresses de l entreprise.',
      button: {
          Edit: 'Réviser'
      },
      dialog: {
          editpwd: {
              title: 'Changer le mot de passe administrateur',
              label: {
                  oldPwd: 'ancien mot de passe',
                  newPwd: 'nouveau mot de passe',
                  reNewPwd: 'Confirmez le nouveau mot de passe'
              },
              inputPlaceholder: {
                  oldPwd: 'Veuillez saisir l ancien mot de passe',
                  newPwd: 'Veuillez entrer un nouveau mot de passe',
                  reNewPwd: 'Veuillez entrer pour confirmer le nouveau mot de passe'
              }
          },
          editName: {
              title: 'Modifier le nom de l administrateur',
              label: {
                  name: 'nouveau nom'
              },
              inputPlaceholder: {
                  name: 'Veuillez entrer un nouveau nom'
              }
          },
          editEmail: {
              title: 'Modifier l e-mail de l administrateur',
              title2: 'Vous n avez pas encore lié votre adresse e-mail. Veuillez lier votre adresse e-mail à temps.',
              label: {
                  email: 'nouvelle boîte aux lettres'
              },
              inputPlaceholder: {
                  email: 'Veuillez entrer un nouvel e-mail'
              }
          },
          editCompanyFullName: {
              title: 'Modifier le nom complet de l entreprise',
              label: {
                  name: 'nouveau nom complet'
              },
              inputPlaceholder: {
                  name: 'Veuillez entrer un nouveau nom complet'
              }
          },
          editCompanyAbbreviationCn: {
              title: 'Modifier l abréviation de l entreprise (chinois)',
              label: {
                  name: 'Nouvelle abréviation'
              },
              inputPlaceholder: {
                  name: 'Veuillez saisir un nouveau nom court'
              }
          },
          editCompanyAbbreviationEn: {
              title: 'Modifier l abréviation de l entreprise (anglais)'
          },
          editWebsite: {
              title: 'Modifier l adresse du site Web de l entreprise',
              label: {
                  website: 'Nouvelle URL'
              },
              inputPlaceholder: {
                  website: 'Veuillez entrer une nouvelle URL'
              }
          },
          editNoAuditJoin: {
              title: 'Modifier le commutateur d adhésion sans application',
              radio: {
                  open: 'Ouvrir',
                  close: 'fermeture'
              }
          }
      },
      validator: {
          value_null: 'La valeur ne peut pas être vide',
          oldPwd: {
              null: 'Veuillez saisir l ancien mot de passe'
          },
          newPwd: {
              length: 'Veuillez définir un mot de passe de 6 caractères ou plus'
          },
          reNewPwd: {
              consistent: 'Les mots de passe saisis deux fois sont incohérents !'
          },
          name: {
              length: 'Le nom ne peut pas comporter plus de 20 caractères'
          },
          email: {
              rule: 'Le format de l e-mail est incorrect'
          },
          NoAuditJoin: {
              null: 'Veuillez sélectionner un article'
          }
      }
  },
  register: {
      step_1: 'Mot de passe',
      step_2: 'informations',
      step_3: 'Finition',
      head: 'Enregistrement des utilisateurs',
      usernameError: 'Le nom du membre doit comporter de 6 à 20 caractères, sans compter les caractères chinois, et commencer par une lettre.',
      usernameError_exist: 'Le nom d utilisateur existe déjà, veuillez modifier votre nom d utilisateur',
      emailError: 'Le format de l e-mail est incorrect',
      emailError_exist: 'l email existe déjà',
      passwordError_length: '6 à 20 caractères, uniquement des caractères alphanumériques et des signes de ponctuation',
      passwordError_same: 'Le mot de passe ne peut pas être le même nom d utilisateur',
      passwordError: 'Le format du mot de passe est incorrect',
      rePasswordError: 'Les mots de passe saisis deux fois sont incohérents, veuillez les saisir à nouveau',
      companyNameError: 'Veuillez saisir le nom complet de l entreprise',
      companyAbbreviationError: 'Veuillez saisir l abréviation de l entreprise',
      orgError_exist:'La société a été enregistrée, veuillez changer le nom de votre entreprise',
      button: {
          next: 'L étape suivante',
          login: 'connectez-vous immédiatement'
      },
      inputPlaceholder: {
          username: 'nom d utilisateur',
          email: 'Mail',
          password: 'définir le mot de passe',
          rePassword: 'Entrez à nouveau le mot de passe',
          name: 'Nom complet de la société (dénomination légalement enregistrée)*',
          simpleNameCN: 'Abréviation de l entreprise (chinois)*',
          simpleNameEN: 'Abréviation de l entreprise (anglais)',
          website: 'Site Internet de l entreprise (www)'
      },
      success: 'Vous avez créé avec succès un compte professionnel !',
      location: 'emplacement:',
      Scale: 'échelle:'
  },
  forgotPwd: {
      head: 'Récupération d e-mails',
      resetSuccess: 'Vous avez réinitialisé votre mot de passe avec succès !',
      inputPlaceholder: {
          code: 'veuillez entrer le code de vérification'
      },
      sendCodeError: 'code de vérification incorrect',
      sendCodeError_sended: 'Le code de vérification a été envoyé, veuillez le vérifier',
      button: {
          getCode: 'obtenir le code de vérification',
          reGetCode: 'Obtenez à nouveau le code de vérification'
      }
  },
  button: {
      ok: 'confirmer',
      cancel: 'Annuler',
      search: 'recherche',
      clear: 'Clair'
  },
  device: {
      button: {
          setContent: 'Configurer le calendrier de diffusion',
          setGroupMng: 'Configurer l administrateur de groupe',
          startNow: 'Poussez maintenant',
          stopNow: 'Arrête de pousser',
          add: 'Ajouter un appareil',
          search: 'recherche',
          adjust: 'Ajuster le regroupement',
          del: 'supprimer'
      },
      inputPlaceholder: {
          search: 'Numéro d appareil/numéro de téléphone portable/numéro ViiTALK'
      },
      group: {
          add: 'Ajouter un groupe',
          edit: 'Modifier le groupe',
          del: 'Supprimer le groupe'
      },
      table: {
          th_name: 'Nom de l appareil',
          th_number: 'Numéro d appareil',
          th_time: 'Heure de la dernière connexion',
          th_brandNumber: 'Numéro de la salle de conférence',
          th_online: 'Est en ligne',
          th_status: 'État',
          th_bindNumber: 'Lier un numéro de grand écran'
      },
      dialog: {
          addGroupTitle: 'Veuillez entrer un nom de groupe',
          inputPlaceholder: {
              groupName: 'Veuillez entrer le nom du groupe'
          },
          delGroupTitle: 'Supprimer le groupe',
          delGroupTips: 'Êtes-vous sûr de vouloir supprimer le groupe ?',
          editGroupTitle: 'Modifier le groupe',
          groupMng: {
              title: 'Modifier le gestionnaire de groupe',
              inputPlaceholder: {
                  name: 'Veuillez entrer le nom de l administrateur'
              },
              label: {
                  name: 'Nom de l administrateur'
              }
          },
          addDevice: {
              title: 'Ajouter un appareil',
              inputPlaceholder: {
                  name: 'Veuillez saisir le nom de l appareil',
                  number: 'Veuillez saisir le numéro de l appareil'
              },
              label: {
                  name: 'Nom de l appareil',
                  number: 'Numéro d appareil',
                  group: 'Veuillez sélectionner un groupe d appareils'
              }
          },
          delDevice: {
              title: 'Enlevez l appareil',
              tips: 'Êtes-vous sûr de vouloir supprimer l appareil ?'
          },
          startNowDevice: {
              title: 'Poussez maintenant',
              tips: 'Êtes-vous sûr de vouloir le pousser maintenant ?'
          },
          stopNowDevice: {
              title: 'Arrête de pousser',
              tips: 'Êtes-vous sûr de vouloir arrêter de pousser ?'
          },
          adjustTitle: 'Ajuster le regroupement',
          editDevice: {
              title: 'Modifier le nom de l appareil',
              inputPlaceholder: {
                  name: 'Veuillez saisir le nom de l appareil'
              }
          },
          noChildren: {
              title: 'avertir',
              tips: 'Veuillez d abord ajouter un groupe'
          }
      },
      validator: {
          group: {
              name_null: 'Le nom du groupe ne peut pas être vide',
              name_length: 'Ne peut pas dépasser 10 caractères'
          },
          number_null: 'Le numéro ne peut pas être vide',
          number_rule: 'Ne peut contenir que 10 chiffres commençant par 6 ou 8.',
          name_null: 'Le nom du terminal ne peut pas être vide'
      }
  },
  user: {
      button: {
          add: 'Ajouter un utilisateur'
      },
      inputPlaceholder: {
          search: 'Numéro ViiTALK'
      },
      table: {
          th_name: 'Nom',
          th_number: 'Numéro de téléphone portable/numéro Maizhe'
      },
      dialog: {
          addUser: {
              title: 'Ajouter un utilisateur',
              inputPlaceholder: {
                  number: 'Veuillez entrer le numéro ViiTALK'
              },
              label: {
                  number: 'Numéro ViiTALK',
                  group: 'Veuillez sélectionner un groupe d utilisateurs'
              }
          },
          delUser: {
              title: 'supprimer des utilisateurs',
              tips: 'Etes-vous sûr de vouloir supprimer l utilisateur ?'
          }
      },
      validator: {
          number_null: 'Le numéro ne peut pas être vide',
          number_rule: 'Ce numéro n existe pas'
      }
  },
  addrbook: {
      return: {
          delDevice: {
              tips_1: 'Le groupe à supprimer n existe pas',
              tips_2: 'Il existe des sous-groupes sous le groupe à supprimer et ne peuvent pas être supprimés.',
              tips_3: 'Certaines personnes du groupe doivent être supprimées et ne peuvent pas être supprimées.'
          },
          addDevice: {
              tips_1: 'Déjà ajouté, veuillez consulter les autres groupes',
              tips_2: 'A été ajouté par d autres sociétés',
              tips_3: 'Ce numéro n existe pas'
          }
      }
  },
  audit: {
      option: {
          label: {
              NoPass: 'N a pas passé',
              hasRefuse: 'rejeté',
              hasPass: 'passé',
              hasInvalid: 'expiré',
              all: 'tous'
          }
      },
      button: {
          pass: 'passer',
          reject: 'rejeter'
      },
      table: {
          th_number: 'Numéro d appareil',
          th_date: 'date',
          th_status: 'État',
          th_source: 'source'
      },
      dialog: {
          passTitle: 'Approuvé',
          reject: {
              title: 'Refuser l examen',
              tips: 'Êtes-vous sûr de vouloir rejeter l avis ?'
          }
      },
      api: {
          req: {
              tips_1: 'Impossible de passer l examen, les éléments sélectionnés proviennent de différentes sources !',
              tips_2: 'Veuillez sélectionner la région à attribuer'
          },
          return: {
              tips_1: 'Le numéro d appareil n existe pas',
              tips_2: 'Vous avez rejoint l entreprise',
              tips_3: 'Déjà ajouté, veuillez consulter les autres groupes'
          }
      }
  },
  conferenceLog: {
      inputPlaceholder: 'Veuillez saisir le contenu',
      table: {
          th: {
              roomId: 'numéro de chambre',
              state: 'statut de la chambre',
              username: 'Créé parjid',
              createTime: 'Heure de création de la réunion',
              closeTime: 'heure de fin de la réunion',
              duration: 'Durée'
          }
      },
      dialog: {
          title: 'Journalisation des membres rejoignant/sortiant de la salle',
          table: {
              th: {
                  username: 'Créé parjid',
                  joinTime: 'se joindre à',
                  leaveTime: 'partir',
                  duration: 'Durée'
              }
          }
      }
  },
  callLog: {
      table: {
          th: {
              callerMzNum: 'numéro d appel',
              callTime: 'Heure de composition',
              endTime: 'Heure de fin',
              duration: 'Durée',
              responderMzNum: 'numéro appelé'
          }
      }
  },
  roomStatus: {
      table: {
          th: {
              roomMessage: 'Informations de base sur la chambre',
              Attendees: 'participant',
              bystander: 'spectateur',
              join: 'Être ajouté',
              exited: 'Quitté'
          }
      },
      roomNumber: 'Numéro de la salle de conférence',
      name: 'Nom de la salle de réunion',
      dissolution: 'Dissoudre',
      pattern: 'modèle',
      confluence: 'confluence',
      forward: 'Avant',
      createdTime: 'temps de creation',
      limit: 'Limite de libération/nombre total de personnes',
      createMode: 'créer un motif',
      order: 'Réunion de rendez-vous)',
      simple: 'Multijoueur ordinaire',
      type: 'Type de réunion',
      creator: 'créateur',
      host: 'Présider:',
      windowMode: 'Mode fenêtre:',
      WindowMode1: 'Un grand et plusieurs petits',
      WindowMode2: 'Tuile',
      hide: 'S il faut masquer :',
      forbidden: 'Faut-il interdire le blé :',
      inorder: 'commande:',
      source: 'source:',
      joinTime: 'Heure d adhésion :',
      comeOut: 'S il te plaît, sors',
      openMic: 'allumer le micro',
      closeMic: 'Éteignez le micro',
      leave: 'partir:',
      peopleNumber: 'Nombre de personnes:'
  },
  menu: {
      index: 'page de garde',
      device_manage: 'Gestion d appareils',
      user_manage: 'Gestion des utilisateurs',
      conference_brand: 'Numéro de porte de conférence',
      audit_manage: 'Gestion des audits',
      orgMessage: 'Information d entreprise',
      schoolMessage: 'Information d entreprise',
      roomStatus: 'réunion en cours',
      p2pCallLog: 'Historique des appels vidéo',
      conferenceLog: 'comptes rendus des réunions',
      authorizedMessage: 'Informations d autorisation',
      title: 'Plateforme de gestion d entreprise',
      schooltitle: 'Plateforme de gestion d entreprise',
      account_mangae: 'Gestion de compte',
      contacts: 'Carnet d adresses de groupe',
      records: 'enregistrements d appels',
      LicenseInformation: 'Informations d autorisation',
      pushInfo: 'Poussée d informations',
      infoGroup: 'Gestion des groupes push'
  },
  download: 'télécharger des applications',
  unauthorized: {
      title: 'Plateforme de gestion d entreprise ViiTALK (non autorisée)',
      span_1: 'Il a été détecté que votre serveur n autorise pas le service ViiTALK,',
      span_2: 'Merci de contacter le service client pour obtenir le dossier d autorisation.',
      span_3: '(Pour obtenir le fichier d autorisation, vous devez fournir l adresse IP et l adresse Mac de votre serveur au service client)',
      upError: 'Désolé, le fichier que vous avez téléchargé est erroné, veuillez le télécharger à nouveau',
      chooseFile_b: 'Sélectionnez un document',
      upFile_b: 'Télécharger le fichier d autorisation',
      success: {
          span_1: 'Autorisation réussie',
          span_2: 'Entrer dans la plateforme de gestion d entreprise...',
          span_3: '(Si vous n êtes pas entré depuis longtemps, veuillez actualiser le navigateur)'
      },
      dialog: {
          title: 'Veuillez modifier le mot de passe administrateur',
          span: 'Votre mot de passe administrateur n a pas encore été modifié, veuillez modifier votre mot de passe immédiatement',
          button: 'soumettre'
      }
  },
  lang: {
    'zh-CN': 'Chinois simplifié',
    'zh-TW': 'chinois traditionnel',
    en: 'Anglais',
    ja: 'Japonais',
    ko: 'coréen',
    th: 'thaïlandais',
    ar: 'arabe',
    bn: 'bengali',
    de: 'Allemand',
    el: 'grec',
    es: 'Espagnol',
    fa: 'persan',
    fr: 'Français',
    id: 'indonésien',
    it: 'italien',
    iw: 'hébreu',
    ku: 'kurde',
    nl: 'Néerlandais',
    pl: 'polonais',
    pt: 'Portugais',
    ro: 'roumain',
    ru: 'russe',
    tr: 'turc',
    vi: 'vietnamien'
  },
  authorized: {
      org: 'Entreprise autorisée',
      userLimit: 'Limite d utilisateurs',
      roomMax: 'Capacité maximale de la salle de conférence cloud',
      roomCountLimit: 'Nombre maximum de salles de réunion cloud en ligne',
      authorizedActiveDate: 'Date d activation de la licence Cloud privé',
      authorizedValidDate: 'La licence cloud privé est valable jusqu au',
      button_up: 'Mettre à jour l autorisation',
      upFileError: 'Aucun fichier sélectionné'
  },
  index_title: 'Plateforme de gestion d entreprise ViiTalk',
  brand: {
    table: {
        th: {
            brandNumber: 'numéro de maison',
            bindNumber: 'Lier un numéro de grand écran',
            onlineStatus: 'statut en ligne',
            lastLoginTime: 'heure de la dernière connexion'
        },
        col: {
            content: {
                onlineStatus: {
                    online: 'en ligne',
                    offline: 'Hors ligne'
                }
            }
        }
    }
  }
}