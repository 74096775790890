import InfoGroup from "@/components/pushInfo/infoGroup.vue";

export default {
  language: 'Tiếng Việt',
  serverError: 'Máy chủ bất thường, vui lòng liên hệ với quản trị viên',
  opearteSuccess: 'Hoạt động thành công',
  agreement: {
      agreement_1: 'Đã đọc và đồng ý',
      agreement_2: '\'Sự thỏa thuận của người dùng\'',
      agreement_3: 'Và',
      agreement_4: '\'Chính sách bảo mật\'',
      agreement_5: 'Vui lòng đánh dấu vào ô để đồng ý với thỏa thuận'
  },
  login: {
      webName: 'Nền tảng quản lý doanh nghiệp ViiTALK',
      website: 'Trang web chính thức:',
      tel: 'Điện thoại:',
      email: 'Thư:',
      title: 'Đăng nhập người dùng doanh nghiệp',
      inputPlaceholder: {
          name: 'Vui lòng nhập tên người dùng/email',
          pwd: 'Vui lòng nhập mật khẩu của bạn'
      },
      button: {
          login: 'Đăng nhập',
          email: 'Đăng nhập email',
          account: 'Đăng nhập tài khoản',
          register: 'Đăng ký người dùng doanh nghiệp',
          pwd: 'quên mật khẩu'
      },
      error: {
          uPwd: 'tên người dùng hoặc mật khẩu sai',
          uLock: 'Tài khoản đã bị khóa',
          uLeave: 'Bạn đã từ chức và không thể sử dụng hệ thống',
          uRelogin: 'Bạn đã đăng nhập ở nơi khác!!!',
          uReplaceLogin: 'Tài khoản của bạn đã được đăng nhập ở nơi khác và bạn buộc phải đăng xuất. Nếu đó không phải lỗi của bạn, mật khẩu của bạn có thể đã bị rò rỉ. Vui lòng đăng nhập lại và thay đổi mật khẩu càng sớm càng tốt.',
          user_null: 'Vui lòng nhập tên người dùng',
          pwd_null: 'Xin vui lòng nhập mật khẩu'
      }
  },
  personal: {
      companyNumber: 'Doanh nghiệp',
      administrators: 'người quản lý',
      changeLanguage: 'chuyển đổi ngôn ngữ',
      logout: 'đăng xuất'
  },
  companyMessage: {
      adminPwd: 'Mật khẩu quản trị viên',
      adminUserName: 'Tên quản trị viên',
      adminEmail: 'Email quản trị viên',
      companyFullName: 'Tên đầy đủ của công ty (tên đăng ký hợp pháp)',
      companySimpleNameCn: 'Tên viết tắt công ty',
      companySimpleNameEn: 'Tên viết tắt của công ty (tiếng Anh)',
      companyWebsite: 'Trang web công ty',
      companyNumber: 'Mã số nhóm doanh nghiệp',
      companyNoAuditJoin: 'Tham gia mà không cần xem xét',
      adminExplain: 'Tên quản trị viên được hiển thị cho tất cả các thành viên sổ địa chỉ công ty',
      companySimpleExplain: 'Chữ viết tắt của công ty sẽ được hiển thị trên trang chủ của thiết bị Rooms và hiển thị cho tất cả thành viên trong sổ địa chỉ của công ty.',
      button: {
          Edit: 'Ôn lại'
      },
      dialog: {
          editpwd: {
              title: 'Thay đổi mật khẩu quản trị viên',
              label: {
                  oldPwd: 'Mật khẩu cũ',
                  newPwd: 'mật khẩu mới',
                  reNewPwd: 'Xác nhận mật khẩu mới'
              },
              inputPlaceholder: {
                  oldPwd: 'Vui lòng nhập mật khẩu cũ',
                  newPwd: 'Vui lòng nhập mật khẩu mới',
                  reNewPwd: 'Vui lòng nhập để xác nhận mật khẩu mới'
              }
          },
          editName: {
              title: 'Sửa đổi tên quản trị viên',
              label: {
                  name: 'tên mới'
              },
              inputPlaceholder: {
                  name: 'Vui lòng nhập tên mới'
              }
          },
          editEmail: {
              title: 'Sửa đổi email quản trị viên',
              title2: 'Bạn chưa ràng buộc địa chỉ email của mình. Vui lòng liên kết địa chỉ email của bạn kịp thời.',
              label: {
                  email: 'hộp thư mới'
              },
              inputPlaceholder: {
                  email: 'Vui lòng nhập email mới'
              }
          },
          editCompanyFullName: {
              title: 'Sửa đổi tên đầy đủ của công ty',
              label: {
                  name: 'tên đầy đủ mới'
              },
              inputPlaceholder: {
                  name: 'Vui lòng nhập tên đầy đủ mới'
              }
          },
          editCompanyAbbreviationCn: {
              title: 'Sửa đổi tên viết tắt của công ty (tiếng Trung)',
              label: {
                  name: 'Viết tắt mới'
              },
              inputPlaceholder: {
                  name: 'Vui lòng nhập tên ngắn mới'
              }
          },
          editCompanyAbbreviationEn: {
              title: 'Sửa đổi tên viết tắt của công ty (tiếng Anh)'
          },
          editWebsite: {
              title: 'Sửa đổi địa chỉ trang web của công ty',
              label: {
                  website: 'URL mới'
              },
              inputPlaceholder: {
                  website: 'Vui lòng nhập URL mới'
              }
          },
          editNoAuditJoin: {
              title: 'Sửa đổi công tắc tham gia không có ứng dụng',
              radio: {
                  open: 'Mở',
                  close: 'Khép kín'
              }
          }
      },
      validator: {
          value_null: 'Giá trị không thể trống',
          oldPwd: {
              null: 'Vui lòng nhập mật khẩu cũ'
          },
          newPwd: {
              length: 'Vui lòng đặt mật khẩu có độ dài từ 6 ký tự trở lên'
          },
          reNewPwd: {
              consistent: 'Mật khẩu được nhập hai lần không nhất quán!'
          },
          name: {
              length: 'Tên không thể dài hơn 20 ký tự'
          },
          email: {
              rule: 'Định dạng email không chính xác'
          },
          NoAuditJoin: {
              null: 'Vui lòng chọn một mục'
          }
      }
  },
  register: {
      step_1: 'tên người dùng và mật khẩu',
      step_2: 'Điền thông tin công ty',
      step_3: 'Hoàn thành',
      head: 'Đăng ký người dùng doanh nghiệp',
      usernameError: 'Tên thành viên phải dài từ 6-20 ký tự, không bao gồm ký tự tiếng Trung và bắt đầu bằng một chữ cái.',
      usernameError_exist: 'Tên người dùng đã tồn tại, vui lòng sửa đổi tên người dùng của bạn',
      emailError: 'Định dạng email không chính xác',
      emailError_exist: 'Email đã tồn tại',
      passwordError_length: '6-20 ký tự, chỉ có chữ và số và dấu chấm câu',
      passwordError_same: 'Mật khẩu không thể giống tên người dùng',
      passwordError: 'Định dạng mật khẩu không chính xác',
      rePasswordError: 'Mật khẩu nhập 2 lần không khớp, vui lòng nhập lại',
      companyNameError: 'Vui lòng nhập tên đầy đủ của công ty',
      companyAbbreviationError: 'Vui lòng nhập tên viết tắt của công ty',
      orgError_exist:'Công ty đã được đăng ký, vui lòng thay đổi tên doanh nghiệp của bạn',
      button: {
          next: 'Bước tiếp theo',
          login: 'đăng nhập ngay lập tức'
      },
      inputPlaceholder: {
          username: 'tên tài khoản',
          email: 'Thư',
          password: 'đặt mật khẩu',
          rePassword: 'Nhập lại mật khẩu',
          name: 'Tên đầy đủ của công ty (tên đăng ký hợp pháp)*',
          simpleNameCN: 'Tên viết tắt của công ty (tiếng Trung)*',
          simpleNameEN: 'Tên viết tắt của công ty (tiếng Anh)',
          website: 'Trang web của công ty (www)'
      },
      success: 'Bạn đã tạo thành công tài khoản doanh nghiệp!',
      location: 'vị trí:',
      Scale: 'tỉ lệ:'
  },
  forgotPwd: {
      head: 'Truy xuất email',
      resetSuccess: 'Bạn đã đặt lại mật khẩu thành công!',
      inputPlaceholder: {
          code: 'vui lòng nhập mã xác nhận'
      },
      sendCodeError: 'Mã xác minh không chính xác',
      sendCodeError_sended: 'Mã xác minh đã được gửi, vui lòng kiểm tra',
      button: {
          getCode: 'lấy mã xác minh',
          reGetCode: 'Nhận lại mã xác minh'
      }
  },
  button: {
      ok: 'xác nhận',
      cancel: 'Hủy bỏ',
      search: 'tìm kiếm',
      clear: 'Thông thoáng'
  },
  device: {
      button: {
          setContent: 'Định cấu hình lịch đẩy',
          setGroupMng: 'Thiết lập quản trị viên nhóm',
          startNow: 'Đẩy ngay bây giờ',
          stopNow: 'Dừng đẩy',
          add: 'Thêm thiết bị',
          search: 'tìm kiếm',
          adjust: 'Điều chỉnh nhóm',
          del: 'xóa bỏ'
      },
      inputPlaceholder: {
          search: 'Số thiết bị/Số điện thoại di động/Số ViiTALK'
      },
      group: {
          add: 'Thêm nhóm',
          edit: 'Chỉnh sửa nhóm',
          del: 'Xóa nhóm'
      },
      table: {
          th_name: 'Tên thiết bị',
          th_number: 'Số thiết bị',
          th_time: 'Lần đăng nhập cuối cùng',
          th_brandNumber: 'Số nhà hội nghị',
          th_online: 'Đang trực tuyến',
          th_status: 'tình trạng',
          th_bindNumber: 'Ràng buộc số màn hình lớn'
      },
      dialog: {
          addGroupTitle: 'Vui lòng nhập tên nhóm',
          inputPlaceholder: {
              groupName: 'Vui lòng nhập tên nhóm'
          },
          delGroupTitle: 'Xóa nhóm',
          delGroupTips: 'Bạn có chắc chắn muốn xóa nhóm không?',
          editGroupTitle: 'Chỉnh sửa nhóm',
          groupMng: {
              title: 'Chỉnh sửa người quản lý nhóm',
              inputPlaceholder: {
                  name: 'Vui lòng nhập tên quản trị viên'
              },
              label: {
                  name: 'Tên quản trị viên'
              }
          },
          addDevice: {
              title: 'Thêm thiết bị',
              inputPlaceholder: {
                  name: 'Vui lòng nhập tên thiết bị',
                  number: 'Vui lòng nhập số thiết bị'
              },
              label: {
                  name: 'Tên thiết bị',
                  number: 'Số thiết bị',
                  group: 'Vui lòng chọn nhóm thiết bị'
              }
          },
          delDevice: {
              title: 'Gỡ bỏ thiết bị',
              tips: 'Bạn có chắc chắn muốn xóa thiết bị không?'
          },
          startNowDevice: {
              title: 'Đẩy ngay bây giờ',
              tips: 'Bạn có chắc chắn muốn đẩy nó ngay bây giờ không?'
          },
          stopNowDevice: {
              title: 'Dừng đẩy',
              tips: 'Bạn có chắc chắn muốn ngừng đẩy không?'
          },
          adjustTitle: 'Điều chỉnh nhóm',
          editDevice: {
              title: 'Sửa đổi tên thiết bị',
              inputPlaceholder: {
                  name: 'Vui lòng nhập tên thiết bị'
              }
          },
          noChildren: {
              title: 'cảnh báo',
              tips: 'Vui lòng thêm nhóm trước'
          }
      },
      validator: {
          group: {
              name_null: 'Tên nhóm không được để trống',
              name_length: 'Không thể vượt quá 10 ký tự'
          },
          number_null: 'Số không thể trống',
          number_rule: 'Chỉ có thể có 10 chữ số bắt đầu bằng 6 hoặc 8',
          name_null: 'Tên thiết bị đầu cuối không thể trống'
      }
  },
  user: {
      button: {
          add: 'Thêm người dùng'
      },
      inputPlaceholder: {
          search: 'số ViiTALK'
      },
      table: {
          th_name: 'Tên',
          th_number: 'Số điện thoại di động/Số Maizhe'
      },
      dialog: {
          addUser: {
              title: 'Thêm người dùng',
              inputPlaceholder: {
                  number: 'Vui lòng nhập số ViiTALK'
              },
              label: {
                  number: 'số ViiTALK',
                  group: 'Vui lòng chọn nhóm người dùng'
              }
          },
          delUser: {
              title: 'xóa người dùng',
              tips: 'Bạn có chắc chắn muốn xóa người dùng này không?'
          }
      },
      validator: {
          number_null: 'Số không thể trống',
          number_rule: 'Số này không tồn tại'
      }
  },
  addrbook: {
      return: {
          delDevice: {
              tips_1: 'Nhóm cần xóa không tồn tại',
              tips_2: 'Có những nhóm con trong nhóm cần xóa và không thể xóa.',
              tips_3: 'Có người trong nhóm cần xóa và không thể xóa.'
          },
          addDevice: {
              tips_1: 'Đã thêm, vui lòng xem các nhóm khác',
              tips_2: 'Đã được thêm vào bởi các công ty khác',
              tips_3: 'Số này không tồn tại'
          }
      }
  },
  audit: {
      option: {
          label: {
              NoPass: 'Không vượt qua',
              hasRefuse: 'vật bị loại bỏ',
              hasPass: 'đi qua',
              hasInvalid: 'hết hạn',
              all: 'tất cả'
          }
      },
      button: {
          pass: 'vượt qua',
          reject: 'từ chối'
      },
      table: {
          th_number: 'Số thiết bị',
          th_date: 'ngày',
          th_status: 'tình trạng',
          th_source: 'nguồn'
      },
      dialog: {
          passTitle: 'Tán thành',
          reject: {
              title: 'Từ chối xem xét',
              tips: 'Bạn có chắc chắn muốn từ chối đánh giá không?'
          }
      },
      api: {
          req: {
              tips_1: 'Không thể vượt qua đánh giá, các mục đã chọn đến từ nhiều nguồn khác nhau!',
              tips_2: 'Vui lòng chọn khu vực được phân bổ'
          },
          return: {
              tips_1: 'Số thiết bị không tồn tại',
              tips_2: 'Bạn đã gia nhập doanh nghiệp',
              tips_3: 'Đã thêm, vui lòng xem các nhóm khác'
          }
      }
  },
  conferenceLog: {
      inputPlaceholder: 'Vui lòng nhập nội dung',
      table: {
          th: {
              roomId: 'số phòng',
              state: 'tình trạng phòng',
              username: 'Tạo bởijid',
              createTime: 'Thời gian tạo cuộc họp',
              closeTime: 'giờ kết thúc cuộc họp',
              duration: 'Khoảng thời gian'
          }
      },
      dialog: {
          title: 'Ghi nhật ký thành viên tham gia/ra khỏi phòng',
          table: {
              th: {
                  username: 'Tạo bởijid',
                  joinTime: 'tham gia vào',
                  leaveTime: 'rời khỏi',
                  duration: 'Khoảng thời gian'
              }
          }
      }
  },
  callLog: {
      table: {
          th: {
              callerMzNum: 'số gọi',
              callTime: 'Quay số thời gian',
              endTime: 'Thời gian kết thúc',
              duration: 'Khoảng thời gian',
              responderMzNum: 'số được gọi'
          }
      }
  },
  roomStatus: {
      table: {
          th: {
              roomMessage: 'Thông tin phòng cơ bản',
              Attendees: 'người tham dự',
              bystander: 'người ngoài cuộc',
              join: 'Để được thêm vào',
              exited: 'Đã thoát'
          }
      },
      roomNumber: 'Số phòng hội thảo',
      name: 'Tên phòng họp',
      dissolution: 'Giải tán',
      pattern: 'người mẫu',
      confluence: 'hợp lưu',
      forward: 'Phía trước',
      createdTime: 'thời gian sáng tạo',
      limit: 'Giới hạn phát hành/tổng ​​số người',
      createMode: 'tạo mẫu',
      order: 'Cuộc họp bổ nhiệm)',
      simple: 'Nhiều người chơi thông thường',
      type: 'Loại cuộc họp',
      creator: 'người sáng tạo',
      host: 'Chủ nhà:',
      windowMode: 'Chế độ cửa sổ:',
      WindowMode1: 'Một lớn và nhiều nhỏ',
      WindowMode2: 'Ngói',
      hide: 'Có nên ẩn hay không:',
      forbidden: 'Có nên cấm lúa mì hay không:',
      inorder: 'đặt hàng:',
      source: 'nguồn:',
      joinTime: 'Thời gian tham gia:',
      comeOut: 'Xin hãy ra ngoài',
      openMic: 'bật micrô',
      closeMic: 'Tắt micrô',
      leave: 'rời khỏi:',
      peopleNumber: 'Số người:'
  },
  menu: {
      index: 'trang đầu',
      device_manage: 'Quản lý thiết bị',
      user_manage: 'Quản lý người dùng',
      conference_brand: 'Số cửa hội nghị',
      audit_manage: 'Quản lý kiểm toán',
      orgMessage: 'Thông tin doanh nghiệp',
      roomStatus: 'cuộc họp đang diễn ra',
      p2pCallLog: 'Lịch sử cuộc gọi video',
      conferenceLog: 'biên bản cuộc họp',
      authorizedMessage: 'Thông tin ủy quyền',
      title: 'Nền tảng quản lý doanh nghiệp',
      account_mangae: 'Quản lý tài khoản',
      contacts: 'Sổ địa chỉ nhóm',
      records: 'bản ghi cuộc gọi',
      LicenseInformation: 'Thông tin ủy quyền',
      pushInfo: 'Đẩy thông tin',
      infoGroup: 'Quản lý nhóm đẩy'
  },
  download: 'tải ứng dụng',
  unauthorized: {
      title: 'Nền tảng quản lý doanh nghiệp ViiTALK (trái phép)',
      span_1: 'Đã phát hiện thấy máy chủ của bạn không cho phép dịch vụ ViiTALK,',
      span_2: 'Vui lòng liên hệ với dịch vụ khách hàng để lấy tập tin ủy quyền.',
      span_3: '(Để có được tệp ủy quyền, bạn cần cung cấp địa chỉ IP máy chủ và địa chỉ Mac của mình cho bộ phận dịch vụ khách hàng)',
      upError: 'Xin lỗi file bạn upload bị lỗi, bạn upload lại nhé',
      chooseFile_b: 'Chọn một tài liệu',
      upFile_b: 'Tải lên tập tin ủy quyền',
      success: {
          span_1: 'Ủy quyền thành công',
          span_2: 'Bước vào nền tảng quản lý doanh nghiệp...',
          span_3: '(Nếu lâu chưa vào, vui lòng Refresh lại trình duyệt)'
      },
      dialog: {
          title: 'Vui lòng thay đổi mật khẩu quản trị viên',
          span: 'Mật khẩu quản trị viên của bạn chưa được thay đổi, vui lòng thay đổi mật khẩu của bạn ngay lập tức',
          button: 'nộp'
      }
  },
  lang: {
    'zh-CN': 'Tiếng Trung giản thể',
    'zh-TW': 'truyền thống Trung Quốc',
    en: 'Tiếng Anh',
    ja: 'Tiếng Nhật',
    ko: 'Hàn Quốc',
    th: 'tiếng Thái',
    ar: 'tiếng Ả Rập',
    bn: 'tiếng Bengali',
    de: 'tiếng Đức',
    el: 'người Hy Lạp',
    es: 'người Tây Ban Nha',
    fa: 'tiếng Ba Tư',
    fr: 'người Pháp',
    id: 'tiếng Indonesia',
    it: 'người Ý',
    iw: 'tiếng Do Thái',
    ku: 'người Kurd',
    nl: 'tiếng Hà Lan',
    pl: 'Đánh bóng',
    pt: 'tiếng Bồ Đào Nha',
    ro: 'người Rumani',
    ru: 'tiếng Nga',
    tr: 'tiếng Thổ Nhĩ Kỳ',
    vi: 'Tiếng Việt'
  },
  authorized: {
      org: 'Doanh nghiệp được ủy quyền',
      userLimit: 'Giới hạn người dùng',
      roomMax: 'Công suất tối đa của phòng hội nghị đám mây',
      roomCountLimit: 'Số lượng phòng họp trực tuyến đám mây tối đa',
      authorizedActiveDate: 'Ngày kích hoạt giấy phép đám mây riêng',
      authorizedValidDate: 'Giấy phép đám mây riêng có hiệu lực cho đến khi',
      button_up: 'Cập nhật ủy quyền',
      upFileError: 'Không có tập tin được chọn'
  },
  index_title: 'Nền tảng quản lý doanh nghiệp ViiTalk',
  brand: {
    table: {
        th: {
            brandNumber: 'số nhà',
            bindNumber: 'Ràng buộc số màn hình lớn',
            onlineStatus: 'trạng thái trực tuyến',
            lastLoginTime: 'lần đăng nhập cuối cùng'
        },
        col: {
            content: {
                onlineStatus: {
                    online: 'trực tuyến',
                    offline: 'Ngoại tuyến'
                }
            }
        }
    }
  }
}