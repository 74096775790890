import InfoGroup from "@/components/pushInfo/infoGroup.vue";

export default {
  language: 'Ελληνικά',
  serverError: 'Ανωμαλία διακομιστή, επικοινωνήστε με τον διαχειριστή',
  opearteSuccess: 'Επιτυχής λειτουργία',
  agreement: {
      agreement_1: 'Διάβασα και συμφώνησε',
      agreement_2: '\'Συμφωνία Χρήστη\'',
      agreement_3: 'και',
      agreement_4: '\'Πολιτική Απορρήτου\'',
      agreement_5: 'Επιλέξτε το πλαίσιο για να συμφωνήσετε με τη συμφωνία'
  },
  login: {
      webName: 'Πλατφόρμα διαχείρισης επιχειρήσεων ViiTALK',
      website: 'Επίσημη ιστοσελίδα:',
      tel: 'Τηλέφωνο:',
      email: 'Ταχυδρομείο:',
      title: 'Είσοδος εταιρικού χρήστη',
      inputPlaceholder: {
          name: 'Εισαγάγετε όνομα χρήστη/email',
          pwd: 'Παρακαλώ εισάγετε τον κωδικό σας'
      },
      button: {
          login: 'Σύνδεση',
          email: 'Email Είσοδος',
          account: 'Είσοδος σε λογαριασμό',
          register: 'Εγγραφή εταιρικού χρήστη',
          pwd: 'ξεχάστε τον κωδικό πρόσβασης'
      },
      error: {
          uPwd: 'Λάθος όνομα χρήστη ή κωδικός',
          uLock: 'Ο λογαριασμός έχει κλειδωθεί',
          uLeave: 'Έχετε παραιτηθεί και δεν μπορείτε να χρησιμοποιήσετε το σύστημα',
          uRelogin: 'Είσαι ήδη συνδεδεμένος κάπου αλλού!!!',
          uReplaceLogin: 'Ο λογαριασμός σας έχει συνδεθεί αλλού και αναγκαστήκατε να αποσυνδεθείτε. Εάν δεν ήταν δικό σας λάθος, ο κωδικός πρόσβασής σας ενδέχεται να έχει διαρρεύσει ξανά και να αλλάξετε τον κωδικό πρόσβασής σας το συντομότερο δυνατό.',
          user_null: 'παρακαλώ εισάγετε το όνομα χρήστη',
          pwd_null: 'Παρακαλούμε εισάγετε τον κωδικό πρόσβασης'
      }
  },
  personal: {
      companyNumber: 'Επιχείρηση',
      administrators: 'διαχειριστής',
      changeLanguage: 'αλλαγή γλώσσας',
      logout: 'αποσύνδεση'
  },
  companyMessage: {
      adminPwd: 'Κωδικός πρόσβασης διαχειριστή',
      adminUserName: 'Όνομα διαχειριστή',
      adminEmail: 'Email διαχειριστή',
      companyFullName: 'Πλήρες όνομα της εταιρείας (νόμιμα καταχωρημένη επωνυμία)',
      companySimpleNameCn: 'Συντομογραφία εταιρείας',
      companySimpleNameEn: 'Συντομογραφία εταιρείας (Αγγλικά)',
      companyWebsite: 'Εταιρική ιστοσελίδα',
      companyNumber: 'Αριθμός ομάδας επιχειρήσεων',
      companyNoAuditJoin: 'Εγγραφείτε χωρίς κριτική',
      adminExplain: 'Το όνομα διαχειριστή είναι ορατό σε όλα τα μέλη του εταιρικού βιβλίου διευθύνσεων',
      companySimpleExplain: 'Η συντομογραφία της εταιρείας θα εμφανίζεται στην αρχική σελίδα της συσκευής Rooms και θα είναι ορατή σε όλα τα μέλη του βιβλίου διευθύνσεων της εταιρείας.',
      button: {
          Edit: 'Αναθεωρώ'
      },
      dialog: {
          editpwd: {
              title: 'Αλλαγή κωδικού πρόσβασης διαχειριστή',
              label: {
                  oldPwd: 'ΠΑΛΙΟΣ ΚΩΔΙΚΟΣ',
                  newPwd: 'Νέος Κωδικός',
                  reNewPwd: 'Επιβεβαιώστε τον νέο κωδικό πρόσβασης'
              },
              inputPlaceholder: {
                  oldPwd: 'Εισαγάγετε τον παλιό κωδικό πρόσβασης',
                  newPwd: 'Εισαγάγετε έναν νέο κωδικό πρόσβασης',
                  reNewPwd: 'Εισαγάγετε για να επιβεβαιώσετε τον νέο κωδικό πρόσβασης'
              }
          },
          editName: {
              title: 'Τροποποίηση ονόματος διαχειριστή',
              label: {
                  name: 'νέο όνομα'
              },
              inputPlaceholder: {
                  name: 'Εισαγάγετε νέο όνομα'
              }
          },
          editEmail: {
              title: 'Τροποποίηση email διαχειριστή',
              title2: 'Δεν έχετε δεσμεύσει ακόμα τη διεύθυνση email σας.',
              label: {
                  email: 'νέα ταχυδρομική θυρίδα'
              },
              inputPlaceholder: {
                  email: 'Εισαγάγετε ένα νέο email'
              }
          },
          editCompanyFullName: {
              title: 'Τροποποιήστε το πλήρες όνομα της εταιρείας',
              label: {
                  name: 'νέο πλήρες όνομα'
              },
              inputPlaceholder: {
                  name: 'Εισαγάγετε νέο πλήρες όνομα'
              }
          },
          editCompanyAbbreviationCn: {
              title: 'Τροποποίηση συντομογραφίας εταιρείας (Κινεζικά)',
              label: {
                  name: 'Νέα συντομογραφία'
              },
              inputPlaceholder: {
                  name: 'Εισαγάγετε ένα νέο σύντομο όνομα'
              }
          },
          editCompanyAbbreviationEn: {
              title: 'Τροποποίηση συντομογραφίας εταιρείας (Αγγλικά)'
          },
          editWebsite: {
              title: 'Τροποποίηση διεύθυνσης εταιρικού ιστότοπου',
              label: {
                  website: 'Νέα διεύθυνση URL'
              },
              inputPlaceholder: {
                  website: 'Εισαγάγετε νέα διεύθυνση URL'
              }
          },
          editNoAuditJoin: {
              title: 'Τροποποιήστε τον διακόπτη σύνδεσης χωρίς εφαρμογή',
              radio: {
                  open: 'Ανοιξε',
                  close: 'κλείσιμο'
              }
          }
      },
      validator: {
          value_null: 'Η τιμή δεν μπορεί να είναι κενή',
          oldPwd: {
              null: 'Εισαγάγετε τον παλιό κωδικό πρόσβασης'
          },
          newPwd: {
              length: 'Ορίστε έναν κωδικό πρόσβασης 6 ή περισσότερων χαρακτήρων σε μήκος'
          },
          reNewPwd: {
              consistent: 'Οι κωδικοί πρόσβασης που εισάγονται δύο φορές είναι ασυνεπείς!'
          },
          name: {
              length: 'Το όνομα δεν μπορεί να είναι μεγαλύτερο από 20 χαρακτήρες'
          },
          email: {
              rule: 'Η μορφή ηλεκτρονικού ταχυδρομείου είναι εσφαλμένη'
          },
          NoAuditJoin: {
              null: 'Επιλέξτε ένα στοιχείο'
          }
      }
  },
  register: {
      step_1: 'όνομα χρήστη και κωδικός',
      step_2: 'Συμπληρώστε τα στοιχεία της εταιρείας',
      step_3: 'Φινίρισμα',
      head: 'Εγγραφή εταιρικού χρήστη',
      usernameError: 'Το όνομα του μέλους πρέπει να αποτελείται από 6-20 χαρακτήρες, χωρίς τους κινέζικους χαρακτήρες, και να ξεκινά με ένα γράμμα.',
      usernameError_exist: 'Το όνομα χρήστη υπάρχει ήδη, τροποποιήστε το όνομα χρήστη σας',
      emailError: 'Η μορφή ηλεκτρονικού ταχυδρομείου είναι εσφαλμένη',
      emailError_exist: 'Το email υπάρχει ήδη',
      passwordError_length: '6-20 χαρακτήρες, μόνο αλφαριθμητικά και σημεία στίξης',
      passwordError_same: 'Ο κωδικός πρόσβασης δεν μπορεί να είναι το ίδιο όνομα χρήστη',
      passwordError: 'Η μορφή του κωδικού πρόσβασης είναι εσφαλμένη',
      rePasswordError: 'Οι κωδικοί πρόσβασης που εισήχθησαν δύο φορές είναι ασυνεπείς, παρακαλώ εισάγετε ξανά',
      companyNameError: 'Εισαγάγετε το πλήρες όνομα της εταιρείας',
      companyAbbreviationError: 'Εισαγάγετε τη συντομογραφία της εταιρείας',
      orgError_exist:'Η εταιρεία έχει εγγραφεί, αλλάξτε το όνομα της επιχείρησής σας',
      button: {
          next: 'Επόμενο βήμα',
          login: 'συνδεθείτε αμέσως'
      },
      inputPlaceholder: {
          username: 'όνομα χρήστη',
          email: 'Ταχυδρομείο',
          password: 'Ορίστε κωδικό',
          rePassword: 'Εισαγάγετε ξανά τον κωδικό πρόσβασης',
          name: 'Πλήρες όνομα της εταιρείας (νόμιμα καταχωρημένη επωνυμία)*',
          simpleNameCN: 'Συντομογραφία εταιρείας (Κινέζικα)*',
          simpleNameEN: 'Συντομογραφία εταιρείας (Αγγλικά)',
          website: 'Ιστοσελίδα της εταιρείας (www)'
      },
      success: 'Έχετε δημιουργήσει με επιτυχία έναν επαγγελματικό λογαριασμό!',
      location: 'τοποθεσία:',
      Scale: 'κλίμακα:'
  },
  forgotPwd: {
      head: 'Ανάκτηση email',
      resetSuccess: 'Επαναφέρατε με επιτυχία τον κωδικό πρόσβασής σας!',
      inputPlaceholder: {
          code: 'εισάγετε τον κωδικό επαλήθευσης'
      },
      sendCodeError: 'Εσφαλμένος κωδικός επαλήθευσης',
      sendCodeError_sended: 'Ο κωδικός επαλήθευσης έχει σταλεί, ελέγξτε τον',
      button: {
          getCode: 'λάβετε κωδικό επαλήθευσης',
          reGetCode: 'Λάβετε ξανά τον κωδικό επαλήθευσης'
      }
  },
  button: {
      ok: 'επιβεβαιώνω',
      cancel: 'Ματαίωση',
      search: 'Αναζήτηση',
      clear: 'Σαφή'
  },
  device: {
      button: {
          setContent: 'Διαμόρφωση προγράμματος ώθησης',
          setGroupMng: 'Ρύθμιση διαχειριστή ομάδας',
          startNow: 'Σπρώξτε τώρα',
          stopNow: 'Σταμάτα να πιέζεις',
          add: 'Προσθέστε ΣΥΣΚΕΥΗ',
          search: 'Αναζήτηση',
          adjust: 'Προσαρμογή ομαδοποίησης',
          del: 'διαγράφω'
      },
      inputPlaceholder: {
          search: 'Αριθμός συσκευής/Αριθμός κινητού τηλεφώνου/Αριθμός ViiTALK'
      },
      group: {
          add: 'Προσθήκη ομάδας',
          edit: 'Επεξεργασία ομάδας',
          del: 'Διαγραφή ομάδας'
      },
      table: {
          th_name: 'Ονομα της συσκευής',
          th_number: 'Αριθμός συσκευής',
          th_time: 'Τελευταίος χρόνος σύνδεσης',
          th_brandNumber: 'Αριθμός συνεδριακού οίκου',
          th_online: 'Είναι συνδεδεμένος',
          th_status: 'κατάσταση',
          th_bindNumber: 'Σύνδεση μεγάλου αριθμού οθόνης'
      },
      dialog: {
          addGroupTitle: 'Εισαγάγετε ένα όνομα ομάδας',
          inputPlaceholder: {
              groupName: 'Εισαγάγετε το όνομα της ομάδας'
          },
          delGroupTitle: 'Διαγραφή ομάδας',
          delGroupTips: 'Είστε βέβαιοι ότι θέλετε να διαγράψετε την ομάδα;',
          editGroupTitle: 'Επεξεργασία ομάδας',
          groupMng: {
              title: 'Επεξεργασία διαχειριστή ομάδας',
              inputPlaceholder: {
                  name: 'Εισαγάγετε το όνομα διαχειριστή'
              },
              label: {
                  name: 'Όνομα διαχειριστή'
              }
          },
          addDevice: {
              title: 'Προσθέστε ΣΥΣΚΕΥΗ',
              inputPlaceholder: {
                  name: 'Εισαγάγετε το όνομα της συσκευής',
                  number: 'Εισαγάγετε τον αριθμό της συσκευής'
              },
              label: {
                  name: 'Ονομα της συσκευής',
                  number: 'Αριθμός συσκευής',
                  group: 'Επιλέξτε μια ομάδα συσκευών'
              }
          },
          delDevice: {
              title: 'Αφαίρεση της συσκευής',
              tips: 'Είστε βέβαιοι ότι θέλετε να διαγράψετε τη συσκευή;'
          },
          startNowDevice: {
              title: 'Σπρώξτε τώρα',
              tips: 'Είστε βέβαιοι ότι θέλετε να το προωθήσετε τώρα;'
          },
          stopNowDevice: {
              title: 'Σταμάτα να πιέζεις',
              tips: 'Είστε βέβαιοι ότι θέλετε να σταματήσετε να πιέζετε;'
          },
          adjustTitle: 'Προσαρμογή ομαδοποίησης',
          editDevice: {
              title: 'Τροποποίηση ονόματος συσκευής',
              inputPlaceholder: {
                  name: 'Εισαγάγετε το όνομα της συσκευής'
              }
          },
          noChildren: {
              title: 'προειδοποιώ',
              tips: 'Προσθέστε πρώτα μια ομάδα'
          }
      },
      validator: {
          group: {
              name_null: 'Το όνομα της ομάδας δεν μπορεί να είναι κενό',
              name_length: 'Δεν μπορεί να υπερβαίνει τους 10 χαρακτήρες'
          },
          number_null: 'Ο αριθμός δεν μπορεί να είναι κενός',
          number_rule: 'Μπορεί να είναι μόνο 10 ψηφία που ξεκινούν με 6 ή 8',
          name_null: 'Το όνομα τερματικού δεν μπορεί να είναι κενό'
      }
  },
  user: {
      button: {
          add: 'Πρόσθεσε χρήστη'
      },
      inputPlaceholder: {
          search: 'Αριθμός ViiTALK'
      },
      table: {
          th_name: 'Ονομα',
          th_number: 'Αριθμός κινητού τηλεφώνου/Αριθμός Maizhe'
      },
      dialog: {
          addUser: {
              title: 'Πρόσθεσε χρήστη',
              inputPlaceholder: {
                  number: 'Εισαγάγετε τον αριθμό ViiTALK'
              },
              label: {
                  number: 'Αριθμός ViiTALK',
                  group: 'Επιλέξτε ομάδα χρηστών'
              }
          },
          delUser: {
              title: 'διαγραφή χρηστών',
              tips: 'Είστε βέβαιοι ότι θέλετε να διαγράψετε τον χρήστη;'
          }
      },
      validator: {
          number_null: 'Ο αριθμός δεν μπορεί να είναι κενός',
          number_rule: 'Αυτός ο αριθμός δεν υπάρχει'
      }
  },
  addrbook: {
      return: {
          delDevice: {
              tips_1: 'Η ομάδα που θα διαγραφεί δεν υπάρχει',
              tips_2: 'Υπάρχουν υποομάδες κάτω από την ομάδα προς διαγραφή και δεν μπορούν να διαγραφούν.',
              tips_3: 'Υπάρχουν άτομα κάτω από την ομάδα που πρέπει να διαγραφούν και δεν μπορούν να διαγραφούν.'
          },
          addDevice: {
              tips_1: 'Προστέθηκε ήδη, δείτε άλλες ομάδες',
              tips_2: 'Προστέθηκε από άλλες εταιρείες',
              tips_3: 'Αυτός ο αριθμός δεν υπάρχει'
          }
      }
  },
  audit: {
      option: {
          label: {
              NoPass: 'Δεν πέρασε',
              hasRefuse: 'απορρίφθηκε',
              hasPass: 'πέρασε',
              hasInvalid: 'έχει λήξει',
              all: 'όλα'
          }
      },
      button: {
          pass: 'πέρασμα',
          reject: 'απορρίπτω'
      },
      table: {
          th_number: 'Αρ. συσκευής',
          th_date: 'ημερομηνία',
          th_status: 'κατάσταση',
          th_source: 'πηγή'
      },
      dialog: {
          passTitle: 'Εγκρίθηκε',
          reject: {
              title: 'Άρνηση κριτικής',
              tips: 'Είστε βέβαιοι ότι θέλετε να απορρίψετε την κριτική;'
          }
      },
      api: {
          req: {
              tips_1: 'Δεν είναι δυνατή η επισκόπηση, τα επιλεγμένα στοιχεία προέρχονται από διαφορετικές πηγές!',
              tips_2: 'Επιλέξτε την περιοχή που θα εκχωρηθεί'
          },
          return: {
              tips_1: 'Ο αριθμός συσκευής δεν υπάρχει',
              tips_2: 'Έχετε εγγραφεί στην επιχείρηση',
              tips_3: 'Προστέθηκε ήδη, δείτε άλλες ομάδες'
          }
      }
  },
  conferenceLog: {
      inputPlaceholder: 'Εισαγάγετε περιεχόμενο',
      table: {
          th: {
              roomId: 'αριθμός δωματίου',
              state: 'κατάσταση δωματίου',
              username: 'Δημιουργήθηκε byjid',
              createTime: 'Ώρα δημιουργίας συνάντησης',
              closeTime: 'ώρα λήξης συνάντησης',
              duration: 'Διάρκεια'
          }
      },
      dialog: {
          title: 'Μέλος που συμμετέχει/εξέρχεται από την καταγραφή δωματίου',
          table: {
              th: {
                  username: 'Δημιουργήθηκε byjid',
                  joinTime: 'συμμετέχω',
                  leaveTime: 'άδεια',
                  duration: 'Διάρκεια'
              }
          }
      }
  },
  callLog: {
      table: {
          th: {
              callerMzNum: 'αριθμός κλήσης',
              callTime: 'Ώρα κλήσης',
              endTime: 'Τέλος χρόνου',
              duration: 'Διάρκεια',
              responderMzNum: 'καλούμενος αριθμός'
          }
      }
  },
  roomStatus: {
      table: {
          th: {
              roomMessage: 'Βασικές πληροφορίες δωματίου',
              Attendees: 'συμμετέχων',
              bystander: 'θεατής',
              join: 'Να προστεθεί',
              exited: 'Έγινε έξοδος'
          }
      },
      roomNumber: 'Αριθμός αίθουσας συνεδριάσεων',
      name: 'Όνομα αίθουσας συνεδριάσεων',
      dissolution: 'Απολύω',
      pattern: 'μοντέλο',
      confluence: 'συμβολή',
      forward: 'Προς τα εμπρός',
      createdTime: 'χρόνος δημιουργίας',
      limit: 'Όριο κυκλοφορίας/συνολικού αριθμού ατόμων',
      createMode: 'δημιουργήστε μοτίβο',
      order: 'Ραντεβού (συνάντηση)',
      simple: 'Συνηθισμένο multiplayer',
      type: 'Τύπος συνάντησης',
      creator: 'δημιουργός',
      host: 'Πλήθος:',
      windowMode: 'Λειτουργία παραθύρου:',
      WindowMode1: 'Ένα μεγάλο και πολλά μικρά',
      WindowMode2: 'Πλακάκι',
      hide: 'Εάν πρέπει να κρυφτεί:',
      forbidden: 'Εάν θα απαγορευτεί το σιτάρι:',
      inorder: 'Σειρά:',
      source: 'πηγή:',
      joinTime: 'Χρόνος συμμετοχής:',
      comeOut: 'Παρακαλώ βγείτε έξω',
      openMic: 'ενεργοποιήστε το μικρόφωνο',
      closeMic: 'Απενεργοποιήστε το μικρόφωνο',
      leave: 'άδεια:',
      peopleNumber: 'Αριθμός των ανθρώπων:'
  },
  menu: {
      index: 'εξώφυλλο',
      device_manage: 'Διαχείριση συσκευής',
      user_manage: 'Διαχείριση χρηστών',
      conference_brand: 'Αριθμός πόρτας συνεδρίου',
      audit_manage: 'Διαχείριση ελέγχου',
      orgMessage: 'Εταιρικές πληροφορίες',
      roomStatus: 'σε εξέλιξη συνάντηση',
      p2pCallLog: 'Ιστορικό βιντεοκλήσεων',
      conferenceLog: 'λεπτά συνάντησης',
      authorizedMessage: 'Πληροφορίες εξουσιοδότησης',
      title: 'Πλατφόρμα διαχείρισης επιχειρήσεων',
      account_mangae: 'Διαχείριση λογαριασμών',
      contacts: 'Βιβλίο διευθύνσεων ομάδας',
      records: 'αρχεία κλήσεων',
      LicenseInformation: 'Πληροφορίες εξουσιοδότησης',
      pushInfo: 'ώθηση πληροφοριών',
      infoGroup: 'Διαχείριση ομάδας Push'
  },
  download: 'λήψη εφαρμογών',
  unauthorized: {
      title: 'Πλατφόρμα εταιρικής διαχείρισης ViiTALK (μη εξουσιοδοτημένη)',
      span_1: 'Έχει εντοπιστεί ότι ο διακομιστής σας δεν εξουσιοδοτεί την υπηρεσία ViiTALK,',
      span_2: 'Επικοινωνήστε με την εξυπηρέτηση πελατών για να λάβετε το αρχείο εξουσιοδότησης.',
      span_3: '(Για να αποκτήσετε το αρχείο εξουσιοδότησης, πρέπει να δώσετε τη διεύθυνση IP του διακομιστή σας και τη διεύθυνση Mac στην εξυπηρέτηση πελατών)',
      upError: 'Λυπούμαστε, το αρχείο που ανεβάσατε είναι λάθος. Ανεβάστε ξανά',
      chooseFile_b: 'Επιλέξτε ένα έγγραφο',
      upFile_b: 'Μεταφόρτωση αρχείου εξουσιοδότησης',
      success: {
          span_1: 'Επιτυχής εξουσιοδότηση',
          span_2: 'Είσοδος στην πλατφόρμα διαχείρισης επιχειρήσεων...',
          span_3: '(Εάν δεν έχετε εισέλθει για μεγάλο χρονικό διάστημα, ανανεώστε το πρόγραμμα περιήγησης)'
      },
      dialog: {
          title: 'Αλλάξτε τον κωδικό πρόσβασης διαχειριστή',
          span: 'Ο κωδικός πρόσβασης διαχειριστή δεν έχει αλλάξει ακόμα, αλλάξτε αμέσως τον κωδικό πρόσβασής σας',
          button: 'υποβάλλουν'
      }
  },
  lang: {
    'zh-CN': 'Απλοποιημένα Κινέζικα',
    'zh-TW': 'παραδοσιακά κινεζικά',
    en: 'Αγγλικά',
    ja: 'Ιαπωνικά',
    ko: 'κορεάτης',
    th: 'Ταϊλανδός',
    ar: 'αραβικός',
    bn: 'Μπενγκάλι',
    de: 'Γερμανός',
    el: 'Ελληνικά',
    es: 'Ισπανικά',
    fa: 'περσικός',
    fr: 'γαλλική γλώσσα',
    id: 'Ινδονησιακά',
    it: 'ιταλικός',
    iw: 'Εβραϊκά',
    ku: 'κουρδικά',
    nl: 'Ολλανδός',
    pl: 'Στίλβωση',
    pt: 'Πορτογαλικά',
    ro: 'ρουμανικός',
    ru: 'Ρωσική',
    tr: 'τούρκικος',
    vi: 'Βιετναμέζικο'
  },
  authorized: {
      org: 'Εξουσιοδοτημένη επιχείρηση',
      userLimit: 'Όριο χρήστη',
      roomMax: 'Μέγιστη χωρητικότητα αίθουσας συνεδριάσεων cloud',
      roomCountLimit: 'Μέγιστος αριθμός διαδικτυακών αιθουσών συσκέψεων cloud',
      authorizedActiveDate: 'Ημερομηνία ενεργοποίησης άδειας ιδιωτικού Cloud',
      authorizedValidDate: 'Η ιδιωτική άδεια cloud ισχύει μέχρι',
      button_up: 'Ενημέρωση εξουσιοδότησης',
      upFileError: 'Κανένα επιλεγμένο αρχείο'
  },
  index_title: 'ViiTalk Enterprise Management Platform',
  brand: {
    table: {
        th: {
            brandNumber: 'τον αριθμό του σπιτιού',
            bindNumber: 'Σύνδεση μεγάλου αριθμού οθόνης',
            onlineStatus: 'online κατάσταση',
            lastLoginTime: 'τελευταία ώρα σύνδεσης'
        },
        col: {
            content: {
                onlineStatus: {
                    online: 'Σε σύνδεση',
                    offline: 'Εκτός σύνδεσης'
                }
            }
        }
    }
  }
}