// 中文
exports.default = {
  el: {
      colorpicker: {
          confirm: 'Potwierdzać',
          clear: 'Jasne'
      },
      datepicker: {
          now: 'Teraz',
          today: 'Dzisiaj',
          cancel: 'Anulować',
          clear: 'Jasne',
          confirm: 'Potwierdzać',
          selectDate: 'Wybierz datę',
          selectTime: 'Wybierz czas',
          startDate: 'Data rozpoczęcia',
          startTime: 'czas rozpoczęcia',
          endDate: 'Data końcowa',
          endTime: 'Koniec czasu',
          prevYear: 'Poprzedni rok',
          nextYear: 'Następny rok',
          prevMonth: 'w zeszłym miesiącu',
          nextMonth: 'w następnym miesiącu',
          year: 'Rok',
          month1: 'Styczeń',
          month2: 'Luty',
          month3: 'Marsz',
          month4: 'Kwiecień',
          month5: 'Móc',
          month6: 'Czerwiec',
          month7: 'Lipiec',
          month8: 'Sierpień',
          month9: 'Wrzesień',
          month10: 'Październik',
          month11: 'Listopad',
          month12: 'Grudzień',
          weeks: {
              sun: 'dzień',
              mon: 'jeden',
              tue: 'dwa',
              wed: 'trzy',
              thu: 'Cztery',
              fri: 'pięć',
              sat: 'sześć'
          },
          months: {
              jan: 'Styczeń',
              feb: 'Luty',
              mar: 'Marsz',
              apr: 'Kwiecień',
              may: 'Móc',
              jun: 'Czerwiec',
              jul: 'Lipiec',
              aug: 'Sierpień',
              sep: 'Wrzesień',
              oct: 'Październik',
              nov: 'Listopad',
              dec: 'Grudzień'
          }
      },
      select: {
          loading: 'Ładowanie',
          noMatch: 'Brak pasujących danych',
          noData: 'Brak informacji',
          placeholder: 'Proszę wybrać'
      },
      cascader: {
          noMatch: 'Brak pasujących danych',
          loading: 'Ładowanie',
          placeholder: 'Proszę wybrać',
          noData: 'Brak informacji'
      },
      pagination: {
          goto: 'Iść do',
          pagesize: 'Element/strona',
          total: 'Łącznie {total} elementów',
          pageClassifier: 'strona'
      },
      messagebox: {
          title: 'wskazówka',
          confirm: 'określić',
          cancel: 'Anulować',
          error: 'Wprowadzone informacje nie spełniają wymagań!'
      },
      upload: {
          deleteTip: 'Naciśnij klawisz Delete, aby usunąć',
          delete: 'usuwać',
          preview: 'Zobacz zdjęcia',
          continue: 'Kontynuuj przesyłanie'
      },
      table: {
          emptyText: 'Brak informacji',
          confirmFilter: 'Filtr',
          resetFilter: 'Resetowanie',
          clearFilter: 'Wszystko',
          sumText: 'Suma'
      },
      tree: {
          emptyText: 'Brak informacji'
      },
      transfer: {
          noMatch: 'Brak pasujących danych',
          noData: 'Brak informacji',
          titles: [
              'Lista 1',
              'Lista 2'
          ],
          filterPlaceholder: 'Wpisz słowo kluczowe',
          noCheckedFormat: '{total} elementów',
          hasCheckedFormat: 'Zaznaczono {checked}/{total}'
      },
      image: {
          error: 'Ładowanie nie powiodło się'
      },
      pageHeader: {
          title: 'powrót'
      },
      popconfirm: {
          confirmButtonText: 'Tak',
          cancelButtonText: 'NIE'
      },
      empty: {
          description: 'Brak informacji'
      }
  }
}
