import Vue from 'vue'
import axios from 'axios'
import router from '@/router'

// 设置超时时长
axios.defaults.timeout = '60000'

// request拦截器
axios.interceptors.request.use((config) => {
  return config
}, (error) => {
  return Promise.reject(error)
})

// responese拦截器
axios.interceptors.response.use((res) => {
  return res
}, (error) => {
  // Vue.$message.error("Server exception")
  console.log(error)
  return Promise.reject(error)
})

/**
 * 设置请求header
 * @param {Object} headers
 */
const setHeader = (headers) => {
  for (const key in headers) {
    axios.defaults.headers[key] = headers[key]
  }
}

const dealResponse = (response) => {

  if (response.data == 'No login') {
    const token = localStorage.getItem('token')
    if(token){
      localStorage.removeItem('token')
    }
    router.push('/login').catch(error => {
      console.log(error)
    })
    return true
  }

  if (response.data == 'Replace login') {
    const token = localStorage.getItem('token')
    if(token){
      localStorage.removeItem('token')
    }
    router.push({ path: '/login', query: { param: 'Replace login' } }).catch(error => {
      console.log(error)
    })
    return true
  }

}


/**
 * POST请求
 * @param {String} url 地址
 * @param {Object} params 参数
 * @param {Object} headers headers
 */
export const methodPost = (url, params, headers = {}) => {
  return new Promise((resolve, reject) => {
    setHeader({ 'content-type': 'application/x-www-form-urlencoded' })
    axios.post(url, params).then((response) => {
      if (dealResponse(response)) {
        return
      }
      resolve(response.data)
    }).catch((error) => {
      reject(error)
    })
  })
}

export const methodPost2 = (url, params, headers = {}) => {
  return new Promise((resolve, reject) => {
    setHeader({ 'content-type': 'application/json' })
    axios.post(url, params).then((response) => {
      if (dealResponse(response)) {
        return
      }
      resolve(response.data)
    }).catch((error) => {
      reject(error)
    })
  })
}

export const methodGet = (url, params, headers = {}) => {
  return new Promise((resolve, reject) => {
    // setHeader()
    axios.get(url, params, { headers: { 'content-type': 'application/json' } }).then((response) => {
      if (dealResponse(response)) {
        return
      }
      resolve(response.data)
    }).catch((error) => {
      reject(error)
    })
  })
}

/**
 * 文件上传
 * @param {String} url 地址
 * @param {File} file 文件
 */
export const methodUpload = (url, file) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData()
    formData.append('didInputFile', file)
    axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((response) => {
      resolve(response.data)
    }).catch((error) => {
      reject(error)
    })
  })
}

