// 中文
exports.default = {
  el: {
      colorpicker: {
          confirm: 'নিশ্চিত করুন',
          clear: 'পরিষ্কার'
      },
      datepicker: {
          now: 'এখন',
          today: 'আজ',
          cancel: 'বাতিল করুন',
          clear: 'পরিষ্কার',
          confirm: 'নিশ্চিত করুন',
          selectDate: 'তারিখ নির্বাচন করুন',
          selectTime: 'সময় নির্বাচন করুন',
          startDate: 'শুরুর তারিখ',
          startTime: 'সময় শুরু',
          endDate: 'শেষ তারিখ',
          endTime: 'শেষ সময়',
          prevYear: 'গত বছর',
          nextYear: 'আগামী বছর',
          prevMonth: 'গত মাসে',
          nextMonth: 'পরের মাসে',
          year: 'বছর',
          month1: 'জানুয়ারি',
          month2: 'ফেব্রুয়ারি',
          month3: 'মার্চ',
          month4: 'এপ্রিল',
          month5: 'মে',
          month6: 'জুন',
          month7: 'জুলাই',
          month8: 'আগস্ট',
          month9: 'সেপ্টেম্বর',
          month10: 'অক্টোবর',
          month11: 'নভেম্বর',
          month12: 'ডিসেম্বর',
          weeks: {
              sun: 'দিন',
              mon: 'এক',
              tue: 'দুই',
              wed: 'তিন',
              thu: 'চার',
              fri: 'পাঁচ',
              sat: 'ছয়'
          },
          months: {
              jan: 'জানুয়ারি',
              feb: 'ফেব্রুয়ারি',
              mar: 'মার্চ',
              apr: 'এপ্রিল',
              may: 'মে',
              jun: 'জুন',
              jul: 'জুলাই',
              aug: 'আগস্ট',
              sep: 'সেপ্টেম্বর',
              oct: 'অক্টোবর',
              nov: 'নভেম্বর',
              dec: 'ডিসেম্বর'
          }
      },
      select: {
          loading: 'লোড হচ্ছে',
          noMatch: 'কোন মিল তথ্য',
          noData: 'কোন তথ্য নেই',
          placeholder: 'অনুগ্রহ করে নির্বাচন করুন'
      },
      cascader: {
          noMatch: 'কোন মিল তথ্য',
          loading: 'লোড হচ্ছে',
          placeholder: 'অনুগ্রহ করে নির্বাচন করুন',
          noData: 'কোন তথ্য নেই'
      },
      pagination: {
          goto: 'যাও',
          pagesize: 'আইটেম/পৃষ্ঠা',
          total: 'মোট {total}টি আইটেম',
          pageClassifier: 'পৃষ্ঠা'
      },
      messagebox: {
          title: 'ইঙ্গিত',
          confirm: 'নির্ধারণ',
          cancel: 'বাতিল করুন',
          error: 'প্রবেশ করা তথ্য প্রয়োজনীয়তা পূরণ করে না!'
      },
      upload: {
          deleteTip: 'মুছে ফেলতে ডিলিট কী টিপুন',
          delete: 'মুছে ফেলা',
          preview: 'ছবি দেখুন',
          continue: 'আপলোড চালিয়ে যান'
      },
      table: {
          emptyText: 'এখনো কোনো তথ্য নেই',
          confirmFilter: 'ছাঁকনি',
          resetFilter: 'রিসেট',
          clearFilter: 'সব',
          sumText: 'সমষ্টি'
      },
      tree: {
          emptyText: 'এখনো কোনো তথ্য নেই'
      },
      transfer: {
          noMatch: 'কোন মিল তথ্য',
          noData: 'কোন তথ্য নেই',
          titles: [
              'তালিকা 1',
              'তালিকা 2'
          ],
          filterPlaceholder: 'কীওয়ার্ড লিখুন',
          noCheckedFormat: '{total}টি আইটেম',
          hasCheckedFormat: '{checked}/{total} চেক করা হয়েছে৷'
      },
      image: {
          error: 'লোডিং ব্যর্থ হয়েছে৷'
      },
      pageHeader: {
          title: 'ফিরে'
      },
      popconfirm: {
          confirmButtonText: 'হ্যাঁ',
          cancelButtonText: 'না'
      },
      empty: {
          description: 'এখনো কোনো তথ্য নেই'
      }
  }
}
