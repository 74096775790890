import InfoGroup from "@/components/pushInfo/infoGroup.vue";

export default {
  language: 'español',
  serverError: 'Anormalidad en el servidor, comuníquese con el administrador.',
  opearteSuccess: 'Operación exitosa',
  agreement: {
      agreement_1: 'He leído y aceptado',
      agreement_2: '\'Acuerdo del Usuario\'',
      agreement_3: 'y',
      agreement_4: '\'Política de privacidad\'',
      agreement_5: 'marque la casilla para aceptar'
  },
  login: {
      webName: 'Plataforma de gestión empresarial ViiTALK',
      webNameSchool: 'Plataforma de gestión empresarial ViiTALK',
      website: 'Página web oficial:',
      tel: 'Teléfono:',
      email: 'Correo:',
      title: 'Inicio de sesión del usuario',
      titleSchool: 'Inicio de sesión del usuario',
      inputPlaceholder: {
          name: 'Por favor ingrese nombre de usuario/correo electrónico',
          pwd: 'Por favor, introduzca su contraseña'
      },
      button: {
          login: 'Acceso',
          email: 'Iniciar sesión por correo electrónico',
          account: 'Cuenta de Ingreso',
          register: 'Registro de usuario empresarial',
          registerSchool: 'Registro de usuario empresarial',
          pwd: 'olvida la contraseña'
      },
      error: {
          uPwd: 'Nombre de usuario o contraseña incorrectos',
          uLock: 'La cuenta ha sido bloqueada',
          uLeave: 'Has renunciado y no puedes utilizar el sistema.',
          uRelogin: '¡¡¡Ya has iniciado sesión en otro lugar!!!',
          uReplaceLogin: 'Se ha iniciado sesión en su cuenta en otro lugar y se ha visto obligado a cerrar sesión. Si no fue culpa suya, es posible que su contraseña se haya filtrado. Inicie sesión nuevamente y cambie su contraseña lo antes posible.',
          user_null: 'por favor ingrese el nombre de usuario',
          pwd_null: 'Por favor, ingrese contraseña'
      }
  },
  personal: {
      companyNumber: 'Empresa',
      schoolNumber: 'Empresa',
      administrators: 'administrador',
      changeLanguage: 'cambiar de idioma',
      logout: 'desconectar'
  },
  companyMessage: {
      adminPwd: 'Contraseña de administrador',
      adminUserName: 'Nombre del administrador',
      adminEmail: 'Correo electrónico del administrador',
      companyFullName: 'Nombre completo de la empresa (nombre legal)',
      companySimpleNameCn: 'Abreviatura de la empresa',
      companySimpleNameEn: 'Abreviatura de la empresa (inglés)',
      companyWebsite: 'Web corporativa',
      companyNumber: 'Número de grupo empresarial',

      schoolFullName: 'Nombre completo de la empresa (nombre legal)',
      schoolSimpleNameCn: 'Abreviatura de la empresa',
      schoolSimpleNameEn: 'Abreviatura de la empresa (inglés)',
      schoolWebsite: 'Web corporativa',
      schoolNumber: 'Número de grupo empresarial',

      companyNoAuditJoin: 'Unirse sin revisión',
      adminExplain: 'El nombre del administrador es visible para todos los miembros de la libreta de direcciones corporativa',
      companySimpleExplain: 'La abreviatura de la empresa se mostrará en la página de inicio del dispositivo Rooms y será visible para todos los miembros de la libreta de direcciones de la empresa.',
      button: {
          Edit: 'Revisar'
      },
      dialog: {
          editpwd: {
              title: 'Cambiar contraseña de administrador',
              label: {
                  oldPwd: 'Contraseña anterior',
                  newPwd: 'Nueva contraseña',
                  reNewPwd: 'Confirma la nueva contraseña'
              },
              inputPlaceholder: {
                  oldPwd: 'Por favor ingrese la contraseña anterior',
                  newPwd: 'Por favor ingrese una nueva contraseña',
                  reNewPwd: 'Por favor ingresa para confirmar la nueva contraseña'
              }
          },
          editName: {
              title: 'Modificar el nombre del administrador',
              label: {
                  name: 'nuevo nombre'
              },
              inputPlaceholder: {
                  name: 'Por favor ingresa un nuevo nombre'
              }
          },
          editEmail: {
              title: 'Modificar correo electrónico del administrador',
              title2: 'Aún no ha vinculado su dirección de correo electrónico. Vincule su dirección de correo electrónico a tiempo.',
              label: {
                  email: 'nuevo buzón de correo'
              },
              inputPlaceholder: {
                  email: 'Por favor ingrese un nuevo correo electrónico'
              }
          },
          editCompanyFullName: {
              title: 'Modificar el nombre completo de la empresa',
              label: {
                  name: 'nuevo nombre completo'
              },
              inputPlaceholder: {
                  name: 'Por favor ingrese nuevo nombre completo'
              }
          },
          editCompanyAbbreviationCn: {
              title: 'Modificar abreviatura de empresa (chino)',
              label: {
                  name: 'Nueva abreviatura'
              },
              inputPlaceholder: {
                  name: 'Por favor ingresa un nuevo nombre corto'
              }
          },
          editCompanyAbbreviationEn: {
              title: 'Modificar abreviatura de empresa (inglés)'
          },
          editWebsite: {
              title: 'Modificar dirección web corporativa',
              label: {
                  website: 'Nueva URL'
              },
              inputPlaceholder: {
                  website: 'Por favor ingresa una nueva URL'
              }
          },
          editNoAuditJoin: {
              title: 'Modificar el interruptor de unión sin aplicaciones',
              radio: {
                  open: 'Abierto',
                  close: 'cierre'
              }
          }
      },
      validator: {
          value_null: 'El valor no puede estar vacío.',
          oldPwd: {
              null: 'Por favor ingrese la contraseña anterior'
          },
          newPwd: {
              length: 'Establezca una contraseña de 6 caracteres o más de longitud'
          },
          reNewPwd: {
              consistent: '¡Las contraseñas ingresadas dos veces son inconsistentes!'
          },
          name: {
              length: 'El nombre no puede tener más de 20 caracteres.'
          },
          email: {
              rule: 'El formato del correo electrónico es incorrecto.'
          },
          NoAuditJoin: {
              null: 'Por favor seleccione un artículo'
          }
      }
  },
  register: {
      step_1: 'contraseña',
      step_2: 'información',
      step_3: 'Finalizar',
      head: 'Registro de usuario empresarial',
      usernameError: 'El nombre del miembro debe tener entre 6 y 20 caracteres, sin incluir los caracteres chinos y comenzar con una letra.',
      usernameError_exist: 'El nombre de usuario ya existe, por favor modifique su nombre de usuario',
      emailError: 'El formato del correo electrónico es incorrecto.',
      emailError_exist: 'el Email ya existe',
      passwordError_length: '6-20 caracteres, solo alfanuméricos y signos de puntuación',
      passwordError_same: 'La contraseña no puede ser el mismo nombre de usuario',
      passwordError: 'El formato de contraseña es incorrecto',
      rePasswordError: 'Las contraseñas ingresadas dos veces no coinciden, vuelva a ingresarlas',
      companyNameError: 'Por favor ingrese el nombre completo de la empresa',
      companyAbbreviationError: 'Por favor introduzca la abreviatura de la empresa.',
      orgError_exist:'La empresa ha sido registrada, cambie el nombre de su empresa',
      button: {
          next: 'Próximo paso',
          login: 'iniciar sesión inmediatamente'
      },
      inputPlaceholder: {
          username: 'nombre de usuario',
          email: 'Correo',
          password: 'configurar la clave',
          rePassword: 'Ingrese de nuevo la contraseña',
          name: 'Nombre completo de la empresa (razón legal)*',
          simpleNameCN: 'Abreviatura de la empresa (chino)*',
          simpleNameEN: 'Abreviatura de la empresa (inglés)',
          website: 'Sitio web de la empresa (www)'
      },
      success: '¡Has creado exitosamente una cuenta comercial!',
      location: 'ubicación:',
      Scale: 'escala:'
  },
  forgotPwd: {
      head: 'Recuperación de correo electrónico',
      resetSuccess: '¡Has restablecido exitosamente tu contraseña!',
      inputPlaceholder: {
          code: 'por favor ingrese el código de verificación'
      },
      sendCodeError: 'código de verificación incorrecto',
      sendCodeError_sended: 'El código de verificación ha sido enviado, verifíquelo.',
      button: {
          getCode: 'obtener código de verificación',
          reGetCode: 'Obtener el código de verificación nuevamente'
      }
  },
  button: {
      ok: 'confirmar',
      cancel: 'Cancelar',
      search: 'buscar',
      clear: 'Claro'
  },
  device: {
      button: {
          setContent: 'Configurar el horario de inserción',
          setGroupMng: 'Configurar administrador de grupo',
          startNow: 'Empuja ahora',
          stopNow: 'deja de empujar',
          add: 'Añadir dispositivo',
          search: 'buscar',
          adjust: 'Ajustar agrupación',
          del: 'borrar'
      },
      inputPlaceholder: {
          search: 'Número de dispositivo/Número de teléfono móvil/Número ViiTALK'
      },
      group: {
          add: 'Añadir grupo',
          edit: 'Editar grupo',
          del: 'Eliminar grupo'
      },
      table: {
          th_name: 'Nombre del dispositivo',
          th_number: 'Número del dispositivo',
          th_time: 'Hora del último inicio de sesión',
          th_brandNumber: 'Número de la casa de conferencias',
          th_online: 'Está en linea',
          th_status: 'estado',
          th_bindNumber: 'Vincular número de pantalla grande'
      },
      dialog: {
          addGroupTitle: 'Por favor ingresa un nombre de grupo',
          inputPlaceholder: {
              groupName: 'Por favor ingrese el nombre del grupo'
          },
          delGroupTitle: 'Eliminar grupo',
          delGroupTips: '¿Estás seguro de que quieres eliminar el grupo?',
          editGroupTitle: 'Editar grupo',
          groupMng: {
              title: 'Editar administrador de grupo',
              inputPlaceholder: {
                  name: 'Por favor ingrese el nombre del administrador'
              },
              label: {
                  name: 'Nombre del administrador'
              }
          },
          addDevice: {
              title: 'Añadir dispositivo',
              inputPlaceholder: {
                  name: 'Por favor ingrese el nombre del dispositivo',
                  number: 'Por favor ingrese el número del dispositivo'
              },
              label: {
                  name: 'Nombre del dispositivo',
                  number: 'Número del dispositivo',
                  group: 'Por favor seleccione un grupo de dispositivos'
              }
          },
          delDevice: {
              title: 'Retire el dispositivo',
              tips: '¿Estás seguro de que deseas eliminar el dispositivo?'
          },
          startNowDevice: {
              title: 'Empuja ahora',
              tips: '¿Estás seguro de que quieres impulsarlo ahora?'
          },
          stopNowDevice: {
              title: 'deja de empujar',
              tips: '¿Estás seguro de que quieres dejar de pujar?'
          },
          adjustTitle: 'Ajustar agrupación',
          editDevice: {
              title: 'Modificar el nombre del dispositivo',
              inputPlaceholder: {
                  name: 'Por favor ingrese el nombre del dispositivo'
              }
          },
          noChildren: {
              title: 'advertir',
              tips: 'Por favor agregue un grupo primero'
          }
      },
      validator: {
          group: {
              name_null: 'El nombre del grupo no puede estar vacío',
              name_length: 'No puede exceder los 10 caracteres'
          },
          number_null: 'El número no puede estar vacío',
          number_rule: 'Solo puede tener 10 dígitos comenzando con 6 u 8',
          name_null: 'El nombre del terminal no puede estar vacío'
      }
  },
  user: {
      button: {
          add: 'Agregar usuario'
      },
      inputPlaceholder: {
          search: 'Número ViiTALK'
      },
      table: {
          th_name: 'Nombre',
          th_number: 'Número de teléfono móvil/Número de Maizhe'
      },
      dialog: {
          addUser: {
              title: 'Agregar usuario',
              inputPlaceholder: {
                  number: 'Por favor ingrese el número de ViiTALK'
              },
              label: {
                  number: 'Número ViiTALK',
                  group: 'Por favor seleccione grupo de usuarios'
              }
          },
          delUser: {
              title: 'eliminar usuarios',
              tips: '¿Estás seguro de que deseas eliminar al usuario?'
          }
      },
      validator: {
          number_null: 'El número no puede estar vacío',
          number_rule: 'este numero no existe'
      }
  },
  addrbook: {
      return: {
          delDevice: {
              tips_1: 'El grupo a eliminar no existe',
              tips_2: 'Hay subgrupos debajo del grupo que se eliminarán y no se pueden eliminar.',
              tips_3: 'Hay personas en el grupo que se deben eliminar y no se pueden eliminar.'
          },
          addDevice: {
              tips_1: 'Ya agregado, por favor vea otros grupos',
              tips_2: 'Ha sido añadido por otras empresas.',
              tips_3: 'este numero no existe'
          }
      }
  },
  audit: {
      option: {
          label: {
              NoPass: 'No pasó',
              hasRefuse: 'rechazado',
              hasPass: 'aprobado',
              hasInvalid: 'venció',
              all: 'todo'
          }
      },
      button: {
          pass: 'aprobar',
          reject: 'rechazar'
      },
      table: {
          th_number: 'Número de dispositivo',
          th_date: 'fecha',
          th_status: 'estado',
          th_source: 'fuente'
      },
      dialog: {
          passTitle: 'Aprobado',
          reject: {
              title: 'Denegar revisión',
              tips: '¿Estás seguro de que quieres rechazar la reseña?'
          }
      },
      api: {
          req: {
              tips_1: 'No se pudo pasar la revisión, ¡los elementos seleccionados provienen de diferentes fuentes!',
              tips_2: 'Por favor seleccione la región a asignar'
          },
          return: {
              tips_1: 'El número de dispositivo no existe',
              tips_2: 'Te has unido a la empresa',
              tips_3: 'Ya agregado, por favor vea otros grupos'
          }
      }
  },
  conferenceLog: {
      inputPlaceholder: 'Por favor ingresa el contenido',
      table: {
          th: {
              roomId: 'número de habitación',
              state: 'estado de la habitación',
              username: 'Creado porjid',
              createTime: 'Hora de creación de la reunión',
              closeTime: 'hora de finalización de la reunión',
              duration: 'Duración'
          }
      },
      dialog: {
          title: 'Registro de entrada/salida de miembros de la sala',
          table: {
              th: {
                  username: 'Creado porjid',
                  joinTime: 'participar',
                  leaveTime: 'dejar',
                  duration: 'Duración'
              }
          }
      }
  },
  callLog: {
      table: {
          th: {
              callerMzNum: 'número que llama',
              callTime: 'marcar el tiempo',
              endTime: 'Hora de finalización',
              duration: 'Duración',
              responderMzNum: 'número llamado'
          }
      }
  },
  roomStatus: {
      table: {
          th: {
              roomMessage: 'Información básica de la habitación',
              Attendees: 'asistente',
              bystander: 'espectador',
              join: 'Para ser agregado',
              exited: 'Salido'
          }
      },
      roomNumber: 'Número de sala de conferencias',
      name: 'Nombre de la sala de reuniones',
      dissolution: 'Disolver',
      pattern: 'modelo',
      confluence: 'confluencia',
      forward: 'Adelante',
      createdTime: 'tiempo de creación',
      limit: 'Liberación/límite de número total de personas',
      createMode: 'crear patrón',
      order: 'Cita (reunión)',
      simple: 'Multijugador ordinario',
      type: 'Tipo de reunión',
      creator: 'creador',
      host: 'Anfitrión:',
      windowMode: 'Modo ventana:',
      WindowMode1: 'Uno grande y muchos pequeños.',
      WindowMode2: 'Teja',
      hide: 'Ya sea para ocultar:',
      forbidden: 'Si se debe prohibir el trigo:',
      inorder: 'orden:',
      source: 'fuente:',
      joinTime: 'Hora de incorporación:',
      comeOut: 'por favor sal',
      openMic: 'encender el micrófono',
      closeMic: 'Apagar el micrófono',
      leave: 'dejar:',
      peopleNumber: 'Número de personas:'
  },
  menu: {
      index: 'página delantera',
      device_manage: 'Gestión de dispositivos',
      user_manage: 'Gestión de usuarios',
      conference_brand: 'Número de puerta de la conferencia',
      audit_manage: 'Gestión de auditoría',
      orgMessage: 'Información corporativa',
      schoolMessage: 'Información corporativa',
      roomStatus: 'reunión en curso',
      p2pCallLog: 'Historial de videollamadas',
      conferenceLog: 'actas de la reunión',
      authorizedMessage: 'Información de autorización',
      title: 'Plataforma de gestión empresarial',
      schooltitle: 'Plataforma de gestión empresarial',
      account_mangae: 'Administración de cuentas',
      contacts: 'libreta de direcciones de grupo',
      records: 'registros de llamadas',
      LicenseInformation: 'Información de autorización',
      pushInfo: 'Empuje de información',
      infoGroup: 'Gestión de grupos push'
  },
  download: 'descargar aplicaciones',
  unauthorized: {
      title: 'Plataforma de gestión empresarial ViiTALK (no autorizada)',
      span_1: 'Se ha detectado que su servidor no autoriza el servicio ViiTALK,',
      span_2: 'Comuníquese con el servicio de atención al cliente para obtener el archivo de autorización.',
      span_3: '(Para obtener el archivo de autorización, debe proporcionar la dirección IP de su servidor y la dirección Mac al servicio de atención al cliente)',
      upError: 'Lo sentimos, el archivo que subiste es incorrecto, por favor súbelo nuevamente.',
      chooseFile_b: 'Seleccione un documento',
      upFile_b: 'Cargar archivo de autorización',
      success: {
          span_1: 'Autorización exitosa',
          span_2: 'Ingresando a la plataforma de gestión empresarial...',
          span_3: '(Si no ha ingresado durante mucho tiempo, actualice el navegador)'
      },
      dialog: {
          title: 'Por favor cambie la contraseña del administrador',
          span: 'Su contraseña de administrador aún no ha sido cambiada, cámbiela inmediatamente',
          button: 'entregar'
      }
  },
  lang: {
    'zh-CN': 'Chino simplificado',
    'zh-TW': 'chino tradicional',
    en: 'Inglés',
    ja: 'japonés',
    ko: 'coreano',
    th: 'tailandés',
    ar: 'Arábica',
    bn: 'bengalí',
    de: 'Alemán',
    el: 'Griego',
    es: 'español',
    fa: 'persa',
    fr: 'Francés',
    id: 'indonesio',
    it: 'italiano',
    iw: 'hebreo',
    ku: 'kurdo',
    nl: 'Holandés',
    pl: 'Polaco',
    pt: 'portugués',
    ro: 'rumano',
    ru: 'ruso',
    tr: 'turco',
    vi: 'vietnamita'
  },
  authorized: {
      org: 'Empresa autorizada',
      userLimit: 'Límite de usuarios',
      roomMax: 'Capacidad máxima de la sala de conferencias en la nube',
      roomCountLimit: 'Número máximo de salas de reuniones en la nube en línea',
      authorizedActiveDate: 'Fecha de activación de la licencia de nube privada',
      authorizedValidDate: 'La licencia de nube privada es válida hasta',
      button_up: 'Autorización de actualización',
      upFileError: 'Ningún archivo seleccionado'
  },
  index_title: 'Plataforma de gestión empresarial ViiTalk',
  brand: {
    table: {
        th: {
            brandNumber: 'número de casa',
            bindNumber: 'Vincular número de pantalla grande',
            onlineStatus: 'Estado en línea',
            lastLoginTime: 'última hora de inicio de sesión'
        },
        col: {
            content: {
                onlineStatus: {
                    online: 'en línea',
                    offline: 'Desconectado'
                }
            }
        }
    }
  }
}