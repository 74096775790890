<template>
  <div>
      <el-card class="table-card">
        <el-table :data="brandTableData" class="table-col-font"
            :header-cell-style="{ background: '#FFFFFF', color: '#000000', textAlign: 'center' }"
            @selection-change="handleSelectionChange" :cell-style="{ textAlign: 'center' }">
            <el-table-column prop="brandNumber" :label="$t('brand.table.th.brandNumber')"
              width="200"></el-table-column>
            <el-table-column prop="bindNumber" :label="$t('brand.table.th.bindNumber')" width="200">
            </el-table-column>
            <el-table-column prop="online" :label="$t('brand.table.th.onlineStatus')" width="150">
              <template slot-scope="scope">
                {{ formatOnline(scope.row.online) }}
              </template>
            </el-table-column>
            <el-table-column prop="lastLoginTime" :label="$t('brand.table.th.lastLoginTime')" width="200">
              <template slot-scope="scope">
                {{ formatDateTime(scope.row.lastLoginTime) }}
              </template>
            </el-table-column>
          </el-table>

          <el-pagination background @size-change="handleBrandSizeChange" @current-change="handleBrandCurrentChange"
            :current-page="brandPageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="brandPageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="brandDataCount" class="pagination-right-aligned">
          </el-pagination>
      </el-card>
  </div>
</template>

<script>
import { conferenceBrand } from '../js/conferenceBrand.js'
export default {
  mixins: [conferenceBrand]
}
</script>

<style src="../../assets/css/brand.css" scoped></style>