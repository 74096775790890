// 中文
exports.default = {
  el: {
      colorpicker: {
          confirm: 'Mengonfirmasi',
          clear: 'Jernih'
      },
      datepicker: {
          now: 'Sekarang',
          today: 'Hari ini',
          cancel: 'Membatalkan',
          clear: 'Jernih',
          confirm: 'Mengonfirmasi',
          selectDate: 'Pilih tanggal',
          selectTime: 'Pilih waktu',
          startDate: 'mulai tanggal',
          startTime: 'waktu mulai',
          endDate: 'tanggal akhir',
          endTime: 'akhir waktu',
          prevYear: 'Tahun sebelumnya',
          nextYear: 'tahun depan',
          prevMonth: 'bulan lalu',
          nextMonth: 'bulan depan',
          year: 'Tahun',
          month1: 'Januari',
          month2: 'Februari',
          month3: 'Berbaris',
          month4: 'April',
          month5: 'Mungkin',
          month6: 'Juni',
          month7: 'Juli',
          month8: 'Agustus',
          month9: 'September',
          month10: 'Oktober',
          month11: 'November',
          month12: 'Desember',
          weeks: {
              sun: 'hari',
              mon: 'satu',
              tue: 'dua',
              wed: 'tiga',
              thu: 'Empat',
              fri: 'lima',
              sat: 'enam'
          },
          months: {
              jan: 'Januari',
              feb: 'Februari',
              mar: 'Berbaris',
              apr: 'April',
              may: 'Mungkin',
              jun: 'Juni',
              jul: 'Juli',
              aug: 'Agustus',
              sep: 'September',
              oct: 'Oktober',
              nov: 'November',
              dec: 'Desember'
          }
      },
      select: {
          loading: 'Memuat',
          noMatch: 'Tidak ada data yang cocok',
          noData: 'Tidak ada informasi',
          placeholder: 'Silahkan pilih'
      },
      cascader: {
          noMatch: 'Tidak ada data yang cocok',
          loading: 'Memuat',
          placeholder: 'Silahkan pilih',
          noData: 'Tidak ada informasi'
      },
      pagination: {
          goto: 'Pergi ke',
          pagesize: 'Barang/halaman',
          total: '{total} item totalnya',
          pageClassifier: 'halaman'
      },
      messagebox: {
          title: 'petunjuk',
          confirm: 'menentukan',
          cancel: 'Membatalkan',
          error: 'Informasi yang dimasukkan tidak memenuhi persyaratan!'
      },
      upload: {
          deleteTip: 'Tekan tombol hapus untuk menghapus',
          delete: 'menghapus',
          preview: 'Lihat gambar',
          continue: 'Lanjutkan mengunggah'
      },
      table: {
          emptyText: 'Belum ada informasi',
          confirmFilter: 'Saring',
          resetFilter: 'mengatur ulang',
          clearFilter: 'semua',
          sumText: 'Jumlah'
      },
      tree: {
          emptyText: 'Belum ada informasi'
      },
      transfer: {
          noMatch: 'Tidak ada data yang cocok',
          noData: 'Tidak ada informasi',
          titles: [
              'Daftar 1',
              'Daftar 2'
          ],
          filterPlaceholder: 'Masukkan kata kunci',
          noCheckedFormat: '{total} item',
          hasCheckedFormat: '{checked}/{total} dicentang'
      },
      image: {
          error: 'Gagal Memuat'
      },
      pageHeader: {
          title: 'kembali'
      },
      popconfirm: {
          confirmButtonText: 'Ya',
          cancelButtonText: 'TIDAK'
      },
      empty: {
          description: 'Belum ada informasi'
      }
  }
}
