import { methodGet, methodPost, methodPost2 } from '@/api'

export const addrbook = {
  data() {
    const checkGroupName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('device.validator.group.name_null')))
      }

      if (value.length > 10) {
        return callback(new Error(this.$t('device.validator.group.name_length')))
      }

      callback()
    }
    const checkDeviceNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('device.validator.number_null')))
      }

      const regMzNumber = /^(6|8)\d{9}$/

      if (!regMzNumber.test(value)) {
        return callback(new Error(this.$t('device.validator.number_rule')))
      }

      callback()
    }
    const checkUserNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('user.validator.number_null')))
      }

      if (value.match('^1\\d{10}$')) {
        this.addUserForm.number = `386${value}`
      } else {
        if (!value.match('^[1-8]\\d{6,16}$')) {
          return callback(new Error(this.$t('user.validator.number_rule')))
        }
      }

      callback()
    }
    const checkDeviceName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('device.validator.name_null')))
      }
      callback()
    }
    return {
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      dataCount: 0,
      pageSize: 10,
      pageIndex: 1,
      listBook: [],
      tableData: [],
      filteredTreeData: [],
      addGroupDialogVisible: false,
      delGroupDialogVisible: false,
      editGroupDialogVisible: false,
      addDeviceDialogVisible: false,
      addUserDialogVisible: false,
      delDeviceDialogVisible: false,
      adjustDeviceGroupDialogVisible: false,
      editDeviceNameDialogVisible: false,
      noChildrenDialogVisible: false,
      operateNodeId: 0,
      defaultExpandedKeys: [],
      defaultCheckedKey: [],
      addGroupForm: {
        name: '',
        parentId: 0,
        maxId: 0
      },
      editGroupForm: {
        name: ''
      },
      addDeviceForm: {
        name: '',
        number: '',
        groupName: '',
        groupId: 0
      },
      addUserForm: {
        number: '',
        groupName: '',
        groupId: 0
      },
      editDeviceForm: {
        name: '',
        number: '',
        id: 0
      },
      addGroupRules: {
        name: [
          {
            validator: checkGroupName,
            trigger: 'blur'
          }
        ]
      },
      addDeviceRules: {
        number: [
          {
            validator: checkDeviceNumber,
            trigger: 'blur'
          }
        ]
      },
      addUserRules: {
        number: [
          {
            validator: checkUserNumber,
            trigger: 'blur'
          }
        ]
      },
      editDeviceRules: {
        name: [
          {
            validator: checkDeviceName,
            trigger: 'blur'
          }
        ]
      },
      loading: false,
      multipleSelection: [],
      opearteDeviceIds: [],
      adjustGroupId: 0,
      selectedNode: '',
      searchKeyword: '',
      gtype: 1,
      visible: false,
      adjustButtonClass: 'adjust-button opearte-font opearte-border',
      addDeviceButtonClass: 'add-device-button',
      searchInputplaceholder: 'opearte-font'
    }
  },
  created() {
    if (this.$route.path == '/page/addrbook/device_manage') {
      this.gtype = 1
    } else {
      this.gtype = 2
    }
    this.loadGroupTree(this.gtype)
  },
  beforeRouteLeave(to, from, next) {
    // if (from.path == '/page/addrbook/device_manage') {
    //   this.gtype = 2
    // } else {
    //   this.gtype = 1
    // }
    // this.selectedNode = ''
    // this.treeData = []
    // this.loadGroupTree(this.gtype)
    next()
  },
  watch: {
    // 监听 treeData 的变化
    treeData: {
      handler(newVal) {
        // 过滤掉根节点，只保留子节点
        if (newVal[0] != undefined) {
          this.filteredTreeData = newVal[0].children
        }
      },
      immediate: true // 立即触发一次，以处理初始数据
    },
    // 监听语言变化
    '$i18n.locale': {
      handler(newVal, oldVal) {
        if (newVal === 'en') {
          this.adjustButtonClass = 'adjust-button-en opearte-font opearte-border'
          this.addDeviceButtonClass = 'add-device-button-en'
          this.searchInputplaceholder = 'opearte-font-en'
        }
      },
      immediate: true,
    },
  },
  computed: {
    isButtonDisabled() {
      // 如果没有选中的行，按钮应该被禁用  
      return this.multipleSelection.length === 0;
    }
  },
  methods: {
    // handleVisibleChange(data) {
    // //   // eslint-disable-next-line no-console
    // //   console.log(data);
    //   if(data.show) {
    //     this.$set(data, 'show', false)
    //   }else{
    //     this.$set(data, 'show', true)
    //   }
    // },
    formatDateTime(timestamp) {
      if (timestamp == undefined || timestamp == null || timestamp == '') {
        return ''
      }
      const date = new Date(timestamp) // 将时间戳转换为毫秒  
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2); // 月份从0开始，所以要加1  
      const day = ('0' + date.getDate()).slice(-2);
      const hours = ('0' + date.getHours()).slice(-2);
      const minutes = ('0' + date.getMinutes()).slice(-2);
      const seconds = ('0' + date.getSeconds()).slice(-2); // 添加秒数  
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    handleCommand(data) {
      // eslint-disable-next-line no-console
      console.log(data)
    },
    handleSizeChange(newSize) {
      this.pageSize = newSize
      this.computePage()
    },
    handleCurrentChange(newPage) {
      this.pageIndex = newPage
      this.computePage()
    },
    handleNodeClick(node) {
      this.selectedNode = node
      this.listMemberUnderGroup(node.id)
    },
    listMemberUnderGroup(id) {
      const params = {
        params: {
          groupId: id
        }
      }
      methodGet('/api/addrbook/get', params).then((data) => {
        this.listBook = data.data
        this.computePage()
      })
    },
    computePage() {
      if (this.listBook == null) {
        this.dataCount = 0
        this.tableData = []
      } else {
        this.dataCount = this.listBook.length
        let start = (this.pageIndex - 1) * this.pageSize
        start = start > this.dataCount ? this.dataCount : start
        let end = Number(this.pageSize) + start
        end = end > this.dataCount ? this.dataCount : end
        this.tableData = this.listBook.slice(start, end)
      }
    },
    handleExpandCollapse(node) {
      // 处理展开和折叠事件
      this.$refs.tree.toggleNode(node)
    },
    mouseenter(data) {
      this.$set(data, 'show', true)
    },
    mouseleave(data) {
      this.$set(data, 'show', false)
    },
    loadGroupTree(gtype, chooseId) {
      methodGet(`api/group/loadGroupTree/${gtype}`).then((data) => {
        let arr = []
        arr[0] = data.data
        this.treeData = arr
        this.defaultExpandedKeys.push(data.data.id)
        if (chooseId == undefined) {

          this.$nextTick(() => {
            if (data.data.children[0] != undefined) {
              this.defaultCheckedKey = data.data.children[0].id
              this.$refs.tree.setCurrentKey(this.defaultCheckedKey)
            } else {
              this.defaultCheckedKey = data.data.id
              this.$refs.tree.setCurrentKey(this.defaultCheckedKey)
            }
          })

          if (this.selectedNode != '') {
            this.listMemberUnderGroup(this.selectedNode.id)
          } else {
            if (data.data.children[0] != undefined) {
              this.listMemberUnderGroup(data.data.children[0].id)
            } else if (this.gtype == 2) {
              this.listMemberUnderGroup(data.data.id)
            }
          }

        } else {
          this.$nextTick(() => {
            this.$refs.tree.setCurrentKey(chooseId)
          })
          this.listMemberUnderGroup(chooseId)
        }
      })
    },
    closeAddGroupDialog() {
      this.addGroupDialogVisible = false
      this.addGroupForm = {}
      this.$refs.addGroupForm.resetFields()
    },
    closeEditGroupDialog() {
      this.editGroupDialogVisible = false
      this.editGroupForm.name = {}
      this.$refs.editGroupForm.resetFields()
    },
    openAddGroupDialog(data) {
      const array = data.children
      let maxId
      // eslint-disable-next-line no-undefined
      if (!array || array.length == 0) {
        maxId = data.id
      } else {
        maxId = array[array.length - 1].id
      }
      this.addGroupForm.maxId = maxId
      this.addGroupForm.parentId = data.id
      this.addGroupDialogVisible = true
    },
    openDelGroupDialog(data) {
      this.operateNodeId = data.id
      this.delGroupDialogVisible = true
    },
    openEditGroupDialog(data) {
      this.operateNodeId = data.id
      this.editGroupForm.name = data.name
      this.editGroupDialogVisible = true
    },
    addGroup() {
      this.$refs['addGroupForm'].validate((valid) => {
        if (valid) {
          this.addGroupDialogVisible = false
          let name
          if (this.addGroupForm.name == '' || this.addGroupForm.name == undefined) {
            name = this.addGroupForm.name
          } else {
            name = this.addGroupForm.name.replace(/\s/g, '')
          }
          const params = {
            parentId: this.addGroupForm.parentId,
            name: name,
            maxId: this.addGroupForm.maxId
          }
          methodPost('api/group/addGroup', params).then((data) => {
            if (data.status) {
              this.loadGroupTree(this.gtype)
              this.$message({ message: this.$t('opearteSuccess'), type: 'success' })
            } else {
              this.$message.error(this.$t('serverError'))
            }
          })
        } else {
          return false
        }
      })
    },
    delGroup() {
      this.delGroupDialogVisible = false
      const params = {
        id: this.operateNodeId
      }
      methodPost('api/group/deleteGroup', params).then((data) => {
        if (data.status) {
          this.loadGroupTree(this.gtype)
          this.$message({ message: this.$t('opearteSuccess'), type: 'success' })
        } else {
          let error = this.$t('serverError')
          if (data.msg == '要删除的分组不存在') {
            error = this.$t('addrbook.return.delDevice.tips_1')
          }
          if (data.msg == '要删除的分组下有子分组，不能删除') {
            error = this.$t('addrbook.return.delDevice.tips_2')
          }
          if (data.msg == '要删除的分组下有人员，不能删除') {
            error = this.$t('addrbook.return.delDevice.tips_3')
          }
          this.$message.error(error);
        }
      })
    },
    editGroup() {
      this.$refs['editGroupForm'].validate((valid) => {
        if (valid) {
          this.editGroupDialogVisible = false
          let name
          if (this.editGroupForm.name == '' || this.editGroupForm.name == undefined) {
            name = this.editGroupForm.name
          } else {
            name = this.editGroupForm.name.replace(/\s/g, '')
          }
          const params = {
            id: this.operateNodeId,
            name: name
          }
          methodPost('api/group/updateGroup', params).then((data) => {
            if (data.status) {
              this.loadGroupTree(this.gtype, this.operateNodeId)
              this.$message({ message: this.$t('opearteSuccess'), type: 'success' })
            } else {
              this.$message.error(this.$t('serverError'));
            }
          })
        } else {
          return false
        }
      })
    },
    allowDrop(draggingNode, dropNode, type) {
      if (draggingNode.data.parentId == 0) {
        return false
      }

      if (type === 'inner') {
        return false
      }

      if (dropNode.data.parentId != draggingNode.data.parentId) {
        return false
      }

      return true
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
      const params = {
        id: draggingNode.data.id,
        targetId: dropNode.data.id
      }
      methodPost('api/group/adjustOrderGroup', params).then((data) => {
        if (data.status) {
          this.loadGroupTree(this.gtype, draggingNode.data.id)
          this.$message({ message: this.$t('opearteSuccess'), type: 'success' })
        } else {
          this.$message.error(this.$t('serverError'));
        }
      })
    },
    openAddDeviceDialog() {
      if (this.treeData[0].children.length == 0 && this.gtype == 1) {
        this.noChildrenDialogVisible = true
        return
      }
      this.addDeviceDialogVisible = true
      if (this.selectedNode != '') {
        this.addDeviceForm.groupName = this.selectedNode.name
        this.addDeviceForm.groupId = this.selectedNode.id
      } else {
        const defaultNode = this.$refs.tree.getNode(this.defaultCheckedKey)
        this.addDeviceForm.groupName = defaultNode.data.name
        this.addDeviceForm.groupId = defaultNode.data.id
      }
    },
    openAddUserDialog() {
      this.addUserDialogVisible = true
      // eslint-disable-next-line no-negated-condition
      if (this.selectedNode != '') {
        this.addUserForm.groupName = this.selectedNode.name
        this.addUserForm.groupId = this.selectedNode.id
      } else {
        const defaultNode = this.$refs.tree.getNode(this.defaultCheckedKey)
        this.addUserForm.groupName = defaultNode.data.name
        this.addUserForm.groupId = defaultNode.data.id
      }
    },
    closeAddDeviceDialog() {
      this.addDeviceDialogVisible = false
      this.addDeviceForm = {}
      this.$refs.addDeviceForm.resetFields()
    },
    closeAddUserDialog() {
      this.addUserDialogVisible = false
      this.addUserForm = {}
      this.$refs.addUserForm.resetFields()
    },
    openDelDeviceDialog() {
      this.delDeviceDialogVisible = true
      this.opearteDeviceIds = this.multipleSelection.map((item) => item.id)
    },
    openAdjustDeviceGroupDialog() {
      this.adjustDeviceGroupDialogVisible = true
      this.opearteDeviceIds = this.multipleSelection.map((item) => item.id)
    },
    addDeviceTreeNodeCilck(data) {
      this.addDeviceForm.groupId = data.id
      this.addDeviceForm.groupName = data.name
      this.$refs.selectUpResId.blur()
    },
    addUserTreeNodeCilck(data) {
      this.addUserForm.groupId = data.id
      this.addUserForm.groupName = data.name
      this.$refs.selectUpResId.blur()
    },
    addDevice() {
      this.$refs['addDeviceForm'].validate((valid) => {
        if (valid) {
          this.addDeviceDialogVisible = false
          let name
          if (this.addDeviceForm.name == '' || this.addDeviceForm.name == undefined) {
            name = this.addDeviceForm.name
          } else {
            name = this.addDeviceForm.name.replace(/\s/g, '')
          }
          const params = {
            mzNumber: this.addDeviceForm.number,
            groupId: this.addDeviceForm.groupId,
            devModel: name
          }
          methodPost2('api/addrbook/insert', params).then((data) => {
            if (data.status) {
              if (this.selectedNode == '') {
                const defaultNode = this.$refs.tree.getNode(this.defaultCheckedKey)
                this.$refs.tree.setCurrentKey(defaultNode.id)
                this.listMemberUnderGroup(defaultNode.data.id)
              } else {
                this.$refs.tree.setCurrentKey(this.selectedNode.id)
                this.listMemberUnderGroup(this.selectedNode.id)
              }
              this.$message({ message: this.$t('opearteSuccess'), type: 'success' })
            } else {
              let error = this.$t('serverError')
              if (data.msg == '已添加，请查看其它分组') {
                error = this.$t('addrbook.return.addDevice.tips_1')
              }
              if (data.msg == '已被其它企业添加') {
                error = this.$t('addrbook.return.addDevice.tips_2')
              }
              if (data.msg == '该号码不存在') {
                error = this.$t('addrbook.return.addDevice.tips_3')
              }
              this.$message.error(error);
            }
          })
        } else {
          return false
        }
      })
    },
    addUser() {
      this.$refs['addUserForm'].validate((valid) => {
        if (valid) {
          this.addUserDialogVisible = false
          const params = {
            mzNumber: this.addUserForm.number,
            groupId: this.addUserForm.groupId,
            devModel: ''
          }
          methodPost2('api/addrbook/insert', params).then((data) => {
            if (data.status) {
              if (this.selectedNode == '') {
                const defaultNode = this.$refs.tree.getNode(this.defaultCheckedKey)
                this.$refs.tree.setCurrentKey(defaultNode.id)
                this.listMemberUnderGroup(defaultNode.data.id)
              } else {
                this.$refs.tree.setCurrentKey(this.selectedNode.id)
                this.listMemberUnderGroup(this.selectedNode.id)
              }
              this.$message({ message: this.$t('opearteSuccess'), type: 'success' })
            } else {
              let error = this.$t('serverError')
              if (data.msg == '已添加，请查看其它分组') {
                error = this.$t('addrbook.return.addDevice.tips_1')
              }
              if (data.msg == '已被其它企业添加') {
                error = this.$t('addrbook.return.addDevice.tips_2')
              }
              if (data.msg == '该号码不存在') {
                error = this.$t('addrbook.return.addDevice.tips_3')
              }
              this.$message.error(error);
            }
          })
        } else {
          return false
        }
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    delDevice() {
      this.delDeviceDialogVisible = false
      const params = {
        // 传递数组的参数形式
        deleteMembersId: `${this.opearteDeviceIds}`
      }
      methodPost('api/addrbook/delete', params).then((data) => {
        if (data.status) {
          if (this.selectedNode != '') {
            this.listMemberUnderGroup(this.selectedNode.id)
          } else {
            const defaultNode = this.$refs.tree.getNode(this.defaultCheckedKey)
            this.listMemberUnderGroup(defaultNode.data.id)
          }
          this.$message({ message: this.$t('opearteSuccess'), type: 'success' })
        } else {
          this.$message.error(this.$t('serverError'));
        }
      })
    },
    adjustDeviceGroupTreeNodeCilck(node) {
      this.adjustGroupId = node.id
    },
    adjustDeviceGroup() {
      this.adjustDeviceGroupDialogVisible = false
      const params = {
        // 传递数组的参数形式
        contactMembersId: `${this.opearteDeviceIds}`,
        adjustAtGroupId: this.adjustGroupId
      }
      // eslint-disable-next-line no-undef
      methodPost('api/addrbook/adjustGroup', params).then((data) => {
        if (data.status) {
          this.$refs.tree.setCurrentKey(this.adjustGroupId)
          this.listMemberUnderGroup(this.adjustGroupId)
          this.$message({ message: this.$t('opearteSuccess'), type: 'success' })
        } else {
          this.$message.error(this.$t('serverError'));
        }
      })
    },
    searchMember() {
      if (this.searchKeyword == '') {
        return
      }
      const params = {
        params: {
          searchKeyword: this.searchKeyword
        }
      }
      methodGet('/api/addrbook/listBook', params).then((data) => {
        if (data.status) {
          this.listBook = data.data
          this.computePage()
          this.$refs.tree.setCurrentKey(data.data[0].groupId)
        }
      })
    },
    openEditDeviceNameDialog(row) {
      this.editDeviceNameDialogVisible = true
      this.editDeviceForm.number = row.mzNumber
      this.editDeviceForm.id = row.id
      if (row.nickName != undefined && row.nickName != null) {
        this.editDeviceForm.name = row.nickName
      }
    },
    closeEditDeviceNameDialog() {
      this.editDeviceNameDialogVisible = false
      this.editDeviceForm = {}
      this.$refs.editDeviceForm.resetFields()
    },
    editDeviceName() {
      this.$refs['editDeviceForm'].validate((valid) => {
        if (valid) {
          this.editDeviceNameDialogVisible = false
          const params = {
            nickName: this.editDeviceForm.name,
            mzNumber: this.editDeviceForm.number,
            id: this.editDeviceForm.id
          }
          methodPost2('api/addrbook/modify', params).then((data) => {
            if (data.status) {
              if (this.selectedNode == '') {
                const defaultNode = this.$refs.tree.getNode(this.defaultCheckedKey)
                this.$refs.tree.setCurrentKey(defaultNode.id)
                this.listMemberUnderGroup(defaultNode.data.id)
              } else {
                this.$refs.tree.setCurrentKey(this.selectedNode.id)
                this.listMemberUnderGroup(this.selectedNode.id)
              }
              this.$message({ message: this.$t('opearteSuccess'), type: 'success' })
            } else {
              this.$message.error(this.$t('serverError'));
            }
          })
        } else {
          return false
        }
      })
    },
    closeNoChildrenDialog() {
      this.noChildrenDialogVisible = false
      this.openAddGroupDialog(this.treeData[0])
    },
    trimLR(val, val1) {
      if (this[val][val1]) {
        this[val][val1] = this[val][val1].replace(/\s+/g, "");
      }
    },
    async addDeviceNumberComplete() {
      if (this.addDeviceForm.name != '' && this.addDeviceForm.name != undefined) {
        return;
      }
      if(this.addDeviceForm.number == undefined || this.addDeviceForm.number == ''){
        return;
      }
      const params = {
        params: {
          number: this.addDeviceForm.number
        }
      }
      const response = await methodGet('/api/account/getNickName', params).then((data) => {
        if (data.status) {
          if (data.data != null && data.data.length > 0) {
            return data.data
          }
        }
      })
      this.addDeviceForm.name = response
      this.$forceUpdate()
    },
    handleChangeDataForm(e) {
      this.$forceUpdate()
    },
  }
}

