// 中文
exports.default = {
  el: {
      colorpicker: {
          confirm: 'Xác nhận',
          clear: 'Thông thoáng'
      },
      datepicker: {
          now: 'Hiện nay',
          today: 'Hôm nay',
          cancel: 'Hủy bỏ',
          clear: 'Thông thoáng',
          confirm: 'Xác nhận',
          selectDate: 'Chọn ngày',
          selectTime: 'Chọn thời gian',
          startDate: 'ngày bắt đầu',
          startTime: 'thời gian bắt đầu',
          endDate: 'ngày cuối',
          endTime: 'thời gian kết thúc',
          prevYear: 'Năm trước',
          nextYear: 'năm sau',
          prevMonth: 'tháng trước',
          nextMonth: 'tháng tiếp theo',
          year: 'Năm',
          month1: 'Tháng Một',
          month2: 'Tháng hai',
          month3: 'Bước đều',
          month4: 'Tháng tư',
          month5: 'Có thể',
          month6: 'Tháng sáu',
          month7: 'Tháng bảy',
          month8: 'Tháng tám',
          month9: 'Tháng 9',
          month10: 'Tháng Mười',
          month11: 'Tháng mười một',
          month12: 'Tháng 12',
          weeks: {
              sun: 'ngày',
              mon: 'một',
              tue: 'hai',
              wed: 'ba',
              thu: 'bốn',
              fri: 'năm',
              sat: 'sáu'
          },
          months: {
              jan: 'Tháng Một',
              feb: 'Tháng hai',
              mar: 'Bước đều',
              apr: 'Tháng tư',
              may: 'Có thể',
              jun: 'Tháng sáu',
              jul: 'Tháng bảy',
              aug: 'Tháng tám',
              sep: 'Tháng 9',
              oct: 'Tháng Mười',
              nov: 'Tháng mười một',
              dec: 'Tháng 12'
          }
      },
      select: {
          loading: 'Đang tải',
          noMatch: 'Không có dữ liệu phù hợp',
          noData: 'Không có thông tin',
          placeholder: 'Vui lòng chọn'
      },
      cascader: {
          noMatch: 'Không có dữ liệu phù hợp',
          loading: 'Đang tải',
          placeholder: 'Vui lòng chọn',
          noData: 'Không có thông tin'
      },
      pagination: {
          goto: 'Đi đến',
          pagesize: 'Mục/trang',
          total: 'Tổng cộng có {total} mặt hàng',
          pageClassifier: 'trang'
      },
      messagebox: {
          title: 'gợi ý',
          confirm: 'quyết tâm',
          cancel: 'Hủy bỏ',
          error: 'Thông tin nhập vào không đáp ứng yêu cầu!'
      },
      upload: {
          deleteTip: 'Nhấn phím xóa để xóa',
          delete: 'xóa bỏ',
          preview: 'Xem ảnh',
          continue: 'Tiếp tục tải lên'
      },
      table: {
          emptyText: 'Chưa có thông tin',
          confirmFilter: 'Lọc',
          resetFilter: 'cài lại',
          clearFilter: 'tất cả',
          sumText: 'Tổng'
      },
      tree: {
          emptyText: 'Chưa có thông tin'
      },
      transfer: {
          noMatch: 'Không có dữ liệu phù hợp',
          noData: 'Không có thông tin',
          titles: [
              'Danh sách 1',
              'Danh sách 2'
          ],
          filterPlaceholder: 'Nhập từ khóa',
          noCheckedFormat: '{total} mục',
          hasCheckedFormat: '{checked}/{total} đã kiểm tra'
      },
      image: {
          error: 'Tải không thành công'
      },
      pageHeader: {
          title: 'trở lại'
      },
      popconfirm: {
          confirmButtonText: 'Đúng',
          cancelButtonText: 'KHÔNG'
      },
      empty: {
          description: 'Chưa có thông tin'
      }
  }
}
