// 中文
exports.default = {
  el: {
      colorpicker: {
          confirm: 'confirme',
          clear: 'Claro'
      },
      datepicker: {
          now: 'agora',
          today: 'hoje',
          cancel: 'Cancelar',
          clear: 'Claro',
          confirm: 'confirme',
          selectDate: 'Selecione a data',
          selectTime: 'Selecione o horário',
          startDate: 'data de início',
          startTime: 'hora de início',
          endDate: 'data final',
          endTime: 'Fim do tempo',
          prevYear: 'Ano anterior',
          nextYear: 'Próximo ano',
          prevMonth: 'mês passado',
          nextMonth: 'próximo mês',
          year: 'Ano',
          month1: 'Janeiro',
          month2: 'Fevereiro',
          month3: 'Marchar',
          month4: 'abril',
          month5: 'Poderia',
          month6: 'Junho',
          month7: 'Julho',
          month8: 'Agosto',
          month9: 'Setembro',
          month10: 'Outubro',
          month11: 'novembro',
          month12: 'dezembro',
          weeks: {
              sun: 'dia',
              mon: 'um',
              tue: 'dois',
              wed: 'três',
              thu: 'Quatro',
              fri: 'cinco',
              sat: 'seis'
          },
          months: {
              jan: 'Janeiro',
              feb: 'Fevereiro',
              mar: 'Marchar',
              apr: 'abril',
              may: 'Poderia',
              jun: 'Junho',
              jul: 'Julho',
              aug: 'Agosto',
              sep: 'Setembro',
              oct: 'Outubro',
              nov: 'novembro',
              dec: 'dezembro'
          }
      },
      select: {
          loading: 'Carregando',
          noMatch: 'Nenhum dado correspondente',
          noData: 'Nenhuma informação',
          placeholder: 'Por favor selecione'
      },
      cascader: {
          noMatch: 'Nenhum dado correspondente',
          loading: 'Carregando',
          placeholder: 'Por favor selecione',
          noData: 'Nenhuma informação'
      },
      pagination: {
          goto: 'Vá para',
          pagesize: 'Item/página',
          total: '{total} itens no total',
          pageClassifier: 'página'
      },
      messagebox: {
          title: 'dica',
          confirm: 'determinar',
          cancel: 'Cancelar',
          error: 'As informações inseridas não atendem aos requisitos!'
      },
      upload: {
          deleteTip: 'Pressione a tecla delete para excluir',
          delete: 'excluir',
          preview: 'Ver fotos',
          continue: 'Continuar a carregar'
      },
      table: {
          emptyText: 'Ainda não há informações',
          confirmFilter: 'Filtro',
          resetFilter: 'reiniciar',
          clearFilter: 'todos',
          sumText: 'Soma'
      },
      tree: {
          emptyText: 'Ainda não há informações'
      },
      transfer: {
          noMatch: 'Nenhum dado correspondente',
          noData: 'Nenhuma informação',
          titles: [
              'Lista 1',
              'Lista 2'
          ],
          filterPlaceholder: 'Insira a palavra-chave',
          noCheckedFormat: '{total} itens',
          hasCheckedFormat: '{checked}/{total} verificado'
      },
      image: {
          error: 'Falha no carregamento'
      },
      pageHeader: {
          title: 'retornar'
      },
      popconfirm: {
          confirmButtonText: 'Sim',
          cancelButtonText: 'não'
      },
      empty: {
          description: 'Ainda não há informações'
      }
  }
}
