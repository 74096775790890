import { myMode } from '@/components/i18n'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// 本地存储-修改
const storageSet = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value))
}

// 本地存储-获取
const storageGet = (key) => {
  return JSON.parse(localStorage.getItem(key))
}

export default new Vuex.Store({
  // 数据源 使用：this.$store.state.xxx
  state: {
    user: {}, // 用户信息
    isGroupMng: false,
    groupID: 0,
    myMode: localStorage.getItem('myMode')
  },
  // 派生数据 使用：this.$store.getters.xxx
  getters: {
    // 获取当前-用户对象
    GET_USER(state) {
      state.user = storageGet('STORE_USER') || {}
      return state.user
    },
    isGroupMng: state => state.isGroupMng,
    getGroupID: state => state.groupID,
    getMyMode: state => state.myMode
  },
  // 更改数据-同步 使用：this.$store.commit('xxx', data)
  mutations: {
    // 保存当前-用户对象
    SET_USER(state, data) {
      state.user = data
      storageSet('STORE_USER', data)
    },
    setGroupMng(state, value) {
      state.isGroupMng = value
    },
    setGroupID(state, value) {
      state.groupID = value
    },
    setMyMode(state, value) {
      state.myMode = value
    }
  },
  // mutations装饰器-异步
  actions: {
  }
})
