// 中文
exports.default = {
  el: {
      colorpicker: {
          confirm: 'Подтверждать',
          clear: 'Прозрачный'
      },
      datepicker: {
          now: 'сейчас',
          today: 'сегодня',
          cancel: 'Отмена',
          clear: 'Прозрачный',
          confirm: 'Подтверждать',
          selectDate: 'Выберите дату',
          selectTime: 'Выберите время',
          startDate: 'Дата начала',
          startTime: 'время начала',
          endDate: 'Дата окончания',
          endTime: 'время окончания',
          prevYear: 'Предыдущий год',
          nextYear: 'Следующий год',
          prevMonth: 'прошлый месяц',
          nextMonth: 'в следующем месяце',
          year: 'Год',
          month1: 'январь',
          month2: 'февраль',
          month3: 'Маршировать',
          month4: 'апрель',
          month5: 'Май',
          month6: 'Июнь',
          month7: 'Июль',
          month8: 'Август',
          month9: 'Сентябрь',
          month10: 'Октябрь',
          month11: 'ноябрь',
          month12: 'Декабрь',
          weeks: {
              sun: 'день',
              mon: 'один',
              tue: 'два',
              wed: 'три',
              thu: 'Четыре',
              fri: 'пять',
              sat: 'шесть'
          },
          months: {
              jan: 'январь',
              feb: 'февраль',
              mar: 'Маршировать',
              apr: 'апрель',
              may: 'Может',
              jun: 'Июнь',
              jul: 'Июль',
              aug: 'Август',
              sep: 'Сентябрь',
              oct: 'Октябрь',
              nov: 'ноябрь',
              dec: 'Декабрь'
          }
      },
      select: {
          loading: 'Загрузка',
          noMatch: 'Нет соответствующих данных',
          noData: 'Нет информации',
          placeholder: 'Пожалуйста выберите'
      },
      cascader: {
          noMatch: 'Нет соответствующих данных',
          loading: 'Загрузка',
          placeholder: 'Пожалуйста выберите',
          noData: 'Нет информации'
      },
      pagination: {
          goto: 'Идти к',
          pagesize: 'Товар/страница',
          total: 'Всего {total} элементов',
          pageClassifier: 'страница'
      },
      messagebox: {
          title: 'намекать',
          confirm: 'определять',
          cancel: 'Отмена',
          error: 'Введенная информация не соответствует требованиям!'
      },
      upload: {
          deleteTip: 'Нажмите клавишу удаления, чтобы удалить',
          delete: 'удалить',
          preview: 'Посмотреть фотографии',
          continue: 'Продолжить загрузку'
      },
      table: {
          emptyText: 'Пока нет информации',
          confirmFilter: 'Фильтр',
          resetFilter: 'перезагрузить',
          clearFilter: 'все',
          sumText: 'Сумма'
      },
      tree: {
          emptyText: 'Пока нет информации'
      },
      transfer: {
          noMatch: 'Нет соответствующих данных',
          noData: 'Нет информации',
          titles: [
              'Список 1',
              'Список 2'
          ],
          filterPlaceholder: 'Введите ключевое слово',
          noCheckedFormat: '{total} предметов',
          hasCheckedFormat: '{checked}/{total} проверено'
      },
      image: {
          error: 'Загрузка не удалась'
      },
      pageHeader: {
          title: 'возвращаться'
      },
      popconfirm: {
          confirmButtonText: 'Да',
          cancelButtonText: 'нет'
      },
      empty: {
          description: 'Пока нет информации'
      }
  }
}
