// 中文
exports.default = {
  el: {
      colorpicker: {
          confirm: 'לְאַשֵׁר',
          clear: 'ברור'
      },
      datepicker: {
          now: 'עַכשָׁיו',
          today: 'היום',
          cancel: 'לְבַטֵל',
          clear: 'ברור',
          confirm: 'לְאַשֵׁר',
          selectDate: 'בחר תאריך',
          selectTime: 'בחר זמן',
          startDate: 'תאריך התחלה',
          startTime: 'שעת התחלה',
          endDate: 'תאריך סיום',
          endTime: 'זמן סיום',
          prevYear: 'שנה שעברה',
          nextYear: 'שנה הבאה',
          prevMonth: 'חודש שעבר',
          nextMonth: 'חודש הבא',
          year: 'שָׁנָה',
          month1: 'יָנוּאָר',
          month2: 'פברואר',
          month3: 'מרץ',
          month4: 'אַפּרִיל',
          month5: 'מאי',
          month6: 'יוני',
          month7: 'יולי',
          month8: 'אוגוסט',
          month9: 'סֶפּטֶמבֶּר',
          month10: 'אוֹקְטוֹבֶּר',
          month11: 'נוֹבֶמבֶּר',
          month12: 'דֵצֶמבֶּר',
          weeks: {
              sun: 'יְוֹם',
              mon: 'אחד',
              tue: 'שתיים',
              wed: 'שְׁלוֹשָׁה',
              thu: 'ארבע',
              fri: 'חָמֵשׁ',
              sat: 'שֵׁשׁ'
          },
          months: {
              jan: 'יָנוּאָר',
              feb: 'פברואר',
              mar: 'מרץ',
              apr: 'אַפּרִיל',
              may: 'מאי',
              jun: 'יוני',
              jul: 'יולי',
              aug: 'אוגוסט',
              sep: 'סֶפּטֶמבֶּר',
              oct: 'אוֹקְטוֹבֶּר',
              nov: 'נוֹבֶמבֶּר',
              dec: 'דֵצֶמבֶּר'
          }
      },
      select: {
          loading: 'טוען',
          noMatch: 'אין נתונים תואמים',
          noData: 'אין מידע',
          placeholder: 'בבקשה תבחר'
      },
      cascader: {
          noMatch: 'אין נתונים תואמים',
          loading: 'טוען',
          placeholder: 'בבקשה תבחר',
          noData: 'אין מידע'
      },
      pagination: {
          goto: 'לך ל',
          pagesize: 'פריט/עמוד',
          total: '{total} פריטים בסך הכל',
          pageClassifier: 'עמוד'
      },
      messagebox: {
          title: 'רֶמֶז',
          confirm: 'לקבוע',
          cancel: 'לְבַטֵל',
          error: 'המידע שהוזן אינו עומד בדרישות!'
      },
      upload: {
          deleteTip: 'לחץ על מקש המחיקה כדי למחוק',
          delete: 'לִמְחוֹק',
          preview: 'צפה בתמונות',
          continue: 'המשך להעלות'
      },
      table: {
          emptyText: 'אין מידע עדיין',
          confirmFilter: 'לְסַנֵן',
          resetFilter: 'אִתחוּל',
          clearFilter: 'את כל',
          sumText: 'סְכוּם'
      },
      tree: {
          emptyText: 'אין מידע עדיין'
      },
      transfer: {
          noMatch: 'אין נתונים תואמים',
          noData: 'אין מידע',
          titles: [
              'רשימה 1',
              'רשימה 2'
          ],
          filterPlaceholder: 'הכנס מילת מפתח',
          noCheckedFormat: '{total} פריטים',
          hasCheckedFormat: '{checked}/{total} מסומן'
      },
      image: {
          error: 'הטעינה נכשלה'
      },
      pageHeader: {
          title: 'לַחֲזוֹר'
      },
      popconfirm: {
          confirmButtonText: 'כן',
          cancelButtonText: 'לא'
      },
      empty: {
          description: 'אין מידע עדיין'
      }
  }
}
