import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { methodGet } from '@/api'

Vue.use(VueI18n)

// 各个国家的key
export const localeKeys = [
  { key: 'zh-CN', name: '简体中文' }, // 中文
  { key: 'zh-TW', name: '繁体中文' },
  { key: 'en', name: 'English' },// 英语
  { key: 'ja', name: '日语' },
  { key: 'ko', name: '韩语' },
  { key: 'th', name: '泰语' },
  { key: 'ar', name: '阿拉伯语' },
  { key: 'bn', name: '孟加拉语' },
  { key: 'de', name: '德语' },
  { key: 'el', name: '希腊语' },
  { key: 'es', name: '西班牙语' },
  { key: 'fa', name: '波斯语' },
  { key: 'fr', name: '法语' },
  { key: 'id', name: '印尼语' },
  { key: 'it', name: '意大利语' },
  { key: 'iw', name: '希伯来语' },
  { key: 'ku', name: '库尔德语' },
  { key: 'nl', name: '荷兰语' },
  { key: 'pl', name: '波兰语' },
  { key: 'pt', name: '葡萄牙语' },
  { key: 'ro', name: '罗马尼亚语' },
  { key: 'ru', name: '俄语' },
  { key: 'tr', name: '土耳其语' },
  { key: 'vi', name: '越南语' }
]

// 各个国家语言包
const messages = {}
for (const item of localeKeys) {
  const key = item.key
  const langObj = require(`./locales/${key}/index.js`).default
  const langElement = require(`./element/${key}`)
  messages[key] = {
    ...langObj,
    ...langElement ? langElement.default : {}
  }
}



const setLang = () => {

  let langCode = navigator.language
  console.log('langCode=========='+langCode)
  if (langCode == 'en-US') {
    langCode = 'en'
  } else {
    if (langCode != 'zh-CN' || langCode != 'zh-TW' || langCode != 'en') {
      langCode = 'zh-CN'
    }
  }

  const params = {
    params: {
      lang: langCode
    }
  }

  if (!localStorage.getItem('change-language')) {
    methodGet('/api/setLang', params).then((data) => {
      console.log(data)
      localStorage.setItem('change-language', langCode)
    })
  }

  console.log(langCode)

  return langCode

}

export const myMode = localStorage.getItem('myMode')
// setLang()

export default new VueI18n({
  locale: localStorage.getItem('change-language') || setLang(),
  messages,
  silentTranslationWarn: true // 忽略翻译警告
})