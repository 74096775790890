import InfoGroup from "@/components/pushInfo/infoGroup.vue";

export default {
  language: '中文',
  serverError: '服务器异常,请联系管理员',
  opearteSuccess: '操作成功',
  agreement: {
    agreement_1: '已阅读并同意',
    agreement_2: '《用户协议》',
    agreement_3: '及',
    agreement_4: '《隐私政策》',
    agreement_5: '请勾选同意协议'
  },
  login: {
    webName: 'ViiTalk企业管理平台',
    webNameSchool: 'ViiTalk学校管理平台',
    website: '官网：',
    tel: '电话：',
    email: '邮箱：',
    title: '企业用户登录',
    titleSchool: '学校用户登录',
    inputPlaceholder: {
      name: '请输入用户名/邮箱',
      pwd: '请输入登录密码'
    },
    button: {
      login: '登录',
      email: '邮箱登录',
      account: '账号登录',
      register: '企业用户注册',
      registerSchool: '学校用户注册',
      pwd: '忘记密码'
    },
    error: {
      uPwd: '用户名或密码错误',
      uLock: '帐号已被锁定',
      uLeave: '您已离职,不能使用系统',
      uRelogin: '您已在其它地方登录!!!',
      uReplaceLogin: '您的账号已在其他地方登录，您被迫下线。如果并非您本人的操作，那么您的密码可能已泄露，请尽快重新登录并更改密码',
      user_null: '请输入用户名',
      pwd_null: '请输入密码'
    }
  },
  personal: {
    companyNumber: '企业号',
    schoolNumber: '学校号',
    administrators: '管理员',
    changeLanguage: '切换语言',
    logout: '退出登录'
  },
  companyMessage: {
    adminPwd: '管理员密码',
    adminUserName: '管理员姓名',
    adminEmail: '管理员邮箱',
    companyFullName: '企业全称 (法律注册名称)',
    companySimpleNameCn: '企业简称 (中文)',
    companySimpleNameEn: '企业简称 (英文)',
    companyWebsite: '企业网址',
    companyNumber: '企业集团号',

    schoolFullName: '学校全称 (法律注册名称)',
    schoolSimpleNameCn: '学校简称 (中文)',
    schoolSimpleNameEn: '学校简称 (英文)',
    schoolWebsite: '学校网址',
    schoolNumber: '学校集团号',

    companyNoAuditJoin: '免审核加入',
    adminExplain: '管理员姓名对所有企业通讯录成员可见',
    companySimpleExplain: '企业简称会显示到Rooms设备首页上，并对所有企业通讯录成员可见',
    button: {
      Edit: '修改'
    },
    dialog: {
      editpwd: {
        title: '修改管理员密码',
        label: {
          oldPwd: '旧密码',
          newPwd: '新密码',
          reNewPwd: '确认新密码'
        },
        inputPlaceholder: {
          oldPwd: '请输入旧密码',
          newPwd: '请输入新密码',
          reNewPwd: '请输入确认新密码'
        }
      },
      editName: {
        title: '修改管理员姓名',
        label: {
          name: '新姓名'
        },
        inputPlaceholder: {
          name: '请输入新姓名'
        }
      },
      editEmail: {
        title: '修改管理员邮箱',
        title2: '您尚未绑定邮箱，请及时绑定邮箱',
        label: {
          email: '新邮箱'
        },
        inputPlaceholder: {
          email: '请输入新邮箱'
        }
      },
      editCompanyFullName: {
        title: '修改企业全称',
        label: {
          name: '新全称'
        },
        inputPlaceholder: {
          name: '请输入新全称'
        }
      },
      editCompanyAbbreviationCn: {
        title: '修改企业简称 (中文)',
        label: {
          name: '新简称'
        },
        inputPlaceholder: {
          name: '请输入新简称'
        }
      },
      editCompanyAbbreviationEn: {
        title: '修改企业简称 (English)'
      },
      editWebsite: {
        title: '修改企业网址',
        label: {
          website: '新网址'
        },
        inputPlaceholder: {
          website: '请输入新网址'
        }
      },
      editNoAuditJoin: {
        title: '修改免申请加入开关',
        radio: {
          open: '打开',
          close: '关闭'
        }
      }
    },
    validator: {
      value_null: '值不能为空',
      oldPwd: {
        null: '请输入旧密码'
      },
      newPwd: {
        length: '请设置6位或以上长度密码'
      },
      reNewPwd: {
        consistent: '两次输入密码不一致!'
      },
      name: {
        length: '名称长度不可大于20位'
      },
      email: {
        rule: '邮箱格式不正确'
      },
      NoAuditJoin: {
        null: '请选择项'
      }
    }
  },
  register: {
    step_1: '用户名和密码',
    step_2: '填写单位信息',
    step_3: '完成',
    head: '企业用户注册',
    usernameError: '会员名为6-20个字符，不包含中文，以字母开头',
    usernameError_exist: '用户名已存在，请修改您的用户名',
    emailError: '邮箱格式不正确',
    emailError_exist: '邮箱已存在',
    passwordError_length: '6-20个字符，只能包含字母数字以及标点符号',
    passwordError_same: '密码不能是相同用户名',
    passwordError: '密码格式不正确',
    rePasswordError: '两次输入的密码不一致，请重新输入',
    companyNameError: '请输入企业全称',
    companyAbbreviationError: '请输入企业简称',
    orgError_exist: '企业已注册，请修改您的企业名',
    button: {
      next: '下一步',
      login: '立即登录'
    },
    inputPlaceholder: {
      username: '用户名',
      email: '邮箱',
      password: '设置密码',
      rePassword: '再次输入密码',
      name: '企业全称（法律注册名称）*',
      simpleNameCN: '企业简称 （中文）*',
      simpleNameEN: '企业简称 （英文）',
      website: '公司网址 （www）'
    },
    success: '您已成功创建企业账号！',
    location: '所在地：',
    Scale: '规模：'
  },
  forgotPwd: {
    head: '邮箱找回',
    resetSuccess: '您已成功重置密码！',
    inputPlaceholder: {
      code: '请输入验证码'
    },
    sendCodeError: '验证码不正确',
    sendCodeError_sended: '验证码已发送请注意查收',
    button: {
      getCode: '获取验证码',
      reGetCode: '重新获取验证码'
    }
  },
  button: {
    ok: '确认',
    cancel: '取消',
    search: '搜索',
    clear: '清空'
  },
  device: {
    button: {
      setContent: '配置推送计划',
      setGroupMng: '设置分组管理员',
      startNow: '立即推送',
      stopNow: '停止推送',
      add: '添加设备',
      search: '搜索',
      adjust: '调整分组',
      del: '删除'
    },
    inputPlaceholder: {
      search: '设备号码/手机号码/ViiTALK号码'
    },
    group: {
      add: '添加分组',
      edit: '编辑分组',
      del: '删除分组'
    },
    table: {
      th_name: '设备名称',
      th_number: '设备号码',
      th_time: '最近登录时间',
      th_brandNumber: '会议门牌号码',
      th_online: '是否在线',
      th_status: '状态',
      th_bindNumber: '绑定大屏号码'
    },
    dialog: {
      addGroupTitle: '请输入分组名称',
      inputPlaceholder: {
        groupName: '请输入分组名'
      },
      delGroupTitle: '删除分组',
      delGroupTips: '确定删除分组吗？',
      editGroupTitle: '编辑分组',
      groupMng: {
        title: '编辑分组管理员 ',
        inputPlaceholder: {
          name: '请输入管理员名称'
        
        },
        label: {
          name: '管理员名称'
          
        }
      },
      addDevice: {
        title: '添加设备',
        inputPlaceholder: {
          name: '请输入设备名称',
          number: '请输入设备号码'
        },
        label: {
          name: '设备名称',
          number: '设备号码',
          group: '请选择设备分组'
        }
      },
      delDevice: {
        title: '删除设备',
        tips: '确定删除设备吗？'
      },
      startNowDevice: {
        title: '立即推送',
        tips: '确定立即推送吗？'
      },
      stopNowDevice: {
        title: '停止推送',
        tips: '确定停止推送吗？'
      },
      adjustTitle: '调整分组',
      editDevice: {
        title: '修改设备名称',
        inputPlaceholder: {
          name: '请输入设备名称'
        }
      },
      noChildren :{
        title: '警告',
        tips: '请先添加分组'
      }
    },
    validator: {
      group: {
        name_null: '分组名不能为空',
        name_length: '不能超过10个字符'
      },
      number_null: '号码不能为空',
      number_rule: '只能为6或8开头10位数',
      name_null: '终端名称不能为空'
    }
  },
  user: {
    button: {
      add: '添加用户'
    },
    inputPlaceholder: {
      search: 'ViiTALK号码'
    },
    table: {
      th_name: '姓名',
      th_number: '手机号码/麦哲号'
    },
    dialog: {
      addUser: {
        title: '添加用户',
        inputPlaceholder: {
          number: '请输入ViiTALK号码'
        },
        label: {
          number: 'ViiTALK号码',
          group: '请选择用户分组'
        }
      },
      delUser: {
        title: '删除用户',
        tips: '确定删除用户吗？'
      }
    },
    validator: {
      number_null: '号码不能为空',
      number_rule: '该号码不存在'
    }
  },
  addrbook: {
    return: {
      delDevice: {
        tips_1: '要删除的分组不存在',
        tips_2: '要删除的分组下有子分组，不能删除',
        tips_3: '要删除的分组下有人员，不能删除'
      },
      addDevice: {
        tips_1: '已添加，请查看其它分组',
        tips_2: '已被其它企业添加',
        tips_3: '该号码不存在'
      }
    }
  },
  audit: {
    option: {
      label: {
        NoPass: '未通过',
        hasRefuse: '已拒绝',
        hasPass: '已通过',
        hasInvalid: '已失效',
        all: '全部'
      }
    },
    button: {
      pass: '通过',
      reject: '拒绝'
    },
    table: {
      th_number: '设备号',
      th_date: '日期',
      th_status: '状态',
      th_source: '来源'
    },
    dialog: {
      passTitle: '通过审核',
      reject: {
        title: '拒绝审核',
        tips: '确定拒绝审核吗？'
      }
    },
    api: {
      req: {
        tips_1: '不能通过审核，选中项来源不同！',
        tips_2: '请选择需要分配的地区'
      },
      return: {
        tips_1: '不存在的设备号',
        tips_2: '您已加入企业',
        tips_3: '已添加，请查看其它分组'
      }
    }
  },
  conferenceLog: {
    inputPlaceholder: '请输入内容',
    table: {
      th: {
        roomId: '房间号',
        state: '房间状态',
        username: '创建者jid',
        createTime: '会议创建时间',
        closeTime: '会议结束时间',
        duration: '持续时长'
      }
    },
    dialog: {
      title: '成员加入/退出房间日志记录',
      table: {
        th: {
          username: '创建者jid',
          joinTime: '加入',
          leaveTime: '离开',
          duration: '持续时长'
        }
      }
    }
  },
  callLog: {
    table: {
      th: {
        callerMzNum: '主叫号码',
        callTime: '拨打时间',
        endTime: '结束时间',
        duration: '持续时长',
        responderMzNum: '被叫号码'
      }
    }
  },
  roomStatus: {
    table: {
      th: {
        roomMessage: '房间基本信息',
        Attendees: '参会者',
        bystander: '旁观者',
        join: '待加入',
        exited: '已退出'
      }
    },
    roomNumber: '会议室号',
    name: '会议室名称',
    dissolution: '解散',
    pattern: '模式',
    confluence: '合流',
    forward: '转发',
    createdTime: '创建时间',
    limit: '发布/总人数限制',
    createMode: '创建模式',
    order: '预约(会议)',
    simple: '普通多人',
    type: '会议类型',
    creator: '创建者',
    host: '主持:',
    windowMode: '窗口模式:',
    WindowMode1: '一大多小',
    WindowMode2: '平铺',
    hide: '是否隐藏:',
    forbidden: '是否禁麦:',
    inorder: '顺序:',
    source: '来源:',
    joinTime: '加入时间:',
    comeOut: '请出',
    openMic: '打开麦克风',
    closeMic: '关闭麦克风',
    leave: '离开:',
    peopleNumber: '人数:'
  },
  menu: {
    index: '首页',
    device_manage: '设备管理',
    user_manage: '用户管理',
    conference_brand: '会议门牌',
    audit_manage: '审核管理',
    orgMessage: '企业信息',
    schoolMessage: '学校信息',
    roomStatus: '进行中的会议',
    p2pCallLog: '视频通话记录',
    conferenceLog: '会议记录',
    authorizedMessage: '授权信息',
    title: '企业管理平台',
    schooltitle: '学校管理平台',
    account_mangae: '账号管理',
    contacts: '集团通讯录',
    records: '通话记录',
    LicenseInformation: '授权信息',
    pushInfo: '信息推送',
    infoGroup: '推送分组管理'
  },
  download: '下载客户端',
  unauthorized: {
    title: 'ViiTALK企业管理平台（未授权）',
    span_1: '检测到您的服务器未授权ViiTALK服务，',
    span_2: '请联系客服获取授权文件。',
    span_3: '（获取授权文件需要提供您的服务器IP地址及Mac地址给客服）',
    upError: '抱歉，您上传的文件错误，请重新上传',
    chooseFile_b: '选择文件',
    upFile_b: '上传授权文件',
    success: {
      span_1: '授权成功',
      span_2: '正在进入企业管理平台...',
      span_3: '(若长时间未进入请刷新浏览器)'
    },
    dialog:{
     title: '请修改管理员密码',
     span: '您的管理员密码尚未修改，请立即修改密码',
     button: '提交'
    }
  },
  lang: {
    'zh-CN': '简体中文',
    'zh-TW': '繁体中文',
    en: 'EngLish',
    ja: '日语',
    ko: '韩语',
    th: '泰语',
    ar: '阿拉伯语',
    bn: '孟加拉语',
    de: '德语',
    el: '希腊语',
    es: '西班牙语',
    fa: '波斯语',
    fr: '法语',
    id: '印尼语',
    it: '意大利语',
    iw: '希伯来语',
    ku: '库尔德语',
    nl: '荷兰语',
    pl: '波兰语',
    pt: '葡萄牙语',
    ro: '罗马尼亚语',
    ru: '俄语',
    tr: '土耳其语',
    vi: '越南语'
  },
  authorized: {
    org: '授权企业',
    userLimit: '用户上限',
    roomMax: '云会议室最大容量',
    roomCountLimit: '在线云会议室数量上限',
    authorizedActiveDate: '私有云授权激活日期',
    authorizedValidDate: '私有云授权有效期至',
    button_up: '更新授权',
    upFileError: '未选择文件'
  },
  index_title: 'ViiTalk 企业管理平台',
  brand :{
    table:{
      th : {
        brandNumber : '门牌号码',
        bindNumber: '绑定大屏号码',
        onlineStatus: '在线状态',
        lastLoginTime: '最后登录时间'
      },
      col :{
        content: {
          onlineStatus :{
            online : '在线',
            offline : '离线'
          }
        }
      }
    }
  }
}