import InfoGroup from "@/components/pushInfo/infoGroup.vue";

export default {
  language: 'Polski',
  serverError: 'Jeśli serwer działa nieprawidłowo, skontaktuj się z administratorem',
  opearteSuccess: 'Pomyślna operacja',
  agreement: {
      agreement_1: 'Czyta i zgadza się',
      agreement_2: 'Umowa z użytkownikiem',
      agreement_3: 'i',
      agreement_4: '\'Polityka prywatności\'',
      agreement_5: 'Zaznacz pole, aby wyrazić zgodę na umowę'
  },
  login: {
      webName: 'Platforma do zarządzania przedsiębiorstwem ViiTALK',
      website: 'Oficjalna strona internetowa:',
      tel: 'Telefon:',
      email: 'Skrzynka pocztowa:',
      title: 'Logowanie użytkownika korporacyjnego',
      inputPlaceholder: {
          name: 'Podaj swoją nazwę użytkownika/adres e-mail',
          pwd: 'Wprowadź hasło'
      },
      button: {
          login: 'login',
          email: 'Logowanie przez e-mail',
          account: 'Logowanie do konta',
          register: 'Rejestracja użytkownika korporacyjnego',
          pwd: 'Nie pamiętam hasła'
      },
      error: {
          uPwd: 'Nieprawidłowa nazwa użytkownika lub hasło',
          uLock: 'Konto zostało zablokowane',
          uLeave: 'Zrezygnowałeś i nie możesz korzystać z systemu',
          uRelogin: 'Jesteś zalogowany w innym miejscu!!',
          uReplaceLogin: 'Twoje konto jest zalogowane w innym miejscu i jesteś zmuszony do przejścia do trybu offline. Jeśli to nie Ty, Twoje hasło mogło zostać naruszone, więc zaloguj się ponownie i zmień hasło tak szybko, jak to możliwe',
          user_null: 'Podaj nazwę użytkownika',
          pwd_null: 'Podaj swoje hasło'
      }
  },
  personal: {
      companyNumber: 'Numer przedsiębiorstwa',
      administrators: 'administrator',
      changeLanguage: 'Przełącz języki',
      logout: 'Wyloguj się'
  },
  companyMessage: {
      adminPwd: 'Hasło administratora',
      adminUserName: 'Nazwa administratora',
      adminEmail: 'Skrzynka pocztowa administratora',
      companyFullName: 'Pełna nazwa firmy (nazwa prawnie zarejestrowana)',
      companySimpleNameCn: 'Skrót',
      companySimpleNameEn: 'Skrót firmy',
      companyWebsite: 'Firmowa strona internetowa',
      companyNumber: 'Numer grupy przedsiębiorstw',
      companyNoAuditJoin: 'Wdrażanie bez audytu',
      adminExplain: 'Nazwa administratora jest widoczna dla wszystkich członków firmowej książki adresowej',
      companySimpleExplain: 'Nazwa firmy jest wyświetlana na stronie głównej urządzenia Rooms i jest widoczna dla wszystkich członków firmowej książki adresowej',
      button: {
          Edit: 'Poprawiać'
      },
      dialog: {
          editpwd: {
              title: 'Zmień hasło administratora',
              label: {
                  oldPwd: 'Stare hasło',
                  newPwd: 'nowe hasło',
                  reNewPwd: 'Potwierdź nowe hasło'
              },
              inputPlaceholder: {
                  oldPwd: 'Proszę wprowadzić stare hasło',
                  newPwd: 'Proszę wprowadzić nowe hasło',
                  reNewPwd: 'Proszę wejść aby potwierdzić nowe hasło'
              }
          },
          editName: {
              title: 'Zmień nazwę administratora',
              label: {
                  name: 'nowe imie'
              },
              inputPlaceholder: {
                  name: 'Proszę wprowadzić nową nazwę'
              }
          },
          editEmail: {
              title: 'Zmodyfikuj adres e-mail administratora',
              title2: 'Nie powiązałeś jeszcze swojego adresu e-mail. Proszę o terminowe powiązanie adresu e-mail.',
              label: {
                  email: 'nowa skrzynka pocztowa'
              },
              inputPlaceholder: {
                  email: 'Proszę wprowadzić nowy adres e-mail'
              }
          },
          editCompanyFullName: {
              title: 'Zmień pełną nazwę firmy',
              label: {
                  name: 'nowe pełne imię i nazwisko'
              },
              inputPlaceholder: {
                  name: 'Proszę wprowadzić nowe imię i nazwisko'
              }
          },
          editCompanyAbbreviationCn: {
              title: 'Zmień skrót firmy (chiński)',
              label: {
                  name: 'Nowy skrót'
              },
              inputPlaceholder: {
                  name: 'Proszę wprowadzić nową, krótką nazwę'
              }
          },
          editCompanyAbbreviationEn: {
              title: 'Zmień skrót firmy (angielski)'
          },
          editWebsite: {
              title: 'Zmodyfikuj adres strony internetowej firmy',
              label: {
                  website: 'Nowy adres URL'
              },
              inputPlaceholder: {
                  website: 'Proszę wprowadzić nowy adres URL'
              }
          },
          editNoAuditJoin: {
              title: 'Zmodyfikuj przełącznik łączenia bez aplikacji',
              radio: {
                  open: 'otwarty',
                  close: 'zamknięcie'
              }
          }
      },
      validator: {
          value_null: 'Wartość nie może być pusta',
          oldPwd: {
              null: 'Proszę wprowadzić stare hasło'
          },
          newPwd: {
              length: 'Ustaw hasło o długości co najmniej 6 znaków'
          },
          reNewPwd: {
              consistent: 'Hasła wpisane dwukrotnie są niespójne!'
          },
          name: {
              length: 'Nazwa nie może być dłuższa niż 20 znaków'
          },
          email: {
              rule: 'Format wiadomości e-mail jest nieprawidłowy'
          },
          NoAuditJoin: {
              null: 'Proszę wybrać element'
          }
      }
  },
  register: {
      step_1: 'Nazwa użytkownika i hasło',
      step_2: 'Wypełnij dane firmy',
      step_3: 'Skończyć',
      head: 'Rejestracja użytkownika korporacyjnego',
      usernameError: 'Nazwa członka musi mieć długość od 6 do 20 znaków (nie wliczając znaków chińskich) i zaczynać się od litery.',
      usernameError_exist: 'Nazwa użytkownika już istnieje. Zmodyfikuj swoją nazwę użytkownika',
      emailError: 'Format wiadomości e-mail jest nieprawidłowy',
      emailError_exist: 'email już istnieje',
      passwordError_length: '6-20 znaków, tylko znaki alfanumeryczne i interpunkcyjne',
      passwordError_same: 'Hasło nie może być tą samą nazwą użytkownika',
      passwordError: 'Format hasła jest nieprawidłowy',
      rePasswordError: 'Hasła wpisane dwukrotnie są niespójne, prosimy o ponowne wprowadzenie',
      companyNameError: 'Proszę wpisać pełną nazwę firmy',
      companyAbbreviationError: 'Proszę wpisać skrót firmy',
      orgError_exist:'Firma została zarejestrowana, zmień nazwę firmy',
      button: {
          next: 'Następny krok',
          login: 'zaloguj się natychmiast'
      },
      inputPlaceholder: {
          username: 'nazwa użytkownika',
          email: 'Poczta',
          password: 'Ustaw hasło',
          rePassword: 'Wprowadź hasło ponownie',
          name: 'Pełna nazwa firmy (nazwa prawnie zarejestrowana)*',
          simpleNameCN: 'Skrót firmy (chiński)*',
          simpleNameEN: 'Skrót firmy (angielski)',
          website: 'Strona firmowa (www)'
      },
      success: 'Pomyślnie utworzyłeś konto firmowe!',
      location: 'Lokalizacja:',
      Scale: 'skala:'
  },
  forgotPwd: {
      head: 'Odzyskiwanie e-maili',
      resetSuccess: 'Pomyślnie zresetowałeś hasło!',
      inputPlaceholder: {
          code: 'proszę wpisać kod weryfikacyjny'
      },
      sendCodeError: 'Nieprawidłowy kod weryfikacyjny',
      sendCodeError_sended: 'Kod weryfikacyjny został wysłany. Sprawdź go',
      button: {
          getCode: 'uzyskaj kod weryfikacyjny',
          reGetCode: 'Uzyskaj ponownie kod weryfikacyjny'
      }
  },
  button: {
      ok: 'potwierdzać',
      cancel: 'Anulować',
      search: 'szukaj',
      clear: 'Jasne'
  },
  device: {
      button: {
          setContent: 'Skonfiguruj harmonogram push',
          setGroupMng: 'Skonfiguruj administratora grupy',
          startNow: 'Naciśnij teraz',
          stopNow: 'Przestań naciskać',
          add: 'Dodaj urządzenie',
          search: 'szukaj',
          adjust: 'Dostosuj grupowanie',
          del: 'usuwać'
      },
      inputPlaceholder: {
          search: 'Numer urządzenia/numer telefonu komórkowego/numer ViiTALK'
      },
      group: {
          add: 'Dodaj grupę',
          edit: 'Edytuj grupę',
          del: 'Usuń grupę'
      },
      table: {
          th_name: 'Nazwa urządzenia',
          th_number: 'Numer urządzenia',
          th_time: 'Ostatni czas logowania',
          th_brandNumber: 'Numer domu konferencyjnego',
          th_online: 'Jest online',
          th_status: 'państwo',
          th_bindNumber: 'Powiąż duży numer ekranu'
      },
      dialog: {
          addGroupTitle: 'Proszę wprowadzić nazwę grupy',
          inputPlaceholder: {
              groupName: 'Proszę wpisać nazwę grupy'
          },
          delGroupTitle: 'Usuń grupę',
          delGroupTips: 'Czy na pewno chcesz usunąć grupę?',
          editGroupTitle: 'Edytuj grupę',
          groupMng: {
              title: 'Edytuj menedżera grupy',
              inputPlaceholder: {
                  name: 'Proszę wprowadzić nazwę administratora'
              },
              label: {
                  name: 'Nazwa administratora'
              }
          },
          addDevice: {
              title: 'Dodaj urządzenie',
              inputPlaceholder: {
                  name: 'Proszę wprowadzić nazwę urządzenia',
                  number: 'Proszę wprowadzić numer urządzenia'
              },
              label: {
                  name: 'Nazwa urządzenia',
                  number: 'Numer urządzenia',
                  group: 'Wybierz grupę urządzeń'
              }
          },
          delDevice: {
              title: 'Odłącz urządzenie',
              tips: 'Czy na pewno chcesz usunąć urządzenie?'
          },
          startNowDevice: {
              title: 'Naciśnij teraz',
              tips: 'Czy na pewno chcesz to teraz wypchnąć?'
          },
          stopNowDevice: {
              title: 'Przestań naciskać',
              tips: 'Czy na pewno chcesz przestać naciskać?'
          },
          adjustTitle: 'Dostosuj grupowanie',
          editDevice: {
              title: 'Zmień nazwę urządzenia',
              inputPlaceholder: {
                  name: 'Proszę wprowadzić nazwę urządzenia'
              }
          },
          noChildren: {
              title: 'ostrzegać',
              tips: 'Najpierw dodaj grupę'
          }
      },
      validator: {
          group: {
              name_null: 'Nazwa grupy nie może być pusta',
              name_length: 'Nie może przekraczać 10 znaków'
          },
          number_null: 'Liczba nie może być pusta',
          number_rule: 'Może składać się tylko z 10 cyfr i zaczynać się od 6 lub 8',
          name_null: 'Nazwa terminala nie może być pusta'
      }
  },
  user: {
      button: {
          add: 'Dodaj użytkownika'
      },
      inputPlaceholder: {
          search: 'Numer ViiTALK'
      },
      table: {
          th_name: 'Nazwa',
          th_number: 'Numer telefonu komórkowego/numer Maizhe'
      },
      dialog: {
          addUser: {
              title: 'Dodaj użytkownika',
              inputPlaceholder: {
                  number: 'Proszę podać numer ViiTALK'
              },
              label: {
                  number: 'Numer ViiTALK',
                  group: 'Proszę wybrać grupę użytkowników'
              }
          },
          delUser: {
              title: 'usuń użytkowników',
              tips: 'Czy na pewno chcesz usunąć użytkownika?'
          }
      },
      validator: {
          number_null: 'Liczba nie może być pusta',
          number_rule: 'Ten numer nie istnieje'
      }
  },
  addrbook: {
      return: {
          delDevice: {
              tips_1: 'Grupa do usunięcia nie istnieje',
              tips_2: 'W ramach grupy znajdują się podgrupy, które mają zostać usunięte i których nie można usunąć.',
              tips_3: 'W grupie znajdują się osoby do usunięcia, których nie można usunąć.'
          },
          addDevice: {
              tips_1: 'Już dodane. Zobacz inne grupy',
              tips_2: 'Został dodany przez inne firmy',
              tips_3: 'Ten numer nie istnieje'
          }
      }
  },
  audit: {
      option: {
          label: {
              NoPass: 'Nie przeszedł',
              hasRefuse: 'odrzucony',
              hasPass: 'przeszedł',
              hasInvalid: 'wygasły',
              all: 'Wszystko'
          }
      },
      button: {
          pass: 'przechodzić',
          reject: 'odrzucić'
      },
      table: {
          th_number: 'Nr urządzenia',
          th_date: 'data',
          th_status: 'państwo',
          th_source: 'źródło'
      },
      dialog: {
          passTitle: 'Zatwierdzony',
          reject: {
              title: 'Odrzuć recenzję',
              tips: 'Czy na pewno chcesz odrzucić recenzję?'
          }
      },
      api: {
          req: {
              tips_1: 'Nie można przejść recenzji, wybrane elementy pochodzą z różnych źródeł!',
              tips_2: 'Proszę wybrać region, który ma zostać przydzielony'
          },
          return: {
              tips_1: 'Numer urządzenia nie istnieje',
              tips_2: 'Dołączyłeś do przedsiębiorstwa',
              tips_3: 'Już dodane. Zobacz inne grupy'
          }
      }
  },
  conferenceLog: {
      inputPlaceholder: 'Proszę wpisać treść',
      table: {
          th: {
              roomId: 'numer pokoju',
              state: 'stan pokoju',
              username: 'Utworzono przezjid',
              createTime: 'Czas utworzenia spotkania',
              closeTime: 'godzina zakończenia spotkania',
              duration: 'Czas trwania'
          }
      },
      dialog: {
          title: 'Rejestrowanie członków dołączających/wychodzących z pokoju',
          table: {
              th: {
                  username: 'Utworzono przezjid',
                  joinTime: 'dołączyć',
                  leaveTime: 'Zostawić',
                  duration: 'Czas trwania'
              }
          }
      }
  },
  callLog: {
      table: {
          th: {
              callerMzNum: 'numer dzwoniący',
              callTime: 'Czas wybierania',
              endTime: 'Koniec czasu',
              duration: 'Czas trwania',
              responderMzNum: 'wywołany numer'
          }
      }
  },
  roomStatus: {
      table: {
          th: {
              roomMessage: 'Podstawowe informacje o pokojach',
              Attendees: 'uczestnik',
              bystander: 'widz',
              join: 'Do dodania',
              exited: 'Wyszedł'
          }
      },
      roomNumber: 'Numer sali konferencyjnej',
      name: 'Nazwa sali konferencyjnej',
      dissolution: 'Rozpuszczać',
      pattern: 'Model',
      confluence: 'zbieg',
      forward: 'Do przodu',
      createdTime: 'czas utworzenia',
      limit: 'Limit wydania/całkowitej liczby osób',
      createMode: 'utwórz wzór',
      order: 'Spotkanie na spotkaniu)',
      simple: 'Zwykły multi',
      type: 'Typ spotkania',
      creator: 'twórca',
      host: 'Gospodarz:',
      windowMode: 'Tryb okienkowy:',
      WindowMode1: 'Jeden duży i wiele małych',
      WindowMode2: 'Płytka',
      hide: 'Czy ukryć:',
      forbidden: 'Czy zakazać pszenicy:',
      inorder: 'zamówienie:',
      source: 'źródło:',
      joinTime: 'Czas dołączenia:',
      comeOut: 'Proszę wyjdź',
      openMic: 'włącz mikrofon',
      closeMic: 'Wyłącz mikrofon',
      leave: 'Zostawić:',
      peopleNumber: 'Liczba ludzi:'
  },
  menu: {
      index: 'pierwsza strona',
      device_manage: 'Zarządzanie urządzeniami',
      user_manage: 'Zarządzanie użytkownikami',
      conference_brand: 'Numer drzwi konferencji',
      audit_manage: 'Zarządzanie audytem',
      orgMessage: 'Informacje o firmie',
      roomStatus: 'trwające spotkanie',
      p2pCallLog: 'Historia rozmów wideo',
      conferenceLog: 'protokoły spotkań',
      authorizedMessage: 'Informacje o autoryzacji',
      title: 'Platforma zarządzania przedsiębiorstwem',
      account_mangae: 'Zarządzanie kontem',
      contacts: 'Grupowa książka adresowa',
      records: 'zapisy rozmów',
      LicenseInformation: 'Informacje o autoryzacji',
      pushInfo: 'Nacisk informacyjny',
      infoGroup: 'Zarządzanie grupą push'
  },
  download: 'pobieraj aplikacje',
  unauthorized: {
      title: 'Platforma zarządzania przedsiębiorstwem ViiTALK (nieautoryzowana)',
      span_1: 'Wykryto, że Twój serwer nie autoryzuje usługi ViiTALK,',
      span_2: 'Skontaktuj się z obsługą klienta, aby uzyskać plik autoryzacyjny.',
      span_3: '(Aby uzyskać plik autoryzacyjny, musisz podać adres IP swojego serwera i adres Mac obsłudze klienta)',
      upError: 'Przepraszamy, przesłany plik jest nieprawidłowy. Prześlij go ponownie',
      chooseFile_b: 'Wybierz dokument',
      upFile_b: 'Prześlij plik autoryzacyjny',
      success: {
          span_1: 'Autoryzacja pomyślna',
          span_2: 'Wejście na platformę zarządzania przedsiębiorstwem...',
          span_3: '(Jeśli nie wchodziłeś przez dłuższy czas, proszę odśwież przeglądarkę)'
      },
      dialog: {
          title: 'Zmień hasło administratora',
          span: 'Twoje hasło administratora nie zostało jeszcze zmienione. Zmień je natychmiast',
          button: 'składać'
      }
  },
  lang: {
    'zh-CN': 'Chiński uproszczony',
    'zh-TW': 'tradycyjne chińskie',
    en: 'Język angielski',
    ja: 'język japoński',
    ko: 'koreański',
    th: 'tajski',
    ar: 'arabski',
    bn: 'bengalski',
    de: 'Niemiecki',
    el: 'grecki',
    es: 'hiszpański',
    fa: 'perski',
    fr: 'Francuski',
    id: 'indonezyjski',
    it: 'Włoski',
    iw: 'hebrajski',
    ku: 'kurdyjski',
    nl: 'Holenderski',
    pl: 'Polski',
    pt: 'portugalski',
    ro: 'rumuński',
    ru: 'Rosyjski',
    tr: 'turecki',
    vi: 'wietnamski'
  },
  authorized: {
      org: 'Autoryzowane przedsiębiorstwo',
      userLimit: 'Limit użytkowników',
      roomMax: 'Maksymalna pojemność sali konferencyjnej w chmurze',
      roomCountLimit: 'Maksymalna liczba sal konferencyjnych online w chmurze',
      authorizedActiveDate: 'Data aktywacji licencji Private Cloud',
      authorizedValidDate: 'Licencja na chmurę prywatną jest ważna do',
      button_up: 'Zaktualizuj autoryzację',
      upFileError: 'Nie wybrano pliku'
  },
  index_title: 'Platforma zarządzania przedsiębiorstwem ViiTalk',
  brand: {
    table: {
        th: {
            brandNumber: 'numer domu',
            bindNumber: 'Powiąż duży numer ekranu',
            onlineStatus: 'status aktywności',
            lastLoginTime: 'czas ostatniego logowania'
        },
        col: {
            content: {
                onlineStatus: {
                    online: 'online',
                    offline: 'Nieaktywny'
                }
            }
        }
    }
  }
}