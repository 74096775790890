import Vue from 'vue'
import VueRouter from 'vue-router'
import user_manage from '../components/addrbook/user_manage.vue'
import device_manage from '../components/addrbook/device_manage.vue'
import audit_manage from '../components/addrbook/audit_manage.vue'
import orgMessage from '../components/account/orgMessage.vue'
import roomStatus from '../components/call_log/roomStatus.vue'
import p2pCallLog from '../components/call_log/p2pCallLog.vue'
import conferenceLog from '../components/call_log/conferenceLog.vue'
import authorizedMessage from '../components/account/authorizedMessage.vue'
import infoGroup from '../components/pushInfo/infoGroup.vue'
import conference_brand from '../components/addrbook/conference_brand.vue'
import i18n from '../components/i18n'

Vue.use(VueRouter)

const pages = [
  {
    path: '/index',
    redirect: '/page/account/company',
    component: (resolve) => require(['@/components/index.vue'], resolve),
    children:
      [
        {
          path: '/page/addrbook/device_manage',
          // eslint-disable-next-line camelcase
          component: device_manage,
          meta: {
            parentTitle: i18n.t('menu.contacts'),
            title: i18n.t('menu.device_manage')
          }
        },
        {
          path: '/page/addrbook/user_manage',
          // eslint-disable-next-line camelcase
          component: user_manage,
          meta: {
            parentTitle: i18n.t('menu.contacts'),
            title: i18n.t('menu.user_manage')
          }
        },
        {
          path: '/page/addrbook/conference_brand',
          component: conference_brand,
          meta: {
            parentTitle: i18n.t('menu.contacts'),
            title: i18n.t('menu.conference_brand')
          }
        },
        {
          path: '/page/addrbook/audit_manage',
          component: audit_manage,
          meta: {
            parentTitle: i18n.t('menu.contacts'),
            title: i18n.t('menu.audit_manage')
          }
        },
        {
          path: '/page/account/company',
          component: orgMessage,
          meta: {
            parentTitle: i18n.t('menu.account_mangae'),
            title: i18n.t('menu.orgMessage')
          }
        },
        {
          path: '/page/call_log/roomStatus',
          component: roomStatus,
          meta: {
            parentTitle: i18n.t('menu.records'),
            title: i18n.t('menu.roomStatus')
          }
        },
        {
          path: '/page/call_log/p2pCallLog',
          component: p2pCallLog,
          meta: {
            parentTitle: i18n.t('menu.records'),
            title: i18n.t('menu.p2pCallLog')
          }
        },
        {
          path: '/page/call_log/conferenceLog',
          component: conferenceLog,
          meta: {
            parentTitle: i18n.t('menu.records'),
            title: i18n.t('menu.conferenceLog')
          }
        },
        {
          path: '/page/pushInfo/infoGroup',
          component: infoGroup,
          meta: {
            parentTitle: i18n.t('menu.pushInfo'),
            title: i18n.t('menu.infoGroup')
          }
        },
        {
          path: '/page/account/privateAuthInfo',
          component: authorizedMessage,
          meta: {
            parentTitle: i18n.t('menu.LicenseInformation'),
            title: i18n.t('menu.authorizedMessage')
          }
        }
      ]
  },
  {
    path: '/login',
    component: (resolve) => require(['@/components/Login.vue'], resolve)
  },
  {
    path: '/register',
    component: (resolve) => require(['@/components/register.vue'], resolve)
  },
  {
    path: '/forgotPwd',
    component: (resolve) => require(['@/components/forgotPwd.vue'], resolve)
  },
  {
    path: '/test',
    component: (resolve) => require(['@/components/test.vue'], resolve)
  },
  {
    path: '/unauthorized',
    component: (resolve) => require(['@/components/unauthorized.vue'], resolve)
  }
]

const router = new VueRouter({
  routes: [
    // 页面路由
    ...pages
  ]
})

// 路由跳转前
router.beforeEach((to, from, next) => {
  document.title = i18n.t('index_title')
  // 可用于拦截导航并执行一些操作，例如验证用户身份、权限控制等。
  const token = localStorage.getItem('token')
  console.log(to.path)
  if (token && to.path == '/login' || to.path == '/') {
    return next('/index')
  }
  if (to.path == '/login' ||
    to.path == '/unauthorized' ||
    to.path == '/register' ||
    to.path == '/forgotPwd') {
    return next()
  }
  if (!token) {
    return next('/login')
  }
  next()
})

// 路由跳转后
router.afterEach((to, from) => {
  window.scrollTo(0, 0) // 每次路由改变滚动条都回到顶部
})

export default router