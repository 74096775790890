<template>
    <div>

        <el-card class="top-card card-format">

            <div class="first-div">
                <div class="div-bottom">
                    <div class="label-format">{{ $t('authorized.org') }}</div>
                    <div class="button-front-position val-format">
                        {{ company }}
                    </div>
                </div>

                <el-button @click="uploadFile" class="button-format button-position">
                    <span class="button-font">{{ $t('authorized.button_up') }}</span>
                    <input ref="uploadInput" style="display: none;" type="file" @change="handleFileUpload"/>
                </el-button>


                <!-- <div class="button-format button-position">
                    <button >选择文件</button>
                    <input ref="uploadInput" style="display: none;" type="file"/>
                </div> -->


                <el-divider class="custom-divider"></el-divider>
            </div>

            <div class="second-div">
                <div class="div-bottom">
                    <div class="label-format">{{ $t('authorized.userLimit') }}</div>
                    <div class="val-format val-position">
                        {{ userLimit }}
                    </div>
                </div>
                <el-divider class="custom-divider"></el-divider>
            </div>

            <div class="second-div">
                <div class="div-bottom">
                    <div class="label-format">{{ $t('authorized.roomMax') }}</div>
                    <div class="val-format val-position">
                        {{ roomMax }}
                    </div>
                </div>
                <el-divider class="custom-divider"></el-divider>
            </div>


            <div class="second-div">
                <div class="div-bottom">
                    <div class="label-format">{{ $t('authorized.roomCountLimit') }}</div>
                    <div class="val-format val-position">
                        {{ roomCountLimit }}
                    </div>
                </div>
                <el-divider class="custom-divider"></el-divider>
            </div>


            <div class="second-div">
                <div class="div-bottom">
                    <div class="label-format">{{ $t('authorized.authorizedActiveDate') }}</div>
                    <div class="val-format val-position">
                        {{ authorizedActiveDate }}
                    </div>
                </div>
                <el-divider class="custom-divider"></el-divider>
            </div>


            <div class="second-div">
                <div class="div-bottom">
                    <div class="label-format">{{ $t('authorized.authorizedValidDate') }}</div>
                    <div class="val-format val-position">
                        {{ authorizedValidDate }}
                    </div>
                </div>
                <el-divider class="custom-divider"></el-divider>
            </div>

        </el-card>

    </div>
</template>

<script>
import { authorized } from '../js/authorized.js'
export default {
    mixins: [authorized]
}
</script>

<style src="../../assets/css/authorized.css" scoped></style>
