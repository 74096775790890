import InfoGroup from "@/components/pushInfo/infoGroup.vue";

export default {
  language: 'Русский',
  serverError: 'Неисправность сервера, обратитесь к администратору',
  opearteSuccess: 'Успешная операция',
  agreement: {
      agreement_1: 'Прочитал и согласен',
      agreement_2: '\'Соглашение\'',
      agreement_3: 'и',
      agreement_4: '\'Политика\'',
      agreement_5: 'чтобы согласиться с соглашением'
  },
  login: {
      webName: 'Платформа управления предприятием ViiTALK',
      webNameSchool: 'Платформа управления предприятием ViiTALK',
      website: 'Официальный веб-сайт:',
      tel: 'Телефон:',
      email: 'Почта:',
      title: 'Регистрация пользователей',
      titleSchool: 'Регистрация пользователей',
      inputPlaceholder: {
          name: 'Пожалуйста, введите имя пользователя/адрес электронной почты',
          pwd: 'Пожалуйста введите ваш пароль'
      },
      button: {
          login: 'Авторизоваться',
          email: 'Электронная почта Войти',
          account: 'Логин аккаунта',
          register: 'Регистрация пользователей',
          registerSchool: 'Регистрация пользователей',
          pwd: 'забыть пароль'
      },
      error: {
          uPwd: 'неправильное имя пользователя или пароль',
          uLock: 'Аккаунт заблокирован',
          uLeave: 'Вы уволились и не можете пользоваться системой',
          uRelogin: 'Вы уже авторизованы где-то еще!!!',
          uReplaceLogin: 'В вашу учетную запись был выполнен вход в другую учетную запись, и вы были вынуждены выйти из системы. Если это не ваша вина, возможно, ваш пароль был украден. Пожалуйста, войдите в систему еще раз и смените пароль как можно скорее.',
          user_null: 'пожалуйста, введите имя пользователя',
          pwd_null: 'Пожалуйста введите пароль'
      }
  },
  personal: {
      companyNumber: 'Предприятие',
      schoolNumber: 'Предприятие',
      administrators: 'администратор',
      changeLanguage: 'переключить язык',
      logout: 'выход'
  },
  companyMessage: {
      adminPwd: 'Пароль администратора',
      adminUserName: 'Имя администратора',
      adminEmail: 'электронная почта администратора',
      companyFullName: 'Полное название компании',
      companySimpleNameCn: 'Аббревиатура компании',
      companySimpleNameEn: 'Аббревиатура компании (англ.)',
      companyWebsite: 'Корпоративный сайт',
      companyNumber: 'Номер группы предприятий',

      schoolFullName: 'Полное название компании',
      schoolSimpleNameCn: 'Аббревиатура компании',
      schoolSimpleNameEn: 'Аббревиатура компании (англ.)',
      schoolWebsite: 'Корпоративный сайт',
      schoolNumber: 'Номер группы предприятий',

      companyNoAuditJoin: 'Присоединяйтесь без отзыва',
      adminExplain: 'Имя администратора видно всем участникам корпоративной адресной книги.',
      companySimpleExplain: 'Аббревиатура компании будет отображаться на главной странице устройства Rooms и будет видна всем участникам адресной книги компании.',
      button: {
          Edit: 'Исправлять'
      },
      dialog: {
          editpwd: {
              title: 'Изменить пароль администратора',
              label: {
                  oldPwd: 'Старый пароль',
                  newPwd: 'Новый пароль',
                  reNewPwd: 'Подтвердите новый пароль'
              },
              inputPlaceholder: {
                  oldPwd: 'Пожалуйста, введите старый пароль',
                  newPwd: 'Пожалуйста, введите новый пароль',
                  reNewPwd: 'Пожалуйста, введите, чтобы подтвердить новый пароль'
              }
          },
          editName: {
              title: 'Изменить имя администратора',
              label: {
                  name: 'новое имя'
              },
              inputPlaceholder: {
                  name: 'Пожалуйста, введите новое имя'
              }
          },
          editEmail: {
              title: 'Изменить адрес электронной почты администратора',
              title2: 'Вы еще не привязали свой адрес электронной почты. Пожалуйста, привяжите свой адрес электронной почты вовремя.',
              label: {
                  email: 'новый почтовый ящик'
              },
              inputPlaceholder: {
                  email: 'Пожалуйста, введите новый адрес электронной почты'
              }
          },
          editCompanyFullName: {
              title: 'Изменить полное название компании',
              label: {
                  name: 'новое полное имя'
              },
              inputPlaceholder: {
                  name: 'Пожалуйста, введите новое полное имя'
              }
          },
          editCompanyAbbreviationCn: {
              title: 'Изменить аббревиатуру компании (китайский)',
              label: {
                  name: 'Новая аббревиатура'
              },
              inputPlaceholder: {
                  name: 'Пожалуйста, введите новое короткое имя'
              }
          },
          editCompanyAbbreviationEn: {
              title: 'Изменить аббревиатуру компании (на английском языке)'
          },
          editWebsite: {
              title: 'Изменить адрес корпоративного сайта',
              label: {
                  website: 'Новый URL-адрес'
              },
              inputPlaceholder: {
                  website: 'Пожалуйста, введите новый URL'
              }
          },
          editNoAuditJoin: {
              title: 'Измените переключатель присоединения без приложения',
              radio: {
                  open: 'Открыть',
                  close: 'закрытие'
              }
          }
      },
      validator: {
          value_null: 'Значение не может быть пустым',
          oldPwd: {
              null: 'Пожалуйста, введите старый пароль'
          },
          newPwd: {
              length: 'Пожалуйста, установите пароль длиной 6 или более символов.'
          },
          reNewPwd: {
              consistent: 'Пароли, введенные дважды, не совпадают!'
          },
          name: {
              length: 'Имя не может быть длиннее 20 символов.'
          },
          email: {
              rule: 'Формат электронной почты неправильный'
          },
          NoAuditJoin: {
              null: 'Пожалуйста, выберите элемент'
          }
      }
  },
  register: {
      step_1: 'Пароль',
      step_2: 'Информаци',
      step_3: 'Заканчивать',
      head: 'Регистрация пользователей',
      usernameError: '6–20 символов',
      usernameError_exist: 'Имя пользователя уже существует, пожалуйста, измените свое имя пользователя',
      emailError: 'Формат электронной почты неправильный',
      emailError_exist: 'Эл. адрес уже существует',
      passwordError_length: '6-20 символов, только буквы, цифры и знаки препинания.',
      passwordError_same: 'Пароль не может совпадать с именем пользователя',
      passwordError: 'Неверный формат пароля',
      rePasswordError: 'Пароли, введенные дважды, не совпадают, пожалуйста',
      companyNameError: 'Пожалуйста, введите полное название компании',
      companyAbbreviationError: 'Пожалуйста, введите аббревиатуру компании',
      orgError_exist:'Компания зарегистрирована, пожалуйста, измените название компании',
      button: {
          next: 'Следующий шаг',
          login: 'войти в систему немедленно'
      },
      inputPlaceholder: {
          username: 'имя пользователя',
          email: 'Почта',
          password: 'Установка пароля',
          rePassword: 'Введите пароль еще раз',
          name: 'Полное название компании',
          simpleNameCN: 'Аббревиатура компании (китайский)',
          simpleNameEN: 'Аббревиатура компании (англ.)',
          website: 'Сайт компании (www)'
      },
      success: 'Вы успешно создали бизнес-аккаунт!',
      location: 'расположение:',
      Scale: 'шкала:'
  },
  forgotPwd: {
      head: 'Получение электронной почты',
      resetSuccess: 'Вы успешно сбросили пароль!',
      inputPlaceholder: {
          code: 'пожалуйста, введите код подтверждения'
      },
      sendCodeError: 'Неправильный код подтверждения',
      sendCodeError_sended: 'Код подтверждения отправлен, проверьте его.',
      button: {
          getCode: 'получить код подтверждения',
          reGetCode: 'Получите код подтверждения еще раз'
      }
  },
  button: {
      ok: 'подтверждать',
      cancel: 'Отмена',
      search: 'поиск',
      clear: 'Прозрачный'
  },
  device: {
      button: {
          setContent: 'Настроить график отправки',
          setGroupMng: 'Настройка администратора группы',
          startNow: 'Нажмите сейчас',
          stopNow: 'Хватит давить',
          add: 'Добавить устройство',
          search: 'поиск',
          adjust: 'Настроить группировку',
          del: 'удалить'
      },
      inputPlaceholder: {
          search: 'Номер устройства/номер мобильного телефона/номер ViiTALK'
      },
      group: {
          add: 'Добавить группу',
          edit: 'Изменить группу',
          del: 'Удалить группу'
      },
      table: {
          th_name: 'Имя устройства',
          th_number: 'Номер устройства',
          th_time: 'Время последнего входа в систему',
          th_brandNumber: 'Номер конференц-зала',
          th_online: 'В сети',
          th_status: 'состояние',
          th_bindNumber: 'Привязать большой номер экрана'
      },
      dialog: {
          addGroupTitle: 'Пожалуйста, введите название группы',
          inputPlaceholder: {
              groupName: 'Пожалуйста, введите название группы'
          },
          delGroupTitle: 'Удалить группу',
          delGroupTips: 'Вы уверены, что хотите удалить группу?',
          editGroupTitle: 'Изменить группу',
          groupMng: {
              title: 'Редактировать менеджера группы',
              inputPlaceholder: {
                  name: 'Пожалуйста, введите имя администратора'
              },
              label: {
                  name: 'Имя администратора'
              }
          },
          addDevice: {
              title: 'Добавить устройство',
              inputPlaceholder: {
                  name: 'Пожалуйста, введите имя устройства',
                  number: 'Пожалуйста, введите номер устройства'
              },
              label: {
                  name: 'Имя устройства',
                  number: 'Номер устройства',
                  group: 'Пожалуйста, выберите группу устройств'
              }
          },
          delDevice: {
              title: 'Удалить устройство',
              tips: 'Вы уверены, что хотите удалить устройство?'
          },
          startNowDevice: {
              title: 'Нажмите сейчас',
              tips: 'Вы уверены, что хотите нажать на него сейчас?'
          },
          stopNowDevice: {
              title: 'Хватит давить',
              tips: 'Вы уверены, что хотите прекратить давить?'
          },
          adjustTitle: 'Настроить группировку',
          editDevice: {
              title: 'Изменить имя устройства',
              inputPlaceholder: {
                  name: 'Пожалуйста, введите имя устройства'
              }
          },
          noChildren: {
              title: 'предупреждать',
              tips: 'Пожалуйста, сначала добавьте группу'
          }
      },
      validator: {
          group: {
              name_null: 'Название группы не может быть пустым',
              name_length: 'Не может превышать 10 символов.'
          },
          number_null: 'Номер не может быть пустым',
          number_rule: 'Может состоять только из 10 цифр, начиная с 6 или 8.',
          name_null: 'Имя терминала не может быть пустым'
      }
  },
  user: {
      button: {
          add: 'Добавить пользователя'
      },
      inputPlaceholder: {
          search: 'Номер ViiTALK'
      },
      table: {
          th_name: 'Имя',
          th_number: 'Номер мобильного телефона/номер Майже'
      },
      dialog: {
          addUser: {
              title: 'Добавить пользователя',
              inputPlaceholder: {
                  number: 'Пожалуйста, введите номер ViiTALK'
              },
              label: {
                  number: 'Номер ViiTALK',
                  group: 'Пожалуйста, выберите группу пользователей'
              }
          },
          delUser: {
              title: 'удалять пользователей',
              tips: 'Вы уверены, что хотите удалить пользователя?'
          }
      },
      validator: {
          number_null: 'Номер не может быть пустым',
          number_rule: 'Этого номера не существует'
      }
  },
  addrbook: {
      return: {
          delDevice: {
              tips_1: 'Группа, которую необходимо удалить, не существует.',
              tips_2: 'В группе есть подгруппы, которые необходимо удалить, но удалить невозможно.',
              tips_3: 'В группе есть люди, которых нужно удалить, но удалить невозможно.'
          },
          addDevice: {
              tips_1: 'Уже добавлено, пожалуйста, просмотрите другие группы',
              tips_2: 'Добавлено другими компаниями',
              tips_3: 'Этого номера не существует'
          }
      }
  },
  audit: {
      option: {
          label: {
              NoPass: 'Не прошел',
              hasRefuse: 'отклоненный',
              hasPass: 'прошедший',
              hasInvalid: 'истекший',
              all: 'все'
          }
      },
      button: {
          pass: 'проходить',
          reject: 'отклонять'
      },
      table: {
          th_number: 'Номер устройства',
          th_date: 'дата',
          th_status: 'состояние',
          th_source: 'источник'
      },
      dialog: {
          passTitle: 'Одобренный',
          reject: {
              title: 'Отклонить отзыв',
              tips: 'Вы уверены, что хотите отклонить отзыв?'
          }
      },
      api: {
          req: {
              tips_1: 'Невозможно пройти проверку, выбранные элементы взяты из разных источников!',
              tips_2: 'Пожалуйста, выберите регион для выделения'
          },
          return: {
              tips_1: 'Номер устройства не существует',
              tips_2: 'Вы присоединились к предприятию',
              tips_3: 'Уже добавлено, пожалуйста, просмотрите другие группы'
          }
      }
  },
  conferenceLog: {
      inputPlaceholder: 'Пожалуйста, введите содержание',
      table: {
          th: {
              roomId: 'номер комнаты',
              state: 'статус комнаты',
              username: 'Создано: jid',
              createTime: 'Время создания встречи',
              closeTime: 'время окончания встречи',
              duration: 'Продолжительность'
          }
      },
      dialog: {
          title: 'Регистрация входа/выхода участника в комнату',
          table: {
              th: {
                  username: 'Создано: jid',
                  joinTime: 'Присоединяйся',
                  leaveTime: 'оставлять',
                  duration: 'Продолжительность'
              }
          }
      }
  },
  callLog: {
      table: {
          th: {
              callerMzNum: 'вызывающий номер',
              callTime: 'Время набора',
              endTime: 'Время окончания',
              duration: 'Продолжительность',
              responderMzNum: 'вызываемый номер'
          }
      }
  },
  roomStatus: {
      table: {
          th: {
              roomMessage: 'Основная информация о номере',
              Attendees: 'участник',
              bystander: 'свидетель',
              join: 'Быть добавленным',
              exited: 'Вышел'
          }
      },
      roomNumber: 'Номер конференц-зала',
      name: 'Название конференц-зала',
      dissolution: 'Расформировать',
      pattern: 'модель',
      confluence: 'слияние',
      forward: 'Вперед',
      createdTime: 'время создания',
      limit: 'Освобождение/ограничение общего количества людей',
      createMode: 'создать узор',
      order: 'Назначение (встреча)',
      simple: 'Обычный мультиплеер',
      type: 'Тип встречи',
      creator: 'создатель',
      host: 'Хозяин:',
      windowMode: 'Оконный режим:',
      WindowMode1: 'Один большой и много маленьких',
      WindowMode2: 'Плитка',
      hide: 'Скрывать ли:',
      forbidden: 'Запретить ли пшеницу:',
      inorder: 'заказ:',
      source: 'источник:',
      joinTime: 'Время присоединения:',
      comeOut: 'Пожалуйста, выйди',
      openMic: 'включи микрофон',
      closeMic: 'Выключить микрофон',
      leave: 'оставлять:',
      peopleNumber: 'Число людей:'
  },
  menu: {
      index: 'титульная страница',
      device_manage: 'Управление устройствами',
      user_manage: 'Управление пользователями',
      conference_brand: 'Номер двери конференции',
      audit_manage: 'Управление аудитом',
      orgMessage: 'Корпоративная информация',
      schoolMessage: 'Корпоративная информация',
      roomStatus: 'продолжающаяся встреча',
      p2pCallLog: 'История видеозвонков',
      conferenceLog: 'протокол заседаний',
      authorizedMessage: 'Информация об авторизации',
      title: 'Платформа управления предприятием',
      schooltitle: 'Платформа управления предприятием',
      account_mangae: 'Управление аккаунтом',
      contacts: 'Групповая адресная книга',
      records: 'записи звонков',
      LicenseInformation: 'Информация об авторизации',
      pushInfo: 'Информационный толчок',
      infoGroup: 'Управление push-группами'
  },
  download: 'загружать приложения',
  unauthorized: {
      title: 'Платформа управления предприятием ViiTALK (несанкционированная)',
      span_1: 'Было обнаружено, что ваш сервер не авторизует службу ViiTALK,',
      span_2: 'Пожалуйста, свяжитесь со службой поддержки клиентов, чтобы получить файл авторизации.',
      span_3: '(Чтобы получить файл авторизации, вам необходимо предоставить IP-адрес и Mac-адрес вашего сервера в службу поддержки клиентов)',
      upError: 'Извините, вы загрузили неправильный файл, пожалуйста, загрузите еще раз.',
      chooseFile_b: 'Выберите документ',
      upFile_b: 'Загрузить файл авторизации',
      success: {
          span_1: 'Авторизация прошла успешно',
          span_2: 'Вход в платформу управления предприятием...',
          span_3: '(Если вы давно не заходили, обновите браузер)'
      },
      dialog: {
          title: 'Пожалуйста, измените пароль администратора',
          span: 'Ваш пароль администратора еще не был изменен, пожалуйста, немедленно измените свой пароль.',
          button: 'представлять на рассмотрение'
      }
  },
  lang: {
    'zh-CN': 'Упрощенный китайский',
    'zh-TW': 'традиционный китайский',
    en: 'Английский',
    ja: 'Японский',
    ko: 'Корейский',
    th: 'тайский',
    ar: 'арабский',
    bn: 'Бенгальский',
    de: 'Немецкий',
    el: 'греческий',
    es: 'испанский',
    fa: 'персидский',
    fr: 'Французский',
    id: 'индонезийский',
    it: 'итальянский',
    iw: 'иврит',
    ku: 'курдский',
    nl: 'Голландский',
    pl: 'Польский',
    pt: 'португальский',
    ro: 'румынский',
    ru: 'Русский',
    tr: 'Турецкий',
    vi: 'вьетнамский'
  },
  authorized: {
      org: 'Авторизованное предприятие',
      userLimit: 'Лимит пользователя',
      roomMax: 'Максимальная вместимость облачного конференц-зала',
      roomCountLimit: 'Максимальное количество облачных онлайн-конференц-залов',
      authorizedActiveDate: 'Дата активации лицензии на частное облако',
      authorizedValidDate: 'Лицензия на частное облако действительна до',
      button_up: 'Обновить авторизацию',
      upFileError: 'Файл не выбран'
  },
  index_title: 'Платформа управления предприятием ViiTalk',
  brand: {
    table: {
        th: {
            brandNumber: 'номер дома',
            bindNumber: 'Привязать большой номер экрана',
            onlineStatus: 'онлайн-статус',
            lastLoginTime: 'время последнего входа в систему'
        },
        col: {
            content: {
                onlineStatus: {
                    online: 'В сети',
                    offline: 'Не в сети'
                }
            }
        }
    }
  }
}