// 中文
exports.default = {
  el: {
      colorpicker: {
          confirm: 'A confirma',
          clear: 'clar'
      },
      datepicker: {
          now: 'acum',
          today: 'astăzi',
          cancel: 'Anulare',
          clear: 'clar',
          confirm: 'A confirma',
          selectDate: 'Selectați data',
          selectTime: 'Selectați ora',
          startDate: 'data de început',
          startTime: 'timpul de începere',
          endDate: 'Data de încheiere',
          endTime: 'Sfârșitul timpului',
          prevYear: 'Anul precedent',
          nextYear: 'anul urmator',
          prevMonth: 'luna trecuta',
          nextMonth: 'luna viitoare',
          year: 'An',
          month1: 'ianuarie',
          month2: 'februarie',
          month3: 'Martie',
          month4: 'Aprilie',
          month5: 'Mai',
          month6: 'iunie',
          month7: 'iulie',
          month8: 'August',
          month9: 'Septembrie',
          month10: 'octombrie',
          month11: 'noiembrie',
          month12: 'decembrie',
          weeks: {
              sun: 'zi',
              mon: 'unu',
              tue: 'Două',
              wed: 'Trei',
              thu: 'Patru',
              fri: 'cinci',
              sat: 'şase'
          },
          months: {
              jan: 'ianuarie',
              feb: 'februarie',
              mar: 'Martie',
              apr: 'Aprilie',
              may: 'Mai',
              jun: 'iunie',
              jul: 'iulie',
              aug: 'August',
              sep: 'Septembrie',
              oct: 'octombrie',
              nov: 'noiembrie',
              dec: 'decembrie'
          }
      },
      select: {
          loading: 'Se încarcă',
          noMatch: 'Nu există date care se potrivesc',
          noData: 'Fara informatii',
          placeholder: 'Te rog selecteaza'
      },
      cascader: {
          noMatch: 'Nu există date care se potrivesc',
          loading: 'Se încarcă',
          placeholder: 'Te rog selecteaza',
          noData: 'Fara informatii'
      },
      pagination: {
          goto: 'Mergi la',
          pagesize: 'Articol/pagina',
          total: '{total} articole în total',
          pageClassifier: 'pagină'
      },
      messagebox: {
          title: 'aluzie',
          confirm: 'a determina',
          cancel: 'Anulare',
          error: 'Informațiile introduse nu îndeplinesc cerințele!'
      },
      upload: {
          deleteTip: 'Apăsați tasta ștergere pentru a șterge',
          delete: 'șterge',
          preview: 'Vizualizați imagini',
          continue: 'Continuați să încărcați'
      },
      table: {
          emptyText: 'Nicio informație încă',
          confirmFilter: 'Filtru',
          resetFilter: 'resetare',
          clearFilter: 'toate',
          sumText: 'Sumă'
      },
      tree: {
          emptyText: 'Nicio informație încă'
      },
      transfer: {
          noMatch: 'Nu există date care se potrivesc',
          noData: 'Fara informatii',
          titles: [
              'Lista 1',
              'Lista 2'
          ],
          filterPlaceholder: 'Introduceți cuvântul cheie',
          noCheckedFormat: '{total} articole',
          hasCheckedFormat: '{checked}/{total} verificat'
      },
      image: {
          error: 'Încărcarea nu a reușit'
      },
      pageHeader: {
          title: 'întoarcere'
      },
      popconfirm: {
          confirmButtonText: 'da',
          cancelButtonText: 'Nu'
      },
      empty: {
          description: 'Nicio informație încă'
      }
  }
}
