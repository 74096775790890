// 中文
exports.default = {
  el: {
      colorpicker: {
          confirm: 'Confirmer',
          clear: 'Clair'
      },
      datepicker: {
          now: 'maintenant',
          today: 'aujourd hui',
          cancel: 'Annuler',
          clear: 'Clair',
          confirm: 'Confirmer',
          selectDate: 'Sélectionner une date',
          selectTime: 'Sélectionnez l heure',
          startDate: 'date de début',
          startTime: 'Heure de début',
          endDate: 'date de fin',
          endTime: 'heure de fin',
          prevYear: 'Année précédente',
          nextYear: 'l année prochaine',
          prevMonth: 'le mois dernier',
          nextMonth: 'le mois prochain',
          year: 'Année',
          month1: 'Janvier',
          month2: 'Février',
          month3: 'Mars',
          month4: 'Avril',
          month5: 'Peut',
          month6: 'Juin',
          month7: 'Juillet',
          month8: 'Août',
          month9: 'Septembre',
          month10: 'Octobre',
          month11: 'Novembre',
          month12: 'Décembre',
          weeks: {
              sun: 'jour',
              mon: 'un',
              tue: 'deux',
              wed: 'trois',
              thu: 'Quatre',
              fri: 'cinq',
              sat: 'six'
          },
          months: {
              jan: 'Janvier',
              feb: 'Février',
              mar: 'Mars',
              apr: 'Avril',
              may: 'Peut',
              jun: 'Juin',
              jul: 'Juillet',
              aug: 'Août',
              sep: 'Septembre',
              oct: 'Octobre',
              nov: 'Novembre',
              dec: 'Décembre'
          }
      },
      select: {
          loading: 'Chargement',
          noMatch: 'Aucune donnée correspondante',
          noData: 'Aucune information',
          placeholder: 'Veuillez sélectionner'
      },
      cascader: {
          noMatch: 'Aucune donnée correspondante',
          loading: 'Chargement',
          placeholder: 'Veuillez sélectionner',
          noData: 'Aucune information'
      },
      pagination: {
          goto: 'Aller à',
          pagesize: 'Article/page',
          total: '{total} articles au total',
          pageClassifier: 'page'
      },
      messagebox: {
          title: 'indice',
          confirm: 'déterminer',
          cancel: 'Annuler',
          error: 'Les informations saisies ne répondent pas aux exigences !'
      },
      upload: {
          deleteTip: 'Appuyez sur la touche Supprimer pour supprimer',
          delete: 'supprimer',
          preview: 'Voir les photos',
          continue: 'Continuer à télécharger'
      },
      table: {
          emptyText: 'Aucune information pour l instant',
          confirmFilter: 'Filtre',
          resetFilter: 'réinitialiser',
          clearFilter: 'tous',
          sumText: 'Somme'
      },
      tree: {
          emptyText: 'Aucune information pour l instant'
      },
      transfer: {
          noMatch: 'Aucune donnée correspondante',
          noData: 'Aucune information',
          titles: [
              'Liste 1',
              'Liste 2'
          ],
          filterPlaceholder: 'Entrez un mot-clef',
          noCheckedFormat: '{total} articles',
          hasCheckedFormat: '{checked}/{total} vérifié'
      },
      image: {
          error: 'Le chargement a échoué'
      },
      pageHeader: {
          title: 'retour'
      },
      popconfirm: {
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non'
      },
      empty: {
          description: 'Aucune information pour l instant'
      }
  }
}
