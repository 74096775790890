// 中文
exports.default = {
  el: {
      colorpicker: {
          confirm: 'Tesdîqkirin',
          clear: 'Zelal'
      },
      datepicker: {
          now: 'niha',
          today: 'îro',
          cancel: 'Bişûndekirin',
          clear: 'Zelal',
          confirm: 'Tesdîqkirin',
          selectDate: 'Dîrokê hilbijêre',
          selectTime: 'Dema hilbijêre',
          startDate: 'roja destpêkê',
          startTime: 'dema destpêkirinê',
          endDate: 'roja dawîyê',
          endTime: 'dema dawî',
          prevYear: 'Sala berê',
          nextYear: 'Sala were',
          prevMonth: 'meha borî',
          nextMonth: 'Meha bê',
          year: 'Sal',
          month1: 'Rêbendan',
          month2: 'Reşemî',
          month3: 'Adar',
          month4: 'Avrêl',
          month5: 'Gulan',
          month6: 'Pûşper',
          month7: 'Tîrmeh',
          month8: 'Tebax',
          month9: 'Îlon',
          month10: 'Cotmeh',
          month11: 'Mijdar',
          month12: 'Berfanbar',
          weeks: {
              sun: 'roj',
              mon: 'yek',
              tue: 'du',
              wed: 'sê',
              thu: 'Çar',
              fri: 'pênc',
              sat: 'şeş'
          },
          months: {
              jan: 'Rêbendan',
              feb: 'Reşemî',
              mar: 'Adar',
              apr: 'Avrêl',
              may: 'Gulan',
              jun: 'Pûşper',
              jul: 'Tîrmeh',
              aug: 'Tebax',
              sep: 'Îlon',
              oct: 'Cotmeh',
              nov: 'Mijdar',
              dec: 'Berfanbar'
          }
      },
      select: {
          loading: 'Barkirin',
          noMatch: 'Daneyên lihevhatî tune',
          noData: 'Agahî tune',
          placeholder: 'Ji kerema xwe hilbijêre'
      },
      cascader: {
          noMatch: 'Daneyên lihevhatî tune',
          loading: 'Barkirin',
          placeholder: 'Ji kerema xwe hilbijêre',
          noData: 'Agahî tune'
      },
      pagination: {
          goto: 'Biçe',
          pagesize: 'Babet / rûpel',
          total: 'Bi tevayî {total} hêman',
          pageClassifier: 'rûpel'
      },
      messagebox: {
          title: 'geşî',
          confirm: 'binavkirin',
          cancel: 'Bişûndekirin',
          error: 'Agahdariya têketinê hewcedariyên xwe nagire!'
      },
      upload: {
          deleteTip: 'Ji bo jêbirinê bişkojka jêbirinê bikirtînin',
          delete: 'jêbirin',
          preview: 'Wêneyan bibînin',
          continue: 'Berdewamkirina barkirinê'
      },
      table: {
          emptyText: 'Hîn agahî tune',
          confirmFilter: 'Parzûn',
          resetFilter: 'reset',
          clearFilter: 'gişt',
          sumText: 'Giş'
      },
      tree: {
          emptyText: 'Hîn agahî tune'
      },
      transfer: {
          noMatch: 'Daneyên lihevhatî tune',
          noData: 'Agahî tune',
          titles: [
              'Lîsteya 1',
              'Lîsteya 2'
          ],
          filterPlaceholder: 'Keyword binivîse',
          noCheckedFormat: '{total} hêman',
          hasCheckedFormat: '{checked}/{total} hate kontrol kirin'
      },
      image: {
          error: 'Barkirin têk çû'
      },
      pageHeader: {
          title: 'vegerr'
      },
      popconfirm: {
          confirmButtonText: 'Erê',
          cancelButtonText: 'na'
      },
      empty: {
          description: 'Hîn agahî tune'
      }
  }
}
