import { methodGet, methodPost } from '@/api'

export const audit = {
    data() {
        return {
            listAudit: [],
            dataCount: 0,
            pageSize: 10,
            pageIndex: 1,
            tableData: [],
            treeData: [],
            auditStatus: '',
            tableSelection: [],
            filteredTreeData: [],
            auditIds: [],
            passAuditDialogVisible: false,
            refuseAuditDialogVisible: false,
            gtype: 2,
            groupId: '',
            defaultProps: {
                children: 'children',
                label: 'name'
            }
        }
    },
    created() {
        this.loadListAudit()
    },
    methods: {
        formatStatus(status) {
            if (status == 0) {
                return this.$t('audit.option.label.NoPass')
            }

            if (status == 1) {
                return this.$t('audit.option.label.hasRefuse')
            }

            if (status == 2) {
                return this.$t('audit.option.label.hasPass')
            }

            return this.$t('audit.option.label.hasInvalid')
        },
        handleSelectChange() {
            const params = {
                auditStatus: this.auditStatus
            }
            methodPost('/api/audit/getAuditListByStatus', params).then((data) => {
                if (data.status) {
                    this.listAudit = data.data
                    this.computePage()
                }
            })
        },
        toggleSelection(rows) {
            if (rows) {
                rows.forEach((row) => {
                    this.$refs.multipleTable.toggleRowSelection(row)
                })
            } else {
                this.$refs.multipleTable.clearSelection()
            }
        },
        handleSelectionChange(val) {
            this.tableSelection = val
        },
        loadListAudit() {
            methodGet('/api/audit/listAudit').then((data) => {
                this.listAudit = data.data
                this.computePage()
            })
        },
        computePage() {
            if (this.listAudit == null) {
                this.dataCount = 0
                this.tableData = []
            } else {
                this.dataCount = this.listAudit.length
                let start = (this.pageIndex - 1) * this.pageSize
                start = start > this.dataCount ? this.dataCount : start
                let end = Number(this.pageSize) + start
                end = end > this.dataCount ? this.dataCount : end
                this.tableData = this.listAudit.slice(start, end)
            }
        },
        handleSizeChange(newSize) {
            this.pageSize = newSize
            this.computePage()
        },
        handleCurrentChange(newPage) {
            this.pageIndex = newPage
            this.computePage()
        },
        handleApprove() {

            if (this.tableSelection.length >= 2) {
                // 获取选中项的来源
                const sourceSet = new Set()
                this.tableSelection.forEach((row) => sourceSet.add(row.auditSource))

                // 如果来源数量大于1，则不能通过审核
                if (sourceSet.size > 1) {
                    this.$message.error(this.$t('audit.api.req.tips_1'))
                    return
                }

            }

            if (!this.tableSelection[0].auditSource) {
                this.gtype = 1
            }

            this.loadGroupTree(this.gtype)

        },
        handleReject() {
            this.refuseAuditDialogVisible = true
            this.auditIds = this.tableSelection.map((item) => item.auditId)
        },
        loadGroupTree(gtype) {
            methodGet(`api/group/loadGroupTree/${gtype}`).then((data) => {
                console.log(data)
                if (this.gtype == 1) {
                    this.treeData = data.data.children
                } else {
                    let arr = []
                    arr[0] = data.data
                    this.treeData = arr
                }
                this.passAuditDialogVisible = true
                this.auditIds = this.tableSelection.map((item) => item.auditId)
            })
        },
        treeNodeCilck(node) {
            this.groupId = node.id
        },
        closePassAuditDialog() {
            this.passAuditDialogVisible = false
            this.gtype = 2
            this.groupId = ''
        },
        passAudit() {
            this.passAuditDialogVisible = false
            if (this.groupId == '') {
                this.$message(this.$t('audit.api.req.tips_2'))
                return
            }

            const params = {
                auditIds: `${this.auditIds}`,
                groupId: this.groupId
            }

            methodPost('api/addrbook/passVerification', params).then((data) => {
                if (data.status) {
                    this.loadListAudit()
                    this.$message({ message: this.$t('opearteSuccess'), type: 'success' })
                } else {
                    let error = this.$t('serverError')
                    if (data.msg == "不存在的设备号" || data.msg == "该号码不存在") {
                        error = this.$t('audit.api.return.tips_1')
                    }
                    if (data.msg == "您已加入企业" || data.msg == "已被其它企业添加") {
                        error = this.$t('audit.api.return.tips_2')
                    }
                    if (data.msg == "已添加，请查看其它分组") {
                        error = this.$t('audit.api.return.tips_3')
                    }
                    this.$message.error(error);
                }
            })
        },
        refuseAudit() {
            this.refuseAuditDialogVisible = false
            const params = {
                auditIds: `${this.auditIds}`,
                auditStatus: 1
            }
            methodPost('api/audit/updateAuditStatus', params).then((data) => {
                if (data.status) {
                    this.loadListAudit()
                    this.$message({ message: this.$t('opearteSuccess'), type: 'success' })
                } else {
                    this.$message.error(this.$t('serverError'));
                }
            })
        },
        readerFun({ row, rowIndex }) {
            if (row.auditStatus != 0) {
                return { opacity: "0.3" }
            } else {
                return {};
            }
        }
    }
}