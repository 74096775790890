import InfoGroup from "@/components/pushInfo/infoGroup.vue";

export default {
  language: 'Nederlands',
  serverError: 'Serverafwijking, neem contact op met de beheerder',
  opearteSuccess: 'Succesvolle operatie',
  agreement: {
      agreement_1: 'Heb het gelezen en ga akkoord',
      agreement_2: '\'Gebruikersovereenkomst\'',
      agreement_3: 'En',
      agreement_4: '\'Privacybeleid\'',
      agreement_5: 'Vink het vakje aan om akkoord te gaan met de overeenkomst'
  },
  login: {
      webName: 'ViiTALK ondernemingsbeheerplatform',
      website: 'Officiële website:',
      tel: 'Telefoon:',
      email: 'Mail:',
      title: 'Inloggen voor zakelijke gebruikers',
      inputPlaceholder: {
          name: 'Voer gebruikersnaam/e-mailadres in',
          pwd: 'Voer uw wachtwoord in'
      },
      button: {
          login: 'Log in',
          email: 'E-mail inloggen',
          account: 'Account login',
          register: 'Enterprise-gebruikersregistratie',
          pwd: 'vergeet het wachtwoord'
      },
      error: {
          uPwd: 'verkeerde gebruikersnaam of wachtwoord',
          uLock: 'Account is vergrendeld',
          uLeave: 'U heeft ontslag genomen en kunt het systeem niet gebruiken',
          uRelogin: 'Je bent al ergens anders ingelogd!!!',
          uReplaceLogin: 'Uw account is elders ingelogd en u bent gedwongen uit te loggen. Als het niet uw schuld was, is uw wachtwoord mogelijk gelekt. Meld u opnieuw aan en wijzig uw wachtwoord zo snel mogelijk.',
          user_null: 'Voer alstublieft de gebruikersnaam in',
          pwd_null: 'Voer wachtwoord in alstublieft'
      }
  },
  personal: {
      companyNumber: 'Onderneming',
      administrators: 'beheerder',
      changeLanguage: 'van taal wisselen',
      logout: 'afmelden'
  },
  companyMessage: {
      adminPwd: 'Administrator wachtwoord',
      adminUserName: 'Naam beheerder',
      adminEmail: 'E-mailadres van beheerder',
      companyFullName: 'Volledige naam van het bedrijf (wettelijk geregistreerde naam)',
      companySimpleNameCn: 'Bedrijfsafkorting',
      companySimpleNameEn: 'Bedrijfsafkorting (Engels)',
      companyWebsite: 'Bedrijfswebsite',
      companyNumber: 'Ondernemingsgroepsnummer',
      companyNoAuditJoin: 'Meld u aan zonder beoordeling',
      adminExplain: 'De naam van de beheerder is zichtbaar voor alle leden van het zakelijke adresboek',
      companySimpleExplain: 'De bedrijfsafkorting wordt weergegeven op de startpagina van het Rooms-apparaat en is zichtbaar voor alle leden van het bedrijfsadresboek.',
      button: {
          Edit: 'Herzien'
      },
      dialog: {
          editpwd: {
              title: 'Wijzig het beheerderswachtwoord',
              label: {
                  oldPwd: 'Oud Wachtwoord',
                  newPwd: 'nieuw paswoord',
                  reNewPwd: 'Bevestig het nieuwe wachtwoord'
              },
              inputPlaceholder: {
                  oldPwd: 'Voer het oude wachtwoord in',
                  newPwd: 'Voer een nieuw wachtwoord in',
                  reNewPwd: 'Voer in om het nieuwe wachtwoord te bevestigen'
              }
          },
          editName: {
              title: 'Wijzig de beheerdersnaam',
              label: {
                  name: 'nieuwe naam'
              },
              inputPlaceholder: {
                  name: 'Voer een nieuwe naam in'
              }
          },
          editEmail: {
              title: 'Wijzig het e-mailadres van de beheerder',
              title2: 'U heeft uw e-mailadres nog niet gebonden. Bind uw e-mailadres tijdig in.',
              label: {
                  email: 'nieuwe brievenbus'
              },
              inputPlaceholder: {
                  email: 'Voer een nieuw e-mailadres in'
              }
          },
          editCompanyFullName: {
              title: 'Wijzig de volledige naam van het bedrijf',
              label: {
                  name: 'nieuwe volledige naam'
              },
              inputPlaceholder: {
                  name: 'Voer een nieuwe volledige naam in'
              }
          },
          editCompanyAbbreviationCn: {
              title: 'Bedrijfsafkorting wijzigen (Chinees)',
              label: {
                  name: 'Nieuwe afkorting'
              },
              inputPlaceholder: {
                  name: 'Voer een nieuwe korte naam in'
              }
          },
          editCompanyAbbreviationEn: {
              title: 'Bedrijfsafkorting aanpassen (Engels)'
          },
          editWebsite: {
              title: 'Wijzig het adres van de bedrijfswebsite',
              label: {
                  website: 'Nieuwe URL'
              },
              inputPlaceholder: {
                  website: 'Voer een nieuwe URL in'
              }
          },
          editNoAuditJoin: {
              title: 'Wijzig de toepassingsvrije verbindingsschakelaar',
              radio: {
                  open: 'Open',
                  close: 'sluiting'
              }
          }
      },
      validator: {
          value_null: 'Waarde kan niet leeg zijn',
          oldPwd: {
              null: 'Voer het oude wachtwoord in'
          },
          newPwd: {
              length: 'Stel een wachtwoord in van 6 tekens of meer'
          },
          reNewPwd: {
              consistent: 'De tweemaal ingevoerde wachtwoorden zijn inconsistent!'
          },
          name: {
              length: 'De naam mag niet langer zijn dan 20 tekens'
          },
          email: {
              rule: 'Het e-mailformaat is onjuist'
          },
          NoAuditJoin: {
              null: 'Selecteer een artikel'
          }
      }
  },
  register: {
      step_1: 'gebruikersnaam en wachtwoord',
      step_2: 'Bedrijfsgegevens invullen',
      step_3: 'Finish',
      head: 'Enterprise-gebruikersregistratie',
      usernameError: 'De lidnaam moet 6 tot 20 tekens lang zijn, Chinese karakters niet meegerekend, en beginnen met een letter.',
      usernameError_exist: 'De gebruikersnaam bestaat al, wijzig uw gebruikersnaam',
      emailError: 'Het e-mailformaat is onjuist',
      emailError_exist: 'E-mail bestaat al',
      passwordError_length: '6-20 tekens, alleen alfanumerieke tekens en leestekens',
      passwordError_same: 'Wachtwoord mag niet dezelfde gebruikersnaam zijn',
      passwordError: 'Het wachtwoordformaat is onjuist',
      rePasswordError: 'De tweemaal ingevoerde wachtwoorden zijn inconsistent. Voer deze opnieuw in',
      companyNameError: 'Vul de volledige naam van het bedrijf in',
      companyAbbreviationError: 'Voer de afkorting van het bedrijf in',
      orgError_exist:'Het bedrijf is geregistreerd, wijzig uw bedrijfsnaam',
      button: {
          next: 'Volgende stap',
          login: 'log direct in'
      },
      inputPlaceholder: {
          username: 'gebruikersnaam',
          email: 'Mail',
          password: 'stel een wachtwoord in',
          rePassword: 'Vul het wachtwoord opnieuw in',
          name: 'Volledige naam van het bedrijf (wettelijk geregistreerde naam)*',
          simpleNameCN: 'Bedrijfsafkorting (Chinees)*',
          simpleNameEN: 'Bedrijfsafkorting (Engels)',
          website: 'Bedrijfswebsite (www)'
      },
      success: 'Je hebt succesvol een zakelijk account aangemaakt!',
      location: 'plaats:',
      Scale: 'schaal:'
  },
  forgotPwd: {
      head: 'E-mail ophalen',
      resetSuccess: 'U heeft uw wachtwoord succesvol opnieuw ingesteld!',
      inputPlaceholder: {
          code: 'voer de verificatiecode in'
      },
      sendCodeError: 'Onjuiste verificatiecode',
      sendCodeError_sended: 'De verificatiecode is verzonden. Controleer deze',
      button: {
          getCode: 'verificatiecode verkrijgen',
          reGetCode: 'Ontvang opnieuw de verificatiecode'
      }
  },
  button: {
      ok: 'bevestigen',
      cancel: 'Annuleren',
      search: 'zoekopdracht',
      clear: 'Duidelijk'
  },
  device: {
      button: {
          setContent: 'Push-schema configureren',
          setGroupMng: 'Groepsbeheerder instellen',
          startNow: 'Duw nu',
          stopNow: 'Stop met duwen',
          add: 'Voeg toestel toe',
          search: 'zoekopdracht',
          adjust: 'Groepering aanpassen',
          del: 'verwijderen'
      },
      inputPlaceholder: {
          search: 'Apparaatnummer/Mobiel telefoonnummer/ViiTALK-nummer'
      },
      group: {
          add: 'Groep toevoegen',
          edit: 'Groep bewerken',
          del: 'Groep verwijderen'
      },
      table: {
          th_name: 'Toestelnaam',
          th_number: 'Apparaatnummer',
          th_time: 'Laatste inlogtijd',
          th_brandNumber: 'Huisnummer van de conferentie',
          th_online: 'Is online',
          th_status: 'staat',
          th_bindNumber: 'Bind groot schermnummer'
      },
      dialog: {
          addGroupTitle: 'Voer een groepsnaam in',
          inputPlaceholder: {
              groupName: 'Voer de groepsnaam in'
          },
          delGroupTitle: 'Groep verwijderen',
          delGroupTips: 'Weet u zeker dat u de groep wilt verwijderen?',
          editGroupTitle: 'Groep bewerken',
          groupMng: {
              title: 'Groepsbeheerder bewerken',
              inputPlaceholder: {
                  name: 'Voer de beheerdersnaam in'
              },
              label: {
                  name: 'Naam beheerder'
              }
          },
          addDevice: {
              title: 'Voeg toestel toe',
              inputPlaceholder: {
                  name: 'Voer de apparaatnaam in',
                  number: 'Voer het apparaatnummer in'
              },
              label: {
                  name: 'Toestelnaam',
                  number: 'Apparaatnummer',
                  group: 'Selecteer een apparaatgroep'
              }
          },
          delDevice: {
              title: 'Verwijder apparaat',
              tips: 'Weet u zeker dat u het apparaat wilt verwijderen?'
          },
          startNowDevice: {
              title: 'Duw nu',
              tips: 'Weet je zeker dat je het nu wilt pushen?'
          },
          stopNowDevice: {
              title: 'Stop met duwen',
              tips: 'Weet je zeker dat je wilt stoppen met duwen?'
          },
          adjustTitle: 'Groepering aanpassen',
          editDevice: {
              title: 'Wijzig de apparaatnaam',
              inputPlaceholder: {
                  name: 'Voer de apparaatnaam in'
              }
          },
          noChildren: {
              title: 'waarschuwen',
              tips: 'Voeg eerst een groep toe'
          }
      },
      validator: {
          group: {
              name_null: 'Groepsnaam mag niet leeg zijn',
              name_length: 'Mag niet langer zijn dan 10 tekens'
          },
          number_null: 'Nummer mag niet leeg zijn',
          number_rule: 'Kan slechts uit 10 cijfers bestaan, beginnend met 6 of 8',
          name_null: 'Terminalnaam mag niet leeg zijn'
      }
  },
  user: {
      button: {
          add: 'Voeg gebruiker toe'
      },
      inputPlaceholder: {
          search: 'ViiTALK-nummer'
      },
      table: {
          th_name: 'Naam',
          th_number: 'Mobiel telefoonnummer/Maizhe-nummer'
      },
      dialog: {
          addUser: {
              title: 'Voeg gebruiker toe',
              inputPlaceholder: {
                  number: 'Voer het ViiTALK-nummer in'
              },
              label: {
                  number: 'ViiTALK-nummer',
                  group: 'Selecteer een gebruikersgroep'
              }
          },
          delUser: {
              title: 'gebruikers verwijderen',
              tips: 'Weet u zeker dat u de gebruiker wilt verwijderen?'
          }
      },
      validator: {
          number_null: 'Nummer mag niet leeg zijn',
          number_rule: 'Dit nummer bestaat niet'
      }
  },
  addrbook: {
      return: {
          delDevice: {
              tips_1: 'De te verwijderen groep bestaat niet',
              tips_2: 'Er zijn subgroepen onder de groep die moeten worden verwijderd en die kunnen niet worden verwijderd.',
              tips_3: 'Er zijn mensen onder de groep die moeten worden verwijderd en die kunnen niet worden verwijderd.'
          },
          addDevice: {
              tips_1: 'Al toegevoegd, bekijk andere groepen',
              tips_2: 'Is toegevoegd door andere bedrijven',
              tips_3: 'Dit nummer bestaat niet'
          }
      }
  },
  audit: {
      option: {
          label: {
              NoPass: 'Is niet geslaagt',
              hasRefuse: 'afgewezen',
              hasPass: 'geslaagd',
              hasInvalid: 'verlopen',
              all: 'alle'
          }
      },
      button: {
          pass: 'doorgang ',
          reject: 'afwijzen'
      },
      table: {
          th_number: 'Apparaatnr',
          th_date: 'datum',
          th_status: 'staat',
          th_source: 'bron'
      },
      dialog: {
          passTitle: 'Goedgekeurd',
          reject: {
              title: 'Beoordeling weigeren',
              tips: 'Weet u zeker dat u de recensie wilt afwijzen?'
          }
      },
      api: {
          req: {
              tips_1: 'Kan niet door de beoordeling komen, de geselecteerde items zijn afkomstig uit verschillende bronnen!',
              tips_2: 'Selecteer de regio die u wilt toewijzen'
          },
          return: {
              tips_1: 'Apparaatnummer bestaat niet',
              tips_2: 'U bent bij de onderneming aangesloten',
              tips_3: 'Al toegevoegd, bekijk andere groepen'
          }
      }
  },
  conferenceLog: {
      inputPlaceholder: 'Voer de inhoud in',
      table: {
          th: {
              roomId: 'kamernummer',
              state: 'status van de kamer',
              username: 'Gemaakt doorjid',
              createTime: 'Tijd voor het maken van vergaderingen',
              closeTime: 'eindtijd van de bijeenkomst',
              duration: 'Duur'
          }
      },
      dialog: {
          title: 'Logboekregistratie van leden die de kamer betreden/verlaten',
          table: {
              th: {
                  username: 'Gemaakt doorjid',
                  joinTime: 'kom er bij',
                  leaveTime: 'vertrekken',
                  duration: 'Duur'
              }
          }
      }
  },
  callLog: {
      table: {
          th: {
              callerMzNum: 'bellend nummer',
              callTime: 'Bel tijd',
              endTime: 'Eindtijd',
              duration: 'Duur',
              responderMzNum: 'gebeld nummer'
          }
      }
  },
  roomStatus: {
      table: {
          th: {
              roomMessage: 'Basisinformatie over de kamer',
              Attendees: 'deelnemer',
              bystander: 'omstander',
              join: 'Toe te voegen',
              exited: 'Verlaten'
          }
      },
      roomNumber: 'Nummer vergaderruimte',
      name: 'Naam vergaderruimte',
      dissolution: 'Ontbinden',
      pattern: 'wijze',
      confluence: 'samenvloeiing',
      forward: 'Vooruit',
      createdTime: 'creatie tijd',
      limit: 'Vrijgave/totaal aantal personen limiet',
      createMode: 'patroon creëren',
      order: 'Afspraak (vergadering)',
      simple: 'Gewone multiplayer',
      type: 'Type vergadering',
      creator: 'Schepper',
      host: 'Gastheer:',
      windowMode: 'Raam modus:',
      WindowMode1: 'Eén grote en vele kleine',
      WindowMode2: 'Tegel',
      hide: 'Of het nu gaat om verbergen:',
      forbidden: 'Of we tarwe moeten verbieden:',
      inorder: 'volgorde:',
      source: 'bron:',
      joinTime: 'Deelnametijd:',
      comeOut: 'Kom alsjeblieft naar buiten',
      openMic: 'microfoon aanzetten',
      closeMic: 'Schakel de microfoon uit',
      leave: 'vertrekken:',
      peopleNumber: 'Aantal mensen:'
  },
  menu: {
      index: 'voorpagina',
      device_manage: 'Apparaatbeheer',
      user_manage: 'Gebruikersbeheer',
      conference_brand: 'Deurnummer conferentie',
      audit_manage: 'Auditbeheer',
      orgMessage: 'Bedrijfsinformatie',
      roomStatus: 'lopende vergadering',
      p2pCallLog: 'Geschiedenis van videogesprekken',
      conferenceLog: 'notulen van de vergadering',
      authorizedMessage: 'Autorisatie-informatie',
      title: 'Platform voor ondernemingsbeheer',
      account_mangae: 'Accountbeheer',
      contacts: 'Groepsadresboek',
      records: 'oproeprecords',
      LicenseInformation: 'Autorisatie-informatie',
      pushInfo: 'Informatie push',
      infoGroup: 'Push groepsbeheer'
  },
  download: 'apps downloaden',
  unauthorized: {
      title: 'ViiTALK ondernemingsbeheerplatform (niet geautoriseerd)',
      span_1: 'Er is gedetecteerd dat uw server de ViiTALK-service niet autoriseert,',
      span_2: 'Neem contact op met de klantenservice om het autorisatiebestand te verkrijgen.',
      span_3: '(Om het autorisatiebestand te verkrijgen, moet u uw server-IP-adres en Mac-adres doorgeven aan de klantenservice)',
      upError: 'Sorry, het bestand dat u heeft geüpload is onjuist. Upload het opnieuw',
      chooseFile_b: 'Selecteer een document',
      upFile_b: 'Autorisatiebestand uploaden',
      success: {
          span_1: 'Autorisatie succesvol',
          span_2: 'Het betreden van het enterprise managementplatform...',
          span_3: '(Als u lange tijd niet heeft ingelogd, vernieuw dan de browser)'
      },
      dialog: {
          title: 'Wijzig het beheerderswachtwoord',
          span: 'Uw beheerderswachtwoord is nog niet gewijzigd. Wijzig uw wachtwoord onmiddellijk',
          button: 'indienen'
      }
  },
  lang: {
    'zh-CN': 'Vereenvoudigd Chinees',
    'zh-TW': 'traditioneel Chinees',
    en: 'Engels',
    ja: 'Japans',
    ko: 'Koreaans',
    th: 'Thais',
    ar: 'Arabisch',
    bn: 'Bengaals',
    de: 'Duits',
    el: 'Grieks',
    es: 'Spaans',
    fa: 'Perzisch',
    fr: 'Frans',
    id: 'Indonesisch',
    it: 'Italiaans',
    iw: 'Hebreeuws',
    ku: 'Koerdisch',
    nl: 'Nederlands',
    pl: 'Pools',
    pt: 'Portugees',
    ro: 'Roemeense',
    ru: 'Russisch',
    tr: 'Turks',
    vi: 'Vietnamees'
  },
  authorized: {
      org: 'Geautoriseerde onderneming',
      userLimit: 'Gebruikerslimiet',
      roomMax: 'Maximale capaciteit van cloudconferentieruimte',
      roomCountLimit: 'Maximaal aantal online cloudvergaderruimtes',
      authorizedActiveDate: 'Activeringsdatum van de Private Cloud-licentie',
      authorizedValidDate: 'De private cloud-licentie is geldig tot',
      button_up: 'Autorisatie bijwerken',
      upFileError: 'Geen bestand geselecteerd'
  },
  index_title: 'ViiTalk Enterprise Management-platform',
  brand: {
    table: {
        th: {
            brandNumber: 'huisnummer',
            bindNumber: 'Bind groot schermnummer',
            onlineStatus: 'online status',
            lastLoginTime: 'laatste inlogtijd'
        },
        col: {
            content: {
                onlineStatus: {
                    online: 'online',
                    offline: 'Offline'
                }
            }
        }
    }
  }
}