// 中文
exports.default = {
  el: {
      colorpicker: {
          confirm: 'Επιβεβαιώνω',
          clear: 'Σαφή'
      },
      datepicker: {
          now: 'τώρα',
          today: 'σήμερα',
          cancel: 'Ματαίωση',
          clear: 'Σαφή',
          confirm: 'Επιβεβαιώνω',
          selectDate: 'Επιλέξτε ημερομηνία',
          selectTime: 'Επιλέξτε ώρα',
          startDate: 'ημερομηνία έναρξης',
          startTime: 'ώρα έναρξης',
          endDate: 'ημερομηνία λήξης',
          endTime: 'τέλος χρόνου',
          prevYear: 'Προηγούμενο έτος',
          nextYear: 'του χρόνου',
          prevMonth: 'τον προηγούμενο μήνα',
          nextMonth: 'τον επόμενο μήνα',
          year: 'Ετος',
          month1: 'Ιανουάριος',
          month2: 'Φεβρουάριος',
          month3: 'Μάρτιος',
          month4: 'Απρίλιος',
          month5: 'Ενδέχεται',
          month6: 'Ιούνιος',
          month7: 'Ιούλιος',
          month8: 'Αύγουστος',
          month9: 'Σεπτέμβριος',
          month10: 'Οκτώβριος',
          month11: 'Νοέμβριος',
          month12: 'Δεκέμβριος',
          weeks: {
              sun: 'ημέρα',
              mon: 'ένας',
              tue: 'δύο',
              wed: 'τρία',
              thu: 'Τέσσερα',
              fri: 'πέντε',
              sat: 'έξι'
          },
          months: {
              jan: 'Ιανουάριος',
              feb: 'Φεβρουάριος',
              mar: 'Μάρτιος',
              apr: 'Απρίλιος',
              may: 'Ενδέχεται',
              jun: 'Ιούνιος',
              jul: 'Ιούλιος',
              aug: 'Αύγουστος',
              sep: 'Σεπτέμβριος',
              oct: 'Οκτώβριος',
              nov: 'Νοέμβριος',
              dec: 'Δεκέμβριος'
          }
      },
      select: {
          loading: 'Φόρτωση',
          noMatch: 'Δεν υπάρχουν δεδομένα που να ταιριάζουν',
          noData: 'Καμία πληροφορία',
          placeholder: 'Παρακαλώ επιλέξτε'
      },
      cascader: {
          noMatch: 'Δεν υπάρχουν δεδομένα που να ταιριάζουν',
          loading: 'Φόρτωση',
          placeholder: 'Παρακαλώ επιλέξτε',
          noData: 'Καμία πληροφορία'
      },
      pagination: {
          goto: 'Παω σε',
          pagesize: 'Στοιχείο/σελίδα',
          total: '{total} στοιχεία συνολικά',
          pageClassifier: 'σελίδα'
      },
      messagebox: {
          title: 'ίχνος',
          confirm: 'καθορίσει',
          cancel: 'Ματαίωση',
          error: 'Οι πληροφορίες που εισάγονται δεν πληρούν τις προϋποθέσεις!'
      },
      upload: {
          deleteTip: 'Πατήστε το πλήκτρο διαγραφής για διαγραφή',
          delete: 'διαγράφω',
          preview: 'Προβολή εικόνων',
          continue: 'Συνεχίστε τη μεταφόρτωση'
      },
      table: {
          emptyText: 'Δεν υπάρχουν ακόμη πληροφορίες',
          confirmFilter: 'Φίλτρο',
          resetFilter: 'επαναφορά',
          clearFilter: 'όλα',
          sumText: 'Αθροισμα'
      },
      tree: {
          emptyText: 'Δεν υπάρχουν ακόμη πληροφορίες'
      },
      transfer: {
          noMatch: 'Δεν υπάρχουν δεδομένα που να ταιριάζουν',
          noData: 'Καμία πληροφορία',
          titles: [
              'Λίστα 1',
              'Λίστα 2'
          ],
          filterPlaceholder: 'Εισαγάγετε λέξη-κλειδί',
          noCheckedFormat: '{total} στοιχεία',
          hasCheckedFormat: '{checked}/{total} επιλεγμένο'
      },
      image: {
          error: 'Η φόρτωση απέτυχε'
      },
      pageHeader: {
          title: 'ΕΠΙΣΤΡΟΦΗ'
      },
      popconfirm: {
          confirmButtonText: 'Ναί',
          cancelButtonText: 'όχι'
      },
      empty: {
          description: 'Δεν υπάρχουν ακόμη πληροφορίες'
      }
  }
}
