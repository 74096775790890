<!-- eslint-disable no-undef -->

<template>
    <div>
      <el-button style="width: auto;" type="primary" :class="addDeviceButtonClass" @click="openAddDeviceDialog">
        {{ $t('device.button.add') }}
      </el-button>

      <el-button v-if="!isGroupMng" style="width: auto;" type="primary" :class="addDeviceButtonClass" @click="openGroupManagerDialog">
        {{ $t('device.button.setGroupMng') }}
      </el-button>
  
      <el-divider class="divider"></el-divider>
  
      <div class="flex-container">
  
        <el-card class="left-card card-format">
          <div class="input-group">
            <el-input :class="searchInputplaceholder" v-model="searchKeyword"
              :placeholder="$t('device.inputPlaceholder.search')">
            </el-input>
            <el-button class="opearte-button opearte-font opearte-border" @click="searchMember">
              {{ $t('device.button.search') }}
            </el-button>
          </div>
          <div class="tree-container">
  
            <el-tree :data="treeData" node-key="id" :props="defaultProps" @node-click="handleNodeClick"
              :expand-on-click-node="false" draggable :allow-drop="allowDrop" @node-drop="handleDrop"
              :default-expanded-keys="defaultExpandedKeys" ref="tree" :highlight-current="true">
  
              <span :class="data.parentId == 0 ? 'custom-tree-node tree-root-font' : 'custom-tree-node'"
                slot-scope="{ node, data }" @mouseenter="mouseenter(data)" @mouseleave="mouseleave(data)">
                <span>{{ node.label }}</span>


                <div style="display: flex; align-items: center; justify-content: space-between;" v-show="!isGroupMng">
  
                  <el-dropdown trigger="click" v-show="data.show" @click.native="handleCommand(data)"
                    @visible-change="handleVisibleChange" style="margin-top: 3px;">
                    <span class="el-dropdown-link">
                      <img src="../../assets/image/treeNodeOpearte.png">
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item v-show="data.parentId == 0 ? true : false"
                        @click.native="openAddGroupDialog(data)">{{ $t('device.group.add') }}</el-dropdown-item>
                      <el-dropdown-item v-show="data.parentId == 0 ? false : true"
                        @click.native="openEditGroupDialog(data)">{{ $t('device.group.edit') }}</el-dropdown-item>
                      <el-dropdown-item v-show="data.parentId == 0 ? false : true"
                        @click.native="openDelGroupDialog(data)">{{ $t('device.group.del') }}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
  
                  <span class="el-icon-share" v-show="data.show"></span>
                </div>
  
                
  
              </span>
            </el-tree>
  
          </div>
        </el-card>
  
        <el-row>
  
          <el-card class="right-card card-format">
  
            <el-button :class="adjustButtonClass" style="width: auto; padding-left: 15px; padding-right: 15px;" @click="openInfoPushSetPage()">
              {{ $t('device.button.setContent') }}
            </el-button>

            <!-- <el-button :class="adjustButtonClass" @click="openAdjustDeviceGroupDialog" :disabled=isButtonDisabled>
              {{ $t('device.button.adjust') }}
            </el-button> -->
  
            <el-button class="opearte-button opearte-font opearte-border" style="width: auto; padding-left: 15px; padding-right: 15px;" @click="openDelDeviceDialog"
              :disabled=isButtonDisabled>
              {{ $t('device.button.del') }}
            </el-button>

            <el-button :class="adjustButtonClass" style="width: auto; padding-left: 15px; padding-right: 15px;" @click="openStartNowDialog" :disabled=isButtonDisabled>
              {{ $t('device.button.startNow') }}
            </el-button>

            <el-button :class="adjustButtonClass" style="width: auto; padding-left: 15px; padding-right: 15px;" @click="openStopNowDialog" :disabled=isButtonDisabled>
              {{ $t('device.button.stopNow') }}
            </el-button>
  
            <el-table :data="tableData" class="table-col-font"
              :header-cell-style="{ background: '#FFFFFF', color: '#000000' }" @selection-change="handleSelectionChange">
              <el-table-column type="selection" width="55" header-align="center" align="center"></el-table-column>
              <el-table-column prop="nickName" :label="$t('device.table.th_name')" width="220"></el-table-column>
              <el-table-column prop="mzNumber" :label="$t('device.table.th_number')" width="180" header-align="center"
                align="center"></el-table-column>
              <el-table-column prop="lastTime" :label="$t('device.table.th_time')" header-align="center" align="center"
                width="200">
                <template slot-scope="scope">
                  {{ formatDateTime(scope.row.lastTime) }}
                </template>
              </el-table-column>
              <el-table-column prop="devModel" :label="$t('device.table.th_status')">
                <template slot-scope="scope">
                  {{ formatDevModel(scope.row.devModel) }}
                </template>
              </el-table-column>
              
            </el-table>
  
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="pageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="dataCount" class="pagination-right-aligned">
            </el-pagination>
  
  
  
  
          </el-card>
  
          
        </el-row>
  
      </div>
  
      <el-dialog :title="$t('device.dialog.addGroupTitle')" :visible.sync="addGroupDialogVisible" width="20%"
        @close="closeAddGroupDialog" center>
  
        <el-form :model="addGroupForm" status-icon :rules="addGroupRules" ref="addGroupForm" class="demo-ruleForm">
          <el-form-item prop="name">
            <el-input :placeholder="$t('device.dialog.inputPlaceholder.groupName')" v-model="addGroupForm.name"
              autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
  
        <span slot="footer" class="dialog-footer">
          <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" @click="closeAddGroupDialog"> {{ $t('button.cancel') }}</el-button>
          <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" type="primary" @click="addGroup"> {{ $t('button.ok') }}</el-button>
        </span>
  
      </el-dialog>
  
      <el-dialog :title="$t('device.dialog.delGroupTitle')" :visible.sync="delGroupDialogVisible" width="20%" center>
  
        <span>{{ $t('device.dialog.delGroupTips') }}</span>
  
        <span slot="footer" class="dialog-footer">
          <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" @click="delGroupDialogVisible = false">{{ $t('button.cancel') }}</el-button>
          <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" type="primary" @click="delGroup">{{ $t('button.ok') }}</el-button>
        </span>
  
      </el-dialog>
  
      <el-dialog :title="$t('device.dialog.editGroupTitle')" :visible.sync="editGroupDialogVisible" width="20%"
        @close="closeEditGroupDialog" center>
  
        <el-form :model="editGroupForm" status-icon :rules="addGroupRules" ref="editGroupForm" class="demo-ruleForm">
          <el-form-item prop="name">
            <el-input v-model="editGroupForm.name" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
  
        <span slot="footer" class="dialog-footer">
          <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" @click="closeEditGroupDialog">{{ $t('button.cancel') }}</el-button>
          <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" type="primary" @click="editGroup">{{ $t('button.ok') }}</el-button>
        </span>
  
      </el-dialog>
  
      <el-dialog :title="$t('device.dialog.addDevice.title')" :visible.sync="addDeviceDialogVisible" width="20%"
        @close="closeAddDeviceDialog" center>
  
        <el-form :model="addDeviceForm" status-icon :rules="addDeviceRules" ref="addDeviceForm" label-position="top">
  
          <!-- <el-form-item :label="$t('device.dialog.addDevice.label.name')" prop="name">
            <el-input v-model="addDeviceForm.name" :placeholder="$t('device.dialog.addDevice.inputPlaceholder.name')"
              autocomplete="off"></el-input>
          </el-form-item> -->
  
          <el-form-item :label="$t('device.dialog.addDevice.label.number')" prop="number">
            <el-input v-model="addDeviceForm.number" :placeholder="$t('device.dialog.addDevice.inputPlaceholder.number')"
              autocomplete="off"></el-input>
          </el-form-item>
  
          <el-form-item :label="$t('device.dialog.addDevice.label.group')">
            <el-select v-model="addDeviceForm.groupName" ref="selectUpResId">
              <el-option style="height: auto">
                <el-tree :data="filteredTreeData" node-key="id" :props="defaultProps" @node-click="addDeviceTreeNodeCilck"
                  :expand-on-click-node="false" :check-on-click-node="true">
                  <span class="custom-tree-node" slot-scope="{ node }">
                    <span>{{ node.label }}</span>
                  </span>
                </el-tree>
              </el-option>
            </el-select>
          </el-form-item>
  
        </el-form>
  
        <span slot="footer" class="dialog-footer">
          <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" @click="closeAddDeviceDialog"> {{ $t('button.cancel') }} </el-button>
          <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" type="primary" @click="addDevice">{{ $t('button.ok') }}</el-button>
        </span>
  
      </el-dialog>

      <el-dialog :title="$t('device.dialog.groupMng.title')" :visible.sync="addGroupManagerDialogVisible" width="20%"
        @close="closeGroupManagerDialog" center>
  
        <el-form :model="addGroupManagerForm" status-icon ref="addGroupManagerForm" label-position="top">
  
          <el-form-item :label="$t('device.dialog.groupMng.label.name')" prop="name">
            <el-input :disabled="addGroupManagerForm.abled" v-model="addGroupManagerForm.name" :placeholder="$t('device.dialog.groupMng.inputPlaceholder.name')"
              autocomplete="off"></el-input>
          </el-form-item>
  
          <el-form-item :label="$t('register.inputPlaceholder.password')" prop="number">
            <el-input v-model="addGroupManagerForm.pwd" :placeholder="$t('register.inputPlaceholder.password')"
              autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item :label="$t('register.inputPlaceholder.rePassword')" prop="number">
            <el-input v-model="addGroupManagerForm.rePwd" :placeholder="$t('register.inputPlaceholder.rePassword')"
              autocomplete="off"></el-input>
          </el-form-item>
  
          <el-form-item :label="$t('device.dialog.addDevice.label.group')">
            <el-select v-model="addGroupManagerForm.groupName" ref="selectUpResId">
              <el-option style="height: auto">
                <el-tree :data="filteredTreeData" node-key="id" :props="defaultProps" @node-click="addGroupManagerTreeNodeCilck"
                  :expand-on-click-node="false" :check-on-click-node="true">
                  <span class="custom-tree-node" slot-scope="{ node }">
                    <span>{{ node.label }}</span>
                  </span>
                </el-tree>
              </el-option>
            </el-select>
          </el-form-item>
  
        </el-form>
  
        <span slot="footer" class="dialog-footer">
          <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" @click="closeGroupManagerDialog"> {{ $t('button.cancel') }} </el-button>
          <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" type="primary" @click="addGroupManager">{{ $t('button.ok') }}</el-button>
        </span>
  
      </el-dialog>
  
      <el-dialog :title="$t('device.dialog.delDevice.title')" :visible.sync="delDeviceDialogVisible" width="20%"
        @close="closeDelGroupDialog" center>
  
        <span>{{ $t('device.dialog.delDevice.tips') }}</span>
  
        <span slot="footer" class="dialog-footer">
          <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" @click="delDeviceDialogVisible = false"> {{ $t('button.cancel') }}</el-button>
          <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" type="primary" @click="delDevice">{{ $t('button.ok') }}</el-button>
        </span>
  
      </el-dialog>

      <el-dialog :title="$t('device.dialog.startNowDevice.title')" :visible.sync="startNowDialogVisible" width="20%"
        @close="closeDelGroupDialog" center>
  
        <span>{{ $t('device.dialog.startNowDevice.tips') }}</span>
  
        <span slot="footer" class="dialog-footer">
          <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" @click="startNowDialogVisible = false"> {{ $t('button.cancel') }}</el-button>
          <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" type="primary" @click="startNow">{{ $t('button.ok') }}</el-button>
        </span>
  
      </el-dialog>

      <el-dialog :title="$t('device.dialog.stopNowDevice.title')" :visible.sync="stopNowDialogVisible" width="20%"
        @close="closeDelGroupDialog" center>
  
        <span>{{ $t('device.dialog.stopNowDevice.tips') }}</span>
  
        <span slot="footer" class="dialog-footer">
          <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" @click="stopNowDialogVisible = false"> {{ $t('button.cancel') }}</el-button>
          <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" type="primary" @click="stopNow">{{ $t('button.ok') }}</el-button>
        </span>
  
      </el-dialog>
  
      <el-dialog :title="$t('device.dialog.adjustTitle')" :visible.sync="adjustDeviceGroupDialogVisible" width="20%"
        @close="closeDelGroupDialog" center>
  
        <el-tree :data="filteredTreeData" node-key="id" :props="defaultProps" @node-click="adjustDeviceGroupTreeNodeCilck"
          :expand-on-click-node="false" :check-on-click-node="true">
          <span class="custom-tree-node" slot-scope="{ node }">
            <span>{{ node.label }}</span>
          </span>
        </el-tree>
  
        <span slot="footer" class="dialog-footer">
          <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" @click="adjustDeviceGroupDialogVisible = false">{{ $t('button.cancel') }}</el-button>
          <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" type="primary" @click="adjustDeviceGroup">{{ $t('button.ok') }}</el-button>
        </span>
  
      </el-dialog>
  
      <el-dialog :title="$t('device.dialog.editDevice.title')" :visible.sync="editDeviceNameDialogVisible" width="20%"
        @close="closeEditDeviceNameDialog" center>
  
        <el-form :model="editDeviceForm" status-icon :rules="editDeviceRules" ref="editDeviceForm" class="demo-ruleForm">
          <el-form-item prop="name">
            <el-input :placeholder="$t('device.dialog.editDevice.inputPlaceholder.name')" v-model="editDeviceForm.name"
              autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
  
        <span slot="footer" class="dialog-footer">
          <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" @click="closeEditDeviceNameDialog">{{ $t('button.cancel') }}</el-button>
          <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" type="primary" @click="editDeviceName">{{ $t('button.ok') }}</el-button>
        </span>
  
      </el-dialog>
  
      <el-dialog :title="$t('device.dialog.noChildren.title')" :visible.sync="noChildrenDialogVisible" width="20%"
        @close="closeNoChildrenDialog" center>
  
        <span>{{ $t('device.dialog.noChildren.tips') }}</span>
  
        <span slot="footer" class="dialog-footer">
          <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" @click="noChildrenDialogVisible = false"> {{ $t('button.cancel') }}</el-button>
          <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" type="primary" @click="closeNoChildrenDialog">{{ $t('button.ok') }}</el-button>
        </span>
  
      </el-dialog>
  
    </div>
  </template>
  
  <script>
  import { addrbook } from '../js/infoGroup.js'
  import { mapGetters, mapMutations } from 'vuex';
  export default {
    computed: {
      ...mapGetters(['isGroupMng'])
    },
    mixins: [addrbook]
  }
  </script>
  
  <style src="../../assets/css/dialog.css" scoped></style>
  
  <style src="../../assets/css/tree.css" scoped></style>
  
  <style src="../../assets/css/addrbook.css" scoped></style>