// 中文
exports.default = {
  el: {
      colorpicker: {
          confirm: 'تایید',
          clear: 'پاک کردن'
      },
      datepicker: {
          now: 'اکنون',
          today: 'امروز',
          cancel: 'لغو کنید',
          clear: 'پاک کردن',
          confirm: 'تایید',
          selectDate: 'تاریخ را انتخاب کنید',
          selectTime: 'زمان را انتخاب کنید',
          startDate: 'تاریخ شروع',
          startTime: 'زمان شروع',
          endDate: 'تاریخ پایان',
          endTime: 'زمان پایان',
          prevYear: 'پارسال',
          nextYear: 'سال آینده',
          prevMonth: 'ماه گذشته',
          nextMonth: 'ماه آینده',
          year: 'سال',
          month1: 'ژانویه',
          month2: 'فوریه',
          month3: 'مارس',
          month4: 'آوریل',
          month5: 'ممکن است',
          month6: 'ژوئن',
          month7: 'جولای',
          month8: 'مرداد',
          month9: 'سپتامبر',
          month10: 'اکتبر',
          month11: 'نوامبر',
          month12: 'دسامبر',
          weeks: {
              sun: 'روز',
              mon: 'یکی',
              tue: 'دو',
              wed: 'سه',
              thu: 'چهار',
              fri: 'پنج',
              sat: 'شش'
          },
          months: {
              jan: 'ژانویه',
              feb: 'فوریه',
              mar: 'مارس',
              apr: 'آوریل',
              may: 'ممکن است',
              jun: 'ژوئن',
              jul: 'جولای',
              aug: 'مرداد',
              sep: 'سپتامبر',
              oct: 'اکتبر',
              nov: 'نوامبر',
              dec: 'دسامبر'
          }
      },
      select: {
          loading: 'بارگذاری',
          noMatch: 'هیچ داده منطبقی وجود ندارد',
          noData: 'بدون اطلاعات',
          placeholder: 'لطفا انتخاب کنید'
      },
      cascader: {
          noMatch: 'هیچ داده منطبقی وجود ندارد',
          loading: 'بارگذاری',
          placeholder: 'لطفا انتخاب کنید',
          noData: 'بدون اطلاعات'
      },
      pagination: {
          goto: 'رفتن به',
          pagesize: 'مورد/صفحه',
          total: 'در مجموع {total} مورد',
          pageClassifier: 'صفحه'
      },
      messagebox: {
          title: 'اشاره',
          confirm: 'تعیین کنند',
          cancel: 'لغو کنید',
          error: 'اطلاعات وارد شده مطابق با الزامات نیست!'
      },
      upload: {
          deleteTip: 'برای حذف کلید حذف را فشار دهید',
          delete: 'حذف',
          preview: 'مشاهده تصاویر',
          continue: 'به آپلود ادامه دهید'
      },
      table: {
          emptyText: 'هنوز اطلاعاتی وجود ندارد',
          confirmFilter: 'فیلتر کنید',
          resetFilter: 'تنظیم مجدد',
          clearFilter: 'همه',
          sumText: 'مجموع'
      },
      tree: {
          emptyText: 'هنوز اطلاعاتی وجود ندارد'
      },
      transfer: {
          noMatch: 'هیچ داده منطبقی وجود ندارد',
          noData: 'بدون اطلاعات',
          titles: [
              'لیست 1',
              'لیست 2'
          ],
          filterPlaceholder: 'کلمه کلیدی را وارد کنید',
          noCheckedFormat: '{total} مورد',
          hasCheckedFormat: '{checked}/{total} بررسی شد'
      },
      image: {
          error: 'بارگیری انجام نشد'
      },
      pageHeader: {
          title: 'برگشت'
      },
      popconfirm: {
          confirmButtonText: 'آره',
          cancelButtonText: 'نه'
      },
      empty: {
          description: 'هنوز اطلاعاتی وجود ندارد'
      }
  }
}
