import { methodGet } from '@/api'

export const conferenceBrand = {
  data() {
    return {
      brandDataCount: 0,
      brandPageSize: 10,
      brandPageIndex: 1,
      listBrand: [],
      brandTableData: [],
    }
  },
  created() {
      this.getBrandList()
  },
  methods: {
    formatDateTime(timestamp) {
      if (timestamp == undefined || timestamp == null || timestamp == '') {
        return ''
      }
      const date = new Date(timestamp) // 将时间戳转换为毫秒  
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2); // 月份从0开始，所以要加1  
      const day = ('0' + date.getDate()).slice(-2);
      const hours = ('0' + date.getHours()).slice(-2);
      const minutes = ('0' + date.getMinutes()).slice(-2);
      const seconds = ('0' + date.getSeconds()).slice(-2); // 添加秒数  
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    formatOnline(online){
      if(online){
        return this.$t('brand.table.col.content.onlineStatus.online')
      }else{
        return this.$t('brand.table.col.content.onlineStatus.offline')
      }
    },
    handleBrandSizeChange(newSize) {
      this.brandPageIndex = newSize
      this.computeBrandPage()
    },
    handleBrandCurrentChange(newPage) {
      this.brandPageIndex = newPage
      this.computeBrandPage()
    },
    computeBrandPage() {
      if (this.listBrand == null) {
        this.brandDataCount = 0
        this.brandTableData = []
      } else {
        this.brandDataCount = this.listBrand.length
        let start = (this.brandPageIndex - 1) * this.brandPageSize
        start = start > this.brandDataCount ? this.brandDataCount : start
        let end = Number(this.brandPageSize) + start
        end = end > this.brandDataCount ? this.brandDataCount : end
        this.brandTableData = this.listBrand.slice(start, end)
      }
    },
    getBrandList() {
      methodGet(`api/addrbook/listBrand`).then((data) => {
        if (data.status) {
          this.listBrand = data.data
          this.computeBrandPage()
        } else {
          this.$message.error(this.$t('serverError'))
        }
      })
    }
  }
}

