// 中文
exports.default = {
  el: {
      colorpicker: {
          confirm: 'Confirmar',
          clear: 'Claro'
      },
      datepicker: {
          now: 'ahora',
          today: 'hoy',
          cancel: 'Cancelar',
          clear: 'Claro',
          confirm: 'Confirmar',
          selectDate: 'Seleccione fecha',
          selectTime: 'Seleccionar hora',
          startDate: 'fecha de inicio',
          startTime: 'hora de inicio',
          endDate: 'fecha final',
          endTime: 'hora de finalización',
          prevYear: 'Año anterior',
          nextYear: 'el próximo año',
          prevMonth: 'el mes pasado',
          nextMonth: 'próximo mes',
          year: 'Año',
          month1: 'Enero',
          month2: 'Febrero',
          month3: 'Marzo',
          month4: 'Abril',
          month5: 'Puede',
          month6: 'Junio',
          month7: 'Julio',
          month8: 'Agosto',
          month9: 'Septiembre',
          month10: 'Octubre',
          month11: 'Noviembre',
          month12: 'Diciembre',
          weeks: {
              sun: 'día',
              mon: 'uno',
              tue: 'dos',
              wed: 'tres',
              thu: 'cuatro',
              fri: 'cinco',
              sat: 'seis'
          },
          months: {
              jan: 'Enero',
              feb: 'Febrero',
              mar: 'Marzo',
              apr: 'Abril',
              may: 'Puede',
              jun: 'Junio',
              jul: 'Julio',
              aug: 'Agosto',
              sep: 'Septiembre',
              oct: 'Octubre',
              nov: 'Noviembre',
              dec: 'Diciembre'
          }
      },
      select: {
          loading: 'Cargando',
          noMatch: 'No hay datos coincidentes',
          noData: 'Sin información',
          placeholder: 'Por favor seleccione'
      },
      cascader: {
          noMatch: 'No hay datos coincidentes',
          loading: 'Cargando',
          placeholder: 'Por favor seleccione',
          noData: 'Sin información'
      },
      pagination: {
          goto: 'Ir a',
          pagesize: 'Artículo/página',
          total: '{total} artículos en total',
          pageClassifier: 'página'
      },
      messagebox: {
          title: 'pista',
          confirm: 'determinar',
          cancel: 'Cancelar',
          error: '¡La información ingresada no cumple con los requisitos!'
      },
      upload: {
          deleteTip: 'Presione la tecla Eliminar para eliminar',
          delete: 'borrar',
          preview: 'Ver fotos',
          continue: 'Continuar subiendo'
      },
      table: {
          emptyText: 'Aún no hay información',
          confirmFilter: 'Filtrar',
          resetFilter: 'reiniciar',
          clearFilter: 'todo',
          sumText: 'Suma'
      },
      tree: {
          emptyText: 'Aún no hay información'
      },
      transfer: {
          noMatch: 'No hay datos coincidentes',
          noData: 'Sin información',
          titles: [
              'Lista 1',
              'Lista 2'
          ],
          filterPlaceholder: 'Inserte palabra clave',
          noCheckedFormat: '{total} artículos',
          hasCheckedFormat: '{checked}/{total} marcado'
      },
      image: {
          error: 'Carga fallida'
      },
      pageHeader: {
          title: 'devolver'
      },
      popconfirm: {
          confirmButtonText: 'Sí',
          cancelButtonText: 'No'
      },
      empty: {
          description: 'Aún no hay información'
      }
  }
}